import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "vn",
  data: {
    DROP_DOWN: {
      SETTING: "Cài đặt",
      MANAGE_API_KEY: "Quản lý API key",
      PROFILE: "Tài khoản",
      BILLING: "Lịch sử thanh toán",
      ORGANIZATION: "Tổ chức",
      PAYMENT: "Mua hạn mức",
      LOG_OUT: "Đăng xuất",
    },
    PAGE_REST: "trang còn lại",
  },
};
