<div class="content-wrapper container-xxxl p-0 card mb-0">
    <div class="content-body card-body">
        <div class="d-flex" id="h-recommend">
            <div class="col-md-4" style="overflow-y: auto;">
                <div>

                    <h2 [translate]="'TALENT_POOL.RECOMMEND_RESUME.RECOMMENDED_RESUMES'"></h2>
                    <span [translate]="'TALENT_POOL.RECOMMEND_RESUME.FOR_THE_JOB_OF'"></span> <a
                        routerLink="/manage-job/detail-job/{{jd_id}}"> {{jobTitle}}</a>
                    <p> <a routerLink="/manage-job/saved-cvs/{{jd_id}}"> {{items}} <span
                                [translate]="'TALENT_POOL.SEARCH_RESUME.CANDIDATES'"></span></a>&nbsp; <span
                            [translate]="'TALENT_POOL.RECOMMEND_RESUME.SAVED'"></span></p>
                    <div>
                        <form ngNativeValidate #NewSearchForm="ngForm" (ngSubmit)="(NewSearchForm.form.valid)">
                            <!-- <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="job_title"><b>JobTitle</b></label>
                                </div>
                                <div>
                                    <input
                                    [(ngModel)]="job_title"
        
                                    type="text"
                                    class="form-control"
                                    id="job_title"
                                    name="job_title"
                                    placeholder="Enter job title"
                                />
                                </div>
                            </div> -->
                            <!-- <div>
                                <h4 [translate]="'TALENT_POOL.RECOMMEND_RESUME.SEARCH_REQUIRED'"></h4>
                            </div>
                            <div class="form-group">
                                <div class="col-form-label">
                                    <label for="job_title"><b  [translate]="'TALENT_POOL.RECOMMEND_RESUME.TIME'"></b></label>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <input
                                        [(ngModel)]="from"
                                        bsDatepicker
                                        type="text"
                                        class="form-control"
                                        id="from"
                                        name="from"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                        autocomplete="off"
                                        [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.FROM'|translate"
                                        
                                    />
                                    </div>
                                    <div>
                                        -
                                    </div>
                                    <div>
                                        <input
                                        [(ngModel)]="to"
                                        bsDatepicker
                                        type="text"
                                        class="form-control"
                                        id="to"
                                        name="to"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }"
                                        autocomplete="off"
                                        [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.TO'|translate"
                                        
                                    />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="selectedCity"><b [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'"></b></label>
                                </div>
                                <div>
                                    <ng-select [items]="city" bindLabel="name" bindValue="value"
                                    [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_ADDRESS'|translate" name="selectedCity"
                                        [(ngModel)]="selectedCity" [multiple]="true"
                                        
                                        >

                                    </ng-select>
                                    
                                </div>
                            </div> -->
                            <div>
                                <h4 [translate]="'TALENT_POOL.RECOMMEND_RESUME.SEARCH_OPTION'"></h4>
                            </div>
                            <!-- <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="fulltext_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.FULLTEXT_FILTER'"></b></label>
                                </div>
                                <div>
                                    <textarea rows="2" [(ngModel)]="fulltext_bool_expression" type="text" class="form-control"
                                        id="fulltext_bool_expression" name="fulltext_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"></textarea>
                                </div>
                            </div> -->
                            
                            <!-- <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="experience_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.EXPERIENCE_FILTER'"></b></label>
                                </div>
                                <div>
                                    <textarea rows="2" [(ngModel)]="experience_bool_expression" type="text" class="form-control"
                                        id="experience_bool_expression" name="experience_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="education_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.EDUCATION_FILTER'"></b></label>
                                </div>
                                <div>
                                    <textarea rows="2" [(ngModel)]="education_bool_expression" type="text" class="form-control"
                                        id="education_bool_expression" name="education_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"
                                        style="width: 100%;"></textarea>
                                </div>
                            </div> -->
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="current_title_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.CURRENT_TITLE_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [title]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="customTitle" [addTag]="true" multiple="true"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"
                                        [(ngModel)]="current_title_bool_expression" name="current_title_bool_expression">
                                    </ng-select>

                                </div>
                                
                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="current_company_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.CURRENT_COMPANY_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [title]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="customCompany" [addTag]="true" multiple="true"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"
                                        [(ngModel)]="current_company_bool_expression" name="current_company_bool_expression">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="full_name_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.FULLNAME_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [title]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <input (keydown.enter)="onSubmit()" [(ngModel)]="full_name_bool_expression" type="text" class="form-control"
                                        id="full_name_bool_expression" name="full_name_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"/>
                                </div>
                            </div>

                            <div class="mt-2">
                                <button type="button" style="height: 38px;padding:6px" (click)="onSubmit()" class="btn btn-primary"
                                    rippleEffect> <span [translate]="'TALENT_POOL.RECOMMEND_RESUME.RECOMMEND'"></span>
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-8 position-relative" style="overflow-y: scroll;">
                <form [formGroup]="saveCandidatesForm">
                    <div *ngIf="loading;else noLoading" id="loading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                    <ng-template #noLoading>
                        <div *ngIf="checkSearch">
                            <div class=" mb-2"> <span>
                                    {{num_result}} </span> <span
                                    [translate]="'TALENT_POOL.SEARCH_RESUME.RESUMES'"></span>
                            </div>
                            <div class="mb-2" *ngIf="resultRecommendCvs.length>0">
                                <div class="d-flex align-items-center">
                                    <div class="col-1">
                                        <input *ngIf="idCandidates.length==resultRecommendCvs.length;else noCheckedAll"
                                            value="true" (change)="unSelectAll($event)" type="checkbox"
                                            class="form-check" checked>
                                        <ng-template #noCheckedAll>
                                            <input value="false" (change)="selectAll($event)" type="checkbox"
                                                class="form-check">
                                        </ng-template>
                                    </div>
                                    <div>
                                        <label class="" for="select-all"
                                            translate="TALENT_POOL.SEARCH_RESUME.SELECT_ALL">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2"
                                *ngFor="let resultRecommendCvs_ of resultRecommendCvs| paginate: { itemsPerPage: pageSizeNum, currentPage: page_num, totalItems: num_result }; let i=index">
                                <div class="col-12 d-flex align-items-center">
                                    <div class="col-1">
                                        <input *ngIf="idCandidates.includes(resultRecommendCvs_.id);else noChecked"
                                            [value]="resultRecommendCvs_.id" (change)="onCheckboxChange($event)"
                                            type="checkbox" class="form-check-input" checked>
                                        <ng-template #noChecked>
                                            <input [value]="resultRecommendCvs_.id" (change)="onCheckboxChange($event)"
                                                type="checkbox" class="form-check-input">
                                        </ng-template>
                                    </div>
                                    <div class="card card-employee-task col-11" style="border: 1px solid #dedede;">
                                        <div class="card-body">
                                            <app-resume-talent-pool (newItemEvent)="addItem($event)"
                                                [ResultSearchCv]="resultRecommendCvs_"
                                                [saved]="resultRecommendCvs_.saved" [jd_id]="jd_id"
                                                [hcm_system_jd]="hcm_system_jd" [formData]="formData"></app-resume-talent-pool>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <pagination-controls
                                        (pageChange)="pageChangeEventGetCv($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="popup" [@slideFade]="showActionsPopup ? 'visible' : 'hidden'">
    <div class="button-container" *ngIf="showActionsPopup">
        <button type="button" class="btn btn-link action-btn rounded-left" style="margin: 0"
            (click)="unselectAllRows()">
            <span data-feather="x"></span>
        </button>

        <div>
            <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
                id="dropdownMenuButton" rippleEffect (click)="SaveResumeByJd()">
                <div style="margin: 0 auto 5px auto">
                    <span data-feather="save"></span>
                </div>
                <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.SAVE'"></span>
            </button>
        </div>
    </div>
</div>