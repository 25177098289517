import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { PaymentService } from '../services/payment.service';
import { locale as billingEn } from "./../i18n/en";
import { locale as billingVn } from "./../i18n/vn";
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BillingComponent implements OnInit {
  public basicSelectedOption: number = 5;
  public ColumnMode = ColumnMode;
  widthColumn: number = 0;
  public billingRows = []
  public isLoading = false
  @ViewChild(DatatableComponent) tableBilling: DatatableComponent;
  constructor(
    private _coreTranslateService: CoreTranslationService,
    private elementRef: ElementRef,
    private _paymentService: PaymentService,

  ) {
    this._coreTranslateService.translate(billingEn);
    this._coreTranslateService.translate(billingVn);
   }
  getBilling(){
    this.isLoading = true
    this._paymentService.getBilling().subscribe(
      (res)=>{
        this.billingRows = res.results;
        console.log("res",res)
        this.isLoading = false
      },
      (err)=>{
        console.error("Error: "+err)
        this.isLoading = false
      }
    )
  }
  ngOnInit(): void {
    this.getBilling();
    const resizeTableObserver = new ResizeObserver((entries) => {
      this.widthColumn = entries[0].contentRect.width / 6;
    });
    resizeTableObserver.observe(
      this.elementRef.nativeElement.querySelector("#datatable-billing")
    );
  }

}
