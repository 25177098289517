<!-- Vertical Menu -->
<ng-container *ngIf="layout === 'vertical' && currentUser.role">
  <ng-container *ngFor="let item of menu">
    <!-- section -->
    <li
      core-menu-vertical-section
      *ngIf="
        item.type == 'section' && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
      [item]="item"
      class="navigation-header"
    ></li>

    <!-- sub-menu item/collapsible of sections  -->
    <ng-container *ngIf="item.type == 'section'">
      <ng-container *ngFor="let item of item.children">
        <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
        <li
          core-menu-vertical-item
          *ngIf="
            item.type == 'item' && (item.permissions?item.permissions == tag_resultConnectSf4c_exit:false||!item.permissions) && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
            ||(item.type == 'item' && currentUser.is_superuser && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined))
            "
          [item]="item"
          [ngClass]="{ disabled: item.disabled === true }"
          [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
          [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        >
          <!-- Fix: Required routerLink to identify the active -->
          <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
        </li>
        <!-- collapsible -->
        <li
          core-menu-vertical-collapsible
          *ngIf="
            item.type == 'collapsible' &&
            (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
          "
          [item]="item"
          class="nav-item has-sub"
        ></li>
      </ng-container>
    </ng-container>

    <!-- item -->
    <li
      core-menu-vertical-item
      *ngIf="
        (item.type == 'item' && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined))
        
      "
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>
    <!-- collapsible -->
    <li
      core-menu-vertical-collapsible
      *ngIf="
        item.type == 'collapsible' &&
        (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
      [item]="item"
      class="nav-item has-sub"
    ></li>
  </ng-container>
  <ng-container>
    <div id="package" class="position-absolute w-100 my-2" *ngIf="!currentUser.is_superuser && currentUser.role!='User'">
      <div class="mx-2">
        <div>
          <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.PACKAGE_NAME'"></span>: <span class="font-weight-bolder">{{companyActivityCurrent?.package?.name?.replaceAll('_',' ')}}</span>
        </div>
        <div>
          <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.EXPIRY_DATE'"></span>: <span class="font-weight-bolder">{{companyActivityCurrent?.expiry_date|date:'dd/MM/yyyy'}}</span>
        </div> 
        <!-- <div *ngIf="currentUser.role!='User'">
          <a class="btn btn-primary w-100" [routerLink]="'/pricing'" [translate]="'DASHBOARD.UPGRADE_PACKAGE'"></a>
        </div> -->
      </div>
      
    </div>
  </ng-container>
</ng-container>
<!--/ Vertical Menu -->

<!-- Horizontal Menu -->
<ng-container *ngIf="layout === 'horizontal' && currentUser">
  <ng-container *ngFor="let item of menu">
    <!-- section -->
    <li
      core-menu-horizontal-collapsible
      *ngIf="
        item.type == 'section' && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
      [item]="item"
      class="dropdown nav-item"
    ></li>

    <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
    <li
      core-menu-horizontal-item
      *ngIf="
        item.type == 'item' && (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>

    <!-- collapsible -->
    <li
      core-menu-horizontal-collapsible
      *ngIf="
        item.type == 'collapsible' &&
        (item.role ? item.role.includes(currentUser.role) : false || item.role == undefined)
      "
      [item]="item"
      class="dropdown dropdown-submenu"
    ></li>
  </ng-container>
  <ng-container>
    <div id="package" class="position-absolute w-100 my-2" *ngIf="!currentUser.is_superuser && currentUser.role!='User'">
      <div class="mx-2">
        <div>
          <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.PACKAGE_NAME'"></span>: <span class="font-weight-bolder">{{companyActivityCurrent?.package?.name?.replaceAll('_',' ')}}</span>
        </div>
        <div>
          <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.EXPIRY_DATE'"></span>: <span class="font-weight-bolder">{{companyActivityCurrent?.expiry_date|date:'dd/MM/yyyy'}}</span>
        </div> 
        <!-- <br> -->
        <!-- <li>
          <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.EXPIRY_DATE'"></span>: <span class="font-weight-bolder">{{companyActivityCurrent.expiry_date|date:'dd/MM/yyyy'}}</span>
        </li> -->
        <!-- <div *ngIf="currentUser.role!='User'">
          <a class="btn btn-primary w-100" [routerLink]="'/pricing'" [translate]="'DASHBOARD.UPGRADE_PACKAGE'"></a>
        </div> -->
      </div>
      
    </div>
  </ng-container>
</ng-container>
<!--/ Horizontal Menu -->
