import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PaymentService } from './services/payment.service';
import { Router } from '@angular/router';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocale } from "./i18n/vn";
import { PaymentInfo } from './models/payment.model';
import { PackageService } from '../package/services/package.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '../resume-parser/models/paging.model';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {
  listSubscriptionPlan: any[] = []
  public pageSubscriptionPlan = new Page({});

  public no_full_service = false

  public qandA: any;

  Monthly = false;

  constructor(
    private _paymentService: PaymentService,
    private _router: Router,
    private _coreTranslateService: CoreTranslationService,
    private packageService: PackageService,
    private modalService: NgbModal,

  ) {
    this._coreTranslateService.translate(enLocale);
    this._coreTranslateService.translate(vnLocale);
  }

  ngOnInit(): void {
    this.qandA = [
      {
        question: "PAYMENT.FAQ.Q1",
        ans: "PAYMENT.FAQ.A1",
      },
      {
        question: "PAYMENT.FAQ.Q2",
        ans: "PAYMENT.FAQ.A2",
      },
    ];
    this.getListSubscriptionPlan()
  }
  getListSubscriptionPlan(){
    this.packageService.listSubscriptionPlan(this.pageSubscriptionPlan.pageNumber, this.pageSubscriptionPlan.size).subscribe(
      (res)=>{
        this.listSubscriptionPlan = res.results;
        const currentPageNumber = this.pageSubscriptionPlan.pageNumber;
        const currentPageSize = this.pageSubscriptionPlan.size;
        this.pageSubscriptionPlan = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        this.listSubscriptionPlan = res.results.sort((a,b)=>a.order-b.order);
      },
      (err)=>{
        console.log("err",err)
      }
    )
  }
  modalOpenContact(modalContact:any){
    this.modalService.open(modalContact, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  buyQuota(id: string, costId: string) {
    localStorage.setItem("id", id);
    localStorage.setItem("costId", costId);
    var paymentInfo = new PaymentInfo(id, costId);
    this._paymentService.setPaymentInfoValue(paymentInfo);
    this._router.navigate(["/pricing/order"]);
  }

}
