import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastrModule } from 'ngx-toastr';

const routes: Routes = [
  {
    path: "dashboard/analytic?id_company=:id_company",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard/analytic",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard/activity",
    component: AnalyticsComponent,
    canActivate: [AuthGuard],
    data: { animation: "dashboard" },
  }
]

@NgModule({
  declarations: [
    DashboardComponent,
    AnalyticsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgApexchartsModule,
    RouterModule.forChild(routes),
    NgbModule,
    CoreCommonModule,
    NgxPaginationModule,
    TranslateModule,
    NgxDatatableModule,
    ToastrModule
  ]
})
export class DashboardModule { }
