import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiKeyComponent } from './api-key.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: "token",
    component: ApiKeyComponent,
    canActivate: [AuthGuard]
  }
]


@NgModule({
  declarations: [
    ApiKeyComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    BsDatepickerModule,
    NgbModule
  ]
})
export class ApiKeyModule { }
