import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-detail-package',
  templateUrl: './detail-package.component.html',
  styleUrls: ['./detail-package.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailPackageComponent implements OnInit {
  @Input() detailPackage: any;
  cardVisible = true
  @Output() cardVisibleEvent = new EventEmitter<any>();
  constructor() { }
  closeCard() {
    // Logic to hide the card, e.g., setting a variable to control visibility
    this.cardVisible = false;
    this.cardVisibleEvent.emit(this.cardVisible)
  }
  ngOnInit(): void {


  }

}
