import { AuthResetPasswordV1Component } from 'app/main/pages/authentication/auth-reset-password-v1/auth-reset-password-v1.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiKeyService } from './services/api-key.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Page } from '../resume-parser/models/paging.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { User } from 'app/auth/models';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocal } from "./i18n/vn";

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApiKeyComponent implements OnInit {
  listApiKey: any[] = [];
  public page = new Page({});
  @ViewChild(DatatableComponent) tableRowApiKey: DatatableComponent;
  ColumnMode = ColumnMode;
  CreateApiKeyForm!: FormGroup;
  currentUser: User;
  passwordTextType: boolean=false;
  public submitted = false;
  minDate = new Date();
  constructor(
    private apiKeyService: ApiKeyService,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private modalService: NgbModal,
    private _clipboardService: ClipboardService,
    private _toastrService: ToastrService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.CreateApiKeyForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl('', Validators.required),
      token_name: new FormControl('', Validators.required),
      expired_time: new FormControl(null),
    });
   }
  getListApiKey(){
    this.apiKeyService.listApiKey().subscribe(
      (res)=>{
        this.listApiKey = res.results
        const currentPageNumber = this.page.pageNumber;
        const currentPageSize = this.page.size;
        this.page = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        console.log("err",err)
      }
    )
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.getListApiKey()
  }
  modalOpenCreateApiKey(modalApiKey:any){
    this.modalService.open(modalApiKey, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  createApiKey(){
    this.submitted = true;
    if(this.CreateApiKeyForm.invalid){
      return;
    }
    let formData: any = {}
    formData["email"] = this.currentUser.email
    formData["password"] = this.CreateApiKeyForm.value.password
    formData["token_name"] = this.CreateApiKeyForm.value.token_name
    formData["expired_time"] = this.CreateApiKeyForm.value.expired_time
    this.apiKeyService.obtainAccessToken(formData).subscribe(
      (res)=>{
        this.getListApiKey()
      },
      (err)=>{
        this._toastrService.warning(
          err.error.message,
        );
      }
    )
  }
  get f(){
    return this.CreateApiKeyForm.controls;
  }
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }
  deleteApiKey(id: any) {
    Swal.fire({
      title: this._translateService.instant("MODAL.DELETE_A"),
      text: this._translateService.instant("MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.apiKeyService.deleteApiKey(id).subscribe(
          (res) => {
            this._toastrService.success(
              this._translateService.instant("TOAST.DELETE_A_SUC"),
              this._translateService.instant("TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.getListApiKey();
          },
          (err) => {
            this._toastrService.error(
              this._translateService.instant("TOAST.ERROR"),
              this._translateService.instant("TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  copyToThisClipboard(content: string) {
    this._clipboardService.copy(content);
    this._toastrService.success(
      ``,
      this._translateService.instant("TOASTR.COPY_TO_CLIPBOARD"),
      this._translateService.instant("TOASTR.SUCCESS")
    );
  }
  ngOnInit(): void {
    this.getListApiKey();
    this.currentUser = this.authenticationService.currentUserValue;

  }

}
