<div class="content-wrapper container-xxxl p-0">
  <div>
    <div class="content-body mb-2">
      <form [formGroup]="FilterStatForm">
        <div class="d-flex">
          <div class="col-md pl-0" *ngIf="user.is_superuser">
            <select
              (keydown.enter)="onSubmit()"
              style="height: 2.714rem"
              id="company"
              class="form-control"
              (change)="changeCompany($event)"
              formControlName="company"
            >
              <option value="" [translate]="'DASHBOARD.ALL_COMPANY'"></option>
              <option *ngFor="let company of companys" [value]="company.id">
                {{ company.name }}
              </option>
            </select>
          </div>
          <div
            class="col-md pl-0"
            *ngIf="
              (user.is_superuser || user.role == 'Admin' || user.role == 'Super admin') &&
              subCompanys?.length > 0
            "
          >
            <select
              (keydown.enter)="onSubmit()"
              style="height: 2.714rem"
              id="sub_company"
              class="form-control"
              (change)="changeSubCompany($event)"
              formControlName="sub_company"
            >
              <option value="" [translate]="'DASHBOARD.CHOOSE_SUB_COMPANY'"></option>
              <option *ngFor="let sub_company of subCompanys" [value]="sub_company.id">
                {{ sub_company.name }}
              </option>
            </select>
          </div>
          <div class="col-md pl-0">
            <input
              (keydown.enter)="onSubmit()"
              id="period"
              formControlName="period"
              [placeholder]="'DASHBOARD.CHOOSE_PERIOD' | translate"
              bsDaterangepicker
              type="text"
              class="form-control"
              [bsConfig]="{ isAnimated: true, rangeInputFormat: 'DD-MM-YYYY' }"
              autocomplete="off"
            />
          </div>
          <div class="col-md pl-0">
            <button
              (click)="onSubmit()"
              class="btn btn-primary w-btn-dashboard"
              rippleEffect
              [translate]="'DASHBOARD.FILTER'"
            ></button>
            <button
              (click)="onReset()"
              class="btn btn-secondary ml-1 w-btn-dashboard"
              style="background-color: #6c757d"
              rippleEffect
              [translate]="'DASHBOARD.RESET'"
            ></button>
          </div>
          <div
            [ngClass]="
              user.is_superuser && subCompanys?.length > 0
                ? 'd-flex justify-content-end col-md':
                subCompanys?.length > 0 || user.is_superuser? 'd-flex justify-content-end col-md'

                : 'd-flex justify-content-end col-md'
            "
            style="padding-right: 0px"
          >
            <button
              *ngIf="tag_export; else noLoadingReport"
              class="btn btn-success btn-icon place-order"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              <span [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"></span>
            </button>
            <ng-template #noLoadingReport>
              <button
                (click)="downloadReport()"
                class="btn btn-success w-btn-dashboard"
                rippleEffect
                
              ><span data-feather="download"></span><span style="margin-left: 2px;" [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"></span></button>
            </ng-template>
          </div>
        </div>
      </form>
    </div>
    <div
      *ngIf="loading; else noLoading"
      id="loading"
      class="d-flex justify-content-center"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <ng-template #noLoading>
      <div class="row match-height">
        <div class="col-xl-6 col-12">
          <div class="card card-statistics">
            <div class="card-header flex-column align-items-start">
              <h4
                class="card-title mb-75"
                [translate]="'DASHBOARD.LIST_REPORT_RESUME_SEARCH'"
              ></h4>
              <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
            </div>
            <div class="card-body">
              <ul ngbNav #nav="ngbNav" class="nav nav-pills">
                <li ngbNavItem>
                  <a ngbNavLink (click)="tabIsMatchReportSearch(true, true)"><span [translate]="'TALENT_POOL.SEARCH_RESUME.MATCH'"></span></a>
                  <ng-template ngbNavContent>
                    <div class="tab-pane active" id="match-search" aria-labelledby="match-search-tab" role="tabpanel">
                      <ngx-datatable
                      id="table-matched-search"
                      #tableReportResumneSearch
                      class="bootstrap core-bootstrap"
                      [rows]="listReportResumeSearch"
                      [externalPaging]="true"
                      [count]="pageReportResumeSearch.totalElements"
                      [offset]="pageReportResumeSearch.pageNumber"
                      [limit]="10"
                      (page)="setPageReportResumeSearch($event)"
                      [rowHeight]="50"
                      class="bootstrap core-bootstrap"
                      [columnMode]="ColumnMode.force"
                      [headerHeight]="50"
                      [footerHeight]="50"
                      [scrollbarH]="true"
                      [messages]="{
                        emptyMessage:
                          'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                        totalMessage:
                          ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT'
                          | translate)
                      }"
                    >
                    <ngx-datatable-column
                        [name]="'DASHBOARD.TABLE.SEARCH_INFO' | translate"
                      >
                        <ng-template ngx-datatable-cell-template let-row="row">
                          <div class="w-100">
                            <a
                              [translate]="'DASHBOARD.TABLE.DETAIL'"
                              (click)="modalOpenInfoSearch(modalBasicInfoSearch)"
                              style="text-decoration: underline"
                              href="javascript:void(0)"
                            >
                              Xem thêm
                            </a>
                            <ng-template #modalBasicInfoSearch let-modal>
                              <div class="modal-header">
                                <h4
                                  class="modal-title"
                                  [translate]="
                                    'TALENT_POOL.DETAIL_CANDIDATE.WORK_EXPERIENCE'
                                  "
                                ></h4>
                                <button
                                  type="button"
                                  class="close"
                                  (click)="modal.dismiss('Cross click')"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="card-body" style="overflow-y: auto">
                                <div class="row">
                                  <div class="col-12 mb-1">
                                    <span
                                      class="text-dark"
                                      data-feather="calendar"
                                    ></span
                                    ><span style="width: fit-content">
                                      {{
                                        row.search_exp.created_at
                                          | date : 'd/M/yyyy, h:mm a'
                                      }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.full_name_bool_expression"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.FULLNAME_FILTER"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.full_name_bool_expression"
                                  >
                                    <span>{{
                                      row.search_exp?.full_name_bool_expression
                                    }}</span>
                                  </div>

                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.fulltext_bool_expression"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.FULLTEXT_FILTER"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.fulltext_bool_expression"
                                  >
                                    <span>{{
                                      row.search_exp?.fulltext_bool_expression
                                    }}</span>
                                  </div>

                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.experience_bool_expression"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.EXPERIENCE_FILTER"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.experience_bool_expression"
                                  >
                                    <span>{{
                                      row.search_exp?.experience_bool_expression
                                    }}</span>
                                  </div>

                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.education_bool_expression"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.EDUCATION_FILTER"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.education_bool_expression"
                                  >
                                    <span>{{
                                      row.search_exp?.education_bool_expression
                                    }}</span>
                                  </div>

                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="
                                      row.search_exp?.current_title_bool_expression
                                    "
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.CURRENT_TITLE_FILTER"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="
                                      row.search_exp?.current_title_bool_expression
                                    "
                                  >
                                    <span>{{
                                      row.search_exp?.current_title_bool_expression
                                    }}</span>
                                  </div>

                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="
                                      row.search_exp?.current_company_bool_expression
                                    "
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.CURRENT_COMPANY_FILTER"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="
                                      row.search_exp?.current_company_bool_expression
                                    "
                                  >
                                    <span>{{
                                      row.search_exp?.current_company_bool_expression
                                    }}</span>
                                  </div>
                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.category?.length > 0"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.CATEGORY"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.category?.length > 0"
                                  >
                                    <span>{{
                                      row.search_exp?.category.toString()
                                    }}</span>
                                  </div>
                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.skills?.length > 0"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.SKILL"
                                    ></span>
                                  </div>

                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.skills?.length > 0"
                                  >
                                    <span>{{
                                      row.search_exp?.skills.toString()
                                    }}</span>
                                  </div>
                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.keywords?.length > 0"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.CREATE_JOB.KEYWORDS"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.keywords?.length > 0"
                                  >
                                    <span>{{
                                      row.search_exp?.keywords.toString()
                                    }}</span>
                                  </div>
                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.user_talentpool"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.TALENT_POOL"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.user_talentpool"
                                  >
                                    <span>{{ row.search_exp?.user_talentpool }}</span>
                                  </div>
                                  <div class="col-4 mb-1">
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.YEARS_OF_EXPERIENCE"
                                    ></span>
                                  </div>
                                  <div class="col-8 mb-1">
                                    <div [ngSwitch]="true">
                                      <div
                                        *ngSwitchCase="
                                          (row.search_exp.years_of_experience_from ==
                                            0 ||
                                            row.search_exp.years_of_experience_from ==
                                              null) &&
                                          (row.search_exp.years_of_experience_to ==
                                            0 ||
                                            row.search_exp.years_of_experience_to ==
                                              null)
                                        "
                                      >
                                        <span
                                          [translate]="
                                            'TALENT_POOL.CREATE_JOB.NOT_REQUIRED'
                                          "
                                        ></span>
                                      </div>
                                      <div
                                        *ngSwitchCase="
                                          row.search_exp.years_of_experience_from !=
                                            null &&
                                          row.search_exp.years_of_experience_from !=
                                            0 &&
                                          row.search_exp.years_of_experience_to ==
                                            null
                                        "
                                      >
                                        <span
                                          [translate]="
                                            'TALENT_POOL.CREATE_JOB.MINIMUM'
                                          "
                                        ></span
                                        ><span>
                                          {{
                                            row.search_exp.years_of_experience_from
                                          }}
                                        </span>
                                        <span
                                          [translate]="
                                            'TALENT_POOL.CREATE_JOB.YEARS_OF_EXP'
                                          "
                                        ></span>
                                      </div>
                                      <div
                                        *ngSwitchCase="
                                          row.search_exp.years_of_experience_to !=
                                            null &&
                                          row.search_exp.years_of_experience_to !=
                                            0 &&
                                          row.search_exp.years_of_experience_from !=
                                            row.search_exp.years_of_experience_to
                                        "
                                      >
                                        <span>{{
                                          row.search_exp.years_of_experience_from || 0
                                        }}</span
                                        ><span>-</span
                                        ><span>{{
                                          row.search_exp.years_of_experience_to
                                        }}</span>
                                      </div>
                                      <div
                                        *ngSwitchCase="
                                          row.search_exp.years_of_experience_from ==
                                            row.search_exp.years_of_experience_to &&
                                          row.search_exp.years_of_experience_from !=
                                            null &&
                                            row.search_exp.years_of_experience_from !=
                                              0 &&
                                          row.search_exp.years_of_experience_to !=
                                            null &&
                                            row.search_exp.years_of_experience_to != 0
                                        "
                                      >
                                        <span>{{
                                          row.search_exp.years_of_experience_from
                                        }}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-4 mb-1"
                                    *ngIf="row.search_exp?.city?.length > 0"
                                  >
                                    <span
                                      style="font-weight: 800"
                                      translate="TALENT_POOL.SEARCH_RESUME.ADDRESS"
                                    ></span>
                                  </div>
                                  <div
                                    class="col-8 mb-1"
                                    *ngIf="row.search_exp?.city?.length > 0"
                                  >
                                    <span>{{ row.search_exp?.city.toString() }}</span>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="resume"
                        [name]="'TALENT_POOL.SEARCH_RESUME.RESUME' | translate"
                      >
                        <ng-template ngx-datatable-cell-template let-row="row">
                          <div class="w-100">
                            <a
                              routerLink="/manage-resume/detail-resume/{{
                                row.resume
                              }}"
                              class="w-100 text-primary"
                              target="_blank"
                              >{{ row.resume }}
                            </a>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                      
                      <ngx-datatable-column prop="created_by"
                        [name]="'TALENT_POOL.SEARCH_RESUME.CREATED_BY_EVALUATE' | translate"
                      >
                        <ng-template ngx-datatable-cell-template let-row="row">
                          <div class="w-100">
                            <span>{{ getEmailUser(row.created_by) }}</span>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column prop="created_at"
                        [name]="'TALENT_POOL.SEARCH_RESUME.CREATED_TIME_EVALUATE' | translate"
                      >
                        <ng-template ngx-datatable-cell-template let-row="row">
                          <div class="w-100">
                            <span>{{ row.created_at | date : 'dd/MM/yyyy' }}</span>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                      
                    </div>

                  </ng-template>
                </li>
                <li ngbNavItem>
                  <a ngbNavLink (click)="tabIsMatchReportSearch(false, true)"><span [translate]="'TALENT_POOL.SEARCH_RESUME.NOT_MATCH'"></span></a>
                  <ng-template ngbNavContent>
                    <div class="tab-pane" id="not-match-search" aria-labelledby="not-match-search-tab" role="tabpanel">
                      <ngx-datatable
                          id="table-not-match-search"
                          #tableReportResumneSearch
                          class="bootstrap core-bootstrap"
                          [rows]="listReportResumeSearch"
                          [externalPaging]="true"
                          [count]="pageReportResumeSearch.totalElements"
                          [offset]="pageReportResumeSearch.pageNumber"
                          [limit]="10"
                          (page)="setPageReportResumeSearch($event)"
                          [rowHeight]="50"
                          class="bootstrap core-bootstrap"
                          [columnMode]="ColumnMode.force"
                          [headerHeight]="50"
                          [footerHeight]="50"
                          [scrollbarH]="true"
                          [messages]="{
                            emptyMessage:
                              'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                            totalMessage:
                              ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT'
                              | translate)
                          }"
                        >
                        <ngx-datatable-column
                            [name]="'DASHBOARD.TABLE.SEARCH_INFO' | translate"
                          >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <a
                                  [translate]="'DASHBOARD.TABLE.DETAIL'"
                                  (click)="modalOpenInfoSearch(modalBasicInfoSearch)"
                                  style="text-decoration: underline"
                                  href="javascript:void(0)"
                                >
                                  Xem thêm
                                </a>
                                <ng-template #modalBasicInfoSearch let-modal>
                                  <div class="modal-header">
                                    <h4
                                      class="modal-title"
                                      [translate]="
                                        'TALENT_POOL.DETAIL_CANDIDATE.WORK_EXPERIENCE'
                                      "
                                    ></h4>
                                    <button
                                      type="button"
                                      class="close"
                                      (click)="modal.dismiss('Cross click')"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="card-body" style="overflow-y: auto">
                                    <div class="row">
                                      <div class="col-12 mb-1">
                                        <span
                                          class="text-dark"
                                          data-feather="calendar"
                                        ></span
                                        ><span style="width: fit-content">
                                          {{
                                            row.search_exp.created_at
                                              | date : 'd/M/yyyy, h:mm a'
                                          }}</span
                                        >
                                      </div>
                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.full_name_bool_expression"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.FULLNAME_FILTER"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.full_name_bool_expression"
                                      >
                                        <span>{{
                                          row.search_exp?.full_name_bool_expression
                                        }}</span>
                                      </div>

                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.fulltext_bool_expression"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.FULLTEXT_FILTER"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.fulltext_bool_expression"
                                      >
                                        <span>{{
                                          row.search_exp?.fulltext_bool_expression
                                        }}</span>
                                      </div>

                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.experience_bool_expression"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.EXPERIENCE_FILTER"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.experience_bool_expression"
                                      >
                                        <span>{{
                                          row.search_exp?.experience_bool_expression
                                        }}</span>
                                      </div>

                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.education_bool_expression"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.EDUCATION_FILTER"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.education_bool_expression"
                                      >
                                        <span>{{
                                          row.search_exp?.education_bool_expression
                                        }}</span>
                                      </div>

                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="
                                          row.search_exp?.current_title_bool_expression
                                        "
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.CURRENT_TITLE_FILTER"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="
                                          row.search_exp?.current_title_bool_expression
                                        "
                                      >
                                        <span>{{
                                          row.search_exp?.current_title_bool_expression
                                        }}</span>
                                      </div>

                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="
                                          row.search_exp?.current_company_bool_expression
                                        "
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.CURRENT_COMPANY_FILTER"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="
                                          row.search_exp?.current_company_bool_expression
                                        "
                                      >
                                        <span>{{
                                          row.search_exp?.current_company_bool_expression
                                        }}</span>
                                      </div>
                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.category?.length > 0"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.CATEGORY"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.category?.length > 0"
                                      >
                                        <span>{{
                                          row.search_exp?.category.toString()
                                        }}</span>
                                      </div>
                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.skills?.length > 0"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.SKILL"
                                        ></span>
                                      </div>

                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.skills?.length > 0"
                                      >
                                        <span>{{
                                          row.search_exp?.skills.toString()
                                        }}</span>
                                      </div>
                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.keywords?.length > 0"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.CREATE_JOB.KEYWORDS"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.keywords?.length > 0"
                                      >
                                        <span>{{
                                          row.search_exp?.keywords.toString()
                                        }}</span>
                                      </div>
                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.user_talentpool"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.TALENT_POOL"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.user_talentpool"
                                      >
                                        <span>{{ row.search_exp?.user_talentpool }}</span>
                                      </div>
                                      <div class="col-4 mb-1">
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.YEARS_OF_EXPERIENCE"
                                        ></span>
                                      </div>
                                      <div class="col-8 mb-1">
                                        <div [ngSwitch]="true">
                                          <div
                                            *ngSwitchCase="
                                              (row.search_exp.years_of_experience_from ==
                                                0 ||
                                                row.search_exp.years_of_experience_from ==
                                                  null) &&
                                              (row.search_exp.years_of_experience_to ==
                                                0 ||
                                                row.search_exp.years_of_experience_to ==
                                                  null)
                                            "
                                          >
                                            <span
                                              [translate]="
                                                'TALENT_POOL.CREATE_JOB.NOT_REQUIRED'
                                              "
                                            ></span>
                                          </div>
                                          <div
                                            *ngSwitchCase="
                                              row.search_exp.years_of_experience_from !=
                                                null &&
                                              row.search_exp.years_of_experience_from !=
                                                0 &&
                                              row.search_exp.years_of_experience_to ==
                                                null
                                            "
                                          >
                                            <span
                                              [translate]="
                                                'TALENT_POOL.CREATE_JOB.MINIMUM'
                                              "
                                            ></span
                                            ><span>
                                              {{
                                                row.search_exp.years_of_experience_from
                                              }}
                                            </span>
                                            <span
                                              [translate]="
                                                'TALENT_POOL.CREATE_JOB.YEARS_OF_EXP'
                                              "
                                            ></span>
                                          </div>
                                          <div
                                            *ngSwitchCase="
                                              row.search_exp.years_of_experience_to !=
                                                null &&
                                              row.search_exp.years_of_experience_to !=
                                                0 &&
                                              row.search_exp.years_of_experience_from !=
                                                row.search_exp.years_of_experience_to
                                            "
                                          >
                                            <span>{{
                                              row.search_exp.years_of_experience_from || 0
                                            }}</span
                                            ><span>-</span
                                            ><span>{{
                                              row.search_exp.years_of_experience_to
                                            }}</span>
                                          </div>
                                          <div
                                            *ngSwitchCase="
                                              row.search_exp.years_of_experience_from ==
                                                row.search_exp.years_of_experience_to &&
                                              row.search_exp.years_of_experience_from !=
                                                null &&
                                                row.search_exp.years_of_experience_from !=
                                                  0 &&
                                              row.search_exp.years_of_experience_to !=
                                                null &&
                                                row.search_exp.years_of_experience_to != 0
                                            "
                                          >
                                            <span>{{
                                              row.search_exp.years_of_experience_from
                                            }}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="col-4 mb-1"
                                        *ngIf="row.search_exp?.city?.length > 0"
                                      >
                                        <span
                                          style="font-weight: 800"
                                          translate="TALENT_POOL.SEARCH_RESUME.ADDRESS"
                                        ></span>
                                      </div>
                                      <div
                                        class="col-8 mb-1"
                                        *ngIf="row.search_exp?.city?.length > 0"
                                      >
                                        <span>{{ row.search_exp?.city.toString() }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </ng-template>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column prop="resume"
                            [name]="'TALENT_POOL.SEARCH_RESUME.RESUME' | translate"
                          >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <a
                                  routerLink="/manage-resume/detail-resume/{{
                                    row.resume
                                  }}"
                                  class="w-100 text-primary"
                                  target="_blank"
                                  >{{ row.resume }}
                                </a>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column prop="comment"
                            width="200"
                            [name]="'TALENT_POOL.SEARCH_RESUME.COMMENT' | translate"
                          >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div
                                class="w-100"
                                container="body"
                                [ngbTooltip]="row.comment"
                              >
                                {{ row?.comment?.slice(0, 30) }} <br />
                                <span *ngIf="row?.comment?.length > 30"
                                  >{{ row?.comment?.slice(30, 2 * 30) }} <br
                                /></span>
                                <span *ngIf="row?.comment?.length > 2 * 30">{{
                                  row?.comment?.slice(2 * 30, 3 * 30)
                                }}</span>
                                <span *ngIf="row?.comment?.length > 3 * 30">...</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          
                          <ngx-datatable-column prop="created_by"
                            [name]="'TALENT_POOL.SEARCH_RESUME.CREATED_BY_EVALUATE' | translate"
                          >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ getEmailUser(row.created_by) }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column prop="created_at"
                            [name]="'TALENT_POOL.SEARCH_RESUME.CREATED_TIME_EVALUATE' | translate"
                          >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.created_at | date : 'dd/MM/yyyy' }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                  </ng-template>
                </li>

              </ul>

              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12">
          <div class="card card-statistics">
            <div class="card-header flex-column align-items-start">
              <h4
                class="card-title mb-75"
                [translate]="'DASHBOARD.LIST_REPORT_RESUME_RECOMMEND'"
              ></h4>
            </div>
            <div class="card-body">
              <ul ngbNav #Nav="ngbNav" class="nav nav-pills">
                <li ngbNavItem>
                  <a ngbNavLink (click)="tabIsMatchReportSearch(true, false)"><span [translate]="'TALENT_POOL.SEARCH_RESUME.MATCH'"></span></a>
                  <ng-template ngbNavContent>
                    <div class="tab-pane active" id="match-recommend" aria-labelledby="match-recommend-tab" role="tabpanel">
                      <div class="table-responsive">
                        <table class="table align-middle table-bordered">
                          <thead>
                            <tr>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.JOB'"></th>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.RESUME'"></th>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.CREATED_BY_EVALUATE'"></th>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.CREATED_TIME_EVALUATE'"></th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let _listReportResumeRecommend of listReportResumeRecommend
                                    | slice: (pageReportResumeRecommend.pageNumber - 1) * pageReportResumeRecommend.size:(pageReportResumeRecommend.pageNumber - 1) * pageReportResumeRecommend.size + pageReportResumeRecommend.size">
                              <td>
                                <div class="">
                                  <a
                                  routerLink="/manage-job/detail-job/{{
                                    _listReportResumeRecommend.job_description
                                  }}"
                                  class="max-width-custom-description text-primary"
                                  container="body"
                                  [ngbTooltip]="_listReportResumeRecommend.job_description_name"
                                  target="_blank"
                                  >
                                  {{ _listReportResumeRecommend.job_description_name?.slice(0, 30) }} 
                                <span *ngIf="_listReportResumeRecommend.job_description_name?.length > 30"
                                  >{{ _listReportResumeRecommend.job_description_name?.slice(30, 2*30) }} 
                                </span>
                                <span *ngIf="_listReportResumeRecommend.job_description_name?.length > 2*30">{{
                                  _listReportResumeRecommend.job_description_name?.slice(2*30, 3*30)
                                }}</span>
                                <span *ngIf="_listReportResumeRecommend.job_description_name?.length > 3*30">...</span>
                                </a>
                                </div>
                              </td>
                              <td>
                                <div class="">
                                  <a
                                  routerLink="/manage-resume/detail-resume/{{
                                    _listReportResumeRecommend.resume
                                  }}"
                                  class="w-100 text-primary"
                                  target="_blank"
                                  >{{ _listReportResumeRecommend.resume }}
                                </a>
                              </div>
                              </td>
                              <td>
                                <div class="">
                                  <span>{{ getEmailUser(_listReportResumeRecommend.created_by) }}</span>
                                </div>
                              </td>
                              <td>
                                <div class="">
                                  <span>{{ _listReportResumeRecommend.created_at | date : 'dd/MM/yyyy' }}</span>
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </table>


                      </div>
                      <div class="row mt-2 justify-content-between" *ngIf="pageReportResumeRecommend.totalElements>0">
                        <div class="col-auto">
                          <div class="mr-1">{{pageReportResumeRecommend.totalElements}} <span
                            [translate]="'RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT'"></span> </div>
                        </div>
                        <div class="col-auto">

                          <nav class="mt-sm-0" aria-label="Jobs list pagination">

                            <ngb-pagination [collectionSize]="pageReportResumeRecommend.totalElements" [(page)]="pageReportResumeRecommend.pageNumber" [pageSize]="pageReportResumeRecommend.size"
                              [maxSize]="2" [rotate]="true" [boundaryLinks]="true">


                            </ngb-pagination>
                          </nav>
                        </div>
                        
                      </div>
                      
                    </div>

                  </ng-template>
                </li>
                <li ngbNavItem>
                  <a ngbNavLink (click)="tabIsMatchReportSearch(false, false)"><span [translate]="'TALENT_POOL.SEARCH_RESUME.NOT_MATCH'"></span></a>
                  <ng-template ngbNavContent>
                    <div class="tab-pane" id="social" aria-labelledby="social-tab" role="tabpanel">
                      <div class="table-responsive">
                        <table class="table align-middle table-bordered">
                          <thead>
                            <tr>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.JOB'"></th>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.RESUME'"></th>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.COMMENT'"></th>

                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.CREATED_BY_EVALUATE'"></th>
                              <th [translate]="'TALENT_POOL.SEARCH_RESUME.CREATED_TIME_EVALUATE'"></th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let _listReportNotMatchResumeRecommend of listReportNotMatchResumeRecommend
                                    | slice: (pageReportNotMatchResumeRecommend.pageNumber - 1) * pageReportNotMatchResumeRecommend.size:(pageReportNotMatchResumeRecommend.pageNumber - 1) * pageReportNotMatchResumeRecommend.size + pageReportNotMatchResumeRecommend.size">
                              <td>
                                <div class="">
                                  <a
                                  routerLink="/manage-job/detail-job/{{
                                    _listReportNotMatchResumeRecommend.job_description
                                  }}"
                                  class="max-width-custom-description text-primary"
                                  container="body"
                                  [ngbTooltip]="_listReportNotMatchResumeRecommend.job_description_name"
                                  target="_blank"
                                  >{{ _listReportNotMatchResumeRecommend.job_description_name?.slice(0, 30) }}
                                  <span *ngIf="_listReportNotMatchResumeRecommend.job_description_name?.length > 30"
                                    >{{ _listReportNotMatchResumeRecommend.job_description_name?.slice(30, 2*30) }}</span>
                                  <span *ngIf="_listReportNotMatchResumeRecommend.job_description_name?.length > 2*30">{{
                                    _listReportNotMatchResumeRecommend.job_description_name?.slice(2*30, 3*30)
                                  }}</span>
                                  <span *ngIf="_listReportNotMatchResumeRecommend.job_description_name?.length > 3*30">...</span>
                                </a>
                                </div>
                              </td>
                              <td>
                                <div class="">
                                  <a
                                  routerLink="/manage-resume/detail-resume/{{
                                    _listReportNotMatchResumeRecommend.resume
                                  }}"
                                  class="w-100 text-primary"
                                  target="_blank"
                                  >{{ _listReportNotMatchResumeRecommend.resume }}
                                </a>
                              </div>
                              </td>
                              <td>
                                <div
                                class="w-100"
                                container="body"
                                [ngbTooltip]="_listReportNotMatchResumeRecommend.comment"
                              >
                                {{ _listReportNotMatchResumeRecommend?.comment?.slice(0, 30) }}
                                <span *ngIf="_listReportNotMatchResumeRecommend?.comment?.length > 30"
                                  >{{ _listReportNotMatchResumeRecommend?.comment?.slice(30, 2 * 30) }} 
                                </span>
                                <span *ngIf="_listReportNotMatchResumeRecommend?.comment?.length > 2 * 30">{{
                                  _listReportNotMatchResumeRecommend?.comment?.slice(2 * 30, 3 * 30)
                                }}</span>
                                <span *ngIf="_listReportNotMatchResumeRecommend?.comment?.length > 3 * 30">...</span>
                              </div>
                              </td>
                              <td>
                                <div class="">
                                  <span>{{ getEmailUser(_listReportNotMatchResumeRecommend.created_by) }}</span>
                                </div>
                              </td>
                              <td>
                                <div class="">
                                  <span>{{ _listReportNotMatchResumeRecommend.created_at | date : 'dd/MM/yyyy' }}</span>
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </table>


                      </div>
                      <div class="row mt-2 justify-content-between" *ngIf="pageReportNotMatchResumeRecommend.totalElements>0">
                        <div class="col-auto">
                          <div class="mr-1">{{pageReportNotMatchResumeRecommend.totalElements}} <span
                            [translate]="'RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT'"></span> </div>
                        </div>
                        <div class="col-auto">

                          <nav class="mt-sm-0" aria-label="Jobs list pagination">

                            <ngb-pagination [collectionSize]="pageReportNotMatchResumeRecommend.totalElements" [(page)]="pageReportNotMatchResumeRecommend.pageNumber" [pageSize]="pageReportNotMatchResumeRecommend.size"
                              [maxSize]="2" [rotate]="true" [boundaryLinks]="true">


                            </ngb-pagination>
                          </nav>
                        </div>
                        
                      </div>
                      
                    </div>

                  </ng-template>
                </li>

              </ul>

              <div [ngbNavOutlet]="Nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
