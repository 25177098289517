import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "en",
  data: {
    SHOW: "Show",
    NAME: "Name API key",
    CREATED: "Created",
    EXPIRED: "Expired",
    STATUS: "Status",
    ACTION: "Action",
    WORK: "Work",
    NO_WORK: "No work",
    NO_LIMIT: "Unlimited",
    CREATE_API_KEY: {
        CREATE: "Create API key",
        PASSWORD: "Enter the password to authenticate",
        EXPIRED_CREATE: "Expiration Time (Optional)",
        CANCEL: "Cancel",
        SAVE: "Save changes",
        PASSWORD_REQUIRED: "Password is required",
        NAME_REQUIRED: "Name API key is required",

    },
    TOASTR: {
        COPY_TO_CLIPBOARD:
        "API Key copied",
        SUCCESS: "Success",
        SAME: "Same api key name"

    },
    MODAL: {
        ACTION: "Action",
        DELETE_A: "Delete API key",
        CANT_UNDO: "You won't be able to undo this",
        CANCEL: "Cancel",
        CONFIRM: "Confirm",
      },
      TOAST: {
        DELETE_A_SUC: "Delete API key",
        ERROR: "Error",
        FAILED: "Failed",
        SUCCESS: "Success"
      },

}
};
