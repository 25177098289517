<div class="content-wrapper container-xxl p-0" *ngIf="transaction?.id">
    <div class="content-body">
      <section class="invoice-preview-wrapper">
        <div class="row invoice-preview">
          <!-- Invoice -->
          <div class="col-xl-12 col-md-12 col-12 printRef">
            <div class="card invoice-preview-card">
              <div class="card-body invoice-padding pb-0">
                <!-- Header Starts -->
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                  <div>
                    <!-- <div class="logo-wrapper">
                      <svg
                        viewBox="0 0 139 95"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        height="24"
                      >
                        <defs>
                          <linearGradient
                            id="invoice-linearGradient-1"
                            x1="100%"
                            y1="10.5120544%"
                            x2="50%"
                            y2="89.4879456%"
                          >
                            <stop stop-color="#000000" offset="0%"></stop>
                            <stop stop-color="#FFFFFF" offset="100%"></stop>
                          </linearGradient>
                          <linearGradient
                            id="invoice-linearGradient-2"
                            x1="64.0437835%"
                            y1="46.3276743%"
                            x2="37.373316%"
                            y2="100%"
                          >
                            <stop stop-color="#EEEEEE" stop-opacity="0" offset="0%"></stop>
                            <stop stop-color="#FFFFFF" offset="100%"></stop>
                          </linearGradient>
                        </defs>
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g transform="translate(-400.000000, -178.000000)">
                            <g transform="translate(400.000000, 178.000000)">
                              <path
                                class="text-primary"
                                d="M-5.68434189e-14,2.84217094e-14 L39.1816085,2.84217094e-14 L69.3453773,32.2519224 L101.428699,2.84217094e-14 L138.784583,2.84217094e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L6.71554594,44.4188507 C2.46876683,39.9813776 0.345377275,35.1089553 0.345377275,29.8015838 C0.345377275,24.4942122 0.230251516,14.560351 -5.68434189e-14,2.84217094e-14 Z"
                                style="fill: currentColor"
                              ></path>
                              <path
                                d="M69.3453773,32.2519224 L101.428699,1.42108547e-14 L138.784583,1.42108547e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L32.8435758,70.5039241 L69.3453773,32.2519224 Z"
                                fill="url(#invoice-linearGradient-1)"
                                opacity="0.2"
                              ></path>
                              <polygon
                                fill="#000000"
                                opacity="0.049999997"
                                points="69.3922914 32.4202615 32.8435758 70.5039241 54.0490008 16.1851325"
                              ></polygon>
                              <polygon
                                fill="#000000"
                                opacity="0.099999994"
                                points="69.3922914 32.4202615 32.8435758 70.5039241 58.3683556 20.7402338"
                              ></polygon>
                              <polygon
                                fill="url(#invoice-linearGradient-2)"
                                opacity="0.099999994"
                                points="101.428699 0 83.0667527 94.1480575 130.378721 47.0740288"
                              ></polygon>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <h3 class="text-primary invoice-logo">Vuexy</h3>
                    </div> -->
                    <!-- <p class="card-text mb-25">Office 149, 450 South Brand Brooklyn</p>
                    <p class="card-text mb-25">San Diego County, CA 91905, USA</p>
                    <p class="card-text mb-0">+1 (123) 456 7891, +44 (876) 543 2198</p> -->
                  </div>
                  <div class="mt-md-0 mt-2">
                    <h4 class="invoice-title">
                      <span [translate]="'BILLING.TRANSACTION'"></span>
                      <span class="invoice-number"> #{{ transaction.id }}</span>
                    </h4>
                    <div class="invoice-date-wrapper">
                      <p class="invoice-date-title"><span [translate]="'BILLING.CREATED_AT'"></span><span>:</span></p>
                      <p class="invoice-date">{{ transaction.created_at|date:'dd/MM/yyyy hh:mm a' }}</p>
                    </div>
                    <!-- <div class="invoice-date-wrapper">
                      <p class="invoice-date-title"><span [translate]="'BILLING.UPDATED_AT'"></span><span>:</span></p>
                      <p class="invoice-date">{{ transaction.updated_at|date:'medium' }}</p>
                    </div> -->
                  </div>
                </div>
                <!-- Header Ends -->
              </div>
  
              <hr class="invoice-spacing" />
  
              <!-- Address And Contact Starts -->
              <div class="card-body invoice-padding pt-0">
                <div class="row invoice-spacing">
                  <div class="col-xl-8 p-0">
                    <h6 class="mb-2 font-weight-bolder"><span [translate]="'BILLING.TRANSACTION_TO'"></span><span>:</span></h6>
                    <h6 class="mb-25">{{ transaction.user.first_name }} {{transaction.user.last_name}}</h6>
                    <p class="card-text mb-25">{{ transaction.company.name }}</p>
                    <p class="card-text mb-0">{{ transaction.user.email }}</p>
                  </div>
                  <div class="col-xl-4 p-0 mt-xl-0 mt-2">
                    <h6 class="mb-2 font-weight-bolder"><span [translate]="'BILLING.PAYMENT_DETAIL'"></span><span>:</span></h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1"><span [translate]="'BILLING.TOTAL_DUE'"></span><span>:</span></td>
                          <td>
                            <span>{{transaction.subscription_plan_cost.cost|number}} <small><small *ngIf="transaction.subscription_plan_cost.cost"> đ / </small></small><small>{{transaction?.subscription_plan_cost?.recurrence_period}} </small>
                              <small *ngIf="transaction?.subscription_plan_cost?.recurrence_unit=='6'" [translate]="'BILLING.MONTH'"></small>
                              <small *ngIf="transaction?.subscription_plan_cost?.recurrence_unit=='7'" [translate]="'BILLING.YEAR'"></small>
                              <small *ngIf="transaction?.subscription_plan_cost?.recurrence_unit=='4'" [translate]="'BILLING.DAY'"></small>
                            </span>
                            
                          </td>
                        </tr>
                        <!-- <tr>
                          <td class="pr-1"><span [translate]="'BILLING.TYPE'"></span><span>:</span></td>
                          <td>{{ transaction.type }}</td>
                        </tr> -->
                        <tr>
                          <td class="pr-1"><span [translate]="'BILLING.PACKAGE'"></span><span>:</span></td>
                          <td>{{ transaction.subscription_plan_cost.subscription_plan.title }}</td>
                        </tr>
                        <!-- <tr>
                          <td class="pr-1"><span [translate]="'DETAILS.NUM_PAGE_PARSING'"></span><span>:</span></td>
                          <td>{{ transaction.package.parse_limited }} {{'DETAILS.PAGE' | translate}}</td>
                        </tr>
                        <tr>
                          <td class="pr-1"><span [translate]="'DETAILS.NUM_PAGE_MATCHING'"></span><span>:</span></td>
                          <td>{{ transaction.package.matching_limited }} {{'DETAILS.PAGE' | translate}}</td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Address And Contact Ends -->
            </div>
          </div>
          <!--/ Invoice -->
        </div>
      </section>
    </div>
  </div>
  