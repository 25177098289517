<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" routerLink="/login">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
      <h2 class="brand-text text-primary ml-1">{{ coreConfig.app.appName }}</h2></a
    >
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img
          class="img-fluid"
          [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/forgot-password-v2-dark.svg'
              : 'assets/images/pages/forgot-password-v2.svg'
          "
          alt="Forgot password V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1" [translate]="'ACCOUNT_SETTING.LOGIN.FORGOT_PASSWORD_TITLE'"></h2>
        <p class="card-text mb-2" translate="ACCOUNT_SETTING.LOGIN.SEND_EMAIL"></p>
        <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm">
          <div class="form-group">
            <label class="form-label" for="forgot-password-email" translate="ACCOUNT_SETTING.LOGIN.EMAIL"></label>
            <input
              class="form-control"
              type="text"
              formControlName="email"
              placeholder="name@example.com"
              aria-describedby="forgot-password-email"
              autofocus=""
              tabindex="1"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required" translate="ACCOUNT_SETTING.LOGIN.EMAIL_REQUIRED"></div>
              <div *ngIf="f.email.errors.email" translate="ACCOUNT_SETTING.LOGIN.EMAIL_VALID"></div>
            </div>
          </div>
          <button
            [disabled]="isLoading"
            (click)="onSubmit()"
            class="btn btn-primary btn-block"
            tabindex="2"
            rippleEffect
            translate="ACCOUNT_SETTING.LOGIN.SEND_NEW_PASSWORD"
          >
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >
          </button>
          <!-- <button class="btn btn-primary btn-block" tabindex="2" rippleEffect translate="ACCOUNT_SETTING.LOGIN.SEND_NEW_PASSWORD"></button> -->
        </form>
        <p class="text-center mt-2">
          <a routerLink="/login" translate="ACCOUNT_SETTING.LOGIN.BACK_TO_LOGIN"
            ><i data-feather="chevron-left" class="mb-25"></i> </a
          >
        </p>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
