import { colors } from './../../colors.const';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ResultDetailResumeMatching, Stat, StatResumeMatching } from '../resume-parser/models/resume.model';
import { FormBuilder, FormControl } from '@angular/forms';
import { ListCompany, User } from 'app/auth/models';
import { DashboardService } from './services/dashboard.service';
import { AuthenticationService } from 'app/auth/service';
import { ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexMarkers, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStates, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocal } from "./i18n/vn";
import { ResumeParserService } from '../resume-parser/services/resume-parser.service';
import { saveAs } from 'file-saver';

export interface ChartOptions2 {
  // Apex-non-axis-chart-series
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart;
  stroke?: ApexStroke;
  tooltip?: ApexTooltip;
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  colors?: string[];
  legend?: ApexLegend;
  labels?: any;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  markers?: ApexMarkers[];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  states?: ApexStates;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  public isAuthenticated=false
  public stat: Stat = new Stat()
  public stat_resume_matching: Stat = new Stat()
  public stat_resume_matching_recruitery: StatResumeMatching = new StatResumeMatching()
  public errorMessagesStat: any
  public errorMessagesStatMatchingResumeRecruitery: any
  public label_owner:string[] = []
  public loading=false
  public typeTime = 0
  //Period
  public companys = [{id:19, name:""}]
  public subCompanys: any
  FilterStatForm = this.fb.group({
    company: new FormControl(''),
    sub_company: new FormControl([]),
    period: new FormControl([]),
  })
  public displayLast7day = false
  public user = new User()
  public is_superuser = false
  public list_compay: ListCompany = new ListCompany()
  public options:any

  tab_successfactors = false
  tab_resume = false
  tab_resume_matching = false
  //Table resume matching recruitery
  public page = 1
  public pageSize = 5
  public totalItems = 0
  public pageSizes = [5, 10, 15]
  public view_resume_check = -1
  public view_resume = false

  @ViewChild('statusChartRef') statusChartRef: any;
  @ViewChild('ownerChartRef') ownerChartRef: any;
  @ViewChild('yearExpChartRef') yearExpChartRef: any;
  @ViewChild('groupCategoryChartRef') groupCategoryChartRef: any;
  @ViewChild('groupSourceChartRef') groupSourceChartRef: any;
  @ViewChild('lineStatusChartRef') lineStatusChartRef: any;
  @ViewChild('resumeStatusUploadedSf4cByDayChartRef') resumeStatusUploadedSf4cByDayChartRef: any;
  @ViewChild('statusUploadSuccessFactorsChartRef') statusUploadSuccessFactorsChartRef: any;


  public statusChartoptions;
  public ownerChartoptions;
  public yearExpChartoptions;
  public groupCategoryChartoptions;
  public groupSourceChartoptions;
  public resumeStatusChartoptions;
  public statusUploadSuccessFactorsChartoptions;
  public resumeStatusUploadedSf4cByDayChartoptions;
  public apexDonutChart: Partial<ChartOptions2>;
   // Color Variables
   chartColors = {
    donut: {
      series1: '#219cd7',
      series2: '#2b9bf4',
      series3: '#ff9f43'
    }
  };
  tag_export = false
  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _authenticationService: AuthenticationService,
    private resumesService: ResumeParserService, 
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.getStatus();
    this.getOwner();
    this.getYearExp();
    this.getGroupCategory();
    this.getGroupSource();
    this.getStatusUploadSuccessFactors();
    this.getSuccessfactorStatusByDay()
    this.getResumeStatus();
  }
  changeSubCompany(e: any) {
    this.FilterStatForm.get('sub_company')?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  getListSubCompany(company_id: any){
    this._authenticationService.listSubCompany(company_id).subscribe(
      (res)=>{
        this.subCompanys = res
      }
    )
  }
  downloadReport(){
    this.tag_export = true
    let url_dynamic = ""
    let sub_company= this.FilterStatForm.value.sub_company
    if(sub_company){
      url_dynamic = url_dynamic+ "&sub_company="+sub_company
    }
    let start_time_raw = this.FilterStatForm.value.period[0]
    let end_time_raw = this.FilterStatForm.value.period[1]
    const gmtOffsetStart = 7;
    const gmtOffsetEnd = 31;

    let start_time = this.FilterStatForm.value.period[0]==undefined?undefined:new Date(new Date(start_time_raw.getFullYear(),start_time_raw.getMonth(),start_time_raw.getDate()).getTime()+ gmtOffsetStart * 60 * 60 * 1000).toISOString()
    let end_time = this.FilterStatForm.value.period[1]==undefined?undefined:new Date(new Date(end_time_raw.getFullYear(),end_time_raw.getMonth(),end_time_raw.getDate()).getTime()+ gmtOffsetEnd * 60 * 60 * 1000).toISOString()
    if(start_time!=undefined&& start_time!=null){
      url_dynamic =url_dynamic+ "&start="+start_time
    }
    if(end_time!=undefined&& end_time!=null){
      url_dynamic =url_dynamic+ "&end="+end_time
    }
    this.resumesService.downloadStat(url_dynamic).subscribe(
      (res) => {
        const file_name = 'Bao_cao_phan_tich_thong_ke_'+ start_time.split('T')[0]+'_'+end_time.split('T')[0] + '.xlsx'
          let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' });
          saveAs(blob, file_name);
          this.tag_export = false
          
      },
      (err) => {
        this.tag_export = false
        
      }
    )
  }
  getStatus(){
    this.statusChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true, position: 'bottom' },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getOwner(){
    this.ownerChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true, position: 'bottom', },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getYearExp(){
    this.yearExpChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true, position: 'bottom', },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getGroupCategory(){
    this.groupCategoryChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true, position: 'bottom', },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getGroupSource(){
    this.groupSourceChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true, position: 'bottom', },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getStatusUploadSuccessFactors(){
    this.statusUploadSuccessFactorsChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: true, position: 'bottom', },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  // getStatusUploadSuccessFactors(){
  //   this.statusUploadSuccessFactorsChartoptions = {
  //     series: [
  //       {
  //         name: '',
  //         data: []
  //       },
  //       {
  //         name: '',
  //         data: []
  //       }
  //     ],
  //     chart: {
  //       type: 'bar',
  //       height: 400,
  //       stacked: false,
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     grid: {
  //       xaxis: {
  //         lines: {
  //           show: true
  //         }
  //       }
  //     },
  //     legend: {
  //       show: false,
  //       position: 'bottom',
  //       horizontalAlign: 'center'
  //     },
  //     plotOptions: {
  //       bar: {
  //         distributed: true,
  //         columnWidth: '15%',
  //         colors: {
  //           backgroundBarColors: [
              
  //           ],
  //           backgroundBarRadius: 10
  //         }
  //       }
  //     },
  //     colors: [],
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent']
  //     },
  //     xaxis: {
  //       categories: []
  //     },
  //     yaxis: {
  //       title: {
  //         text: ''
  //       }
  //     },
  //     fill: {
  //       opacity: 1
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return val;
  //         }
  //       }
  //     }
  //   };
  // }
  getSuccessfactorStatusByDay(){
    this.resumeStatusUploadedSf4cByDayChartoptions = {
      series: [
        {
          data: []
        }
      ],
      chart: {
        height: 400,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: ['#fff'],
        colors: ['FF9F43']
      },
      colors: ['FF9F43'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      xaxis: {
        title: {text: this._translateService.instant('DASHBOARD.XAXIS_TITLE')},
        categories: [
          
        ]
      },
      yaxis: {
        title: {text: this._translateService.instant('DASHBOARD.YAXIS_TITLE')},
      },
      tooltip: {
        shared: false,
        custom: function (data) {
          return (
            '<div class="px-1 py-50">' +
            '<span>' +
            data.series[data.seriesIndex][data.dataPointIndex] +
            '</span>' +
            '</div>'
          );
        }
      }
    };
  }
  getResumeStatus(){
    this.resumeStatusChartoptions = {
      series: [
        {
          data: []
        }
      ],
      chart: {
        height: 400,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [colors.solid.white],
        colors: [colors.solid.warning]
      },
      colors: [colors.solid.warning],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      xaxis: {
        title: {text: this._translateService.instant('DASHBOARD.XAXIS_TITLE')},
        categories: [
          
        ]
      },
      yaxis: {
        title: {text: this._translateService.instant('DASHBOARD.YAXIS_TITLE')},
      },
      tooltip: {
        shared: false,
        custom: function (data) {
          return (
            '<div class="px-1 py-50">' +
            '<span>' +
            data.series[data.seriesIndex][data.dataPointIndex] +
            '</span>' +
            '</div>'
          );
        }
      }
    };
  }
  changeCompany(e: any) {
    this.company?.setValue(e.target.value, {
      onlySelf: true,
    });
    this.getListSubCompany(e.target.value)
  }
  get company() {
    return this.FilterStatForm.get('company');
  }
  get period():any{
    return this.FilterStatForm.get('period')
  }
  onSubmit(){
    this.FilterStat();
    
  }
  onReset(){
    this.displayLast7day = true
    this.period.reset()
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(company!=undefined&& company!=null&&company!=''){
      url_dynamic = url_dynamic+ "&company="+company
    }
    let sub_company= this.FilterStatForm.value.sub_company
    if(sub_company){
      url_dynamic = url_dynamic+ "&sub_company="+sub_company
    }
    this.statistic(url_dynamic)
  }
  FilterStat(){
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    let sub_company= this.FilterStatForm.value.sub_company

    if(this.FilterStatForm.value.period!=undefined){
      
      let start_time_raw = this.FilterStatForm.value.period[0]
      let end_time_raw = this.FilterStatForm.value.period[1]
      const gmtOffsetStart = 7;
      const gmtOffsetEnd = 31;

      let start_time = this.FilterStatForm.value.period[0]==undefined?undefined:new Date(new Date(start_time_raw.getFullYear(),start_time_raw.getMonth(),start_time_raw.getDate()).getTime()+ gmtOffsetStart * 60 * 60 * 1000).toISOString()
      let end_time = this.FilterStatForm.value.period[1]==undefined?undefined:new Date(new Date(end_time_raw.getFullYear(),end_time_raw.getMonth(),end_time_raw.getDate()).getTime()+ gmtOffsetEnd * 60 * 60 * 1000).toISOString()
      if(company!=undefined&& company!=null&&company!=''){
        url_dynamic = url_dynamic+ "&company="+company
      }
      if(sub_company){
        url_dynamic = url_dynamic+ "&sub_company="+sub_company
      }
      if(start_time!=undefined&& start_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&start="+start_time
      }
      if(end_time!=undefined&& end_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&end="+end_time
      }
      this.statistic(url_dynamic);
    }else{
      this.statistic(url_dynamic);
    }
    
    
  }
  ListCompany(){
    this.authService.list_company().subscribe(
      (res)=>{
        this.list_compay = res;
        this.companys = res.results
      },
      (err)=>{
        this.errorMessagesStat = err
      }
    )
  }
  ngOnInit(): void {
    this.tab_resume = true
    this.displayLast7day = true
    this.user = this._authenticationService.currentUserValue

    if(this.user.is_superuser){
      this.is_superuser = true
      this.ListCompany()
      // this.getListSubCompany()
    }
    if(this.user.role == 'Admin' || this.user.role == 'Super admin'){
      this.is_superuser = true
      // this.getListSubCompany()
    }
    let linkUrl = window.location.href
    // Get the current date
    const currentDate = new Date();
    const currentDateISOString = currentDate.toISOString();

    // Get the date 30 days before the current date
    const date30DaysAgo = new Date(currentDate);
    date30DaysAgo.setDate(currentDate.getDate() - 30);
    const date30DaysAgoISOString = date30DaysAgo.toISOString();
    let id_company = linkUrl.split("id_company=")[1]?linkUrl.split("id_company=")[1]:""
    let url_dynamic = ""
    url_dynamic =url_dynamic+ "&start="+date30DaysAgoISOString
    url_dynamic =url_dynamic+ "&end="+currentDateISOString
    this.FilterStatForm.get('period').setValue([date30DaysAgo, currentDate])
    if(id_company){
      url_dynamic = url_dynamic+ "&company="+id_company
      this.FilterStatForm.patchValue({
        company: id_company,
        // period: [currentDate, date30DaysAgo]
      })
    }
    this.statistic(url_dynamic);
    
  }
  sort_data(data: ResultDetailResumeMatching[]):ResultDetailResumeMatching[]{
    let dataSort = data.slice().sort((a,b)=>b.overall_score-a.overall_score)
    return dataSort
  }
  pageChangeEvent(event: number){
    this.page = event;
    this.loading = true
    // this.statistic_resume_matching_recruitery();

  }
  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    // this.statistic_resume_matching_recruitery();

  }
  statistic(url_dynamic:string){
    this.loading = true
    this.dashboardService.getStat(url_dynamic).subscribe(
      (res)=>{
        this.loading = false
        this.stat = res;
        let label_status = []
        let value_status = []
      //pie chart group status
      if(this.stat.group_status.length>0){
        for(let i=0; i<this.stat.group_status.length;i++){
          label_status.push(this.stat.group_status[i].status.replace("_"," "))
          value_status.push(this.stat.group_status[i].total)
        }
      }else{
        label_status=[]
        value_status=[]
      }
      let backgroundColorStatus = []
      for(let i=0;i<label_status.length;i++){
        if(label_status[i]=='Done'){
          backgroundColorStatus.push('#0d6efd')
        }else if(label_status[i]=='Approved'){
          backgroundColorStatus.push('#20c997')
        }else if(label_status[i]=='Failure'){
          backgroundColorStatus.push('#ea5455')
        }else if(label_status[i]=='Suspended'){
          backgroundColorStatus.push('#82868b')
        }else if(label_status[i]=='Modified'){
          backgroundColorStatus.push('#ffc107')
        }else if(label_status[i]=='Wait to process'){
          backgroundColorStatus.push('#dee2e6')
        }else if(label_status[i]=='Processing'){
          backgroundColorStatus.push('#212529')
        }else if(label_status[i]=='Duplicated'){
          backgroundColorStatus.push('#ff9f43')
        }
      }
      this.statusChartoptions.labels = label_status;
      this.statusChartoptions.series = value_status;
      this.statusChartoptions.colors = backgroundColorStatus;
      
      let label_owner=[]
      let value_owner=[]
      if(this.stat.group_owner.length>0){
        for(let i=0; i<this.stat.group_owner.length;i++){
          label_owner.push(this.stat.group_owner[i].owner__email)
          value_owner.push(this.stat.group_owner[i].total)
        }
        this.label_owner = label_owner
        
      }else{
        label_owner=[]
        value_owner=[]
      }
      this.ownerChartoptions.labels = label_owner;
      this.ownerChartoptions.series = value_owner;
      this.ownerChartoptions.colors = ['#0d6efd','#6610f2',"#dc3545",'#ffc107','#198754','#0dcaf0','#4b4b4b','#6c757d','#fd7e14','#212529'];
      //experience stat
      let label_year_exp=[]
      let value_year_exp=[]
      if(this.stat.group_year_exp.length>0){
        for(let i=0; i<this.stat.group_year_exp.length;i++){
          if(this.stat.group_year_exp[i].year_exp!="UNKNOWN"&&this.stat.group_year_exp[i].year_exp!=null){
            label_year_exp.push(this.stat.group_year_exp[i].year_exp)
            value_year_exp.push(this.stat.group_year_exp[i].total)
          }
        }
        
      }else{
        label_year_exp=[]
        value_year_exp=[]
      }
      this.yearExpChartoptions.labels = label_year_exp;
      this.yearExpChartoptions.series = value_year_exp;
      this.yearExpChartoptions.colors = ['#0d6efd','#6610f2',"#dc3545",'#ffc107','#198754','#0dcaf0','#4b4b4b','#6c757d','#fd7e14','#212529'];
      //group category
      //experience stat
      let label_group_category=[]
      let value_group_category=[]
      if(this.stat?.group_category?.length>0){
        for(let i=0; i<this.stat?.group_category?.length;i++){
          if(this.stat.group_category[i].category!="UNKNOWN"&&this.stat.group_category[i].category!=null){
            label_group_category.push(this.stat.group_category[i].category)
            value_group_category.push(this.stat.group_category[i].total)
          }
        }
        
      }else{
        label_group_category=[]
        value_group_category=[]
      }
      let backgroundColorGroupCategory = []

      for(let i=0;i<label_group_category.length;i++){
        let randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      // Ensure the random color is always six characters long
      while (randomColor.length < 7) {
        randomColor += "0";
      }
      backgroundColorGroupCategory.push(randomColor);
      }
      this.groupCategoryChartoptions.labels = label_group_category;
      this.groupCategoryChartoptions.series = value_group_category;
      this.groupCategoryChartoptions.colors = backgroundColorGroupCategory;
      //group sf4c status
      //Source
      let label_group_source=[]
      let value_group_source=[]
      if(this.stat?.group_source?.length>0){
        for(let i=0; i<this.stat?.group_source?.length;i++){
          if(this.stat.group_source[i].source!="UNKNOWN"&&this.stat.group_source[i].source!=null){
            label_group_source.push(this.stat.group_source[i].source)
            value_group_source.push(this.stat.group_source[i].total)
          }
        }
        
      }else{
        label_group_source=[]
        value_group_source=[]
      }
      let backgroundColorGroupSource = []

      for(let i=0;i<label_group_source.length;i++){
        let randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      // Ensure the random color is always six characters long
      while (randomColor.length < 7) {
        randomColor += "0";
      }
      backgroundColorGroupSource.push(randomColor);
      }
      this.groupSourceChartoptions.labels = label_group_source;
      this.groupSourceChartoptions.series = value_group_source;
      this.groupSourceChartoptions.colors = backgroundColorGroupSource;
      //line chart status by day
      let label_status_by_day: string[] = []
      let value_status_by_day_success: number[]=[]
      let value_status_by_day_failed: number[]=[]
      if(this.stat.status_by_day.length>0){
        for(let i=0; i<this.stat.status_by_day.length;i++){

          label_status_by_day.push(this.stat.status_by_day[i].index)
          value_status_by_day_success.push(this.stat.status_by_day[i].Success)
          value_status_by_day_failed.push(this.stat.status_by_day[i].Failed)

        }
        
        this.resumeStatusChartoptions.xaxis.categories = label_status_by_day;
        this.resumeStatusChartoptions.series = [{data: value_status_by_day_success, name: 'Success'},{data: value_status_by_day_failed,name: 'Failure'}];
        this.resumeStatusChartoptions.colors = [colors.solid.success, colors.solid.danger]
      }
      //line chart uploaded to sf4c
      //group sf4c status
      let label_group_sf4c_status: string[]=[]
      let value_group_sf4c_status: number[]=[]
      if(this.stat.group_sf4c_status.length>0){
        for(let i=0; i<this.stat.group_sf4c_status.length;i++){
          label_group_sf4c_status.push(this.stat.group_sf4c_status[i].sf4c_status.replace("_"," "))
          value_group_sf4c_status.push(this.stat.group_sf4c_status[i].total)
        }
  
      }
      let backgroundColorStatusUploadSf4c = []
      for(let i=0;i<label_group_sf4c_status.length;i++){
        if(label_group_sf4c_status[i]=='SF4C uploaded'){
          backgroundColorStatusUploadSf4c.push('#198754')
        }else if(label_group_sf4c_status[i]=='SF4C failed'){
          backgroundColorStatusUploadSf4c.push('#ea5455')
        }else if(label_group_sf4c_status[i]=='SF4C_uploading'){
          backgroundColorStatusUploadSf4c.push('#212529')
        }else if(label_group_sf4c_status[i]=='SF4C_wait_to_upload'){
          backgroundColorStatusUploadSf4c.push('#dee2e6')
        }
      }
      this.statusUploadSuccessFactorsChartoptions.labels = label_group_sf4c_status;
      this.statusUploadSuccessFactorsChartoptions.series = value_group_sf4c_status;
      this.statusUploadSuccessFactorsChartoptions.colors = backgroundColorStatusUploadSf4c

      //line chart uploaded to sf4c
      let successfactor_value_status_by_day_success: number[]=[]
      let successfactor_value_status_by_day_failed: number[]=[]
      let successfactor_label_status_by_day: string[]=[]
      
      if(this.stat.successfactor_status_by_day.length>0){
        
        for(let i=0; i<this.stat.successfactor_status_by_day.length;i++){
          successfactor_label_status_by_day.push(this.stat.successfactor_status_by_day[i].index)
          successfactor_value_status_by_day_success.push(this.stat.successfactor_status_by_day[i].Success)
          successfactor_value_status_by_day_failed.push(this.stat.successfactor_status_by_day[i].Failed)
        }
      }
      this.resumeStatusUploadedSf4cByDayChartoptions.series = [{data: successfactor_value_status_by_day_success, name:'Success'}
      ,{data: successfactor_value_status_by_day_failed, name: "Failure"}]
      this.resumeStatusUploadedSf4cByDayChartoptions.xaxis.categories = successfactor_label_status_by_day
      this.resumeStatusUploadedSf4cByDayChartoptions.colors = ['#198754','#6c757d']
      },
      (err)=>{
        this.errorMessagesStat = err
        this.loading = false
      }
    )
  }

}
