import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-detail-package',
  templateUrl: './detail-package.component.html',
  styleUrls: ['./detail-package.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailPackageComponent implements OnInit {
  @Input() detailPackage: any;
  constructor() { }

  ngOnInit(): void {


  }

}
