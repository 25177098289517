<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
        <div>
            <form>
                <div class="row">
                    <div class="col-md-3">
                        <input type="text" class="form-control" id="job-search"
                            [placeholder]="'TALENT_POOL.DETAIL_JOB.LAST_NAME'|translate" name="last_name"
                            [(ngModel)]="last_name" />
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" id="job-search"
                            [placeholder]="'TALENT_POOL.DETAIL_JOB.FIRST_NAME'|translate" name="first_name"
                            [(ngModel)]="first_name" />
                    </div>
                    <div class="col-md-2 pl-0">
                        <ng-select
                            [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_GENDER'|translate"
                            name="selectedGender" id="selectedGender" [(ngModel)]="selectedGender">
                            <ng-option *ngFor="let _gender of gender" [value]="_gender.value">
                                <span *ngIf="language=='en';else VN">{{ _gender.name }}</span>
                                <ng-template #VN>
                                    <span>{{ _gender.name_vn }}</span>
                                </ng-template>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-2 pl-0">
                        <ng-select [items]="city" bindLabel="name" bindValue="value"
                            [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_ADDRESS'|translate"
                            name="selectedCity" [(ngModel)]="selectedCity">
                        </ng-select>
                    </div>
                    <div class="col-md-2 pl-0">
                        <button style="height: 38px;padding:6px" (click)="onSubmit()" class="btn fw-bold btn-primary"
                            rippleEffect><span [translate]="'TALENT_POOL.SEARCH_RESUME.SEARCH'"></span> </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body">
        
        <div *ngIf="errorMessagesListSavedResumeByJd; else Saved_cvs">
            <p>Errors: <b style="color: red;">{{errorMessagesListSavedResumeByJd}}</b></p>
        </div>
        <ng-template #Saved_cvs>
            <div class="col-auto d-flex justify-content-between">
                <div class="row">
                    <!-- <div>
                        <button (click)="toggle_list_form_table()" *ngIf="!list_form_table"
                            class="btn btn-outline-primary"><span class="fa fa-th-list"></span></button>
                        <button (click)="toggle_list_form_table()" *ngIf="list_form_table"
                            class="btn btn-outline-primary"><span class="fa fa-th-large"></span></button>

                    </div> -->
                    <div class="d-flex justify-content-between align-items-center">
                        <label class="d-flex align-items-center" style="max-width: 100%; flex-wrap: nowrap"><span
                                [translate]="'TALENT_POOL.MANAGE_JOB.SHOW'"></span>
                            <select class="form-control mx-25 pr-3 show-doc" [(ngModel)]="pageSize">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                            <span>CV</span>
                        </label>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <!-- <div class="col-auto">
                        <button class="btn btn-outline-info" *ngIf="saved_cvs.length>0 && have_hcm_system_id"
                            (click)="upload_sf4c()"><span class="fa fa-upload"></span>&nbsp; <span
                                [translate]="'TALENT_POOL.SAVED_RESUME.UPLOAD_SF4C'"></span></button>
                    </div> -->

                    <!-- <div class="col-auto">
                        <button class="btn btn-outline-info" *ngIf="saved_cvs.length>0 && have_hcm_system_id"
                            (click)="modalOpenUploadSf4c(modalBasic)"><span class="fa fa-upload"></span>&nbsp; <span
                                [translate]="'TALENT_POOL.SAVED_RESUME.UPLOAD_SF4C'"></span></button>
                    </div>
                    <ng-template #modalBasic let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title" [translate]="'TALENT_POOL.SAVED_RESUME.UPLOAD_SF4C'">
                            </h4>
                        </div>
                        <form (ngSubmit)="upload_sf4c()" [formGroup]="formCheckBoxValid">
                            <div class="modal-body" ngbAutofocus>
                                <div class="form-group">
                                    <label class="form-label" for="basic-icon-default-fullname"><span>Imported
                                            by</span></label>
                                    <ng-select type="text" id="selectedImportedBy"
                                        [placeholder]="'TALENT_POOL.SAVED_RESUME.PLACEHOLDER.SELECTED_IMPORTEDBY'|translate"
                                        formControlName="importedby">
                                        <ng-option *ngFor="let importedby of sf4cPicklist.importedby"
                                            [value]="importedby">{{importedby}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group">
                                    <label class="form-label"
                                        for="basic-icon-default-fullname"><span>Source</span></label>
                                    <ng-select type="text" id="selectedSource"
                                        [placeholder]="'TALENT_POOL.SAVED_RESUME.PLACEHOLDER.SELECTED_IMPORTEDBY'|translate"
                                        formControlName="source">
                                        <ng-option *ngFor="let source of sf4cPicklist.source"
                                            [value]="source">{{source}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button (click)="modal.dismiss('Cross click')" (click)="cancel()" type="button"
                                    rippleEffect class="btn btn-secondary mr-1"
                                    [translate]="'TALENT_POOL.SAVED_RESUME.MODAL.CANCEL'"></button>
                                <button type="submit" class="btn btn-primary" (click)="modal.dismiss('Cross click')"
                                    rippleEffect [translate]="'TALENT_POOL.SAVED_RESUME.MODAL.CONFIRM'">
                                </button>
                            </div>
                        </form>
                    </ng-template> -->
                    <!-- <div class="col-auto">
                        <input type="text" class="form-control" id="searchFullname"
                            [placeholder]="'TALENT_POOL.SAVED_RESUME.PLACEHOLDER.SEARCH' | translate"
                            [(ngModel)]="searchFullname" (input)="SearchFullname()" />
                    </div> -->
                </div>
            </div>
            <div class="mt-2" *ngIf="list_form_table">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.FULL_NAME'"></th>
                                <th [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'"></th>
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.CURRENT_TITLE'"></th>
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.CURRENT_COMPANY'"></th>
                                <th [translate]="'TALENT_POOL.RESUME_MATCHING.ACTION'"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let saved_cvs_ of saved_cvs
                            | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                <td>
                                    <a class="text-primary" (click)="toDetailResume(saved_saved_cvs_.id)"
                                        >

                                        <div class="">{{saved_cvs_.last_name}} {{saved_cvs_.first_name}}</div>

                                    </a>
                                </td>
                                <td>
                                    <div class="">{{saved_cvs_.address}}</div>
                                </td>
                                <td>
                                    <div class="">{{saved_cvs_.current_title}}</div>
                                </td>
                                <td>
                                    <div class="">{{saved_cvs_.current_company}}</div>
                                </td>
                                <td>
                                    <a type="button" href="javascript:void(0)" (click)="toDetailResume(saved_saved_cvs_.id)"
                                        title="Chi tiết" class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                                        <span class="d-flex align-items-center justify-content-center"><i
                                                class="fa fa-info-circle"></i>
                                        </span>
                                    </a>
                                    <a type="button" href="javascript:void(0)" (click)="UnsaveResumeByJd(saved_cvs_.id)"
                                        title="Xóa" class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                                        <span class="d-flex align-items-center justify-content-center"><i
                                                class="fa fa-trash-o"></i>
                                        </span>
                                    </a>

                                </td>
                            </tr>

                        </tbody>
                    </table>

                    <div class="d-flex mt-2 justify-content-between align-items-center">
                        <div class="col-auto">
                            <span>{{savedCvsCount}} </span> <span
                                [translate]="'TALENT_POOL.SEARCH_RESUME.RESUMES'"></span>

                        </div>
                        <div class="col-auto">
                            <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">

                                <ngb-pagination [collectionSize]="saved_cvs.length" [(page)]="page"
                                    [pageSize]="pageSize" [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                                </ngb-pagination>
                            </nav>


                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2" *ngIf="!list_form_table">
                <div class="mb-2"
                    *ngFor="let saved_cvs_ of saved_cvs| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                    <div class="col-12">
                        <div class="card card-employee-task" style="border: 1px solid #dedede;">
                            <div class="card-body">
                                <app-resume-talent-pool (removeItemEvent)="removeItem($event)"
                                    [ResultSearchCv]="saved_cvs_" [pageSaved]="true"
                                    [jd_id]="jd_id"></app-resume-talent-pool>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex mt-2 justify-content-between align-items-center">
                    <div class="col-auto">
                        <span>{{savedCvsCount}} </span> <span [translate]="'TALENT_POOL.SEARCH_RESUME.RESUMES'"></span>

                    </div>
                    <div class="col-auto">
                        <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">

                            <ngb-pagination [collectionSize]="saved_cvs.length" [(page)]="page" [pageSize]="pageSize"
                                [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                            </ngb-pagination>
                        </nav>


                    </div>
                </div>

            </div>
        </ng-template>
    </div>
</div>