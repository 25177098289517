<div class="content-wrapper container-xxxl p-0">
    <div>


        <div class="content-body mb-2">
            <form [formGroup]="FilterStatForm">

                <div class="d-flex">
                    <div class="col-md-2 pl-0" *ngIf="user.is_superuser">
                        <select (keydown.enter)="onSubmit()" style="height: 2.714rem;" id="company" class="form-control" (change)="changeCompany($event)"
                            formControlName="company">
                            <option value="" [translate]="'DASHBOARD.ALL_COMPANY'"></option>
                            <option *ngFor="let company of companys" [value]="company.id">
                                {{ company.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 pl-0" *ngIf="(user.is_superuser || user.role == 'Admin' || user.role == 'Super admin') && subCompanys?.length>0">
                        <select (keydown.enter)="onSubmit()" style="height: 2.714rem;" id="sub_company" class="form-control" (change)="changeSubCompany($event)"
                            formControlName="sub_company">
                            <option value="" [translate]="'DASHBOARD.CHOOSE_SUB_COMPANY'"></option>
                            <option *ngFor="let sub_company of subCompanys" [value]="sub_company.id">
                                {{ sub_company.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4 pl-0">
                        <input (keydown.enter)="onSubmit()" id="period" formControlName="period" [placeholder]="'DASHBOARD.CHOOSE_PERIOD'|translate" bsDaterangepicker
                            type="text" class="form-control"
                            [bsConfig]="{ isAnimated: true, rangeInputFormat: 'DD-MM-YYYY' }" autocomplete="off"/>

                    </div>
                    <div class="col-md-4 pl-0">


                        <button (click)="onSubmit()" class="btn btn-primary w-btn-dashboard" rippleEffect [translate]="'DASHBOARD.FILTER'"></button>
                        <button (click)="onReset()" class="btn btn-secondary ml-1 w-btn-dashboard"
                            style="background-color: #6c757d;" rippleEffect [translate]="'DASHBOARD.RESET'"></button>
                    </div>
                    <div [ngClass]="user.is_superuser?'col-md-2 d-flex justify-content-end':'col-md-4 d-flex justify-content-end'" style="padding-right: 0px;">
                        <button *ngIf="tag_export;else noLoadingReport" class="btn btn-success btn-icon place-order" disabled>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"></span>
                          </button>
                          <ng-template #noLoadingReport>
                            <button (click)="downloadReport()" class="btn btn-success w-btn-dashboard" rippleEffect [translate]="'RESUME_PARSER.MANAGE_RESUME.DOWNLOAD_TYPE'"></button>
              
                            
                          </ng-template>
                        

                    </div>
                </div>

            </form>
        </div>
        <div *ngIf="loading;else noLoading" id="loading" class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <ng-template #noLoading>
            <div class="card card-statistics">
                <div class="card-body statistics-body">
                    <div class="row">
                        <div class="col-sm-6 col-xl">
                            <div class="media">
                                <div class="avatar bg-light-success mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="file" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <span class="font-weight-bolder mb-0">{{stat.num_resumes}}</span>
                                    <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.RESUME_UPLOADED'"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl">
                            <div class="media">
                                <div class="avatar bg-light-info mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="file" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <span class="font-weight-bolder mb-0">{{stat.latest_resumes}}</span>
                                    <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.RESUME_UPLOADED_DAY'"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl mb-2 mb-xl-0">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="percent" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <span class="font-weight-bolder mb-0">{{stat.avg_score*100|number:'1.2-2'}}</span>
                                    <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.AVERAGE_CONF_SCORE'"></p>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
    
    
            <div class="row match-height align-items-center">
    
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="earnings-donut-chart" #statusChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 400,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="statusChartoptions.dataLabels" [series]="statusChartoptions.series"
                                    [legend]="statusChartoptions.legend" [labels]="statusChartoptions.labels"
                                    [stroke]="statusChartoptions.stroke" [colors]="statusChartoptions.colors"
                                    [grid]="statusChartoptions.grid"
                                    [plotOptions]="statusChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
    
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_OWNER'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="earnings-donut-chart" #ownerChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 400,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="ownerChartoptions.dataLabels" [series]="ownerChartoptions.series"
                                    [legend]="ownerChartoptions.legend" [labels]="ownerChartoptions.labels"
                                    [stroke]="ownerChartoptions.stroke" [colors]="ownerChartoptions.colors"
                                    [grid]="ownerChartoptions.grid"
                                    [plotOptions]="ownerChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
            <div class="row match-height">
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.YEAR_EXP'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="yearExp" #yearExpChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 400,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="yearExpChartoptions.dataLabels" [series]="yearExpChartoptions.series"
                                    [legend]="yearExpChartoptions.legend" [labels]="yearExpChartoptions.labels"
                                    [stroke]="yearExpChartoptions.stroke" [colors]="yearExpChartoptions.colors"
                                    [grid]="yearExpChartoptions.grid"
                                    [plotOptions]="yearExpChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS_SF4C'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="statusSf4c-donut-chart" #statusUploadSuccessFactorsChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 400,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="statusUploadSuccessFactorsChartoptions.dataLabels" [series]="statusUploadSuccessFactorsChartoptions.series"
                                    [legend]="statusUploadSuccessFactorsChartoptions.legend" [labels]="statusUploadSuccessFactorsChartoptions.labels"
                                    [stroke]="statusUploadSuccessFactorsChartoptions.stroke" [colors]="statusUploadSuccessFactorsChartoptions.colors"
                                    [grid]="statusUploadSuccessFactorsChartoptions.grid"
                                    [plotOptions]="statusUploadSuccessFactorsChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
                            
    
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.GROUP_CATEGORY'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="groupCategory" #groupCategoryChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 500,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="groupCategoryChartoptions.dataLabels" [series]="groupCategoryChartoptions.series"
                                    [legend]="groupCategoryChartoptions.legend" [labels]="groupCategoryChartoptions.labels"
                                    [stroke]="groupCategoryChartoptions.stroke" [colors]="groupCategoryChartoptions.colors"
                                    [grid]="groupCategoryChartoptions.grid"
                                    [plotOptions]="groupCategoryChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.GROUP_SOURCE'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="groupSource" #groupSourceChartRef>
                                <!-- Apexchart component-->
                                <apx-chart [chart]="{
                                type: 'donut',             
                                height: 500,
                                toolbar: {
                                  show: false
                                }
                              }" [dataLabels]="groupSourceChartoptions.dataLabels" [series]="groupSourceChartoptions.series"
                                    [legend]="groupSourceChartoptions.legend" [labels]="groupSourceChartoptions.labels"
                                    [stroke]="groupSourceChartoptions.stroke" [colors]="groupSourceChartoptions.colors"
                                    [grid]="groupSourceChartoptions.grid"
                                    [plotOptions]="groupSourceChartoptions.plotOptions"></apx-chart>
                                <!-- / Apexchart component-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                        <div class="card-header flex-column align-items-start">
                            <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS_DAY'"></h4>
                            <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                        </div>
                        <div class="card-body">
                            <div id="column-chart" #lineStatusChartRef>
                                <apx-chart
                                  [series]="resumeStatusChartoptions.series"
                                  [chart]="{
                                    type: 'line',
                                    height: 500,
                                    stacked: false,
                                    toolbar: {
                                      show: false
                                    }
                                  }"
                                  [xaxis]="resumeStatusChartoptions.xaxis"
                                  [yaxis]="resumeStatusChartoptions.yaxis"
                                  [stroke]="resumeStatusChartoptions.stroke"
                                  [tooltip]="resumeStatusChartoptions.tooltip"
                                  [colors]="resumeStatusChartoptions.colors"
                                  [legend]="resumeStatusChartoptions.legend"
                                  [dataLabels]="resumeStatusChartoptions.dataLabels"
                                  [plotOptions]="resumeStatusChartoptions.plotOptions"
                                  [grid]="resumeStatusChartoptions.grid"
                                ></apx-chart>
                              </div>
    
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-6 col-12">
                    <div class="card card-statistics">
                      <div class="card-header flex-column align-items-start">
                        <h4 class="card-title mb-75" [translate]="'DASHBOARD.RESUME_STATUS_SF4C_DAY'"></h4>
                        <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
                      </div>
                      <div class="card-body">
                        <div id="line-chart" #resumeStatusUploadedSf4cByDayChartRef>
                            <apx-chart
                              [series]="resumeStatusUploadedSf4cByDayChartoptions.series"
                              [chart]="{
                                height: 400,
                                
                                type: 'line',
                                
                                toolbar: {
                                  show: false
                                }
                              }"
                              [xaxis]="resumeStatusUploadedSf4cByDayChartoptions.xaxis"
                              [yaxis]="resumeStatusUploadedSf4cByDayChartoptions.yaxis"
                              [stroke]="resumeStatusUploadedSf4cByDayChartoptions.stroke"
                              [tooltip]="resumeStatusUploadedSf4cByDayChartoptions.tooltip"
                              [colors]="resumeStatusUploadedSf4cByDayChartoptions.colors"
                              [dataLabels]="resumeStatusUploadedSf4cByDayChartoptions.dataLabels"
                              [grid]="resumeStatusUploadedSf4cByDayChartoptions.grid"
                            ></apx-chart>
                          </div>
                    </div>
                  </div>
                </div>
                
            </div>
        </ng-template>
    </div>
</div>