import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { DashboardService } from '../services/dashboard.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { locale as enPackage } from "../../package/i18n/en"
import { locale as vnPackage } from "../../package/i18n/vn"
import { Page } from 'app/main/resume-parser/models/paging.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResultPackage } from 'app/main/resume-parser/models/resume.model';
@Component({
  selector: 'app-activity-used',
  templateUrl: './activity-used.component.html',
  styleUrls: ['./activity-used.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityUsedComponent implements OnInit {
  search: any[] = [];
  totalSearch = 0
  recommend: any[] = [];
  unmatch_report: any[] = [];
  loading = false
  @ViewChild(DatatableComponent) tableSearch: DatatableComponent;
  @ViewChild(DatatableComponent) tableRecommend: DatatableComponent;
  @ViewChild(DatatableComponent) tableUnmatchReport: DatatableComponent;
  @ViewChild(DatatableComponent) tableDetailUnmatchReport: DatatableComponent;

  public ColumnMode = ColumnMode;
  lengthUsersSearch: number[] = []
  lengthUsersRecommend: number[] = []
  resultsUnmatch: any[] = []
  page = new Page({})
  idCompanyReport: any
  widthColumn = 0
  tableSearchBoolean = false
  public companyActivity: ResultPackage[] = []
  public pageCompany = new Page({});
  public trialActivity: ResultPackage[] = []
  public pageTrial = new Page({});
  constructor(
    private dashboardService: DashboardService,
    public _coreTranslationService: CoreTranslationService,
    private _modalService: NgbModal,
    private elementRef: ElementRef,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this._coreTranslationService.translate(enPackage);
    this._coreTranslationService.translate(vnPackage);
   }
  getStatUsed(){
    this.loading = true
    this.dashboardService.statSearchMatch().subscribe(
      (res)=>{
        this.loading = false
        this.search = res.search;
        this.search = this.search.map((search)=>{
          return{
            ...search,
            total: search.users.reduce((sum, user) => sum + user.total, 0),
          }
        })
        this.recommend = res.recommend;
        this.recommend = this.recommend.map((recommend)=>{
          return{
            ...recommend,
            total: recommend.users.reduce((sum, user) => sum + user.total, 0),
          }
        })
        this.unmatch_report = res.unmatch_report;
        this.lengthUsersSearch = Array.from(
          { length: this.search.length },
          (_, i) => 0
        );
        this.lengthUsersRecommend = Array.from(
          { length: this.recommend.length },
          (_, i) => 0
        );
      },(err)=>{
        console.log("err",err)
        this.loading = false
      }
    )
  }
  rowDetailsToggleExpand(type, row, rowIndex) {
    if(type==1){
      this.tableSearch.rowDetail.toggleExpandRow(row);
      this.lengthUsersSearch[rowIndex] = row?.users?.length;
    }else if(type==2){
      this.tableRecommend.rowDetail.toggleExpandRow(row);
      this.lengthUsersRecommend[rowIndex] = row?.users?.length;
    }
  }

  reportUnmatchResume(id:any){
    this.idCompanyReport = id
    this.dashboardService.reportUnmatchResume(this.page.pageNumber, this.page.size, id).subscribe((res)=>{
      this.resultsUnmatch = res.results;
      const currentPageNumber = this.page.pageNumber;
        const currentPageSize = this.page.size;
        this.page = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
    })
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.reportUnmatchResume(this.idCompanyReport)
  }
  modalOpenUnmatchReport(modal: any, id:any) {
    this._modalService.open(modal, {
      centered: true,
      size: 'xl'
    });
    this.reportUnmatchResume(id);
  }
  getCompanyActivity(url_dynamic: string){
    this.dashboardService.companyAcivity(url_dynamic, this.pageCompany.pageNumber,this.pageCompany.size).subscribe(
      (res)=>{
        this.companyActivity = res.results
        const currentPageNumber = this.pageCompany.pageNumber;
        const currentPageSize = 5;
        this.pageCompany = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        
      },
      (err)=>{
        console.log(err)
      }
    );
  }
  getTrialActivity(url_dynamic: string){
    this.dashboardService.trialActivity(url_dynamic, this.pageTrial.pageNumber,this.pageTrial.size).subscribe(
      (res)=>{
        this.trialActivity = res.results
        const currentPageNumber = this.pageTrial.pageNumber;
        const currentPageSize = 5;
        this.pageTrial = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        console.log(err)
      }
    )
  }
  convertCost(cost: any){
    return new Intl.NumberFormat(
          'vi-IN', 
          { style: "currency", currency: "VND" }
      ).format(cost)
  }
  modalOpenPackage(modal: any) {
    this._modalService.open(modal, {
      centered: true,
    });
  }
  ngOnInit(): void {
    this.getStatUsed();
    this.getCompanyActivity('');
    this.getTrialActivity('');
    const resizeTableObserver = new ResizeObserver((entries) => {
      this.widthColumn = entries[0].contentRect.width / 4;
    });
    resizeTableObserver.observe(
      this.elementRef.nativeElement.querySelector(
        "#companyW"
      )
    );
  }

}
