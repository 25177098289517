<div class="content-wrapper container-xxxl p-0 card">
  <div class="content-body card-body">
    <ul ngbNav #nav="ngbNav" class="nav nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink (click)="tabFile()"><span [translate]="'TALENT_POOL.RESUME_MATCHING.MATCHING_CV'"></span></a>
        <ng-template ngbNavContent>
          <div class="tab-pane active" id="file" aria-labelledby="file-tab" role="tabpanel">
            <form class="form form-horizontal" [formGroup]="MatchingFileForm">
              <div class="row">
                <div class="col-6">
                  <h3 class="text-center">CV</h3>
                  <div class="col-12">
                    
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.CV_FILE'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <input class="form-control" #InputVar (change)="onFileSelect($event)" type="file"
                              name="file" accept="image/png, image/jpeg, application/pdf, .docx, .xlsx, doc" required />
                        <div *ngIf="(submitted && ff.file.errors)||(ff.file.invalid &&
                        ff.file.touched)" class="invalid-feedback">
                          <div [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <h3 class="text-center">JD</h3>
                  <div class="col-12">
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.TITLE'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <input [ngClass]="{
                            'is-invalid': (submitted && ff.title.errors)||(ff.title.invalid &&
                            ff.title.touched)
                          }" type="text" id="title" formControlName="title" class="form-control" name="title" />
                        <div *ngIf="(submitted && ff.title.errors)||(ff.title.invalid &&
                        ff.title.touched)" class="invalid-feedback">
                          <div [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.DESCRIPTION'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <textarea rows="5" [ngClass]="{
                            'is-invalid': (submitted && ff.description.errors)||(ff.description.invalid &&
                            ff.description.touched)
                          }" type="text" id="description" class="form-control" formControlName="description" name="description">
                        </textarea>
                        <div *ngIf="(submitted && ff.description.errors)||(ff.description.invalid &&
                          ff.description.touched)" class="invalid-feedback">
                          <div [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'">
          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIREMENTS'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <textarea rows="5" [ngClass]="{
                            'is-invalid': (submitted && ff.requirements.errors)||(ff.requirements.invalid &&
                            ff.requirements.touched)
                          }" type="text" id="requirements" class="form-control" formControlName="requirements"
                          name="requirements">
                        </textarea>
                        <div *ngIf="(submitted && ff.requirements.errors)||(ff.requirements.invalid &&
                          ff.requirements.touched)" class="invalid-feedback">
                          <div *ngIf="ff.requirements.errors.required" [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'">
          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        
                </div>
                
              </div>
              <div class="d-flex justify-content-center">
                <!-- <button type="submit" rippleEffect class="btn btn-primary mr-1"
                  [translate]="'TALENT_POOL.RESUME_MATCHING.SUBMIT'"></button> -->
                  <button *ngIf="isLoading;else noLoading" class="btn btn-primary btn-icon mr-2 place-order" disabled (click)="onSubmitMatchingFile()">
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    <span [translate]="'TALENT_POOL.RESUME_MATCHING.SUBMIT'"></span>
                  </button>
                  <ng-template #noLoading>
                    <button
                        class="btn btn-primary btn-icon mr-2 place-order w-btn-matching"
                        rippleEffect
                        type="button"
                        (click)="onSubmitMatchingFile()"
                        [disabled]="MatchingFileForm.invalid"
                    >
                    <span [translate]="'TALENT_POOL.RESUME_MATCHING.SUBMIT'"> </span>
                  </button>
                  </ng-template>
                <button type="reset" rippleEffect class="btn btn-secondary w-btn-matching"
                  [translate]="'TALENT_POOL.RESUME_MATCHING.RESET'"></button>
              </div>
            </form>
            
          </div>

        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink (click)="tabLink()"><span [translate]="'TALENT_POOL.RESUME_MATCHING.MATCHING_CV_LINK'"></span></a>
        <ng-template ngbNavContent>
          <div class="tab-pane" id="link" aria-labelledby="link-tab" role="tabpanel">
            <form class="form form-horizontal" [formGroup]="MatchingForm">
              <div class="row">
                <div class="col-6">
                  <h3 class="text-center">CV</h3>
                  <div class="col-12">
                    
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.CV_LINK'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <input [ngClass]="{
                            'is-invalid': (submitted && f.url.errors)||(f.url.invalid &&
                            f.url.touched)
                          }" type="text" id="url" formControlName="url" class="form-control" name="url" />
                        <div *ngIf="(submitted && f.url.errors)||(f.url.invalid &&
                        f.url.touched)" class="invalid-feedback">
                          <div [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <h3 class="text-center">JD</h3>
                  <div class="col-12">
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.TITLE'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <input [ngClass]="{
                            'is-invalid': (submitted && f.title.errors)||(f.title.invalid &&
                            f.title.touched)
                          }" type="text" id="title" formControlName="title" class="form-control" name="title" />
                        <div *ngIf="(submitted && f.title.errors)||(f.title.invalid &&
                        f.title.touched)" class="invalid-feedback">
                          <div [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.DESCRIPTION'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <textarea rows="5" [ngClass]="{
                            'is-invalid': (submitted && f.description.errors)||(f.description.invalid &&
                            f.description.touched)
                          }" type="text" id="description" class="form-control" formControlName="description" name="description">
                        </textarea>
                        <div *ngIf="(submitted && f.description.errors)||(f.description.invalid &&
                          f.description.touched)" class="invalid-feedback">
                          <div [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'">
          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group row">
                      <div class="col-sm-3 col-form-label">
                        <span [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIREMENTS'"></span><b style="color:red">*</b>
                      </div>
                      <div class="col-sm-9">
                        <textarea rows="5" [ngClass]="{
                            'is-invalid': (submitted && f.requirements.errors)||(f.requirements.invalid &&
                            f.requirements.touched)
                          }" type="text" id="requirements" class="form-control" formControlName="requirements"
                          name="requirements">
                        </textarea>
                        <div *ngIf="(submitted && f.requirements.errors)||(f.requirements.invalid &&
                          f.requirements.touched)" class="invalid-feedback">
                          <div *ngIf="f.requirements.errors.required" [translate]="'TALENT_POOL.RESUME_MATCHING.REQUIRE'">
          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 hidden">
                    <div class="form-group row">
                      <div class="col-sm-2 col-form-label">
                        Mode
                      </div>
                      <div class="col-sm-10">
                        <input type="text" id="mode" class="form-control" formControlName="mode" name="mode" />
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div class="d-flex justify-content-center">
                <!-- <button type="submit" rippleEffect class="btn btn-primary mr-1"
                  [translate]="'TALENT_POOL.RESUME_MATCHING.SUBMIT'"></button> -->
                  <button *ngIf="isLoading;else noLoading" class="btn btn-primary btn-icon mr-2 place-order w-btn" disabled>
                    <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    <span [translate]="'TALENT_POOL.RESUME_MATCHING.SUBMIT'"></span>
                  </button>
                  <ng-template #noLoading>
                    <button
                        class="btn btn-primary btn-icon mr-2 place-order w-btn"
                        rippleEffect
                        type="button"
                        (click)="onSubmit()"
                        [disabled]="MatchingForm.invalid"
                    >
                    <span [translate]="'TALENT_POOL.RESUME_MATCHING.SUBMIT'"> </span>
                  </button>
                  </ng-template>
                <button type="reset" rippleEffect class="btn btn-secondary w-btn"
                  [translate]="'TALENT_POOL.RESUME_MATCHING.RESET'"></button>
              </div>
            </form>
            
          </div>

        </ng-template>
      </li>

    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
    
  </div>
</div>
<div class="content-wrapper container-xxxl p-0 card" *ngIf="resultMatching?.id">
  <div class="content-body card-body">
    <div class="d-flex justify-content-between">
      <div class="col-md-10">
        <!-- <h4 class="card-title">{{ detail_resume.current_title }}</h4> -->
        <div class="media">
            <div class="avatar mr-50">
                <img src="assets/images/avatars/iconPersion.png" alt="Avatar" width="24"
                    height="24" />
            </div>
            <div class="media-body">
  
                <h4 class="text-body">{{resultMatching.resume.full_name}}</h4>
                
            </div>
        </div>
        <div class="my-1 py-25">
          <a href="javascript:void(0);" *ngFor="let skill of resultMatching.resume.skills">
            <div class="badge badge-pill bg-light-primary mr-50">
              {{ skill }}
            </div>
          </a>
        </div>
        <div class="mt-2">
          <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_CANDIDATE.WORK_EXPERIENCE'">
          </h5>

          <div class="card-body">
            <ul class="timeline" *ngFor="let experience of resultMatching.resume.experiences">
              <li class="timeline-item">
                <span class="timeline-point timeline-point-indicator"></span>
                <div class="timeline-event">
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>{{experience.company}}</h6>
                    <span
                      class="timeline-event-time">{{experience.start_time|date}}-{{experience.end_time|date}}</span>
                  </div>
                  <p>{{experience.position}}</p>
                  <div class="media align-items-center">
                    <div class="media-body ml-1" [innerHTML]="experience.detail.trim().split('\n').join('<br>')">
                    </div>
                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
        <div class="mt-2">
          <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_CANDIDATE.EDUCATION'"></h5>
          <div class="card-body">
            <ul class="timeline" *ngFor="let education of resultMatching.resume.educations">
              <li class="timeline-item">
                <span class="timeline-point timeline-point-indicator"></span>
                <div class="timeline-event">
                  <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                    <h6>{{education.school}}</h6>
                    <span class="timeline-event-time">{{education.start_time|date}}-{{education.end_time|date}}</span>
                  </div>
                  <p>{{education.major}}</p>
                  <div class="media align-items-center">
                    <div *ngIf="education.diploma!=null && education.diploma!=''"><span class="font-weight-bolder"
                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.DIPLOMA'"></span>: {{education.diploma}}</div>


                  </div>
                  <div class="media align-items-center">

                    <div *ngIf="education.gpa!=null && education.gpa!=''"><span class="font-weight-bolder"
                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.GPA'"></span>: {{education.gpa}}</div>

                  </div>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-2" *ngIf="resultMatching?.result?.overall_score>=0;else noResult">
        <h4 class="text-body" [translate]="'TALENT_POOL.RESUME_MATCHING.OVERSCORE'"></h4>
        <app-circle-chart [overallScore]="resultMatching.result.overall_score*100">
        </app-circle-chart>
      </div>
      <ng-template #noResult>
        <div class="col-md-2">
          <h4 class="text-body" [translate]="'TALENT_POOL.RESUME_MATCHING.OVERSCORE'"></h4>
          <p [translate]="'TALENT_POOL.RESUME_MATCHING.NO_RESULT'"></p>
        </div>
      </ng-template>
    </div>
  </div>
</div>