<div class="content-wrapper container-xxxl p-0 card mb-0">
    <div class="content-body card-body">
        <div class="d-flex" id="h-search">
            <div class="col-md-4" style="overflow-y: auto;">
                <div>
                    <div class="row align-items-center">
                        <h2 class="col-auto" [translate]="'TALENT_POOL.DETAIL_JOB.SEARCH_RESUME'"></h2>
                        <div class="col-auto">
                            <button placement="bottom"
                                [ngbTooltip]="'TALENT_POOL.SEARCH_HISTORY.SEARCH_HISTORY'|translate" type="button"
                                (click)="toggleSidebar('search-history')" class="btn btn-icon btn-primary"
                                rippleEffect container="body"><i style="margin-right: 3px;" class="fa fa-history"></i> </button>
                        </div>
                    </div>

                    <div>
                        <form (ngSubmit)="(SearchCv.form.valid)" #SearchCv="ngForm">
                            <!-- <div class="form-group">
                                <div class=" col-form-label">
                                    <label for="job_title"><b>JobTitle</b></label>
                                </div>
                                <div>
                                    <input
                                    [(ngModel)]="job_title"
        
                                    type="text"
                                    class="form-control"
                                    id="job_title"
                                    name="job_title"
                                    placeholder="Enter job title"
                                />
                                </div>
                            </div> -->
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="talent_pool"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.TALENT_POOL'">Address</b>
                                        <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>

                                    </label>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="talentPools" bindLabel="name" bindValue="id"
                                        [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.CHOOSE_TALENT_POOL'|translate"
                                        name="talent_pool" [(ngModel)]="talent_pool">

                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="keyword"><b [translate]="'TALENT_POOL.CREATE_JOB.KEYWORDS'"></b></label>
                                    <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="customKeyword" [addTag]="true" multiple="true"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"
                                        [(ngModel)]="keyword" name="keyword">
                                    </ng-select>

                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="selectedCategory"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.CATEGORY'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="categoryItems" bindLabel="name" bindValue="value"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_CATEGORY'|translate"
                                        name="selectedCategory" [(ngModel)]="selectedCategory" [multiple]="true">

                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="experience_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.EXPERIENCE_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.SIMILARITY_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <textarea (keydown.enter)="onSubmit()" rows="2" [(ngModel)]="experience_bool_expression" type="text"
                                        class="form-control" id="experience_bool_expression"
                                        name="experience_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_TEXTAREA'|translate"></textarea>
                                </div>
                            </div>
                            <div class="">
                                <div class=" col-form-label">
                                    <label for="years_of_experience_from"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.YEARS_OF_EXPERIENCE'">Address</b>
                                            </label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                            
                                </div>
                                <div class="row">
                                    <div class="form-group col-6 mb-0">

                                        <div>
                                            <input (keydown.enter)="onSubmit()" [(ngModel)]="years_of_experience_from" type="number"
                                                class="form-control" id="years_of_experience_from"
                                                name="years_of_experience_from"
                                                [placeholder]="'TALENT_POOL.SEARCH_RESUME.YEARS_OF_EXPERIENCE_FROM_PLACEHOLDER'|translate"
                                                [class.error]="YearOfExperienceFromRef.invalid && YearOfExperienceFromRef.touched"
                                                #YearOfExperienceFromRef="ngModel" pattern="^\d+(\.\d+)?$" />
                                            <span
                                                *ngIf="YearOfExperienceFromRef.invalid && YearOfExperienceFromRef.touched"
                                                class="invalid-form">
                                                <small *ngIf="YearOfExperienceFromRef.errors.pattern"
                                                    class="form-text text-danger"
                                                    [translate]="'TALENT_POOL.CREATE_JOB.VALID_NUMBER'"></small>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group col-6 mb-0">

                                        <div>
                                            <input (keydown.enter)="onSubmit()" [(ngModel)]="years_of_experience_to" type="number"
                                                class="form-control" id="years_of_experience_to"
                                                name="years_of_experience_to"
                                                [placeholder]="'TALENT_POOL.SEARCH_RESUME.YEARS_OF_EXPERIENCE_TO_PLACEHOLDER'|translate"
                                                #YearsOfExperienceToRef="ngModel"
                                                [class.error]="YearOfExperienceToRef.invalid && YearOfExperienceToRef.touched"
                                                #YearOfExperienceToRef="ngModel" pattern="^\d+(\.\d+)?$" />
                                            <span *ngIf="YearOfExperienceToRef.invalid && YearOfExperienceToRef.touched"
                                                class="invalid-form">
                                                <small *ngIf="YearOfExperienceToRef.errors.pattern"
                                                    class="form-text text-danger"
                                                    [translate]="'TALENT_POOL.CREATE_JOB.VALID_NUMBER'"></small>
                                            </span>
                                            <span
                                                *ngIf="years_of_experience_from >= years_of_experience_to && years_of_experience_to != null"
                                                class="invalid-form">
                                                <small class="form-text text-danger"
                                                    [translate]="'TALENT_POOL.CREATE_JOB.INVALID'"></small>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="education_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.EDUCATION_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.SIMILARITY_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <textarea (keydown.enter)="onSubmit()" rows="2" [(ngModel)]="education_bool_expression" type="text"
                                        class="form-control" id="education_bool_expression"
                                        name="education_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_TEXTAREA'|translate"
                                        style="width: 100%;"></textarea>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="current_title_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.CURRENT_TITLE_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="customTitle" [addTag]="true" multiple="true"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"
                                        [(ngModel)]="current_title_bool_expression"
                                        name="current_title_bool_expression">
                                    </ng-select>

                                </div>

                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="current_company_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.CURRENT_COMPANY_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="customCompany" [addTag]="true" multiple="true"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate"
                                        [(ngModel)]="current_company_bool_expression"
                                        name="current_company_bool_expression">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="skill"><b [translate]="'TALENT_POOL.SEARCH_RESUME.SKILL'"></b></label>
                                    <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="customSkill" [addTag]="true" multiple="true"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_SKILL'|translate"
                                        [(ngModel)]="skill" name="skill">
                                    </ng-select>

                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="selectedCity"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'">Address</b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <ng-select (keydown.enter)="onSubmit()" [items]="city" bindLabel="name" bindValue="value"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_ADDRESS'|translate"
                                        name="selectedCity" [(ngModel)]="selectedCity" [multiple]="true">

                                    </ng-select>
                                </div>
                            </div>


                            <!-- <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="fulltext_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.FULLTEXT_FILTER'"></b></label>
                                </div>
                                <div>
                                    <input [(ngModel)]="fulltext_bool_expression" type="text" class="form-control"
                                        id="fulltext_bool_expression" name="fulltext_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER'|translate" />
                                </div>
                            </div> -->
                            <div class="form-group mb-0">
                                <div class=" col-form-label">
                                    <label for="full_name_bool_expression"><b
                                            [translate]="'TALENT_POOL.SEARCH_RESUME.FULLNAME_FILTER'"></b></label>
                                            <button rippleEffect class="btn btn-icon btn-sm" placement="bottom"
                                            [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.EXTRACT_TOOLTIP' | translate" style="padding-left: 0px" container="body">
                                            <i data-feather="info" size="18"></i>
                                        </button>
                                </div>
                                <div>
                                    <input (keydown.enter)="onSubmit()" [(ngModel)]="full_name_bool_expression" type="text" class="form-control"
                                        id="full_name_bool_expression" name="full_name_bool_expression"
                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_TEXTAREA'|translate" />
                                </div>
                            </div>

                            <div class="mt-1">
                                <button [disabled]="((years_of_experience_from >= years_of_experience_to) && years_of_experience_from!=null && years_of_experience_to!=null)||
                                    years_of_experience_from < 0 || years_of_experience_to < 0"
                                    style="height: 38px;padding:6px" (click)="onSubmit()" class="btn btn-primary"
                                    rippleEffect> <span [translate]="'TALENT_POOL.SEARCH_RESUME.SEARCH'"></span>
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-8" style="position: relative; overflow-y: auto;">
                <form [formGroup]="saveCandidatesForm">
                    <div class="">
                        <!-- <div class="mb-3" *ngIf="loading;else numResult"><app-loading></app-loading></div> -->
                        <div class="d-flex justify-content-center" *ngIf="loading; else numResult" id="loading"
                            style="margin: 50px auto">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <ng-template #numResult>
                            <div class=" mb-2">
                                {{num_result}} <span [translate]="'TALENT_POOL.SEARCH_RESUME.RESUMES'"></span> <button rippleEffect class="btn btn-icon btn-sm" style="margin-left: 4px;" placement="bottom"
                                [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.TOOLTIP_RESULT_SEARCH' | translate" style="padding-left: 0px" container="body"><i data-feather="info" size="18"></i></button> </div>
                            <div class="mb-2" *ngIf="resultSearchCv.length>0">
                                <div class="col-12 row align-items-center">
                                    <div class="col-1">
                                        <input *ngIf="idCandidates.length==resultSearchCv.length;else noCheckedAll"
                                            value="true" (change)="unSelectAll($event)" type="checkbox"
                                            class="form-check" checked>
                                        <ng-template #noCheckedAll>
                                            <input value="false" (change)="selectAll($event)" type="checkbox"
                                                class="form-check">
                                        </ng-template>

                                    </div>
                                    <div>
                                        <label class="" for="select-all"
                                            translate="TALENT_POOL.SEARCH_RESUME.SELECT_ALL">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2"
                                *ngFor="let resultSearchCv_ of resultSearchCv| paginate: { itemsPerPage: pageSizeNum, currentPage: page_num, totalItems: num_page }; let i=index">
                                <div class="col-12 row align-items-center">
                                    <div class="col-1">
                                        <input *ngIf="idCandidates.includes(resultSearchCv_.id);else noChecked"
                                            [value]="resultSearchCv_.id" (change)="onCheckboxChange($event)"
                                            type="checkbox" class="form-check" checked>
                                        <ng-template #noChecked>
                                            <input [value]="resultSearchCv_.id" (change)="onCheckboxChange($event)"
                                                type="checkbox" class="form-check">
                                        </ng-template>
                                    </div>
                                    <div class="card card-employee-task col-11" style="border: 1px solid #dedede;">
                                        <div class="card-body">
                                            <app-resume-talent-pool
                                                [ResultSearchCv]="resultSearchCv_" [formData]="formData" [liked]="resultSearchCv_.is_match" (reportResumeEvent)="reportResume($event)" (deleteReportResumeEvent)="deleteReportResume($event)"></app-resume-talent-pool>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                    </div>
                    <div *ngIf="resultSearchCv.length>0" class="row justify-content-between align-items-center">
                        <div class="col-auto">
                            <pagination-controls previousLabel="Trước" 
                            nextLabel="Tiếp" (pageChange)="pageChangeEventGetCv($event)"></pagination-controls>
                        </div>
                    </div>
                </form>

            </div>

        </div>
        <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="search-history"
            overlayClass="modal-backdrop">
            <app-search-history (detailHistorySearch)="searchDetailHistory($event)"
                [historySearch]="historySearch"></app-search-history>
        </core-sidebar>
    </div>
</div>
<div class="popup" [@slideFade]="showActionsPopup ? 'visible' : 'hidden'">
    <div class="button-container" *ngIf="showActionsPopup">
        <button type="button" class="btn btn-link action-btn rounded-left" style="margin: 0"
            (click)="unselectAllRows()">
            <span data-feather="x"></span>
        </button>

        <div>
            <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
                id="dropdownMenuButton" rippleEffect (click)="modalOpenSaveCv(modalBasicSaveCv)">
                <div style="margin: 0 auto 5px auto">
                    <span data-feather="save"></span>
                </div>
                <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.SAVE'"></span>
            </button>
            <ng-template #modalBasicSaveCv let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" [translate]="'TALENT_POOL.SEARCH_RESUME.SELECT_JD'"></h4>
                    <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
                </div>
                <div class="me-4 mt-3">
                    <div class="d-flex justify-content-end">

                        <div class="col-md-6">
                            <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                            <input type="text" class="form-control" id="job-search"
                                [placeholder]="'TALENT_POOL.SEARCH_RESUME.SEARCH_JOB' | translate"
                                [(ngModel)]="searchText" (input)="Search()" />
                        </div>

                    </div>
                </div>
                <form [formGroup]="formCheckBoxSaveCv">
                    <div class="modal-body" ngbAutofocus>
                        <!-- <ng-container *ngIf="list_all_jd.length===0">
                            <p [translate]="'TALENT_POOL.SEARCH_RESUME.NOT_JOB'"> <a routerLink="/manage-job/create-job" [translate]="'TALENT_POOL.SEARCH_RESUME.CREATE_JOB'"></a> </p>
                        </ng-container> -->
                        <ng-container>

                            <table class="table align-middle table-bordered" style="height: 300px;">
                                <thead>
                                    <tr>
                                        <th class="pxp-is-checkbox" style="width: 1%;"></th>
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.JOB'"></th>
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.HCM_SYSTEM_ID'"></th>
                                        <!-- <th [translate]="'TALENT_POOL.MANAGE_JOB.CATEGORY'"></th> -->
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.TYPE'"></th>
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.LEVEL'"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr
                                        *ngFor="let list_all_jd_ of list_all_jd| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                        <td *ngIf="arr_checkboxSaveCv==list_all_jd_.id;else noChecked">
                                            <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)"
                                                type="checkbox" class="form-check" checked>

                                        </td>
                                        <ng-template #noChecked>
                                            <td>
                                                <input [value]="list_all_jd_.id"
                                                    (change)="onCheckboxChangeSaveCv($event)" type="checkbox"
                                                    class="form-check">

                                            </td>
                                        </ng-template>


                                        <td>
                                            <div class="">{{list_all_jd_.job_title}}</div>
                                        </td>
                                        <td>
                                            <div class="">{{list_all_jd_.hcm_system_jd}}</div>
                                        </td>
                                        <!-- <td>
                                            <div class="">{{list_all_jd_.industry}}</div>
                                        </td> -->
                                        <td>
                                            <div class="">{{list_all_jd_.job_type}}</div>
                                        </td>
                                        <td>
                                            <div class="">{{list_all_jd_.job_level}}</div>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">

                                    <nav class="mt-2 mt-sm-0" aria-label="Jobs list pagination">

                                        <ngb-pagination [collectionSize]="list_all_jd.length" [(page)]="page"
                                            [pageSize]="pageSize" [maxSize]="2" [rotate]="true" [boundaryLinks]="true">


                                        </ngb-pagination>
                                    </nav>
                                </div>

                            </div>
                        </ng-container>

                    </div>

                    <div class="modal-footer">

                        <button [disabled]="!arr_checkboxSaveCv" (click)="FormCheckBoxSaveCv()"
                            class="btn btn-primary mr-1" rippleEffect (click)="modal.dismiss('Cross click')"
                            [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE'">

                        </button>
                        <button (click)="modal.dismiss('Cross click')" type="button" (click)="cancel()" rippleEffect
                            class="btn btn-secondary" [translate]="'TALENT_POOL.CREATE_JOB.CANCEL'"></button>

                    </div>
                </form>
            </ng-template>
        </div>
    </div>
</div>