import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ListCompany, User } from 'app/auth/models';
import { Page } from 'app/main/resume-parser/models/paging.model';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enTalentpoolLocale} from '../../talent-pool/i18n/en'
import { locale as vnTalentpoolLocale} from '../../talent-pool/i18n/vn'
import { FormBuilder, FormControl } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-match-report',
  templateUrl: './match-report.component.html',
  styleUrls: ['./match-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MatchReportComponent implements OnInit {
  listReportResumeSearch: any
  listReportResumeRecommend: any
  listReportNotMatchResumeRecommend: any

  @ViewChild(DatatableComponent) tableReportResumneSearch: DatatableComponent;
  @ViewChild(DatatableComponent) tableReportResumeRecommend: DatatableComponent;
  public pageReportResumeSearch = new Page({})
  public pageReportResumeRecommend = new Page({})
  public pageReportNotMatchResumeRecommend = new Page({})

  url_dynamic: any
  public users: User[] = []
  public ColumnMode = ColumnMode;
  //Period
    public companys = [{id:19, name:""}]
    public subCompanys: any
    FilterStatForm = this.fb.group({
      company: new FormControl(''),
      sub_company: new FormControl([]),
      period: new FormControl([]),
    })
    public displayLast7day = false
    public user = new User()
    public is_superuser = false
    public list_compay: ListCompany = new ListCompany()
    public options:any
    tag_export = false
    public loading=false
    public selectedTabSearch = false
    public selectedTabNotMatchSearch = false
    public selectedTabRecommend = false
    public selectedTabNotMatchRecommend = false

  constructor(
    private dashboardService: DashboardService,
    public _coreTranslationService: CoreTranslationService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private resumesService: ResumeParserService,
    private modalService: NgbModal,
  ) { 
    this._coreTranslationService.translate(enTalentpoolLocale);
    this._coreTranslationService.translate(vnTalentpoolLocale);
  }
  downloadReport(){
      this.tag_export = true
      let url_dynamic = ""
      let sub_company= this.FilterStatForm.value?.sub_company
      if(sub_company?.length>0){
        url_dynamic = url_dynamic+ "&sub_company="+sub_company
      }
      let start_time_raw = this.FilterStatForm.value.period?.length>0?this.FilterStatForm.value.period[0]: undefined;
    let end_time_raw = this.FilterStatForm.value.period?.length>0?this.FilterStatForm.value.period[1]: undefined;
      const gmtOffsetStart = 7;
      const gmtOffsetEnd = 31;

      let start_time = !this.FilterStatForm.value.period?undefined:new Date(new Date(start_time_raw.getFullYear(),start_time_raw.getMonth(),start_time_raw.getDate()).getTime()+ gmtOffsetStart * 60 * 60 * 1000).toISOString()
    let end_time = !this.FilterStatForm.value.period?undefined:new Date(new Date(end_time_raw.getFullYear(),end_time_raw.getMonth(),end_time_raw.getDate()).getTime()+ gmtOffsetEnd * 60 * 60 * 1000).toISOString()
      if(start_time!=undefined&& start_time!=null){
        url_dynamic =url_dynamic+ "&start="+start_time
      }
      if(end_time!=undefined&& end_time!=null){
        url_dynamic =url_dynamic+ "&end="+end_time
      }
      this.resumesService.downloadStatReportMatch(url_dynamic).subscribe(
        (res) => {
          const file_name = (start_time&&end_time)?'Bao_cao_danh_gia_tinh_nang_'+ start_time.split('T')[0]+'_'+end_time.split('T')[0] + '.xlsx':'Bao_cao_danh_gia_tinh_nang.xlsx'
            let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' });
            saveAs(blob, file_name);
            this.tag_export = false
            
        },
        (err) => {
          this.tag_export = false
          
        }
      )
    }
  changeCompany(e: any) {
    this.company?.setValue(e.target.value, {
      onlySelf: true,
    });
    this.getListSubCompany(e.target.value)
  }
  getListSubCompany(company_id: any){
    this.authService.listSubCompany(company_id).subscribe(
      (res)=>{
        this.subCompanys = res
      }
    )
  }
  get company() {
    return this.FilterStatForm.get('company');
  }
  get period():any{
    return this.FilterStatForm.get('period')
  }
  onSubmit(){
    this.FilterStat();
    
  }
  onReset(){
    this.loading = true
    this.displayLast7day = true
    this.period.reset()
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(company!=undefined&& company!=null&&company!=''){
      url_dynamic = url_dynamic+ "&company="+company
    }
    let sub_company= this.FilterStatForm.value.sub_company
    if(sub_company?.length>0){
      url_dynamic = url_dynamic+ "&sub_company="+sub_company
    }
    this.url_dynamic = url_dynamic
    this.pageReportResumeRecommend.pageNumber = 0
    this.pageReportNotMatchResumeRecommend.pageNumber = 0
    this.tabIsMatchReportSearch(this.selectedTabSearch, true)
    this.tabIsMatchReportSearch(this.selectedTabRecommend, false)
    this.loading = false
  }
  FilterStat(){
    this.loading = true
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    let sub_company= this.FilterStatForm.value.sub_company
    this.pageReportResumeRecommend.pageNumber = 0
    this.pageReportNotMatchResumeRecommend.pageNumber = 0


    if(this.FilterStatForm.value.period!=undefined){
      
      let start_time_raw = this.FilterStatForm.value.period[0]
      let end_time_raw = this.FilterStatForm.value.period[1]
      const gmtOffsetStart = 7;
      const gmtOffsetEnd = 31;

      let start_time = this.FilterStatForm.value.period[0]==undefined?undefined:new Date(new Date(start_time_raw.getFullYear(),start_time_raw.getMonth(),start_time_raw.getDate()).getTime()+ gmtOffsetStart * 60 * 60 * 1000).toISOString()
      let end_time = this.FilterStatForm.value.period[1]==undefined?undefined:new Date(new Date(end_time_raw.getFullYear(),end_time_raw.getMonth(),end_time_raw.getDate()).getTime()+ gmtOffsetEnd * 60 * 60 * 1000).toISOString()
      if(company!=undefined&& company!=null&&company!=''){
        url_dynamic = url_dynamic+ "&company="+company
      }
      if(sub_company!=undefined && sub_company!=null && sub_company?.length>0){
        url_dynamic = url_dynamic+ "&sub_company="+sub_company
      }
      if(start_time!=undefined&& start_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&start="+start_time
      }
      if(end_time!=undefined&& end_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&end="+end_time
      }
      this.url_dynamic =url_dynamic
      this.tabIsMatchReportSearch(this.selectedTabSearch, true)
      this.tabIsMatchReportSearch(this.selectedTabRecommend, false)
    }else{
      this.url_dynamic = url_dynamic
      this.tabIsMatchReportSearch(this.selectedTabSearch, true)
      this.tabIsMatchReportSearch(this.selectedTabRecommend, false)
    }
    this.loading = false
    
  }
  modalOpenInfoSearch(modalBasicInfoSearch: any){
    this.modalService.open(modalBasicInfoSearch, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  ListCompany(){
    this.authService.list_company('').subscribe(
      (res)=>{
        this.list_compay = res;
        this.companys = res
      },
      (err)=>{
      }
    )
  }
  setPageReportResumeSearch(pageInfo) {
    this.pageReportResumeSearch.pageNumber = pageInfo.offset;
    this.tabIsMatchReportSearch(this.selectedTabSearch, true)
  }
  tabIsMatchReportSearch(is_match: boolean, is_search: boolean){
    var url_dynamic = this.url_dynamic + `&is_match=${is_match}`
    if(is_search){
      if(is_match){
        this.selectedTabSearch = true
      }else{
        this.selectedTabSearch = false
      }
      
      this.dashboardService.listReportResume(url_dynamic, is_search, this.pageReportResumeSearch.pageNumber, this.pageReportResumeSearch.size).subscribe((res)=>{
        this.listReportResumeSearch = res.results
        const currentPageNumber = this.pageReportResumeSearch.pageNumber;
        const currentPageSize = this.pageReportResumeSearch.size;
        this.pageReportResumeSearch = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        })
    }else{
      if(is_match){
        this.selectedTabRecommend = true
        this.dashboardService.listReportResume(url_dynamic, is_search, this.pageReportResumeRecommend.pageNumber, this.pageReportResumeRecommend.size).subscribe((res)=>{
          this.listReportResumeRecommend = res.results
          const currentPageNumber = this.pageReportResumeRecommend.pageNumber;
          const currentPageSize = this.pageReportResumeRecommend.size;
          this.pageReportResumeRecommend = new Page({
            size: currentPageSize,
            totalElements: res.count,
            pageNumber: currentPageNumber,
          });
        })
      }else{
        this.selectedTabNotMatchRecommend = true
        this.dashboardService.listReportResume(url_dynamic, is_search, this.pageReportNotMatchResumeRecommend.pageNumber, this.pageReportNotMatchResumeRecommend.size).subscribe((res)=>{
          this.listReportNotMatchResumeRecommend = res.results
          const currentPageNumber = this.pageReportNotMatchResumeRecommend.pageNumber;
            const currentPageSize = this.pageReportNotMatchResumeRecommend.size;
            this.pageReportNotMatchResumeRecommend = new Page({
              size: currentPageSize,
              totalElements: res.count,
              pageNumber: currentPageNumber,
          });
        })
      }

      
    }
  }
  listUser() {
    this.authService.listUsers('').subscribe(
      (res) => {
        this.users = res.results
      },
    )
  }
  getEmailUser(id: any){
    var emailUser = ""
    this.users.forEach((user)=>{
      if(user.id == id){
        emailUser = user.email
      }
    })
    return emailUser
  }
  ngOnInit(): void {
    this.displayLast7day = true
    this.user = this.authService.currentUserValue
    this.listUser()
    if(this.user.is_superuser){
      this.is_superuser = true
      this.ListCompany()
      // this.getListSubCompany()
    }
    if(this.user.role == 'Admin' || this.user.role == 'Super admin'){
      this.is_superuser = true
      this.getListSubCompany(this.user.company)
    }
    let linkUrl = window.location.href
    // Get the current date
    const currentDate = new Date();
    const currentDateISOString = currentDate.toISOString();

    // Get the date 30 days before the current date
    const date30DaysAgo = new Date(currentDate);
    date30DaysAgo.setDate(currentDate.getDate() - 30);
    const date30DaysAgoISOString = date30DaysAgo.toISOString();
    let id_company = linkUrl.split("id_company=")[1]?linkUrl.split("id_company=")[1]:""
    let url_dynamic = ""
    url_dynamic =url_dynamic+ "&start="+date30DaysAgoISOString
    url_dynamic =url_dynamic+ "&end="+currentDateISOString
    this.FilterStatForm.get('period').setValue([date30DaysAgo, currentDate])
    if(id_company){
      url_dynamic = url_dynamic+ "&company="+id_company
      this.FilterStatForm.patchValue({
        company: id_company,
        // period: [currentDate, date30DaysAgo]
      })
    }
    this.url_dynamic = url_dynamic
    this.selectedTabSearch = true
    this.selectedTabRecommend = true
    this.tabIsMatchReportSearch(this.selectedTabSearch, true)
    this.tabIsMatchReportSearch(this.selectedTabRecommend, false)

  }

}
