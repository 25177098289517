<div class="content-wrapper p-0">
  <div class="content-body">
    <!-- content-header component -->
    <div class="col-12">
      <h2 class="mb-10" [translate]="'ACCOUNT_SETTING.ACCOUNT_SETTING'"></h2>
    </div>

    <!-- account setting page -->
    <section id="page-account-settings">
      <div class="row nav-vertical d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
          <ul
            ngbNav
            #navVertical="ngbNav"
            class="nav nav-pills flex-column nav-left"
            [(activeId)]="selectedTab"
          >
            <li [ngbNavItem]="'tab-overview'">
              <a
                ngbNavLink
                [routerLink]="'/account-settings/tab-overview'"
                class="nav-link d-flex py-75"
                id="account-vertical-general"
                data-toggle="pill"
                aria-expanded="true"
              >
                <i data-feather="user" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.GENERAL.TITLE'"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form [formGroup]="EditProfileForm" class="validate-form mt-2">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label
                              for="first_name"
                              [translate]="'ACCOUNT_SETTING.GENERAL.FIRST_NAME'"
                            ></label>
                            <input
                              type="text"
                              formControlName="first_name"
                              class="form-control"
                              id="first_name"
                              name="first_name"
                              [placeholder]="
                                'ACCOUNT_SETTING.GENERAL.FIRST_NAME' | translate
                              "
                            />
                            <span
                              *ngIf="
                                EditProfileForm.hasError('required', 'first_name') &&
                                EditProfileForm.touched
                              "
                            >
                              <small
                                class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"
                              ></small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label
                              for="last_name"
                              [translate]="'ACCOUNT_SETTING.GENERAL.LAST_NAME'"
                            ></label>
                            <input
                              type="text"
                              formControlName="last_name"
                              class="form-control"
                              id="last_name"
                              name="last_name"
                              [placeholder]="
                                'ACCOUNT_SETTING.GENERAL.LAST_NAME' | translate
                              "
                            />
                            <span
                              *ngIf="
                                EditProfileForm.hasError('required', 'last_name') &&
                                EditProfileForm.touched
                              "
                            >
                              <small
                                class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"
                              ></small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label
                              for="email"
                              [translate]="'ACCOUNT_SETTING.GENERAL.EMAIL'"
                            ></label>
                            <input
                              disabled
                              type="email"
                              formControlName="email"
                              class="form-control"
                              id="email"
                              name="email"
                              [placeholder]="'ACCOUNT_SETTING.GENERAL.EMAIL' | translate"
                            />
                            <span
                              *ngIf="
                                EditProfileForm.hasError('required', 'email') &&
                                EditProfileForm.touched
                              "
                            >
                              <small
                                class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"
                              ></small>
                            </span>
                          </div>
                        </div>
                        <!-- <div class="col-12 mt-75">
                          <div class="alert alert-warning mb-50" role="alert">
                            <h4 class="alert-heading">Your email is not confirmed. Please check your inbox.</h4>
                            <div class="alert-body">
                              <a href="javascript: void(0);" class="alert-link">Resend confirmation</a>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-12">
                          <button
                            (click)="saveProfile()"
                            class="btn btn-primary mt-2 mr-1"
                            rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'"
                          ></button>
                          <!-- <button type="reset" class="btn btn-outline-secondary mt-2" rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"></button> -->
                        </div>
                        <ng-container *ngIf="errorMessagesUpdateUser">
                          <div class="alert alert-danger" role="alert">
                            {{ errorMessagesUpdateUser }}
                          </div>
                        </ng-container>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="'tab-change-pw'">
              <a
                ngbNavLink
                [routerLink]="'/account-settings/tab-change-pw'"
                class="nav-link d-flex py-75"
                id="account-vertical-password"
                data-toggle="pill"
                aria-expanded="false"
              >
                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.PASSWORD.TITLE'"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <!-- form -->
                    <form [formGroup]="ChangeProfileForm" class="validate-form">
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-old-password"
                              ><span
                                [translate]="'ACCOUNT_SETTING.PASSWORD.OLD_PASS'"
                              ></span
                              ><b style="color: red">*</b></label
                            >
                            <div
                              class="input-group form-password-toggle input-group-merge"
                            >
                              <input
                                tabindex="1"
                                [type]="passwordTextTypeOld ? 'text' : 'password'"
                                class="form-control"
                                id="account-old-password"
                                name="old_password"
                                [placeholder]="
                                  'ACCOUNT_SETTING.PASSWORD.OLD_PASS' | translate
                                "
                                formControlName="old_password"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeOld,
                                      'icon-eye': !passwordTextTypeOld
                                    }"
                                    (click)="togglePasswordTextTypeOld()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                          </div>

                          <div
                            *ngIf="
                              ChangeProfileForm.hasError('required', 'old_password') &&
                              ChangeProfileForm.touched
                            "
                          >
                            <small
                              class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"
                            ></small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-new-password"
                              ><span
                                [translate]="'ACCOUNT_SETTING.PASSWORD.NEW_PASS'"
                              ></span
                              ><b style="color: red">*</b></label
                            >
                            <div
                              class="input-group form-password-toggle input-group-merge"
                            >
                              <input
                                tabindex="2"
                                [type]="passwordTextTypeNew ? 'text' : 'password'"
                                id="account-new-password"
                                name="new_password"
                                class="form-control"
                                formControlName="new_password"
                                [placeholder]="
                                  'ACCOUNT_SETTING.PASSWORD.NEW_PASS' | translate
                                "
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeNew,
                                      'icon-eye': !passwordTextTypeNew
                                    }"
                                    (click)="togglePasswordTextTypeNew()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="
                              ChangeProfileForm.hasError('required', 'new_password') &&
                              ChangeProfileForm.touched
                            "
                          >
                            <small
                              class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.GENERAL.REQUIRED'"
                            ></small>
                          </div>
                          <div
                            *ngIf="
                              ChangeProfileForm.hasError('notMatch') &&
                              ChangeProfileForm.touched
                            "
                          >
                            <small
                              class="form-text text-danger"
                              [translate]="'ACCOUNT_SETTING.PASSWORD.NOT_MATCH'"
                            ></small>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label for="account-retype-new-password"
                              ><span
                                [translate]="'ACCOUNT_SETTING.PASSWORD.RETYPE_PASS'"
                              ></span
                              ><b style="color: red">*</b></label
                            >
                            <div
                              class="input-group form-password-toggle input-group-merge"
                            >
                              <input
                                tabindex="3"
                                [type]="passwordTextTypeRetype ? 'text' : 'password'"
                                class="form-control"
                                id="account-retype-new-password"
                                name="confirm-new-password"
                                formControlName="confirmPassword"
                                [placeholder]="
                                  'ACCOUNT_SETTING.PASSWORD.NEW_PASS' | translate
                                "
                              />
                              <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"
                                  ><i
                                    class="feather font-small-4"
                                    [ngClass]="{
                                      'icon-eye-off': passwordTextTypeRetype,
                                      'icon-eye': !passwordTextTypeRetype
                                    }"
                                    (click)="togglePasswordTextTypeRetype()"
                                  ></i
                                ></span>
                              </div>
                            </div>
                            <div
                              *ngIf="
                                ChangeProfileForm.hasError('notSame') &&
                                ChangeProfileForm.touched
                              "
                            >
                              <small
                                class="form-text text-danger"
                                [translate]="'ACCOUNT_SETTING.PASSWORD.NOT_SAME'"
                              ></small>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <button
                            tabindex="4"
                            [disabled]="!ChangeProfileForm.valid"
                            type="button"
                            (click)="changePassword()"
                            class="btn btn-primary mr-1 mt-1"
                            rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'"
                          >
                            Save changes
                          </button>
                          <button
                            type="reset"
                            class="btn btn-secondary mt-1"
                            rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                    <!--/ form -->
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="'tab-companys'" *ngIf="currentUser.is_superuser">
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-vertical-company"
                data-toggle="pill"
                [routerLink]="'/account-settings/tab-companys'"
                aria-expanded="false"
              >
                <i data-feather="users" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.COMPANY.LIST_COMPANY'"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <div class="mt-2">
                      <div class="d-flex justify-content-end align-items-center mb-1">
                        <!-- <div class="">
                          <div class="d-flex justify-content-between align-items-center m-1">
                            <label
                              class="d-flex align-items-center"
                              style="max-width: 100%; flex-wrap: nowrap"
                              ><span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.SHOW'"></span>
                              <select
                                class="form-control mx-25 pr-3 show-doc"
                                [(ngModel)]="pageSize" (click)="showUsers()"
                              >
                                <option value="5">10</option>
                                <option value="10">15</option>
                                <option value="15">20</option>
                              </select>
                              <span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.USERS'"></span>
                            </label>
                          </div>
                        </div> -->
                        <div class="" *ngIf="currentUser.is_superuser">
                          <button
                            class="btn btn-primary"
                            (click)="modalOpenCreateCompany(modalBasicCompany)"
                            [translate]="'ACCOUNT_SETTING.COMPANY.CREATE_COMPANY'"
                          ></button>
                        </div>
                        <ng-template #modalBasicCompany let-modal>
                          <div class="modal-header">
                            <h5
                              class="modal-title"
                              id="myModalLabel160"
                              [translate]="'ACCOUNT_SETTING.COMPANY.CREATE_COMPANY'"
                            >
                              Tạo công ty
                            </h5>
                            <button
                              type="button"
                              class="close"
                              (click)="modalCloseCreateCompany()"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div
                            id="create-company"
                            class="bs-stepper bs-stepper-1 horizontal horizontal-wizard-example"
                          >
                            <div class="bs-stepper-header">
                              <div class="step" data-target="#create-company">
                                <button class="step-trigger">
                                  <span class="bs-stepper-box">1</span>
                                  <span class="bs-stepper-label">
                                    <span
                                      class="bs-stepper-title"
                                      [translate]="
                                        'ACCOUNT_SETTING.ACTIVITY_HISTORY.SUB_COMPANY'
                                      "
                                      >Công ty</span
                                    >
                                    <span
                                      class="bs-stepper-subtitle"
                                      [translate]="
                                        'ACCOUNT_SETTING.COMPANY.CREATE_COMPANY'
                                      "
                                      >Tạo công ty</span
                                    >
                                  </span>
                                </button>
                              </div>
                              <div class="line">
                                <i data-feather="chevron-right" class="font-medium-2"></i>
                              </div>
                              <div class="step" data-target="#config-ws">
                                <button
                                  class="step-trigger"
                                  [disabled]="true"
                                  [ngbTooltip]="'' ? 'Hãy tạo công ty' : null"
                                >
                                  <span class="bs-stepper-box">2</span>
                                  <span class="bs-stepper-label">
                                    <span class="bs-stepper-title">Admin</span>
                                    <span
                                      class="bs-stepper-subtitle"
                                      [translate]="'ACCOUNT_SETTING.COMPANY.CREATE_ADMIN'"
                                      >Tạo admin cho công ty</span
                                    >
                                  </span>
                                </button>
                              </div>
                            </div>

                            <div class="bs-stepper-content">
                              <div id="create-company" class="content">
                                <form #typeCreate="ngForm">
                                  <form [formGroup]="createCompanyForm">
                                    <div class="form-group">
                                      <label for="name"
                                        ><span
                                          [translate]="'ACCOUNT_SETTING.COMPANY.NAME'"
                                        ></span
                                        ><b *ngIf="!editBoolean" style="color: red"
                                          >*</b
                                        ></label
                                      >
                                      <input
                                        type="text"
                                        id="name"
                                        formControlName="name"
                                        class="form-control"
                                        name="name"
                                        required
                                        [ngClass]="{
                                          'is-invalid':
                                            createCompanyForm.controls.name.errors &&
                                            !createCompanyForm.controls.name.valid &&
                                            createCompanyForm.controls.name.touched
                                        }"
                                      />
                                      <div
                                        *ngIf="
                                          createCompanyForm.controls.name.errors &&
                                          !createCompanyForm.controls.name.valid &&
                                          createCompanyForm.controls.name.touched
                                        "
                                        class="invalid-feedback"
                                      >
                                        <div
                                          *ngIf="
                                            createCompanyForm.controls.name.errors
                                              .required
                                          "
                                          [translate]="
                                            'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                          "
                                        ></div>
                                      </div>
                                    </div>
                                  </form>

                                  <div class="d-flex justify-content-end">
                                    <button
                                      (click)="horizontalWizardStepperNext(typeCreate)"
                                      type="button"
                                      class="btn btn-primary btn-next"
                                      [disabled]="!createCompanyForm.valid"
                                      [ngbTooltip]="
                                        !createCompanyForm.valid
                                          ? 'Hãy điền tên công ty'
                                          : null
                                      "
                                      rippleEffect
                                    >
                                      <span
                                        class="align-middle d-sm-inline-block d-none"
                                        [translate]="'ACCOUNT_SETTING.COMPANY.CONFIRM'"
                                        >Xác nhận và tạo admin</span
                                      >
                                      <i
                                        data-feather="arrow-right"
                                        class="align-middle ml-sm-25 ml-0"
                                      ></i>
                                    </button>
                                  </div>
                                </form>
                              </div>

                              <div id="config-ws" class="content">
                                <div class="divider divider-primary mt-0">
                                  <div class="divider-text">
                                    <span style="font-size: 1.286rem">
                                      Tạo admin cho công ty
                                    </span>
                                  </div>
                                </div>
                                <form class="form" [formGroup]="createUserForm">
                                  <div class="row">
                                    <div class="form-group" class="col-12">
                                      <div class="form-group">
                                        <label for="first_name"
                                          ><span
                                            [translate]="
                                              'ACCOUNT_SETTING.SOURCE_MAPPING.FIRST_NAME'
                                            "
                                          ></span
                                          ><b *ngIf="!editBoolean" style="color: red"
                                            >*</b
                                          ></label
                                        >
                                        <input
                                          type="text"
                                          id="first_name"
                                          formControlName="first_name"
                                          class="form-control"
                                          name="first_name"
                                          required
                                          [ngClass]="{
                                            'is-invalid':
                                              f_user.first_name.errors &&
                                              !f_user.first_name.valid &&
                                              f_user.first_name.touched
                                          }"
                                        />
                                        <div
                                          *ngIf="
                                            f_user.first_name.errors &&
                                            !f_user.first_name.valid &&
                                            f_user.first_name.touched
                                          "
                                          class="invalid-feedback"
                                        >
                                          <div
                                            *ngIf="f_user.first_name.errors.required"
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                            "
                                          ></div>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label for="last_name"
                                          ><span
                                            [translate]="
                                              'ACCOUNT_SETTING.SOURCE_MAPPING.LAST_NAME'
                                            "
                                          ></span
                                          ><b *ngIf="!editBoolean" style="color: red"
                                            >*</b
                                          ></label
                                        >
                                        <input
                                          type="text"
                                          id="last_name"
                                          formControlName="last_name"
                                          class="form-control"
                                          name="last_name"
                                          required
                                          [ngClass]="{
                                            'is-invalid':
                                              f_user.last_name.errors &&
                                              !f_user.last_name.valid &&
                                              f_user.last_name.touched
                                          }"
                                        />
                                        <div
                                          *ngIf="
                                            f_user.last_name.errors &&
                                            !f_user.last_name.valid &&
                                            f_user.last_name.touched
                                          "
                                          class="invalid-feedback"
                                        >
                                          <div
                                            *ngIf="f_user.last_name.errors.required"
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                            "
                                          ></div>
                                        </div>
                                      </div>
                                      <!-- <div class="form-group" *ngIf="subCompanys?.length > 0">
                                        <label for="sub_company"
                                          ><span
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.SUB_COMPANY'
                                            "
                                          ></span
                                          ><b *ngIf="!editBoolean" style="color: red">*</b></label
                                        >
                                        <ng-select
                                          [ngClass]="{
                                            'is-invalid':
                                              f_user.sub_company.errors &&
                                              !f_user.sub_company.valid &&
                                              f_user.sub_company.touched
                                          }"
                                          id="sub_company"
                                          name="sub_company"
                                          [items]="subCompanys"
                                          bindLabel="name"
                                          bindValue="id"
                                          formControlName="sub_company"
                                        >
                                        </ng-select>
                                        <div
                                          *ngIf="
                                            f_user.sub_company.errors &&
                                            f_user.sub_company.invalid &&
                                            f_user.sub_company.touched
                                          "
                                          class="invalid-feedback"
                                        >
                                          <div
                                            *ngIf="f_user.sub_company.errors.required"
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                            "
                                          ></div>
                                        </div>
                                      </div> -->
                                      <div class="form-group">
                                        <label for="email"
                                          ><span
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.EMAIL'
                                            "
                                          ></span
                                          ><b *ngIf="!editBoolean" style="color: red"
                                            >*</b
                                          ></label
                                        >
                                        <input
                                          autocomplete="off"
                                          type="text"
                                          id="email"
                                          formControlName="email"
                                          class="form-control"
                                          name="email"
                                          required
                                          [ngClass]="{
                                            'is-invalid':
                                              f_user.email.errors &&
                                              !f_user.email.valid &&
                                              f_user.email.touched
                                          }"
                                        />
                                        <div
                                          *ngIf="
                                            f_user.email.errors &&
                                            !f_user.email.valid &&
                                            f_user.email.touched
                                          "
                                          class="invalid-feedback"
                                        >
                                          <div
                                            *ngIf="f_user.email.errors.required"
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                            "
                                          ></div>
                                          <div
                                            *ngIf="f_user.email.errors.email"
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.EMAIL_CHECK'
                                            "
                                          ></div>
                                        </div>
                                      </div>

                                      <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                          <label for="login-password"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.ACTIVITY_HISTORY.PASSWORD'
                                              "
                                            ></span
                                            ><b *ngIf="!editBoolean" style="color: red"
                                              >*</b
                                            ></label
                                          >
                                        </div>
                                        <div
                                          class="input-group input-group-merge form-password-toggle"
                                        >
                                          <input
                                            autocomplete="off"
                                            [type]="
                                              passwordTextType ? 'text' : 'password'
                                            "
                                            formControlName="password"
                                            class="form-control form-control-merge"
                                            [ngClass]="{
                                              'is-invalid error':
                                                f_user.password.errors &&
                                                !f_user.password.valid &&
                                                f_user.password.touched
                                            }"
                                            placeholder="············"
                                            required
                                          />
                                          <div class="input-group-append">
                                            <span class="input-group-text cursor-pointer"
                                              ><i
                                                class="feather font-small-4"
                                                [ngClass]="{
                                                  'icon-eye-off': passwordTextType,
                                                  'icon-eye': !passwordTextType
                                                }"
                                                (click)="togglePasswordTextType()"
                                              ></i
                                            ></span>
                                          </div>
                                        </div>
                                        <div
                                          *ngIf="
                                            f_user.password.errors &&
                                            !f_user.password.valid &&
                                            f_user.password.touched
                                          "
                                          class="invalid-feedback"
                                          [ngClass]="{
                                            'd-block':
                                              f_user.password.errors &&
                                              !f_user.password.valid &&
                                              f_user.password.touched
                                          }"
                                        >
                                          <div
                                            *ngIf="f_user.password.errors.required"
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.PASSWORD_REQUIRED'
                                            "
                                          ></div>
                                        </div>
                                      </div>

                                      <div
                                        class="custom-control custom-checkbox"
                                        *ngIf="createUserForm.value.company"
                                      >
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="is_site_admin"
                                          formControlName="is_site_admin"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="is_site_admin"
                                          [translate]="
                                            'ACCOUNT_SETTING.ACTIVITY_HISTORY.IS_SITE_ADMIN'
                                          "
                                        ></label>
                                      </div>
                                    </div>

                                    <div class="col-sm-9 offset-sm-3">
                                      <div
                                        class="d-flex justify-content-between align-items-center"
                                      >
                                        <button
                                          (click)="horizontalWizardStepperPrevious()"
                                          type="button"
                                          class="btn btn-primary btn-previous"
                                          rippleEffect
                                        >
                                          <i
                                            data-feather="arrow-left"
                                            class="align-middle mr-sm-25 ml-0"
                                          ></i>
                                          <span
                                            class="align-middle d-sm-inline-block d-none"
                                            [translate]="'ACCOUNT_SETTING.COMPANY.RETURN'"
                                            >Quay lại</span
                                          >
                                        </button>
                                        <button
                                          [disabled]="
                                            !createUserForm.valid && !editBoolean
                                          "
                                          class="btn btn-success btn-submit"
                                          rippleEffect
                                          (click)="CreateUser()"
                                        >
                                          <i
                                            data-feather="arrow-right"
                                            class="align-middle mr-sm-25 ml-0"
                                          ></i>
                                          <span
                                            class="align-middle d-sm-inline-block d-none"
                                            [translate]="
                                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.SUBMIT'
                                            "
                                          ></span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template #modalEditCompany let-modal>
                          <form [formGroup]="createCompanyForm">
                            <div class="modal-header">
                              <h4
                                class="modal-title"
                                [translate]="'ACCOUNT_SETTING.COMPANY.EDIT_COMPANY'"
                              ></h4>
                              <button
                                type="button"
                                class="close"
                                (click)="modal.dismiss('Cross click')"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body" ngbAutofocus>
                              <div class="form-group">
                                <label for="name"
                                  ><span
                                    [translate]="'ACCOUNT_SETTING.COMPANY.NAME'"
                                  ></span
                                  ><b *ngIf="!editBoolean" style="color: red">*</b></label
                                >
                                <input
                                  type="text"
                                  id="name"
                                  formControlName="name"
                                  class="form-control"
                                  name="name"
                                  required
                                  [ngClass]="{
                                    'is-invalid':
                                      createCompanyForm.controls.name.errors &&
                                      !createCompanyForm.controls.name.valid &&
                                      createCompanyForm.controls.name.touched
                                  }"
                                />
                                <div
                                  *ngIf="
                                    createCompanyForm.controls.name.errors &&
                                    !createCompanyForm.controls.name.valid &&
                                    createCompanyForm.controls.name.touched
                                  "
                                  class="invalid-feedback"
                                >
                                  <div
                                    *ngIf="
                                      createCompanyForm.controls.name.errors.required
                                    "
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                    "
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                [disabled]="!createCompanyForm.valid"
                                type="button"
                                (click)="createCompany()"
                                class="btn btn-primary"
                                [translate]="'ACCOUNT_SETTING.GENERAL.EDIT'"
                              ></button>
                              <button
                                (click)="modalService.dismissAll()"
                                type="reset"
                                rippleEffect
                                class="btn btn-secondary ml-1"
                                [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.CANCEL'"
                              ></button>
                            </div>
                          </form>
                        </ng-template>
                      </div>
                      <div
                        *ngIf="loadingCompany; else noLoadingCompany"
                        id="loading"
                        class="d-flex justify-content-center"
                      >
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <ng-template #noLoadingCompany>
                        <div class="table-responsive">
                          <ngx-datatable
                            id="table-company"
                            #tableCompany
                            class="bootstrap core-bootstrap"
                            [rows]="listCompany"
                            [limit]="10"
                            [rowHeight]="50"
                            class="bootstrap core-bootstrap"
                            [columnMode]="ColumnMode.force"
                            [headerHeight]="50"
                            [footerHeight]="50"
                            [scrollbarH]="true"
                            [messages]="{
                              emptyMessage:
                                'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                              totalMessage:
                                ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT'
                                | translate)
                            }"
                          >
                            <ngx-datatable-column
                              prop="name"
                              [name]="'ACCOUNT_SETTING.COMPANY.NAME' | translate"
                            >
                              <ng-template ngx-datatable-cell-template let-row="row">
                                <div class="w-100">
                                  <a
                                    [routerLink]="'/account-settings/tab-users'"
                                    [queryParams]="{ company: row.id }"
                                    class="w-100"
                                    >{{ row.name }}</a
                                  >
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column
                              [name]="'RESUME_PARSER.MANAGE_RESUME.ACTION' | translate"
                              [sortable]="false"
                              [sortable]="false"
                              [draggable]="false"
                            >
                              <ng-template ngx-datatable-cell-template let-row="row">
                                <div class="d-flex align-items-center">
                                  <a
                                    type="button"
                                    href="javascript:void(0)"
                                    (click)="
                                      modalOpenEditCompany(modalEditCompany, {
                                        name: row.name,
                                        id: row.id
                                      })
                                    "
                                    container="body"
                                    [ngbTooltip]="
                                      'ACCOUNT_SETTING.COMPANY.EDIT_COMPANY' | translate
                                    "
                                    class="btn btn-icon btn-flat-primary rounded-circle"
                                    rippleEffect
                                  >
                                    <span
                                      class="d-flex align-items-center justify-content-center"
                                      ><i class="fa fa-pencil-square-o"></i>
                                    </span>
                                  </a>

                                  <a
                                    type="button"
                                    href="javascript:void(0)"
                                    (click)="deleteCompany(row.id, true)"
                                    container="body"
                                    [ngbTooltip]="
                                      'ACCOUNT_SETTING.COMPANY.DELETE' | translate
                                    "
                                    class="btn btn-icon btn-flat-danger rounded-circle"
                                    rippleEffect
                                  >
                                    <span
                                      class="d-flex align-items-center justify-content-center"
                                      ><i class="fa fa-trash-o"></i>
                                    </span>
                                  </a>
                                </div>
                              </ng-template>
                            </ngx-datatable-column>
                          </ngx-datatable>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li
              [ngbNavItem]="'tab-users'"
              *ngIf="
                currentUser.is_superuser ||
                currentUser.is_site_admin ||
                currentUser.is_super_site_admin
              "
            >
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-vertical-activity"
                data-toggle="pill"
                [routerLink]="'/account-settings/tab-users'"
                aria-expanded="false"
              >
                <i data-feather="users" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.TITLE'"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <div class="mt-2">
                      <div class="d-flex justify-content-end align-items-center mb-1">
                        <!-- <div class="">
                          <div class="d-flex justify-content-between align-items-center m-1">
                            <label
                              class="d-flex align-items-center"
                              style="max-width: 100%; flex-wrap: nowrap"
                              ><span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.SHOW'"></span>
                              <select
                                class="form-control mx-25 pr-3 show-doc"
                                [(ngModel)]="pageSize" (click)="showUsers()"
                              >
                                <option value="5">10</option>
                                <option value="10">15</option>
                                <option value="15">20</option>
                              </select>
                              <span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.USERS'"></span>
                            </label>
                          </div>
                        </div> -->

                        <div *ngIf="currentUser.is_superuser" class="col-3">
                          <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                          <ng-select
                            [multiple]="true"
                            [items]="companys"
                            bindLabel="name"
                            bindValue="id"
                            [placeholder]="
                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.PLACEHOLDER.FILTER_COMPANY'
                                | translate
                            "
                            name="filterCompany"
                            [(ngModel)]="filterCompany"
                            id="filterCompany"
                            (ngModelChange)="searchUser()"
                          >
                          </ng-select>
                        </div>
                        <div class="mr-1">
                          <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                          <input
                            type="text"
                            class="form-control"
                            id="job-search"
                            [placeholder]="
                              'ACCOUNT_SETTING.ACTIVITY_HISTORY.PLACEHOLDER.SEARCH_EMAIL'
                                | translate
                            "
                            [(ngModel)]="searchText"
                            (input)="searchUser()"
                          />
                        </div>
                        <div
                          class=""
                          *ngIf="
                            currentUser.is_superuser ||
                            currentUser.is_site_admin ||
                            currentUser.is_super_site_admin
                          "
                        >
                          <button
                            class="btn btn-primary"
                            (click)="modalOpenCreateUser(modalBasic)"
                            [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.CREATE_USER'"
                          ></button>
                        </div>
                        <ng-template #modalBasic let-modal>
                          <form [formGroup]="createUserForm">
                            <div class="modal-header">
                              <h4
                                *ngIf="!editBoolean; else EditUser"
                                class="modal-title"
                                [translate]="
                                  'ACCOUNT_SETTING.ACTIVITY_HISTORY.CREATE_USER'
                                "
                              ></h4>
                              <ng-template #EditUser>
                                <h4
                                  class="modal-title"
                                  [translate]="
                                    'ACCOUNT_SETTING.ACTIVITY_HISTORY.EDIT_USER'
                                  "
                                ></h4>
                              </ng-template>
                              <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                  aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button> -->
                            </div>
                            <div class="modal-body" ngbAutofocus>
                              <div class="form-group">
                                <label for="first_name"
                                  ><span
                                    [translate]="
                                      'ACCOUNT_SETTING.SOURCE_MAPPING.FIRST_NAME'
                                    "
                                  ></span
                                  ><b *ngIf="!editBoolean" style="color: red">*</b></label
                                >
                                <input
                                  type="text"
                                  id="first_name"
                                  formControlName="first_name"
                                  class="form-control"
                                  name="first_name"
                                  required
                                  [ngClass]="{
                                    'is-invalid':
                                      f_user.first_name.errors &&
                                      !f_user.first_name.valid &&
                                      f_user.first_name.touched
                                  }"
                                />
                                <div
                                  *ngIf="
                                    f_user.first_name.errors &&
                                    !f_user.first_name.valid &&
                                    f_user.first_name.touched
                                  "
                                  class="invalid-feedback"
                                >
                                  <div
                                    *ngIf="f_user.first_name.errors.required"
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                    "
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="last_name"
                                  ><span
                                    [translate]="
                                      'ACCOUNT_SETTING.SOURCE_MAPPING.LAST_NAME'
                                    "
                                  ></span
                                  ><b *ngIf="!editBoolean" style="color: red">*</b></label
                                >
                                <input
                                  type="text"
                                  id="last_name"
                                  formControlName="last_name"
                                  class="form-control"
                                  name="last_name"
                                  required
                                  [ngClass]="{
                                    'is-invalid':
                                      f_user.last_name.errors &&
                                      !f_user.last_name.valid &&
                                      f_user.last_name.touched
                                  }"
                                />
                                <div
                                  *ngIf="
                                    f_user.last_name.errors &&
                                    !f_user.last_name.valid &&
                                    f_user.last_name.touched
                                  "
                                  class="invalid-feedback"
                                >
                                  <div
                                    *ngIf="f_user.last_name.errors.required"
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                    "
                                  ></div>
                                </div>
                              </div>
                              <div
                                class="form-group"
                                *ngIf="currentUser.is_superuser && !editBoolean"
                              >
                                <label
                                  for="company"
                                  [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.COMPANY'"
                                ></label>
                                <select
                                  style="height: 2.714rem"
                                  id="company"
                                  class="form-control"
                                  name="company"
                                  (change)="changeCompany($event)"
                                  formControlName="company"
                                >
                                  <option
                                    *ngFor="let company of companys"
                                    [value]="company.id"
                                  >
                                    {{ company.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="form-group" *ngIf="subCompanys?.length > 0">
                                <label for="sub_company"
                                  ><span
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.SUB_COMPANY'
                                    "
                                  ></span
                                  ><b *ngIf="!editBoolean" style="color: red">*</b></label
                                >
                                <ng-select
                                  [ngClass]="{
                                    'is-invalid':
                                      f_user.sub_company.errors &&
                                      !f_user.sub_company.valid &&
                                      f_user.sub_company.touched
                                  }"
                                  id="sub_company"
                                  name="sub_company"
                                  [items]="subCompanys"
                                  bindLabel="name"
                                  bindValue="id"
                                  formControlName="sub_company"
                                >
                                </ng-select>
                                <div
                                  *ngIf="
                                    f_user.sub_company.errors &&
                                    f_user.sub_company.invalid &&
                                    f_user.sub_company.touched
                                  "
                                  class="invalid-feedback"
                                >
                                  <div
                                    *ngIf="f_user.sub_company.errors.required"
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                    "
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="email"
                                  ><span
                                    [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.EMAIL'"
                                  ></span
                                  ><b *ngIf="!editBoolean" style="color: red">*</b></label
                                >
                                <input
                                  autocomplete="off"
                                  type="text"
                                  id="email"
                                  formControlName="email"
                                  class="form-control"
                                  name="email"
                                  required
                                  [ngClass]="{
                                    'is-invalid':
                                      f_user.email.errors &&
                                      !f_user.email.valid &&
                                      f_user.email.touched
                                  }"
                                />
                                <div
                                  *ngIf="
                                    f_user.email.errors &&
                                    !f_user.email.valid &&
                                    f_user.email.touched
                                  "
                                  class="invalid-feedback"
                                >
                                  <div
                                    *ngIf="f_user.email.errors.required"
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.REQUIRED'
                                    "
                                  ></div>
                                  <div
                                    *ngIf="f_user.email.errors.email"
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.EMAIL_CHECK'
                                    "
                                  ></div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="d-flex justify-content-between">
                                  <label for="login-password"
                                    ><span
                                      [translate]="
                                        'ACCOUNT_SETTING.ACTIVITY_HISTORY.PASSWORD'
                                      "
                                    ></span
                                    ><b *ngIf="!editBoolean" style="color: red"
                                      >*</b
                                    ></label
                                  >
                                </div>
                                <div
                                  class="input-group input-group-merge form-password-toggle"
                                >
                                  <input
                                    autocomplete="off"
                                    [type]="passwordTextType ? 'text' : 'password'"
                                    formControlName="password"
                                    class="form-control form-control-merge"
                                    [ngClass]="{
                                      'is-invalid error':
                                        f_user.password.errors &&
                                        !f_user.password.valid &&
                                        f_user.password.touched
                                    }"
                                    placeholder="············"
                                    required
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text cursor-pointer"
                                      ><i
                                        class="feather font-small-4"
                                        [ngClass]="{
                                          'icon-eye-off': passwordTextType,
                                          'icon-eye': !passwordTextType
                                        }"
                                        (click)="togglePasswordTextType()"
                                      ></i
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  *ngIf="
                                    f_user.password.errors &&
                                    !f_user.password.valid &&
                                    f_user.password.touched
                                  "
                                  class="invalid-feedback"
                                  [ngClass]="{
                                    'd-block':
                                      f_user.password.errors &&
                                      !f_user.password.valid &&
                                      f_user.password.touched
                                  }"
                                >
                                  <div
                                    *ngIf="f_user.password.errors.required"
                                    [translate]="
                                      'ACCOUNT_SETTING.ACTIVITY_HISTORY.PASSWORD_REQUIRED'
                                    "
                                  ></div>
                                </div>
                              </div>

                              <div
                                class="custom-control custom-checkbox"
                                *ngIf="createUserForm.value.company"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="is_site_admin"
                                  formControlName="is_site_admin"
                                />
                                <label
                                  class="custom-control-label"
                                  for="is_site_admin"
                                  [translate]="
                                    'ACCOUNT_SETTING.ACTIVITY_HISTORY.IS_SITE_ADMIN'
                                  "
                                ></label>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                [disabled]="!createUserForm.valid && !editBoolean"
                                type="button"
                                (click)="CreateUser()"
                                class="btn btn-primary"
                                [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.SUBMIT'"
                              ></button>
                              <button
                                (click)="modalService.dismissAll()"
                                type="reset"
                                rippleEffect
                                class="btn btn-secondary ml-1"
                                [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.CANCEL'"
                              ></button>
                            </div>
                          </form>
                        </ng-template>
                      </div>
                      <div
                        *ngIf="loadingUsers; else noLoadingUsers"
                        id="loading"
                        class="d-flex justify-content-center"
                      >
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <ng-template #noLoadingUsers>
                        <div class="table-responsive">
                          <table class="table align-middle table-bordered">
                            <thead>
                              <tr>
                                <th
                                  [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.NAME'"
                                ></th>
                                <th
                                  [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.EMAIL'"
                                ></th>
                                <th
                                  [translate]="
                                    'ACCOUNT_SETTING.ACTIVITY_HISTORY.SUB_COMPANY'
                                  "
                                ></th>
                                <!-- <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.CURRENT_PACKAGE'"></th> -->

                                <!-- <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PERMISSION_SEARCH'"></th>
  
                                <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.PERMISSION_TALENT'"></th> -->
                                <th
                                  [translate]="
                                    'ACCOUNT_SETTING.ACTIVITY_HISTORY.IS_ACTIVE'
                                  "
                                ></th>

                                <th
                                  [translate]="
                                    'ACCOUNT_SETTING.ACTIVITY_HISTORY.IS_SITE_ADMIN'
                                  "
                                ></th>
                                <!-- <th [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.LAST_LOGIN'"></th> -->
                                <th
                                  [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.ACTION'"
                                ></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let user of users
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>
                                  <div class="">
                                    {{ user.last_name }} {{ user.first_name }}
                                  </div>
                                </td>
                                <td>
                                  <div class="">{{ user.email }}</div>
                                </td>
                                <td>
                                  <div class="">{{ user.sub_company?.name }}</div>
                                </td>
                                <td *ngIf="user.is_active; else noActive">
                                  <div class="">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                  </div>
                                </td>
                                <ng-template #noActive>
                                  <td>
                                    <div class="">
                                      <i class="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                  </td>
                                </ng-template>

                                <td
                                  *ngIf="
                                    user.is_site_admin || user.is_super_site_admin;
                                    else noSiteAdmin
                                  "
                                >
                                  <div class="">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                  </div>
                                </td>
                                <ng-template #noSiteAdmin>
                                  <td>
                                    <div class="">
                                      <i class="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                  </td>
                                </ng-template>
                                <!-- <td>
                                  <div class="">{{user.last_login|date:'medium'}}</div>
                                </td> -->
                                <td class="">
                                  <div
                                    class="d-flex"
                                    *ngIf="
                                      (currentUser.role == 'Super admin' &&
                                        (user.role == 'Admin' || user.role == 'User')) ||
                                      currentUser.role == 'Super user' ||
                                      (currentUser.role == 'Admin' && user.role == 'User')
                                    "
                                  >
                                    <div>
                                      <a
                                        type="button"
                                        href="javascript:void(0)"
                                        (click)="
                                          modalOpenCreateUser(modalBasic, {
                                            first_name: user.first_name,
                                            last_name: user.last_name,
                                            email: user.email,
                                            sub_company: user.sub_company?.id,
                                            is_active: user.is_active,
                                            is_site_admin: user.is_site_admin,

                                            id: user.id
                                          })
                                        "
                                        container="body"
                                        [ngbTooltip]="
                                          'ACCOUNT_SETTING.ACTIVITY_HISTORY.EDIT_USER'
                                            | translate
                                        "
                                        class="btn btn-icon btn-flat-warning rounded-circle"
                                        rippleEffect
                                      >
                                        <span
                                          class="d-flex align-items-center justify-content-center"
                                          ><i class="fa fa-pencil-square-o"></i>
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        type="button"
                                        href="javascript:void(0)"
                                        (click)="
                                          activeUser({
                                            is_active: user.is_active,

                                            id: user.id
                                          })
                                        "
                                        container="body"
                                        [ngbTooltip]="
                                          user.is_active
                                            ? ('ACCOUNT_SETTING.ACTIVITY_HISTORY.LOCK_ACCOUNT'
                                              | translate)
                                            : ('ACCOUNT_SETTING.ACTIVITY_HISTORY.ACTIVE_ACCOUNT'
                                              | translate)
                                        "
                                        class="btn btn-icon btn-flat-primary rounded-circle"
                                        rippleEffect
                                      >
                                        <span
                                          class="d-flex align-items-center justify-content-center"
                                          ><i
                                            [ngClass]="
                                              user.is_active
                                                ? 'fa fa-lock'
                                                : 'fa fa-unlock-alt'
                                            "
                                          ></i>
                                        </span>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        type="button"
                                        href="javascript:void(0)"
                                        (click)="
                                          activeAdminUser({
                                            is_site_admin: user.is_site_admin,

                                            id: user.id
                                          })
                                        "
                                        container="body"
                                        [ngbTooltip]="
                                          user.is_site_admin
                                            ? ('ACCOUNT_SETTING.ACTIVITY_HISTORY.DELETE_PERMISSION'
                                              | translate)
                                            : ('ACCOUNT_SETTING.ACTIVITY_HISTORY.ASSIGN_PERMISSON'
                                              | translate)
                                        "
                                        class="btn btn-icon btn-flat-secondary rounded-circle"
                                        rippleEffect
                                      >
                                        <span
                                          class="d-flex align-items-center justify-content-center"
                                          ><i
                                            [ngClass]="
                                              user.is_site_admin
                                                ? 'fa fa-user-circle'
                                                : 'fa fa-user-circle-o'
                                            "
                                          ></i>
                                        </span>
                                      </a>
                                    </div>
                                    <div
                                      *ngIf="
                                        currentUser.is_superuser ||
                                          currentUser.is_super_site_admin;
                                        else Admin
                                      "
                                    >
                                      <a
                                        type="button"
                                        href="javascript:void(0)"
                                        (click)="
                                          modalOpenDeleteUser(
                                            modalBasicDeleteUser,
                                            user.id,
                                            user.company
                                          )
                                        "
                                        container="body"
                                        [ngbTooltip]="
                                          'ACCOUNT_SETTING.ACTIVITY_HISTORY.DELETE_USER'
                                            | translate
                                        "
                                        class="btn btn-icon btn-flat-danger rounded-circle"
                                        rippleEffect
                                      >
                                        <span
                                          class="d-flex align-items-center justify-content-center"
                                          ><i class="fa fa-trash-o"></i>
                                        </span>
                                      </a>
                                    </div>
                                    <ng-template #Admin>
                                      <a
                                        *ngIf="user.role == role.User"
                                        type="button"
                                        href="javascript:void(0)"
                                        (click)="
                                          modalOpenDeleteUser(
                                            modalBasicDeleteUser,
                                            user.id,
                                            user.company
                                          )
                                        "
                                        title="Xóa"
                                        class="btn btn-icon btn-flat-danger rounded-circle"
                                        rippleEffect
                                      >
                                        <span
                                          class="d-flex align-items-center justify-content-center"
                                          ><i class="fa fa-trash-o"></i>
                                        </span>
                                      </a>
                                    </ng-template>
                                  </div>
                                  <ng-template #modalBasicDeleteUser let-modal>
                                    <div class="modal-header">
                                      <h4
                                        class="modal-title"
                                        [translate]="
                                          'ACCOUNT_SETTING.ACTIVITY_HISTORY.ASSIGN_USER'
                                        "
                                      ></h4>
                                      <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button> -->
                                    </div>
                                    <div class="m-1 d-flex justify-content-end">
                                      <div>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="job-search"
                                          [placeholder]="
                                            'ACCOUNT_SETTING.ACTIVITY_HISTORY.PLACEHOLDER.SEARCH_EMAIL'
                                              | translate
                                          "
                                          [(ngModel)]="searchAssignEmail"
                                          (input)="SearchAssignEmail()"
                                        />
                                      </div>
                                    </div>
                                    <form [formGroup]="deleteUserForm">
                                      <div
                                        class="modal-body"
                                        ngbAutofocus
                                        *ngFor="
                                          let user_ of usersAssign
                                            | slice
                                              : (pageAssignUser - 1) *
                                                  basicSelectedOptionAssignUser
                                              : (pageAssignUser - 1) *
                                                  basicSelectedOptionAssignUser +
                                                  basicSelectedOptionAssignUser
                                        "
                                      >
                                        <div
                                          class="form-check"
                                          *ngIf="
                                            _checkbox == user_.id.toString();
                                            else noChecked
                                          "
                                        >
                                          <input
                                            [value]="user_.id"
                                            (change)="onCheckboxChange($event)"
                                            type="checkbox"
                                            class="form-check-input"
                                            checked
                                          />
                                          <label
                                            class="form-check-label"
                                            for="checkbox_email"
                                          >
                                            {{ user_.email }}
                                          </label>
                                        </div>
                                        <ng-template #noChecked>
                                          <div class="form-check">
                                            <input
                                              [value]="user_.id"
                                              (change)="onCheckboxChange($event)"
                                              type="checkbox"
                                              class="form-check-input"
                                            />

                                            <label
                                              class="form-check-label"
                                              for="checkbox_email"
                                            >
                                              {{ user_.email }}
                                            </label>
                                          </div>
                                        </ng-template>
                                        <!-- <div class="form-check form-check-inline">
                                                      <input
                                                        type="radio"
                                                        class="form-check-input"
                                                        (change)="onCheckboxChange(user_.id.toString(),$event.target.checked)"
                                                        formControlName="checkUser"
                                                
                                                        id="user_.id"
                                                      />
                                                      <label
                                                        class="form-check-label"
                                                        for="checkbox_email"
                                                        
                                                      >{{user_.email}}</label>
                                                    </div> -->
                                      </div>
                                      <div
                                        class="d-flex justify-content-end align-items-center"
                                        *ngIf="users?.length > 0"
                                      >
                                        <div class="col-auto">
                                          <nav
                                            class="mt-sm-0"
                                            aria-label="Users list pagination"
                                          >
                                            <ngb-pagination
                                              [collectionSize]="usersAssign.length"
                                              [(page)]="pageAssignUser"
                                              [pageSize]="basicSelectedOptionAssignUser"
                                              [maxSize]="2"
                                              [rotate]="true"
                                              [boundaryLinks]="true"
                                            >
                                            </ngb-pagination>
                                          </nav>
                                        </div>
                                      </div>
                                      <div class="p-1">
                                        <b style="color: red">*</b
                                        ><span
                                          [translate]="
                                            'ACCOUNT_SETTING.ACTIVITY_HISTORY.NOTE_ASSIGN_USER'
                                          "
                                        >
                                          Thông tin picklist trên hệ thống cần chính xác
                                          tránh ảnh hưởng đến vận hành hệ thống</span
                                        >
                                      </div>
                                      <div class="modal-footer">
                                        <button
                                          (click)="DeleteUser(user.id)"
                                          class="btn btn-primary"
                                          (click)="modal.dismiss('Cross click')"
                                          rippleEffect
                                          [translate]="
                                            'ACCOUNT_SETTING.ACTIVITY_HISTORY.ACCEPT'
                                          "
                                        ></button>
                                        <button
                                          type="button"
                                          class="btn btn-secondary"
                                          style="background-color: gray"
                                          (click)="modal.dismiss('Cross click')"
                                          (click)="cancel()"
                                          rippleEffect
                                          [translate]="
                                            'ACCOUNT_SETTING.ACTIVITY_HISTORY.CANCEL'
                                          "
                                        ></button>
                                      </div>
                                    </form>
                                  </ng-template>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="row mt-2 justify-content-between">
                          <div class="col-auto">
                            <div class="mr-1">
                              {{ users?.length }}
                              <span
                                [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.USERS'"
                              ></span>
                            </div>
                          </div>
                          <div class="col-auto">
                            <nav class="mt-sm-0" aria-label="Jobs list pagination">
                              <ngb-pagination
                                [collectionSize]="users?.length"
                                [(page)]="page"
                                [pageSize]="pageSize"
                                [maxSize]="2"
                                [rotate]="true"
                                [boundaryLinks]="true"
                              >
                              </ngb-pagination>
                            </nav>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>

            <li
              [ngbNavItem]="'tab-auto-settings'"
              *ngIf="currentUser.is_site_admin || currentUser.is_super_site_admin"
            >
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-vertical-notifications"
                data-toggle="pill"
                [routerLink]="'/account-settings/tab-auto-settings'"
                aria-expanded="false"
              >
                <i data-feather="settings" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.TITLE'"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <form
                      ngNativeValidate
                      #ResumeSettingForm="ngForm"
                      class="validate-form"
                    >
                      <div class="row">
                        <!-- <h6 class="section-label mx-1 mb-2">Resume settings</h6> -->

                        <div class="col-12 mb-2">
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="auto_sync_email"
                              id="auto_sync_email"
                              name="auto_sync_email"
                            />
                            <label
                              class="custom-control-label"
                              for="auto_sync_email"
                              [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.AUTO_SYNC'"
                            >
                            </label>
                          </div>
                        </div>
                        <div class="col-12 mb-2">
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              [(ngModel)]="auto_approve_done_resume"
                              id="auto_approve_done_resume"
                              name="auto_approve_done_resume"
                            />
                            <label
                              class="custom-control-label"
                              for="auto_approve_done_resume"
                              [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.AUTO_APPROVE'"
                            >
                            </label>
                          </div>
                        </div>
                        <!-- <div class="col-12 mb-2">
                          <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" [(ngModel)]="censor_contact"
                              id="censor_contact" name="censor_contact" />
                            <label class="custom-control-label" for="censor_contact"
                              [translate]="'ACCOUNT_SETTING.RESUME_SETTINGS.CENSOR_CONTACT'">

                            </label>
                          </div>
                        </div> -->
                        <div class="col-6 mb-2">
                          <div class="form-group">
                            <label
                              for="approve_score"
                              [translate]="
                                'ACCOUNT_SETTING.RESUME_SETTINGS.APPROVE_SCORE'
                              "
                            >
                            </label>
                            <input
                              #ApproveScoreRef="ngModel"
                              type="number"
                              class="form-control"
                              [(ngModel)]="approve_score"
                              id="approve_score"
                              name="approve_score"
                            />
                            <span class="invalid-form">
                              <small
                                *ngIf="approve_score < 0 || approve_score > 1"
                                class="form-text text-danger"
                                [translate]="
                                  'ACCOUNT_SETTING.RESUME_SETTINGS.LIMIT_APPROVE_SCORE'
                                "
                              ></small>
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <button
                            [disabled]="
                              !ResumeSettingForm.form.valid ||
                              approve_score == null ||
                              approve_score < 0 ||
                              approve_score > 1
                            "
                            type="button"
                            (click)="putResumeSettings()"
                            class="btn btn-primary mr-1"
                            rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'"
                          ></button>
                          <button
                            type="reset"
                            class="btn btn-secondary"
                            rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"
                          ></button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-template>
            </li>
            <li
              [ngbNavItem]="'tab-connect-sf4c'"
              *ngIf="
                currentUser.role == 'Admin' ||
                currentUser.role == 'Super admin' ||
                currentUser.is_superuser
              "
            >
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-vertical-connectSf4c"
                data-toggle="pill"
                [routerLink]="'/account-settings/tab-connect-sf4c'"
                aria-expanded="false"
              >
                <i data-feather="upload" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.TITLE'"
                ></span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <ng-container
                      *ngIf="
                        currentUser.is_superuser ||
                        currentUser.role == 'Admin' ||
                        currentUser.role == 'Super admin'
                      "
                    >
                      <div class="card-body">
                        <div
                          *ngIf="
                            listCredential?.length > 0 ||
                            listCredentialCompany?.length > 0
                          "
                        >
                          <h1
                            class="mt-1"
                            [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.INFO_CREDENTIAL'"
                          ></h1>
                          <!-- <a routerLink="/manage-resume/edit-resume/{{resume_parser.id}}"
                          style="font-size: 14px;margin-bottom: 26px;color: darkorange;">
                          <span style="margin-left: 4px;" data-toggle="tooltip" data-placement="bottom"
                            [ngbTooltip]="'RESUME_PARSER.DETAIL_RESUME.EDIT' | translate" data-feather="edit"></span>
                        </a> -->

                          <div class="row justify-content-end align-items-center mb-1">
                            <!-- <div class="">
                            <div class="d-flex justify-content-between align-items-center m-1">
                              <label
                                class="d-flex align-items-center"
                                style="max-width: 100%; flex-wrap: nowrap"
                                ><span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.SHOW'"></span>
                                <select
                                  class="form-control mx-25 pr-3 show-doc"
                                  [(ngModel)]="pageSize" (click)="showUsers()"
                                >
                                  <option value="5">10</option>
                                  <option value="10">15</option>
                                  <option value="15">20</option>
                                </select>
                                <span [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.USERS'"></span>
                              </label>
                            </div>
                          </div> -->

                            <div class="d-flex justify-content-end align-items-center">
                              <div
                                class="mr-1"
                                *ngIf="
                                  currentUser.is_superuser ||
                                  currentUser.role == 'Admin' ||
                                  currentUser.role == 'Super admin'
                                "
                              >
                                <button
                                  class="btn btn-primary"
                                  (click)="modalOpenCreateCredential(modalBasic)"
                                  [translate]="
                                    'ACCOUNT_SETTING.CONNECT_SF4C.CREATE_CREDENTIAL'
                                  "
                                ></button>
                              </div>
                              <ng-template #modalBasic let-modal>
                                <form
                                  [formGroup]="ConnectSf4cForm"
                                  *ngIf="!currentUser.is_superuser"
                                >
                                  <div class="modal-header">
                                    <h1
                                      class="col-12"
                                      [translate]="
                                        !editCred
                                          ? 'ACCOUNT_SETTING.CONNECT_SF4C.TITLE'
                                          : 'ACCOUNT_SETTING.CONNECT_SF4C.EDIT_CRED'
                                      "
                                    ></h1>

                                    <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button> -->
                                  </div>
                                  <div class="modal-body" ngbAutofocus>
                                    <div class="row">
                                      <div
                                        class="col-12 col-sm-6 col-xl-6"
                                        [ngClass]="editCred ? 'hidden' : ''"
                                      >
                                        <div class="form-group">
                                          <label for="username"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.USER_NAME'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <input
                                            type="username"
                                            formControlName="username"
                                            class="form-control"
                                            id="username"
                                            name="username"
                                            [placeholder]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.USER_NAME'
                                                | translate
                                            "
                                            required
                                          />
                                          <span
                                            *ngIf="
                                              ConnectSf4cForm.hasError(
                                                'required',
                                                'username'
                                              ) && ConnectSf4cForm.touched
                                            "
                                          >
                                            <small
                                              class="form-text text-danger"
                                              [translate]="
                                                'ACCOUNT_SETTING.GENERAL.REQUIRED'
                                              "
                                            ></small>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col-12 col-sm-6 col-xl-6">
                                        <div class="form-group">
                                          <label for="user"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.USER'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <ng-select
                                            style="height: 2.714rem"
                                            id="user"
                                            name="user"
                                            (change)="changeUser($event)"
                                            [placeholder]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.USER'
                                                | translate
                                            "
                                            formControlName="user"
                                            required
                                            [ngClass]="{
                                              'is-invalid':
                                                ConnectSf4cForm.controls.user.errors &&
                                                !ConnectSf4cForm.controls.user.valid &&
                                                ConnectSf4cForm.controls.user.touched
                                            }"
                                          >
                                            <ng-option
                                              *ngFor="let user of usersCredential"
                                              [value]="user.email"
                                            >
                                              {{ user.email }}
                                            </ng-option>
                                          </ng-select>
                                          <div
                                            *ngIf="
                                              ConnectSf4cForm.controls.user.errors &&
                                              !ConnectSf4cForm.controls.user.valid &&
                                              ConnectSf4cForm.controls.user.touched
                                            "
                                            class="invalid-feedback"
                                          >
                                            <div
                                              *ngIf="
                                                ConnectSf4cForm.controls.user.errors
                                                  .required
                                              "
                                              [translate]="
                                                'RESUME_PARSER.MANAGE_RESUME.REQUIRED'
                                              "
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      *ngIf="loading; else noLoading"
                                      class="btn btn-primary mt-2 mr-1"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span
                                        [translate]="
                                          editCred
                                            ? 'ACCOUNT_SETTING.GENERAL.EDIT'
                                            : 'ACCOUNT_SETTING.GENERAL.SAVE'
                                        "
                                      ></span>
                                    </button>
                                    <ng-template #noLoading>
                                      <button
                                        (click)="GetCredential()"
                                        [disabled]="ConnectSf4cForm.invalid"
                                        class="btn btn-primary mt-2 mr-1"
                                        rippleEffect
                                        [translate]="
                                          editCred
                                            ? 'ACCOUNT_SETTING.GENERAL.EDIT'
                                            : 'ACCOUNT_SETTING.GENERAL.SAVE'
                                        "
                                      ></button>
                                    </ng-template>

                                    <button
                                      type="reset"
                                      (click)="modal.close('Accept click')"
                                      class="btn btn-secondary mt-2"
                                      rippleEffect
                                      [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"
                                    ></button>
                                  </div>
                                </form>
                                <form
                                  [formGroup]="CreateEditSf4cForm"
                                  *ngIf="currentUser.is_superuser"
                                >
                                  <div class="modal-header">
                                    <h1
                                      class="col-12"
                                      [translate]="
                                        !editCred
                                          ? 'ACCOUNT_SETTING.CONNECT_SF4C.TITLE'
                                          : 'ACCOUNT_SETTING.CONNECT_SF4C.EDIT_CRED'
                                      "
                                    ></h1>

                                    <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button> -->
                                  </div>
                                  <div class="modal-body" ngbAutofocus>
                                    <div class="row">
                                      <div class="col-12 col-sm-6 col-xl-6">
                                        <div class="form-group">
                                          <label for="file"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.UPLOAD_FILE'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <input
                                            class="form-control"
                                            #InputVar
                                            (change)="onFileSelect($event)"
                                            type="file"
                                            name="file"
                                            accept=".pem"
                                            required
                                          />
                                          <span
                                            *ngIf="
                                              CreateEditSf4cForm.hasError(
                                                'required',
                                                'file'
                                              ) && CreateEditSf4cForm.controls.file.touched
                                            "
                                          >
                                            <small
                                              class="form-text text-danger"
                                              [translate]="
                                                'ACCOUNT_SETTING.GENERAL.REQUIRED'
                                              "
                                            ></small>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col-12 col-sm-6 col-xl-6">
                                        <div class="form-group">
                                          <label for="api_key"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.API_KEY'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <input
                                            type="text"
                                            formControlName="api_key"
                                            class="form-control"
                                            id="api_key"
                                            name="api_key"
                                            [placeholder]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.API_KEY'
                                                | translate
                                            "
                                            required
                                          />
                                          <span
                                            *ngIf="
                                              CreateEditSf4cForm.hasError(
                                                'required',
                                                'api_key'
                                              ) &&
                                              CreateEditSf4cForm.controls.api_key.touched
                                            "
                                          >
                                            <small
                                              class="form-text text-danger"
                                              [translate]="
                                                'ACCOUNT_SETTING.GENERAL.REQUIRED'
                                              "
                                            ></small>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        class="col-12 col-sm-6 col-xl-6"
                                        [ngClass]="editCred ? 'hidden' : ''"
                                      >
                                        <div class="form-group">
                                          <label for="company_id"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY_ID'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <input
                                            type="company_id"
                                            formControlName="company_id"
                                            class="form-control"
                                            id="company_id"
                                            name="company_id"
                                            [placeholder]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY_ID'
                                                | translate
                                            "
                                            required
                                          />
                                          <span
                                            *ngIf="
                                              CreateEditSf4cForm.hasError(
                                                'required',
                                                'company_id'
                                              ) &&
                                              CreateEditSf4cForm.controls.company_id.touched
                                            "
                                          >
                                            <small
                                              class="form-text text-danger"
                                              [translate]="
                                                'ACCOUNT_SETTING.GENERAL.REQUIRED'
                                              "
                                            ></small>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        class="col-12 col-sm-6 col-xl-6"
                                        [ngClass]="editCred ? 'hidden' : ''"
                                      >
                                        <div class="form-group">
                                          <label for="token_url"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.TOKEN_URL'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <input
                                            type="token_url"
                                            formControlName="token_url"
                                            class="form-control"
                                            id="token_url"
                                            name="token_url"
                                            [placeholder]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.TOKEN_URL'
                                                | translate
                                            "
                                            required
                                          />
                                          <span
                                            *ngIf="
                                              CreateEditSf4cForm.hasError(
                                                'required',
                                                'token_url'
                                              ) &&
                                              CreateEditSf4cForm.controls.token_url.touched
                                            "
                                          >
                                            <small
                                              class="form-text text-danger"
                                              [translate]="
                                                'ACCOUNT_SETTING.GENERAL.REQUIRED'
                                              "
                                            ></small>
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        class="col-12 col-sm-6 col-xl-6"
                                        [ngClass]="editCred ? 'hidden' : ''"
                                      >
                                        <div class="form-group">
                                          <label for="access_url"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.ACCESS_URL'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <input
                                            type="access_url"
                                            formControlName="access_url"
                                            class="form-control"
                                            id="access_url"
                                            name="access_url"
                                            [placeholder]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.ACCESS_URL'
                                                | translate
                                            "
                                            required
                                          />
                                          <span
                                            *ngIf="
                                              CreateEditSf4cForm.hasError(
                                                'required',
                                                'access_url'
                                              ) &&
                                              CreateEditSf4cForm.controls.access_url.touched
                                            "
                                          >
                                            <small
                                              class="form-text text-danger"
                                              [translate]="
                                                'ACCOUNT_SETTING.GENERAL.REQUIRED'
                                              "
                                            ></small>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col-12 col-sm-6 col-xl-6">
                                        <div class="form-group">
                                          <label for="company"
                                            ><span
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY'
                                              "
                                            ></span
                                            ><b style="color: red">*</b></label
                                          >
                                          <ng-select
                                            style="height: 2.714rem"
                                            id="company"
                                            name="company"
                                            (change)="changeCompany($event)"
                                            [placeholder]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY'
                                                | translate
                                            "
                                            formControlName="company"
                                            required
                                            [ngClass]="{
                                              'is-invalid':
                                                CreateEditSf4cForm.controls.company
                                                  .errors &&
                                                !CreateEditSf4cForm.controls.company
                                                  .valid &&
                                                CreateEditSf4cForm.controls.company.touched
                                            }"
                                          >
                                            <ng-option
                                              *ngFor="let company of listCompany"
                                              [value]="company.id"
                                            >
                                              {{ company.name }}
                                            </ng-option>
                                          </ng-select>
                                          <div
                                            *ngIf="
                                              CreateEditSf4cForm.controls.company.errors &&
                                              !CreateEditSf4cForm.controls.company.valid &&
                                              CreateEditSf4cForm.controls.company.touched
                                            "
                                            class="invalid-feedback"
                                          >
                                            <div
                                              *ngIf="
                                                CreateEditSf4cForm.controls.company.errors
                                                  .required
                                              "
                                              [translate]="
                                                'RESUME_PARSER.MANAGE_RESUME.REQUIRED'
                                              "
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      *ngIf="loading; else noLoading"
                                      class="btn btn-primary mt-2 mr-1"
                                      disabled
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span
                                        [translate]="
                                          editCred
                                            ? 'ACCOUNT_SETTING.GENERAL.EDIT'
                                            : 'ACCOUNT_SETTING.GENERAL.SAVE'
                                        "
                                      ></span>
                                    </button>
                                    <ng-template #noLoading>
                                      <button
                                        (click)="GetCredential()"
                                        [disabled]="CreateEditSf4cForm.invalid"
                                        class="btn btn-primary mt-2 mr-1"
                                        rippleEffect
                                        [translate]="
                                          editCred
                                            ? 'ACCOUNT_SETTING.GENERAL.EDIT'
                                            : 'ACCOUNT_SETTING.GENERAL.SAVE'
                                        "
                                      ></button>
                                    </ng-template>

                                    <button
                                      type="reset"
                                      (click)="modal.close('Accept click')"
                                      class="btn btn-secondary mt-2"
                                      rippleEffect
                                      [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"
                                    ></button>
                                  </div>
                                </form>
                                
                              </ng-template>
                            </div>
                          </div>
                          <div class="table-responsive">
                            <table class="table align-middle table-bordered">
                              <thead>
                                <tr>
                                  <th
                                    *ngIf="!currentUser.is_superuser"
                                    [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.USER'"
                                  ></th>
                                  <th
                                    *ngIf="currentUser.is_superuser"
                                    [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY'"
                                  ></th>
                                  <!-- <th [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY_ID'"></th>
                                <th [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.USER_NAME'"></th> -->

                                  <!-- <th [translate]="'ACCOUNT_SETTING.CONNECT_SF4C.TOKEN_URL'"></th> -->
                                  <th *ngIf="!currentUser.is_superuser"
                                    [translate]="
                                      'ACCOUNT_SETTING.CONNECT_SF4C.YOUR_CREDENTIAL'
                                    "
                                  ></th>

                                  <th
                                    [translate]="
                                      'ACCOUNT_SETTING.CONNECT_SF4C.MODIFIED_AT'
                                    "
                                  ></th>
                                  <th
                                    [translate]="
                                      'ACCOUNT_SETTING.CONNECT_SF4C.MODAL.ACTION'
                                    "
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngIf="!currentUser.is_superuser; else LIST_CRE_COMPANY">
                                  <tr
                                  *ngFor="
                                    let credential of listCredential
                                      | slice
                                        : (page - 1) * pageSize
                                        : (page - 1) * pageSize + pageSize
                                  "
                                >
                                  <td>
                                    <div class="">
                                      <a
                                        href="javascript:void(0)"
                                        (click)="
                                          modalOpenDetailCredential(modalDetailCredential)
                                        "
                                        >{{credential.user}}
                                      </a>
                                    </div>
                                  </td>
                                  <ng-template #modalDetailCredential let-modal>
                                    <div class="modal-header">
                                      <h4 class="modal-title">
                                        <span
                                          [translate]="
                                            'ACCOUNT_SETTING.CONNECT_SF4C.DETAIL_CREDENTIAL'
                                          "
                                        ></span>
                                      </h4>
                                    </div>
                                    <div class="modal-body" ngbAutofocus>
                                      <div class="card-body">
                                        <div>
                                          <p>
                                            <span
                                              class="font-weight-bolder"
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.USER'
                                              "
                                            ></span
                                            >:
                                            {{ credential.user }}
                                          </p>
                                        </div>
                                        <div>
                                          <p>
                                            <span
                                              class="font-weight-bolder"
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY_ID'
                                              "
                                            ></span
                                            >: {{ credential.company_id }}
                                          </p>
                                        </div>
                                        <div>
                                          <p>
                                            <span
                                              class="font-weight-bolder"
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.USER_NAME'
                                              "
                                            ></span
                                            >: {{ credential.username }}
                                          </p>
                                        </div>
                                        <div>
                                          <p>
                                            <span
                                              class="font-weight-bolder"
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.TOKEN_URL'
                                              "
                                            ></span
                                            >: {{ credential.token_url }}
                                          </p>
                                        </div>
                                        <div>
                                          <p>
                                            <span
                                              class="font-weight-bolder"
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.ACCESS_URL'
                                              "
                                            ></span
                                            >: {{ credential.access_url }}
                                          </p>
                                        </div>
                                        <div>
                                          <p>
                                            <span
                                              class="font-weight-bolder"
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.CLIENT_ID'
                                              "
                                            ></span
                                            >: {{ credential.client_id }}
                                          </p>
                                        </div>
                                        <div>
                                          <p>
                                            <span
                                              class="font-weight-bolder"
                                              [translate]="
                                                'ACCOUNT_SETTING.CONNECT_SF4C.MODIFIED_AT'
                                              "
                                            ></span
                                            >:
                                            {{
                                              credential.modified_at
                                                | date : 'd/M/yyyy, h:mm a'
                                            }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button
                                        (click)="modal.close('Accept click')"
                                        rippleEffect
                                        class="btn btn-primary mr-1"
                                        [translate]="
                                          'ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CONFIRM'
                                        "
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </ng-template>
                                  <td>
                                    <div
                                      class="custom-control custom-checkbox"
                                      *ngIf="
                                        currentUser.email == credential.user;
                                        else noOwner
                                      "
                                    >
                                      <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="admin-read"
                                        checked
                                        disabled
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="admin-read"
                                      ></label>
                                    </div>
                                    <ng-template #noOwner>
                                      <div class="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          id="admin-read"
                                          disabled
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="admin-read"
                                        ></label>
                                      </div>
                                    </ng-template>
                                  </td>
                                  <td>
                                    <div class="">
                                      {{
                                        credential.modified_at | date : 'd/M/yyyy, h:mm a'
                                      }}
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      type="button"
                                      href="javascript:void(0)"
                                      (click)="deleteConnectSf4c(credential.id)"
                                      container="body"
                                      [ngbTooltip]="
                                        'ACCOUNT_SETTING.CONNECT_SF4C.MODAL.DELETE_C'
                                          | translate
                                      "
                                      class="btn btn-icon btn-flat-danger rounded-circle"
                                      rippleEffect
                                    >
                                      <span
                                        class="d-flex align-items-center justify-content-center"
                                        ><i class="fa fa-trash-o"></i>
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-template #LIST_CRE_COMPANY>
                                <tr
                                *ngFor="
                                  let credential of listCredentialCompany
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>
                                  <div class="">
                                    <a
                                      href="javascript:void(0)"
                                      (click)="
                                        modalOpenDetailCredential(modalDetailCredential)
                                      "
                                      >{{ credential.company }}
                                    </a>
                                  </div>
                                </td>
                                <ng-template #modalDetailCredential let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title">
                                      <span
                                        [translate]="
                                          'ACCOUNT_SETTING.CONNECT_SF4C.DETAIL_CREDENTIAL'
                                        "
                                      ></span>
                                    </h4>
                                  </div>
                                  <div class="modal-body" ngbAutofocus>
                                    <div class="card-body">
                                      <div>
                                        <p>
                                          <span
                                            class="font-weight-bolder"
                                            [translate]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY'
                                            "
                                          ></span
                                          >:
                                          {{ credential.company }}
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <span
                                            class="font-weight-bolder"
                                            [translate]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.COMPANY_ID'
                                            "
                                          ></span
                                          >: {{ credential.company_hcm_id }}
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <span
                                            class="font-weight-bolder"
                                            [translate]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.USER_NAME'
                                            "
                                          ></span
                                          >: {{ credential.username }}
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <span
                                            class="font-weight-bolder"
                                            [translate]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.TOKEN_URL'
                                            "
                                          ></span
                                          >: {{ credential.token_url }}
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <span
                                            class="font-weight-bolder"
                                            [translate]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.ACCESS_URL'
                                            "
                                          ></span
                                          >: {{ credential.access_url }}
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <span
                                            class="font-weight-bolder"
                                            [translate]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.CLIENT_ID'
                                            "
                                          ></span
                                          >: {{ credential.client_id }}
                                        </p>
                                      </div>
                                      <div>
                                        <p>
                                          <span
                                            class="font-weight-bolder"
                                            [translate]="
                                              'ACCOUNT_SETTING.CONNECT_SF4C.MODIFIED_AT'
                                            "
                                          ></span
                                          >:
                                          {{
                                            credential.modified_at
                                              | date : 'd/M/yyyy, h:mm a'
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      (click)="modal.close('Accept click')"
                                      rippleEffect
                                      class="btn btn-primary mr-1"
                                      [translate]="
                                        'ACCOUNT_SETTING.CONNECT_SF4C.MODAL.CONFIRM'
                                      "
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </ng-template>
                                <td>
                                  <div class="">
                                    {{
                                      credential.modified_at | date : 'd/M/yyyy, h:mm a'
                                    }}
                                  </div>
                                </td>
                                <td>
                                  <a
                                    type="button"
                                    href="javascript:void(0)"
                                    (click)="deleteConnectSf4cCompany(credential.id)"
                                    container="body"
                                    [ngbTooltip]="
                                      'ACCOUNT_SETTING.CONNECT_SF4C.MODAL.DELETE_C'
                                        | translate
                                    "
                                    class="btn btn-icon btn-flat-danger rounded-circle"
                                    rippleEffect
                                  >
                                    <span
                                      class="d-flex align-items-center justify-content-center"
                                      ><i class="fa fa-trash-o"></i>
                                    </span>
                                  </a>
                                </td>
                              </tr>
                              </ng-template>
                              </tbody>
                            </table>
                          </div>
                          <div class="row mt-2 justify-content-between">
                            <div class="col-auto">
                              <div class="mr-1">
                                <span *ngIf="!currentUser.is_superuser;else PAGINATION_COMPANY">{{ listCredential?.length }} </span>
                                <ng-template #PAGINATION_COMPANY>
                                  <span>{{ listCredentialCompany?.length }} </span>
                                </ng-template>
                                <span
                                  [translate]="'ACCOUNT_SETTING.ACTIVITY_HISTORY.USERS'"
                                ></span>
                              </div>
                            </div>
                            <div class="col-auto">
                              <nav class="mt-sm-0" aria-label="Jobs list pagination">
                                <ngb-pagination
                                  [collectionSize]="!currentUser.is_superuser?listCredential?.length: listCredentialCompany?.length"
                                  [(page)]="page"
                                  [pageSize]="pageSize"
                                  [maxSize]="2"
                                  [rotate]="true"
                                  [boundaryLinks]="true"
                                >
                                </ngb-pagination>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </li>
            <li
              [ngbNavItem]="'tab-source-mapping'"
              *ngIf="currentUser.is_site_admin || currentUser.is_super_site_admin"
            >
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-vertical-sourceMapping"
                data-toggle="pill"
                [routerLink]="'/account-settings/tab-source-mapping'"
                aria-expanded="false"
              >
                <i data-feather="copy" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.TITLE'"
                >
                </span>
              </a>
              <ng-template ngbNavContent>
                <div class="card">
                  <div class="card-body">
                    <form class="validate-form mt-2">
                      <div class="row">
                        <h4
                          class="col-12"
                          [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.RESUME_CONFIG'"
                        ></h4>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="gender">gender</label>
                            <input
                              type="text"
                              [(ngModel)]="gender"
                              class="form-control"
                              id="gender"
                              name="gender"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="address">address</label>
                            <input
                              type="text"
                              [(ngModel)]="address"
                              class="form-control"
                              id="address"
                              name="address"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="phone_number">phone_number</label>
                            <input
                              type="text"
                              [(ngModel)]="phone_number"
                              class="form-control"
                              id="phone_number"
                              name="phone_number"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="firstNameMapping">first_name</label>
                            <input
                              type="text"
                              [(ngModel)]="firstNameMapping"
                              class="form-control"
                              id="firstNameMapping"
                              name="firstNameMapping"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="lastNameMapping">last_name</label>
                            <input
                              type="text"
                              [(ngModel)]="lastNameMapping"
                              class="form-control"
                              id="lastNameMapping"
                              name="lastNameMapping"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="date_of_birth">date_of_birth</label>
                            <input
                              type="text"
                              [(ngModel)]="date_of_birth"
                              class="form-control"
                              id="date_of_birth"
                              name="date_of_birth"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="emailMapping">email</label>
                            <input
                              type="text"
                              [(ngModel)]="emailMapping"
                              class="form-control"
                              id="emailMapping"
                              name="emailMapping"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="current_title">current_title</label>
                            <input
                              type="text"
                              [(ngModel)]="current_title"
                              class="form-control"
                              id="current_title"
                              name="current_title"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="current_company">current_company</label>
                            <input
                              type="text"
                              [(ngModel)]="current_company"
                              class="form-control"
                              id="current_company"
                              name="current_company"
                            />
                          </div>
                        </div>
                        <h4 class="col-12">Education</h4>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="id_education">id</label>
                            <input
                              type="text"
                              [(ngModel)]="id_education"
                              class="form-control"
                              id="id_education"
                              name="id_education"
                              placeholder="Id"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="start_time_education">start_time</label>
                            <input
                              type="text"
                              [(ngModel)]="start_time_education"
                              class="form-control"
                              id="start_time_education"
                              name="start_time_education"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="end_time_education">end_time</label>
                            <input
                              type="text"
                              [(ngModel)]="end_time_education"
                              class="form-control"
                              id="end_time_education"
                              name="end_time_education"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="major">picklist_major</label>
                            <input
                              type="text"
                              [(ngModel)]="major"
                              class="form-control"
                              id="major"
                              name="major"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="school">school</label>
                            <input
                              type="text"
                              [(ngModel)]="school"
                              class="form-control"
                              id="school"
                              name="school"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="diploma">diploma</label>
                            <input
                              type="text"
                              [(ngModel)]="diploma"
                              class="form-control"
                              id="diploma"
                              name="diploma"
                            />
                          </div>
                        </div>
                        <!-- <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="gpa">Gpa</label>
                            <input type="text" [(ngModel)]="gpa" class="form-control" id="gpa" name="gpa"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.GPA'|translate" />

                          </div>
                        </div> -->
                        <h4 class="col-12">Experience</h4>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="id_experience">id</label>
                            <input
                              type="text"
                              [(ngModel)]="id_experience"
                              class="form-control"
                              id="id_experience"
                              name="id_experience"
                              placeholder="Id"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="start_time_experience">start_time</label>
                            <input
                              type="text"
                              [(ngModel)]="start_time_experience"
                              class="form-control"
                              id="start_time_experience"
                              name="start_time_experience"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="end_time_experience">end_time</label>
                            <input
                              type="text"
                              [(ngModel)]="end_time_experience"
                              class="form-control"
                              id="end_time_experience"
                              name="end_time_experience"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="position">position</label>
                            <input
                              type="text"
                              [(ngModel)]="position"
                              class="form-control"
                              id="position"
                              name="position"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="company">company</label>
                            <input
                              type="text"
                              [(ngModel)]="company"
                              class="form-control"
                              id="company"
                              name="company"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="detail">detail</label>
                            <input
                              type="text"
                              [(ngModel)]="detail"
                              class="form-control"
                              id="detail"
                              name="detail"
                            />
                          </div>
                        </div>
                        <h4 class="col-12">Picklist fields</h4>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="picklistDiploma">diploma</label>
                            <input
                              type="text"
                              [(ngModel)]="picklistDiploma"
                              class="form-control"
                              id="picklistDiploma"
                              name="picklistDiploma"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="picklistIndustry">industry</label>
                            <input
                              type="text"
                              [(ngModel)]="picklistIndustry"
                              class="form-control"
                              id="picklistIndustry"
                              name="picklistIndustry"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="picklistMajor">picklist_major</label>
                            <input
                              type="text"
                              [(ngModel)]="picklistMajor"
                              class="form-control"
                              id="picklistMajor"
                              name="picklistMajor"
                            />
                          </div>
                        </div>
                        <h4 class="col-12">Job application config</h4>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="matching_score">matching_score</label>
                            <input
                              type="text"
                              [(ngModel)]="matching_score"
                              class="form-control"
                              id="matching_score"
                              name="matching_score"
                            />
                          </div>
                        </div>
                        <!-- <h4 class="col-12">Auto upload score</h4>
                        <div class="col-12 col-sm-4">
                          <div class="form-group">
                            <label for="autoupload_score"
                              [translate]="'ACCOUNT_SETTING.SOURCE_MAPPING.AUTO_UPLOAD_SCORE'"></label>
                            <input type="text" [(ngModel)]="autoupload_score" class="form-control" id="autoupload_score"
                              name="autoupload_score"
                              [placeholder]="'ACCOUNT_SETTING.SOURCE_MAPPING.AUTO_UPLOAD_SCORE'|translate" />

                          </div>
                        </div> -->
                        <div class="col-12">
                          <button
                            (click)="SourceMapping()"
                            class="btn btn-primary mt-2 mr-1"
                            rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.SAVE'"
                          >
                            Save changes
                          </button>
                          <button
                            type="reset"
                            class="btn btn-secondary mt-2"
                            rippleEffect
                            [translate]="'ACCOUNT_SETTING.GENERAL.CANCEL'"
                          >
                            Cancel
                          </button>
                        </div>
                        <ng-container *ngIf="errorMessagesResultConnectSf4c">
                          <div class="alert alert-danger" role="alert">
                            {{ errorMessagesResultConnectSf4c }}
                          </div>
                        </ng-container>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-template>
            </li>
            <li
              [ngbNavItem]="'tab-mapping-picklist'"
              *ngIf="currentUser.is_site_admin || currentUser.is_super_site_admin"
            >
              <a
                ngbNavLink
                class="nav-link d-flex py-75"
                id="account-vertical-mappingPicklist"
                data-toggle="pill"
                [routerLink]="'/account-settings/tab-mapping-picklist'"
                aria-expanded="false"
              >
                <i data-feather="copy" class="font-medium-3 mr-1"></i>
                <span
                  class="font-weight-bold"
                  [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.TITLE'"
                >
                </span>
              </a>
              <ng-template ngbNavContent>
                <div class="card" id="mappingPicklist">
                  <div class="card-body">
                    <div
                      *ngIf="isLoading; else no_loading"
                      id="loading"
                      style="margin: 50px auto"
                    >
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <ng-template #no_loading>
                      <div class="row">
                        <div
                          class="col-md-12 col-12 d-flex justify-content-start justify-content-md-end"
                        >
                          <div class="d-flex align-items-center justify-content-end">
                            <button
                              id="btn-add-user"
                              class="btn btn-primary mb-1"
                              rippleEffect
                              (click)="
                                modalOpenMappingPicklist(modalBasicMappingPicklist)
                              "
                            >
                              <span
                                class="d-none d-sm-inline-block"
                                [translate]="
                                  'ACCOUNT_SETTING.MAPPING_PICKLIST.CREATE_MAPPING_PICKLIST'
                                "
                                >Add mapping picklist</span
                              >
                            </button>
                          </div>
                        </div>
                      </div>
                      <ngx-datatable
                        id="table-mapping-picklist"
                        #tableMappingPicklist
                        class="bootstrap core-bootstrap"
                        [rows]="listPicklist"
                        [rowHeight]="50"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [scrollbarH]="true"
                        [limit]="basicSelectedOption"
                        [messages]="{
                          emptyMessage:
                            'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                          totalMessage:
                            ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT' | translate)
                        }"
                      >
                        <!-- <ngx-datatable-column *ngIf="language=='vn'"
                          [name]="'ACCOUNT_SETTING.MAPPING_PICKLIST.HCM_SYSTEM_VN_LABEL' | translate">
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <span>
                              {{ row.hcm_system_vn_label }}
                            </span>
                          </ng-template>
                        </ngx-datatable-column> -->
                        <ngx-datatable-column
                          [width]="widthColumn"
                          [name]="'ACCOUNT_SETTING.MAPPING_PICKLIST.CHR_TYPE' | translate"
                        >
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <span>
                              {{ row.chr_type }}
                            </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [width]="widthColumn"
                          [name]="
                            'ACCOUNT_SETTING.MAPPING_PICKLIST.CHR_LABEL_NAME' | translate
                          "
                        >
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <span *ngIf="language == 'en'; else VN">
                              {{ row.chr_label_name.en }}
                            </span>
                            <ng-template #VN>
                              <span>
                                {{ row.chr_label_name.vn }}
                              </span>
                            </ng-template>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [width]="widthColumn"
                          [name]="
                            'ACCOUNT_SETTING.MAPPING_PICKLIST.HCM_SYSTEM_LABEL_ID'
                              | translate
                          "
                        >
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <span>
                              {{ row.hcm_system_label_id }}
                            </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [width]="widthColumn"
                          [name]="
                            'ACCOUNT_SETTING.MAPPING_PICKLIST.HCM_SYSTEM_ENG_LABEL'
                              | translate
                          "
                        >
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <span>
                              {{ row.hcm_system_eng_label }}
                            </span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column
                          [width]="widthColumn"
                          [name]="
                            'ACCOUNT_SETTING.MAPPING_PICKLIST.HCM_SYSTEM_VN_LABEL'
                              | translate
                          "
                        >
                          <ng-template ngx-datatable-cell-template let-row="row">
                            {{ row.hcm_system_vn_label }}
                          </ng-template>
                        </ngx-datatable-column>
                        <!-- ACTIONSSSSSSSSSSSSSSSSS -->
                        <ngx-datatable-column
                          [width]="widthColumn"
                          [name]="'ACTION' | translate"
                          [sortable]="false"
                          [sortable]="false"
                          [draggable]="false"
                        >
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="d-flex align-items-center">
                              <a
                                type="button"
                                href="javascript:void(0)"
                                (click)="
                                  modalOpenMappingPicklist(modalBasicMappingPicklist, {
                                    id: row.id,
                                    hcm_system_eng_label: row.hcm_system_eng_label,
                                    hcm_system_label_id: row.hcm_system_label_id,
                                    hcm_system_vn_label: row.hcm_system_vn_label
                                  })
                                "
                                container="body"
                                [ngbTooltip]="
                                  'ACCOUNT_SETTING.MAPPING_PICKLIST.EDIT_MAPPING_PICKLIST'
                                    | translate
                                "
                                class="btn btn-icon btn-flat-warning rounded-circle"
                                rippleEffect
                              >
                                <span
                                  class="d-flex align-items-center justify-content-center"
                                  ><i class="fa fa-pencil-square-o"></i>
                                </span>
                              </a>
                              <a
                                type="button"
                                href="javascript:void(0)"
                                (click)="deleteMappingPicklist(row.id)"
                                container="body"
                                [ngbTooltip]="
                                  'ACCOUNT_SETTING.CONNECT_SF4C.MODAL.DELETE_M'
                                    | translate
                                "
                                class="btn btn-icon btn-flat-danger rounded-circle"
                                rippleEffect
                              >
                                <span
                                  class="d-flex align-items-center justify-content-center"
                                  ><i class="fa fa-trash-o"></i>
                                </span>
                              </a>
                            </div>
                          </ng-template>
                        </ngx-datatable-column>
                      </ngx-datatable>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
              <ng-template #modalBasicMappingPicklist let-modal>
                <form
                  class="add-new-user modal-content pt-0"
                  (ngSubmit)="createMappingPicklist()"
                  [formGroup]="createMappingPicklistForm"
                >
                  <div class="modal-header mb-0">
                    <h5
                      *ngIf="!edit_picklist; else EDIT"
                      class="modal-title"
                      id="exampleModalLabel"
                      [translate]="
                        'ACCOUNT_SETTING.MAPPING_PICKLIST.CREATE_MAPPING_PICKLIST'
                      "
                    >
                      Create mapping picklist
                    </h5>
                    <ng-template #EDIT>
                      <h5
                        class="modal-title"
                        id="exampleModalLabel"
                        [translate]="
                          'ACCOUNT_SETTING.MAPPING_PICKLIST.EDIT_MAPPING_PICKLIST'
                        "
                      >
                        Create mapping picklist
                      </h5>
                    </ng-template>
                  </div>
                  <div class="modal-body flex-grow-1">
                    <div class="form-group" *ngIf="!edit_picklist">
                      <label for="type"
                        ><span
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.CHR_TYPE'"
                        ></span
                        ><b style="color: red">*</b></label
                      >
                      <span
                        *ngIf="
                          createMappingPicklistForm.controls.type.invalid &&
                          createMappingPicklistForm.controls.type.touched
                        "
                        class="invalid-form"
                      >
                        <small
                          class="form-text text-danger"
                          *ngIf="createMappingPicklistForm.controls.type.errors.required"
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.REQUIRED'"
                        ></small>
                      </span>
                      <div *ngFor="let type of typeItems" class="ml-2">
                        <input
                          type="radio"
                          [id]="type.name"
                          class="form-check-input"
                          formControlName="type"
                          [value]="type.name"
                          (change)="changeTypeEvent()"
                        />

                        <label for="type">{{ type.name }}</label>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="!edit_picklist">
                      <label for="chr_label"
                        ><span
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.CHR_LABEL'"
                        ></span
                        ><b style="color: red">*</b></label
                      >

                      <ng-select
                        [searchable]="false"
                        [items]="chr_label_items"
                        [bindLabel]="getBindLabel()"
                        bindValue="id"
                        formControlName="chr_label"
                      >
                      </ng-select>
                      <span
                        *ngIf="
                          createMappingPicklistForm.controls.chr_label.invalid &&
                          createMappingPicklistForm.controls.chr_label.touched
                        "
                        class="invalid-form"
                      >
                        <small
                          class="form-text text-danger"
                          *ngIf="
                            createMappingPicklistForm.controls.chr_label.errors.required
                          "
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.REQUIRED'"
                        ></small>
                      </span>
                    </div>
                    <div class="form-group">
                      <label for="hcm_system_label_id"
                        ><span
                          [translate]="
                            'ACCOUNT_SETTING.MAPPING_PICKLIST.HCM_SYSTEM_LABEL_ID'
                          "
                        ></span
                        ><b style="color: red">*</b></label
                      >

                      <input
                        type="text"
                        class="form-control"
                        id="hcm_system_label_id"
                        formControlName="hcm_system_label_id"
                      />
                      <span
                        *ngIf="
                          createMappingPicklistForm.controls.hcm_system_label_id
                            .invalid &&
                          createMappingPicklistForm.controls.hcm_system_label_id.touched
                        "
                        class="invalid-form"
                      >
                        <small
                          class="form-text text-danger"
                          *ngIf="
                            createMappingPicklistForm.controls.hcm_system_label_id.errors
                              .required
                          "
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.REQUIRED'"
                        ></small>
                      </span>
                    </div>
                    <div class="form-group">
                      <label for="hcm_system_eng_label"
                        ><span
                          [translate]="
                            'ACCOUNT_SETTING.MAPPING_PICKLIST.HCM_SYSTEM_ENG_LABEL'
                          "
                        ></span
                        ><b style="color: red">*</b></label
                      >

                      <input
                        type="text"
                        class="form-control"
                        id="hcm_system_eng_label"
                        formControlName="hcm_system_eng_label"
                      />
                      <span
                        *ngIf="
                          createMappingPicklistForm.controls.hcm_system_eng_label
                            .invalid &&
                          createMappingPicklistForm.controls.hcm_system_eng_label.touched
                        "
                        class="invalid-form"
                      >
                        <small
                          class="form-text text-danger"
                          *ngIf="
                            createMappingPicklistForm.controls.hcm_system_eng_label.errors
                              .required
                          "
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.REQUIRED'"
                        ></small>
                      </span>
                    </div>
                    <div class="form-group">
                      <label for="hcm_system_vn_label"
                        ><span
                          [translate]="
                            'ACCOUNT_SETTING.MAPPING_PICKLIST.HCM_SYSTEM_VN_LABEL'
                          "
                        ></span
                        ><b style="color: red">*</b></label
                      >

                      <input
                        type="text"
                        class="form-control"
                        id="hcm_system_vn_label"
                        formControlName="hcm_system_vn_label"
                      />
                      <span
                        *ngIf="
                          createMappingPicklistForm.controls.hcm_system_vn_label
                            .invalid &&
                          createMappingPicklistForm.controls.hcm_system_vn_label.touched
                        "
                        class="invalid-form"
                      >
                        <small
                          class="form-text text-danger"
                          *ngIf="
                            createMappingPicklistForm.controls.hcm_system_vn_label.errors
                              .required
                          "
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.REQUIRED'"
                        ></small>
                      </span>
                    </div>
                    <div>
                      <b style="color: red">*</b
                      ><span
                        [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.NOTE_PICKLIST'"
                      >
                        Thông tin picklist trên hệ thống cần chính xác tránh ảnh hưởng đến
                        vận hành hệ thống</span
                      >
                    </div>
                    <div class="modal-footer">
                      <button
                        [disabled]="!createMappingPicklistForm.valid"
                        type="submit"
                        class="btn btn-primary"
                      >
                        <span
                          [translate]="
                            !edit_picklist
                              ? 'ACCOUNT_SETTING.MAPPING_PICKLIST.CREATE'
                              : 'ACCOUNT_SETTING.MAPPING_PICKLIST.EDIT'
                          "
                        ></span>
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary ml-1"
                        (click)="modalService.dismissAll()"
                        rippleEffect
                      >
                        <span
                          [translate]="'ACCOUNT_SETTING.MAPPING_PICKLIST.CANCEL'"
                        ></span>
                      </button>
                    </div>
                  </div>
                </form>
              </ng-template>
            </li>
          </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
      </div>
    </section>
    <!--/ account setting page -->
  </div>
</div>
