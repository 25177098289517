import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer-landing',
  templateUrl: './footer-landing.component.html',
  styleUrls: ['./footer-landing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterLandingComponent implements OnInit {
  public customerForm: FormGroup
  public loading = false
  public isSubmited = false
  public registerSuccess = false
  public registerFailure = false
  constructor(
    private formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _coreTranslateService: CoreTranslationService,

  ) {
    this.customerForm = this.formBuilder.group({
      name: [""],
      email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      phone_number:["", [Validators.required,Validators.pattern("[0-9]{10}")]],
      company: [""],
      position: [""]
    })
   }
  createCustomer(){}
  ngOnInit(): void {
  }

}
