import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "en",
  data: {
    DROP_DOWN: {
      SETTING: "Settings",
      MANAGE_API_KEY: "Manage API key",
      PROFILE: "Profile",
      BILLING: "Payment history",
      ORGANIZATION: "Organization",
      PAYMENT: "Buy quota", 
      LOG_OUT: "Log out",
    },
    PAGE_REST: "pages left",
  },
};
