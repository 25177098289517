import { Component, Input } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-avatar",
  template: `
    <div
      class="avatar"
      [ngStyle]="{ 'background-image': 'url(' + avatarUrl + ')' }"
    ></div>
  `,
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent {
  @Input() name: string;
  avatarUrl: string;

  constructor(private _cookieService: CookieService) {
    //var userName = this._cookieService.get("username").replace(/"/g, "");
    var userName = localStorage.getItem("username")
    
  }
  ngOnInit(){
    if(this.name){
      const abbreviation = this.generateAbbreviation(this.name);
      this.avatarUrl = `https://via.placeholder.com/40x40.png?text=${abbreviation}`;
    }
  }
  generateAbbreviation(input: string): string {
    // Split the input string into words
    const words = input.trim().split(" ");

    // Use the first letter of each word to generate the abbreviation
    let abbreviation = "";
    for (let i = 0; i < words.length; i++) {
      abbreviation += words[i].charAt(0);
    }
    
    // Return the abbreviation
    return abbreviation.toUpperCase();
  }
}
