<div class="content-wrapper container-xxxl p-0">
  <div id="companyW">

    <!-- <div class="content-body mb-2">

      <div class="">
        <form (ngSubmit)="onSubmit()" [formGroup]="FilterStatForm">

          <div class="d-flex">
            <div class="col-md-4 pl-0">
              <input id="period" formControlName="period" [placeholder]="'DASHBOARD.CHOOSE_PERIOD'|translate"
                bsDaterangepicker type="text" class="form-control"
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" autocomplete="off"/>

            </div>
            <div class="col-md-4 pl-0">


              <button type="submit" class="btn btn-primary w-btn-analytic" rippleEffect [translate]="'DASHBOARD.FILTER'"></button>
              <button type="button" (click)="onReset()" class="btn btn-secondary ml-1" rippleEffect
                [translate]="'DASHBOARD.RESET'"></button>
            </div>
          </div>

        </form>
      </div>

    </div> -->
    <div *ngIf="isLoading;else noLoading" id="loading" class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <ng-template #noLoading>

      <div class="card card-statistics">
        <div class="card-body statistics-body">
          <div class="row">
            <div class="col-sm-6 col-xl" *ngIf="user.is_superuser">
              <div class="media">
                <div class="avatar bg-light-primary mr-2">
                  <div class="avatar-content">
                    <i data-feather="users" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{activityStat?.free_trial_users}}</span>
                  <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.FREE_TRIAL'"></p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl" *ngIf="user.is_superuser">
              <div class="media">
                <div class="avatar bg-light-primary mr-2">
                  <div class="avatar-content">
                    <i data-feather="users" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{activityStat?.paid_users}}</span>
                  <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.PAID_USERS'"></p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl" *ngIf="user.is_site_admin ||user.is_super_site_admin ||user.role=='User'">
              <div class="media">
                <div class="avatar bg-light-warning mr-2">
                  <div class="avatar-content">
                    <i data-feather="package" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{companyActivityCurrent[0]?.company}}</span>
                  <br>
                  <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.PACKAGE_NAME'"></span>: <span class="font-weight-bolder">{{companyActivityCurrent[0]?.package.name}}</span>
                  <br>
                  <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.EXPIRY_DATE'"></span>: <span class="font-weight-bolder">{{companyActivityCurrent[0]?.expiry_date|date:'dd/MM/yyyy'}}</span>
                
                </div>
              </div>
            </div>
            
            <div class="col-sm-6 col-xl" *ngIf="user.is_site_admin ||user.is_super_site_admin||user.role=='User'">
              <div class="media">
                <div class="avatar bg-light-primary mr-2">
                  <div class="avatar-content">
                    <i data-feather="users" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0" *ngIf="companyActivityCurrent[0]?.users;else noAddUser">{{companyActivityCurrent[0]?.users}}</span>
                  <ng-template #noAddUser>
                    <span class="font-weight-bolder mb-0">0</span>
                  </ng-template>
                  <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.USER_NUM_LEFT'"></p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl" *ngIf="user.is_site_admin||user.is_super_site_admin||user.role=='User'">
              <div class="media">
                <div class="avatar bg-light-info mr-2">
                  <div class="avatar-content">
                    <i data-feather="file" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{companyActivityCurrent[0]?.quota_left.parse_num |number:'0.0-0'}}</span>
                  <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.PARSE_NUM_LEFT'"></p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl" *ngIf="user.is_site_admin||user.is_super_site_admin||user.role=='User'">
              <div class="media">
                <div class="avatar bg-light-info mr-2">
                  <div class="avatar-content">
                    <i data-feather="file" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{companyActivityCurrent[0]?.quota_left.matching_num|number:'0.0-0'}}</span>
                  <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.MATCHING_NUM_LEFT'"></p>
                </div>
              </div>
            </div>
            
            <div class="col-sm-6 col-xl" *ngIf="user.role=='Free trial user'">
              <div class="media">
                <div class="avatar bg-light-primary mr-2">
                  <div class="avatar-content">
                    <i data-feather="user" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{userActivityCurrent[0]?.user}}</span>
                  
                  <br>
                  <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.PACKAGE_NAME'"></span>: <span class="font-weight-bolder">{{userActivityCurrent[0]?.package.name}}</span>
                  <br>
                  <span class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.TABLE.EXPIRY_DATE'"></span>: <span class="font-weight-bolder">{{userActivityCurrent[0]?.expiry_date|date}}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl" *ngIf="user.role=='Free trial user'">
              <div class="media">
                <div class="avatar bg-light-info mr-2">
                  <div class="avatar-content">
                    <i data-feather="file" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{userActivityCurrent[0]?.quota_left.parse_num|number:'0.0-0'}}</span>
                  <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.PARSE_NUM_LEFT'"></p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl" *ngIf="user.role=='Free trial user'">
              <div class="media">
                <div class="avatar bg-light-info mr-2">
                  <div class="avatar-content">
                    <i data-feather="file" class="avatar-icon"></i>
                  </div>
                </div>
                <div class="media-body my-auto">
                  <span class="font-weight-bolder mb-0">{{userActivityCurrent[0]?.quota_left.matching_num|number:'0.0-0'}}</span>
                  <p class="card-text font-small-3 mb-0" [translate]="'DASHBOARD.MATCHING_NUM_LEFT'"></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row" *ngIf="user.is_superuser">
        <div class="col-xl-6 col-12" >
          <div class="card card-statistics h-chart">
            <div class="card-header flex-column align-items-start">
              <h4 class="card-title mb-75" [translate]="'DASHBOARD.ACTIVE_USER_BY_DAY'"></h4>
              <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
            </div>
            <div class="card-body">
              <div id="line-chart" #activeChartRef>
                <apx-chart [series]="activeChartOptions.series" [chart]="{
                      height: 400,
                      
                      type: 'line',
                      
                      toolbar: {
                        show: false
                      }
                    }" [xaxis]="activeChartOptions.xaxis" [yaxis]="activeChartOptions.yaxis" [stroke]="activeChartOptions.stroke"
                  [tooltip]="activeChartOptions.tooltip" [colors]="activeChartOptions.colors"
                  [dataLabels]="activeChartOptions.dataLabels" [grid]="activeChartOptions.grid"></apx-chart>
              </div>

            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12">
          <div class="card card-statistics h-chart">
            <div class="card-header flex-column align-items-start">
              <h4 class="card-title mb-75" [translate]="'DASHBOARD.REVENUE'"></h4>
              <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
            </div>
            <div class="card-body">
              <div id="line-chart" #revenueChartRef>
                <apx-chart [series]="revenueChartOptions.series" [chart]="{
                      height: 400,
                      
                      type: 'line',
                      
                      toolbar: {
                        show: false
                      }
                    }" [xaxis]="revenueChartOptions.xaxis" [yaxis]="revenueChartOptions.yaxis" [stroke]="revenueChartOptions.stroke"
                  [tooltip]="revenueChartOptions.tooltip" [colors]="revenueChartOptions.colors"
                  [dataLabels]="revenueChartOptions.dataLabels" [grid]="revenueChartOptions.grid"></apx-chart>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center" *ngIf="user.is_superuser">

        <div class="col-xl-6 col-12">
          <div class="card card-statistics h-chart">
            <div class="card-header flex-column align-items-start">
              <h4 class="card-title mb-75" [translate]="'DASHBOARD.PACKAGE'"></h4>
              <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
            </div>
            <div class="card-body">
              <div id="earnings-donut-chart" #packageChartRef>
                <!-- Apexchart component-->
                <apx-chart [chart]="{
                          type: 'donut',             
                          height: 400,
                          toolbar: {
                            show: false
                          }
                        }" [dataLabels]="packageChartoptions.dataLabels" [series]="packageChartoptions.series"
                  [legend]="packageChartoptions.legend" [labels]="packageChartoptions.labels"
                  [stroke]="packageChartoptions.stroke" [colors]="packageChartoptions.colors"
                  [grid]="packageChartoptions.grid" [plotOptions]="packageChartoptions.plotOptions"></apx-chart>
                <!-- / Apexchart component-->
              </div>

            </div>
          </div>
        </div>
        
      </div>
      <div class="row align-items-center" *ngIf="user.is_site_admin ||user.is_super_site_admin || user.role=='User'">
        <div class="col-xl-12 col-12">
          <div class="card card-statistics">
            <div class="card-header flex-column align-items-start">
              <h4 class="card-title mb-75" [translate]="'DASHBOARD.COMPANY_ACTIVITY_CURR'"></h4>
              <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
            </div>
            <div class="card-body">
              <ngx-datatable
                          id="table-company-current"
                          #tableCompanyActivityCurrent
                          class="bootstrap core-bootstrap"
                          [rows]="companyActivityCurrent"
                          [externalPaging]="true"
                            [count]="pageCompanyActivityCurrent.totalElements"
                            [offset]="pageCompanyActivityCurrent.pageNumber"
                            [limit]="5"
                            (page)="setPageCompanyActivityCurrent($event)"
                          [rowHeight]="50"
                          class="bootstrap core-bootstrap"
                          [columnMode]="ColumnMode.force"
                          [headerHeight]="50"
                          [footerHeight]="50"
                          [scrollbarH]="false"
                          [messages]="{
                            emptyMessage: 'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                            totalMessage:  ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT' | translate)
                          }"
                        >
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.PACKAGE_NAME'|translate" >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.package.name.replace("_"," ") }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <!-- <ngx-datatable-column [name]="'DASHBOARD.TABLE.PRICE'|translate" >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.package.price|currency:"VND":"symbol" }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column> -->
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.PARSE_NUM'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.parse_num }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.MATCHING_NUM'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.matching_num }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.START_DATE'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.start_date|date:'dd/MM/yyyy' }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.EXPIRY_DATE'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.expiry_date|date:'dd/MM/yyyy' }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center" *ngIf="user.role=='Free trial user'">
        <div class="col-xl-12 col-12">
          <div class="card card-statistics">
            <div class="card-header flex-column align-items-start">
              <h4 class="card-title mb-75" [translate]="'DASHBOARD.USER_ACTIVITY_CURR'"></h4>
              <!-- <span class="card-subtitle text-muted">Spending on various categories </span> -->
            </div>
            <div class="card-body">
              <ngx-datatable
                          id="table-user-current"
                          #tableTrialActivityCurrent
                          class="bootstrap core-bootstrap"
                          [rows]="userActivityCurrent"
                          [externalPaging]="true"
                            [count]="pageUserActivityCurrent.totalElements"
                            [offset]="pageUserActivityCurrent.pageNumber"
                            [limit]="5"
                            (page)="setPageUserActivityCurrent($event)"
                          [rowHeight]="50"
                          class="bootstrap core-bootstrap"
                          [columnMode]="ColumnMode.force"
                          [headerHeight]="50"
                          [footerHeight]="50"
                          [scrollbarH]="false"
                          [messages]="{
                            emptyMessage: 'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                            totalMessage:  ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT' | translate)
                          }"
                        >
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.PACKAGE_NAME'|translate" >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.package.name.replace("_"," ") }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <!-- <ngx-datatable-column [name]="'DASHBOARD.TABLE.PRICE'|translate" >
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.package.price|currency:"VND":"symbol" }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column> -->
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.PARSE_NUM'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.parse_num }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.MATCHING_NUM'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.matching_num }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.START_DATE'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.start_date|date:'dd/MM/yyyy' }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column [name]="'DASHBOARD.TABLE.EXPIRY_DATE'|translate">
                            <ng-template ngx-datatable-cell-template let-row="row">
                              <div class="w-100">
                                <span>{{ row.expiry_date|date:'dd/MM/yyyy' }}</span>
                              </div>
                            </ng-template>
                          </ngx-datatable-column>
                        </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>