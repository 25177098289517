export const locale = {
    lang: 'en',
    data: {
      RESUME_PARSER: {
        PARSE_RESUME: {  
            UPLOAD_UP_TO: "Upload up to 50 Files CV (PDF, DOCX, EXCEL, JPEG)",
            RESUMES: "resumes",
            UPLOAD: "Upload",
            FILE_NAME: "File Name",
            STATUS: "Status",
            SF4C_STATUS: "SAP SF status",
            CREATE_AT: "Created at",
            MODIFIED_AT: "Modified at",
            FIRST_NAME: "First Name",
            LAST_NAME: "Last Name",
            ADDRESS: "Address",
            EMAIL: "Email",
            PHONE_NUMBER: "Phone number",
            GENDER: "Gender",
            DATE_OF_BIRTH: "Date Of Birth",
            CURRENT_TITLE: "Current Title",
            CURRENT_COMPANY: "Current Company",
            CONF_SCORE: "Confidence Score",
            APPROVE_RESUMES: "Approve resumes",
            SELECT_JOB: "Select job requirement title",
            UPLOAD_SF4C: "Upload SAP SF",
            REUPLOAD: "Reupload",
            JOB_REQ_ID: "Job requirement id",
            JOB_TITLE: "Job title",
            SUBMIT: "Submit",
            LABEL_UPLOAD: 'Drag and drop documents here or upload up files (PDF, DOC, DOCX, JPEG)',
            FROM_DEVICE: 'From Device',
            SYNC_EMAIL: "Sync cv from email"
        },
        MANAGE_RESUME: {
            PAGE: "page",
            SEARCH: "Search",
            SHOW: "Show",
            ADD_RESUME: "Add resume",
            MODIFIED_AT: "Modified At",
            YEAR_EXPERIENCE: "Year Experience",
            JOB_APPLICATION_STATUS: "JA Status",
            DELETE_RESUME: "Delete resume!",
            DOWNLOAD_TYPE: "Report",
            DOWNLOAD_STAT: "Download report",
            DOWNLOADING: "Downloading",
            TYPE: "File type",
            CANCEL: "Cancel",
            ACCEPT: "Accept",
            OWNER: "Owner",
            OPTION: "Configure the report file",
            REQUIRED: "This field is required",
            INVALID_DATE_RANGE: "Invalid date range",
            CHOICE_STATUS: "Status",
            CHOICE_SF4C_STATUS: "SAP SF status",
            CHOICE_OWNER: "Owner email",
            CHOICE_SOURCE: "Source",
            MIN_CONF: "Min confidence (0,1)",
            MAX_CONF: "Max confidence (0,1)",
            ACTION: "Action",
            SOURCE: "Source",
            ID: "Resume id",
            ADD_RESUME_TO_TALENTPOOL: "Add resume to talent pool",
            ADD_RESUMES: "Add",
            SYNC_EMAIL: "New resumes from email are being parsed",
            MODAL: {
              ACTION: "Action",
              DELETE_R: "Delete resume",
              DELETE_RESUMES: "Delete resumes",
              CANT_UNDO: "You won't be able to undo this",
              CANCEL: "Cancel",
              CONFIRM: "Confirm",
              IMPORT: "Import",
              TITLE: "Upload file to extract",
              DELETE_T: "Delete talent pool"

            },
            TOAST: {
              EDIT_R_SUC: "Edit resume",
              CREATE_R_SUC: "Create resume",
              DELETE_R_SUC: "Delete resume",
              ERROR: "Error",
              FAILED: "Failed",
              SUCCESS: "Success",
              APPROVE_MUL_RESUME: "Approve resumes"
            },
            STATUS: {
              APPROVED: "Approved",
              DONE: "Done",
              FAILURE: "Failure",
              PROCESSING: "Processing",
              WAIT_TO_PROCESS: "Wait to process",
              SUSPENDED: "Suspended",
              MODIFIED: "Modified",
              DUPLICATED: "Duplicated"
            },
            STATUS_SF4C:{
              SF4C_BACKGROUND_FAILED: "SF background failed",
              SF4C_WAIT: "SF wait to upload",
              SF4C_FAILED: "SF failed",
              SF4C_UPLOADING: "SF uploading",
              SF4C_UPLOADED: "SF uploaded",
              SF4C_NOT_UPLOAD: "SF not upload"
            },
            JA_STATUS: {
              CHECK: "Check",
              SUCCESS: "Success",
              FAILURE: "Fail"
            }
        },
        INTEGRATION: {
            INTEGRATION: "Upload documents to SuccessFactors",
            NO_SUITABLE_JR: " There is no suitable JR",
            UPLOAD_FAILED: " Upload failed!",
            UPDATE_JA_SUCCESSFACTORS: " Document status on SuccessFactors",
            UPLOAD_JA: " Create job application",
            UPLOAD: "Upload SuccessFactors",
            NO_RESUME_APPROVE:"There are no resume in an Approved status.",
            NO_RESUME_SF4C_UPLOAD:"There are no resume in an SF_uploaded status.",
            NEXT: "Next",
            TITLE_PROCESS: "Selecting invalid profile uploads",
            RESUME_ID: "Invalid resume Id",
            ERROR: "Error",
            OVERWRITE: "Overwrite",
            NOTI: "Notification",
            NOTI_TITLE: "You haven't set up your SuccessFactors account yet. Please click on the link: ",
            CONFIRM: "Confirm",
            SET_UP: "Setting",
            IMPORTEDBY: "Imported by",
            SOURCE: "Source",
            PLACEHOLDER: {
              SEARCH: "Search job title",
              SELECTED_IMPORTEDBY: "Selecte imported by",
              SELECTED_SOURCE: "Selecte source"
            },
            JOB_APPLICATION_ID: "Application id",
            JOB_REQ_ID: "Job id",
            MATCHING_SCORE: "Matching score",
            STATUS: "Status SAP SF",
            MESSAGE: "Message",
            MODIFIED_AT: "Modified at",
            IMPORTED_BY: "Imported by",
            ASKED: "Please press Cancel to select another job"
          },
        DETAIL_RESUME:{
            LAST_MODIFIED_BY: "Last modified by",
            TAB_CHR: "Parse resume C-HR",
            TAB_SF4C: "Parse resume of SuccessFactors",
            CANDIDATE_INFORMATION: "Candidate information",
            SF4C_RESUME_ID: "SuccessFactors candidate id:",
            CANDIDATE: "CHR candidate id:",
            RESUME_ID: "Resume id:",
            META_DATA: "General information",
            DOB: "Dob",
            ESTIMATE_DOB: "Estimate dob",
            CATEGORY: "Category",
            SKILLS: "Skills",
            CONF_SCORE: "Confidence score",
            WORK_EXPERIENCE: "Work Experience",
            POSITION: "Position",
            COMPANY: "Company",
            INDUSTRY: "Industry",
            MAJOR: "Major",
            SCHOOL: "School",
            DIPLOMA: "Diploma",
            GPA: "GPA",
            EDUCATION: "Education & Training",
            EDUCATION_: "Education",
            LANGUAGE: "Language",
            JOB_REQ_MATCHING: "Job Requisition matching",
            SELECT_JR: "Select JR:",
            ANALYSIS: "Analysis",
            NO_MATCHING: "No matching results were found",
            SEARCHING: "Scoring results",
            MATCHING_POINT: "Matching point",
            EXPERIENCE: "Experience",
            FOREIGN_LANGUAGE: "Foreign language",
            CANDIDATE_SKILL: "The candidate's skills are suitable for the job:",
            APPROVE: "Approve",
            UPSERT: "Upsert",
            EDIT: "Save",
            CENSOR: "Censor",
            CANDIDATE_SF4C: "Candidate information of SuccessFactors",
            YEAR_EXPERIENCE: "Year experience",
            PICKLIST_MAJOR: "Picklist major",
            DEGREE: "Degree",
            DECENSOR: "Decensor",
            EDIT_CANDIDATE: "Edit candidate information.",
            CHOOSE_GENDER: "--Choose gender--",
            CHOOSE_CATEGORY: "--Choose category--",
            CHOOSE_SOURCE: "--Choose source--",
            CHOOSE_YEAR_EXPERIENCE: "--Choose year experience--",
            START_TIME: "Start time",
            END_TIME: "End time",
            START_TIME_CREATE: "Time create from",
            END_TIME_CREATE: "Time create end",
            CHOOSE_INDUSTRY: "--Choose industry--",
            DETAIL: "Detail",
            DETAIL_SCORE: "Detail score",
            DESCRIPTION_JOB: "Job description",
            CHOOSE_MAJOR: "--Choose major--",
            CHOOSE_DIPLOMA: "--Choose diploma--",
            PHONE_NUMBER_PATTERN: "The phone number must have 10 digits and not allow word.",
            EMAIL_PATTERN: "Email invalidate",
            UPLOAD_SAP_SF: "Resume is being uploaded to SAP SF",
            UPDATE_SAP_SF: "Resume is being processed",
            ADD_TALENT_POOL: "Add to talent pool",
            PLACEHOLDER:{
              PLACEHOLDER_SELECT_MATCHING: "Job"
            },
            GENDER_:{
              MALE: "Male",
              FEMALE: "Female",
            },
            YEAR_EXP_:{
              UNDER_YEAR: "Under 1 year",
              THREE_YEAR: "1 - 3 years",
              FIVE_YEAR: "3 - 5 years",
              TEN_YEAR: "5 - 10 years",
              OVER_TEN_YEAR: "Over 10 years",
            },
            TOTAL_YEAR_EXP: "Total years of experience",
            YEAR: "years",
            MONTH: "months",
            CATEGORY_:{
              PRODUCTION: "Production",
              ENGINEERING: "Engineering",
              ACCOUNT: "Account/Finance",
              CONSTRUCTION: "Construction",
              SERVICES: "Services",
              ADMINISTRATION: "Administration / Human Resources",
              SALES: "Sales / Marketing",
              COMPUTER: "Computer / Information Technology",
              MEDIA: "Media / Communications",
              HOSPITALITY: "Hospitality / Tourism",
              SCIENCE: "Science",
              INDUSTRIES: "Other Industries",
              HEALTHCARE: "Healthcare",
              EDUCATION: "Education / Training",
              CONSUMER: "Consumer Goods",
          },
            SHARE: "Share",
            REMOVE_SHARE: "Remove share",
            TITLE_SHARE: "Select user",
            USER: "User",
            PLACEHOLDER_SHARE: "--Choose user--",
            CONFIRM: "Confirm",
            CANCEL: "Cancel",
            SHARED: "Shared user",
            SHARE_BY: "Share by",
            READ_MORE: "Read more",
            REMOVE_SHARED: "Remove shared user",
            NOT_FOUND: "No profile found or not allow to access",
            RETURN_PAGE: "Come back",
            MODEL_AI: "AI prediction",
            HOVER_YEAR_EXPERIENCE: "Total working time related to the candidate's most recent profession",
            HOVER_ALL_YEAR_EXPERIENCE: "Total working time of the candidate"
        },
        TALENT_POOL: {
          TITLE: "Talent pool",
          PLACEHOLDER_SEARCH: "Search talent pool name",
          EDIT_FOLDER: "Edit talent pool name",
          DELETE_FOLDER: "Delete talent pool",
          ADD_FOLDER: "Add talent pool",
          NAME_FOLDER: "Name talent pool",
          FOLDER: "Talent pool",
          REQUIRED: "This field is required",
          CREATE: "Create",
          EDIT: "Edit",
          CANCEL: "Cancel",
          REMOVE_RESUMES: "Remove resumes",
          CHANGE_RESUMES: "Change talent pool",
          CHANGE: "Change"

        },
        
      },
      CONNECT_SF4C: {
        TITLE: "Connect to SuccessFactors",
        EDIT_CRED: "Edit credential",
        UPLOAD_FILE: "Upload file PEM",
        API_KEY: "API key",
        COMPANY_ID: "Company id",
        USER_NAME: "User name",
        TOKEN_URL: "API server URL",
        ACCESS_URL: "Access URL",
        INFO_CREDENTIAL: "Credential list",
        DETAIL_CREDENTIAL: "Information credential",
        USER: "User",
        CLIENT_ID: "Client id",
        MODIFIED_AT: "Modified at",
        EXPIRE_IN_DAYS: "Expire in days",
        YOUR_CREDENTIAL: "Your credential",
        CREATE_CREDENTIAL: "Create credential",
        AUTO_SETTING_CONFIG: "Auto setting config",
        MAPPING_SOURCE: "Mapping source",
        MODAL: {
            ACTION: "Action",
            DELETE_C: "Delete credential",
            CANT_UNDO: "You won't be able to undo this",
            CANCEL: "Cancel",
            CONFIRM: "Confirm",
            DELETE_M: "Delete mapping picklist"
          },
          TOAST: {
            DELETE_C_SUC: "Delete credential",
            ERROR: "Error",
            FAILED: "Failed",
            SUCCESS: "Success"
          },
    },
    }
  };
  