<app-content-header [contentHeader]="contentHeader"></app-content-header>
<form ngNativeValidate #NewCvTaskForm="ngForm" (ngSubmit)="(NewCvTaskForm.form.valid)">
  <div class="row">

    <div class="col-md-7">
      <div class="content-wrapper container-xxxl p-0 card ">
        <div class="content-body card-body">
          <h3 [translate]="'TALENT_POOL.CREATE_JOB.BASIC_INFO'"></h3>
          <div class="mb-2">
            <label for="job_title" class="form-label"><span
                [translate]="'TALENT_POOL.SEARCH_RESUME.JOB_TITLE'"></span><b style="color:red">*</b></label>
            <input [(ngModel)]="job_title" [class.error]="!JobTitleRef.valid && JobTitleRef.touched"
              #JobTitleRef="ngModel" type="text" class="form-control" id="job_title" name="job_title"
              [placeholder]="'TALENT_POOL.SEARCH_RESUME.JOB_TITLE'|translate" aria-label="Search..."
              aria-describedby="job_title" required />
            <span *ngIf="!JobTitleRef.valid && JobTitleRef.touched" class="invalid-form">
              <small class="form-text text-danger" *ngIf="JobTitleRef?.errors?.['required']"
                [translate]="'TALENT_POOL.CREATE_JOB.REQUIRED'">ThisFieldIsRequired</small>
            </span>

          </div>
          <div class="mb-2">
            <label for="description" class="form-label"><span
                [translate]="'TALENT_POOL.CREATE_JOB.JOB_DESCRIPTION'"></span><b style="color:red">*</b></label>

            <quill-editor [required]="true" [styles]="{height: '250px'}" [(ngModel)]="description"
              [modules]="{ syntax: false, toolbar: [['code-block']] }" name="description"
              [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_DESCRIPTION'|translate"
              [class.error]="!JobDescriptionRef.valid && JobDescriptionRef.touched" #JobDescriptionRef="ngModel">
              <div quill-editor-toolbar>
                <span class="ql-formats">
                  <button class="ql-bold" [title]="'Bold'"></button>
                  <button class="ql-italic" [title]="'italic'"></button>
                  <button class="ql-underline" [title]="'underline'"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-align" [title]="'Alignment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-link" value="ordered" type="button"></button>
                  <button class="ql-image" value="ordered" type="button"></button>
                  <button class="ql-video" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-formula" value="ordered" type="button"></button>
                  <button class="ql-code-block" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-clean" value="ordered" type="button"></button>
                </span>
              </div>
            </quill-editor>
            <span *ngIf="!JobDescriptionRef.valid && JobDescriptionRef.touched" class="invalid-form">
              <small class="form-text text-danger" *ngIf="JobDescriptionRef?.errors?.['required']"
                [translate]="'TALENT_POOL.CREATE_JOB.REQUIRED'">ThisFieldIsRequired</small>
            </span>
          </div>
          <div class="mb-2">
            <label for="job-require" class="form-label"><span
                [translate]="'TALENT_POOL.CREATE_JOB.JOB_REQUIREMENT'"></span><b style="color:red">*</b></label>

            <quill-editor [required]="true" [styles]="{height: '250px'}" [(ngModel)]="requirement"
              [modules]="{ syntax: false, toolbar: [['code-block']] }" name="requirement"
              [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_REQUIREMENT'|translate"
              [class.error]="!JobRequirementsRef.valid && JobRequirementsRef.touched" #JobRequirementsRef="ngModel">
              <div quill-editor-toolbar>
                <span class="ql-formats">
                  <button class="ql-bold" [title]="'Bold'"></button>
                  <button class="ql-italic" [title]="'italic'"></button>
                  <button class="ql-underline" [title]="'underline'"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-align" [title]="'Alignment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-link" value="ordered" type="button"></button>
                  <button class="ql-image" value="ordered" type="button"></button>
                  <button class="ql-video" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-formula" value="ordered" type="button"></button>
                  <button class="ql-code-block" value="ordered" type="button"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-clean" value="ordered" type="button"></button>
                </span>
              </div>
            </quill-editor>
            <span *ngIf="!JobRequirementsRef.valid && JobRequirementsRef.touched" class="invalid-form">
              <small class="form-text text-danger" *ngIf="JobRequirementsRef?.errors?.['required']"
                [translate]="'TALENT_POOL.CREATE_JOB.REQUIRED'"></small>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="content-wrapper container-xxxl p-0 card ">
        <div class="content-body card-body">
          <div class="">
            <button [disabled]="!NewCvTaskForm.form.valid || ((yearOfExperienceFrom > yearOfExperienceTo) && yearOfExperienceFrom!=null && yearOfExperienceTo!=null)||
              yearOfExperienceFrom < 0 || yearOfExperienceTo < 0" class="btn btn-primary" (click)="onSubmit()"
              rippleEffect
              [translate]="id?'TALENT_POOL.CREATE_JOB.SUBMIT_EDIT_JOB':'TALENT_POOL.CREATE_JOB.SUBMIT_ADD_JOB'"></button>
          </div>
          <small class="form-text text-danger" *ngIf="JobTitleRef?.errors?.['required']"
            [translate]="'TALENT_POOL.CREATE_JOB.JOB_TITLE_REQUIRED'">ThisFieldIsRequired</small>
          <small class="form-text text-danger" *ngIf="JobDescriptionRef?.errors?.['required']"
            [translate]="'TALENT_POOL.CREATE_JOB.JOB_DESCRIPTION_REQUIRED'">ThisFieldIsRequired</small>
          <small class="form-text text-danger" *ngIf="JobRequirementsRef?.errors?.['required']"
            [translate]="'TALENT_POOL.CREATE_JOB.JOB_REQUIREMENT_REQUIRED'"></small>
          <div *ngIf="errorMessagesCreateNewJd"><span style="color: red;">Error: </span>{{errorMessagesCreateNewJd}}
          </div>

        </div>
      </div>
      <div class="content-wrapper container-xxxl p-0 card ">
        <div class="content-body card-body">

          <h3 [translate]="'TALENT_POOL.CREATE_JOB.REQUIRES_EXPERTISE'"></h3>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <label for="keywords" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.KEYWORDS'"></label>

                <ng-select [items]="customKeyword" [addTag]="true" multiple="true"
                  [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER_KEYWORDS'|translate" name="keywords"
                  [(ngModel)]="keywords">
                </ng-select>
                
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-2">
                <label for="job_level" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL'"></label>
                <ng-select *ngIf="language=='en';else levelVn" [items]="job_level" bindLabel="name_en" bindValue="value"
                  [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL'|translate" name="selectedRank"
                  [(ngModel)]="selectedRank">
                </ng-select>
                <ng-template #levelVn>
                  <ng-select [items]="job_level" bindLabel="name" bindValue="value"
                    [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL'|translate" name="selectedRank"
                    [(ngModel)]="selectedRank">
                  </ng-select>
                </ng-template>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="mb-2">
                  <label for="industry" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.INDUSTRY'"></label>
                  <ng-select [items]="industry"
                      bindLabel="name"
                      bindValue="value"
                      [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER_INDUSTRY'|translate"
                      
                      name="selectedBusiness"
                      [(ngModel)]="selectedBusiness"
                      [multiple]="true">
                  </ng-select>
              </div>
          </div> -->
            <div class="col-md-6">
              <div class="mb-2">
                <label for="job_type" class="form-label" [translate]="'TALENT_POOL.CREATE_JOB.JOB_TYPE'"></label>

                <ng-select *ngIf="language=='en';else VN" [items]="job_type" bindLabel="name_en" bindValue="value"
                  [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_TYPE'|translate" name="selectedTypeWork"
                  [(ngModel)]="selectedTypeWork">
                </ng-select>
                <ng-template #VN>
                  <ng-select [items]="job_type" bindLabel="name" bindValue="value"
                    [placeholder]="'TALENT_POOL.CREATE_JOB.JOB_TYPE'|translate" name="selectedTypeWork"
                    [(ngModel)]="selectedTypeWork">
                  </ng-select>
                </ng-template>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-2">
                <label for="sliderYearsOfExperience" class="form-label"
                  [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXPERIENCE'"></label>
                <div class="row align-items-center">
                  <div class="pattern col-6">
                    <input id="yearOfExperienceFrom" name="yearOfExperienceFrom" [(ngModel)]="yearOfExperienceFrom"
                      [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER.YEAR_OF_EXPERIENCE_FROM'|translate"
                      [class.error]="YearOfExperienceFromRef.invalid && YearOfExperienceFromRef.touched"
                      #YearOfExperienceFromRef="ngModel" type="number" class="form-control" pattern="^\d+(\.\d+)?$">
                    <span *ngIf="YearOfExperienceFromRef.invalid && YearOfExperienceFromRef.touched"
                      class="invalid-form">
                      <small *ngIf="YearOfExperienceFromRef.errors.pattern" class="form-text text-danger"
                        [translate]="'TALENT_POOL.CREATE_JOB.PATTERN'"></small>
                    </span>

                  </div>

                  <div class="pattern col-6">
                    <input id="yearOfExperienceTo" name="yearOfExperienceTo" [(ngModel)]="yearOfExperienceTo"
                      [placeholder]="'TALENT_POOL.CREATE_JOB.PLACEHOLDER.YEAR_OF_EXPERIENCE_TO'|translate"
                      [class.error]="YearOfExperienceToRef.invalid && YearOfExperienceToRef.touched"
                      #YearOfExperienceToRef="ngModel" type="number" class="form-control" pattern="^\d+(\.\d+)?$">
                    <span *ngIf="YearOfExperienceToRef.invalid && YearOfExperienceToRef.touched" class="invalid-form">
                      <small *ngIf="YearOfExperienceToRef.errors.pattern" class="form-text text-danger"
                        [translate]="'TALENT_POOL.CREATE_JOB.PATTERN'"></small>
                    </span>
                    <span *ngIf="yearOfExperienceTo != null && yearOfExperienceFrom > yearOfExperienceTo"
                      class="invalid-form">
                      <small class="form-text text-danger" [translate]="'TALENT_POOL.CREATE_JOB.INVALID'"></small>
                    </span>

                  </div>
                </div>

              </div>
            </div>
            
          </div>



        </div>
      </div>
    </div>

  </div>
</form>