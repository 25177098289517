import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ListCompany, ResultChangePassword, User } from 'app/auth/models';
import { CHR_Picklist, MappingPicklist } from 'app/main/resume-parser/models/resume.model';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountSettingsService{
  rows: any;
  onSettingsChanged: BehaviorSubject<any>;
  private BASE_URL = `${environment.apiUrl}/users`;
  private listUserSubject: BehaviorSubject<User[]>;
  public listUser: Observable<User[]>;

  /**
   * Constructor
   *
   * @param {HttpClient} _http
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    public _translateService: TranslateService,
    private _coreTranslateService: CoreTranslationService,
    ) {
    // Set the defaults
    this.onSettingsChanged = new BehaviorSubject({});
    //folders
    this.listUserSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("list_user"))
    );
    this.listUser = this.listUserSubject.asObservable();
  }
  setNewListUser(list: any[]) {
    this.listUserSubject.next(list);
    localStorage.setItem("list_user", JSON.stringify(list));
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */

  /**
   * Get rows
   */
  changePassword(id: number, old_password: string, new_password: string):Observable<ResultChangePassword>{
    const url = `${this.BASE_URL}/${id}/change-password`;
    return this._http.put<ResultChangePassword>(url, {old_password, new_password})
  }
  updateUser(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<any>(url, formData)
  }
  detailUser(id: number): Observable<User>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.get<User>(url) 
  }
  resetPassword(id: number): Observable<ResultChangePassword>{
    const url = `${this.BASE_URL}/${id}/reset-password`;
    return this._http.put<ResultChangePassword>(url,{})
  }
  createUser(formData: any): Observable<any>{
    const url = `${this.BASE_URL}/register/`;
    return this._http.post<any>(url, formData)
  }
  adminUpdateUser(id: number, first_name: string, last_name: string, email: string, permissions: string[],is_site_admin: boolean): Observable<User>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<User>(url, {first_name, last_name, email,permissions,is_site_admin})
  }
  deleteUser(id: any, assign_to: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}/delete?assign_to=${assign_to}`;
    return this._http.delete<any>(url)
  }
  //List company
  list_company(): Observable<ListCompany>{
    const url = `${environment.apiUrl}/users/company`;
    return this._http.get<ListCompany>(url)
  }
  //Mapping picklist
  getMappingPicklist():Observable<MappingPicklist[]>{
    const url = `${environment.apiUrl}/configure/picklist/mapping`;
    return this._http.get<any>(url)
  }
  //create mapping picklist
  createMappingPicklist(formData): Observable<any>{
    const url = `${environment.apiUrl}/configure/picklist/mapping`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("ACCOUNT_SETTING.MAPPING_PICKLIST.CREATE"),
            this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
              
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  updateMappingPicklist(id: any, formData): Observable<any>{
    const url = `${environment.apiUrl}/configure/picklist/mapping/${id}`;
    return this._http.put<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("ACCOUNT_SETTING.MAPPING_PICKLIST.EDIT"),
            this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
              
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //delete mapping picklist
deleteMappingPicklist(id: any): Observable<any>{
  const url = `${environment.apiUrl}/configure/picklist/mapping/${id}`;
  return this._http.delete<any>(url)
}
}

