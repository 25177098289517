import { _education, _experience } from "app/main/resume-parser/models/resume.model"
export class Status{
    name: string
    value: string
}
export const status: Status[]= [
    {name: "Approved", value: "Approved"},
    {name: "Done", value: "Done"},
    {name: "Failure", value: "Failure"},
    {name: "Processing", value: "Processing"},
    {name: "Wait to process", value: "Wait_to_process"},
    {name: "Suspended", value: "Suspended"},
    {name: "Modified", value: "Modified"},
]
export const sf4c_status: Status[]= [
    {name: "SF background failed", value: "SF4C_background_failed"},
    {name: "SF wait to upload", value: "SF4C_wait_to_upload"},
    {name: "SF failed", value: "SF4C_failed"},
    {name: "SF uploading", value: "SF4C_uploading"},
    {name: "SF uploaded", value: "SF4C_uploaded"},
    {name: "SF not upload", value: "Not_upload"},
]
export enum status_color{
    Approved= 'success',
    SF4C_background_failed= 'warning',
    Done= "primary",
    Failure= "danger",
    Processing= "info",
    Wait_to_process="dark",
    Suspended="secondary",
    Modified="warning",
    SF4C_wait_to_upload="dark",
    SF4C_failed="danger",
    Not_upload="secondary",
    SF4C_uploading="info",
    SF4C_uploaded="success",
    Duplicated="warning"
}
export enum job_status_color{
    Success= 'success',
    Fail= "danger",
    Check= "warning"
}
export enum range_conf{
    success= 0.6,
    warning= 0.4,
}
export class ResultSearchResume{
    result!: SearchResume[]
    next_page!: boolean
    num_result!: number
    stop_refresh!: boolean
}
export class SearchResume{
    id!: number
    latest_resume_pk!: number
    address!: string
    city!: string
    current_company!: string
    current_title!: string
    education_fulltext!: string
    experience_fulltext!: string
    resume_fulltext!: string
    education: string[]=[]
    experience: string[]=[]
    fullname!: string
    // resume_id!: any
    title_score!: number
    score!: number
    full_name!:string
    status!: string
    avatar!: string
    source!: string
    seen_CV!: number
    seen_CV_lasttime!: string
    C_HR_resume!: C_Hr_resume
    seen_by: string[]=[]
    last_seen!: string
    owner: number
    owner_company: number
    first_name: string
    last_name: string
    created_at: string
    skills: string[]=[]
    category!: string
    year_exp: number
    email: string
    phone_number: string
    gender: string
    date_of_birth: string
    references: []
    saved: boolean
    score_detail: any
    owner_id: string
    is_shared:boolean
    is_seen: boolean
    modified_at: string
    candidate: number
}
export class C_Hr_resume{
    id!: number
    status!: string
}
export class DetailResume{
    avatar!: string

    id!: any
    pdf_content!: string
    education: _education[]=[]
    experience: _experience[]=[]
    source!: string
    file_name!: string
    status!: string
    created_at!: string
    modified_at!: string
    first_name!: string
    last_name!: string
    email!: string
    phone_number!: string
    sf4c_status!: string
    webhook_status!: number
    fulltext!: string
    education_fulltext!: string
    experience_fulltext!: string
    conf_score!: number
    title!: any
    skills: string[]=[]
    gender!: string
    date_of_birth!: string
    address!: string
    category!: string
    talent_pool_indexed!: boolean
    year_exp!: string
    task!: number
    owner!: number
    successfactor_resume_id!: any
    current_title!: string
    current_company!: string
    full_name!: string
    references: []
    city: string
    resume: any
}
export class SaveResumeByJd{
    message: any[]=[]
}
export class ListResumeMatching{
    count!: number
    results: ResumeMatching[] = []
}
export class ResumeMatching{
    id!: number
    jobReqId!: string
    jobReqId_detail!: number
    created_at!: string
    result!: ResultOverall_score
    status!: string
    resume!: number
    created_by!: number
    resume_status!: string
    resume_detail_status!: string
    // job_application_status!: string
    // job_application_detail_status!: string
    resume_detail!: DetailResume
}
export class ResultOverall_score{
    overall_score!: number
}
//Sync sf4c JR
export class ResultSyncSf4cJr{
    message!: string
}
//Matching CV-JD
export class ResultMatchingCV{
    overall_score!: number
    matching_status!: string
    education_score!: number
    experience_score!: number

}