import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';

import { FooterComponent } from 'app/layout/components/footer/footer.component';
import { ScrollTopComponent } from 'app/layout/components/footer/scroll-to-top/scroll-top.component';
import { FooterLandingComponent } from './footer-landing/footer-landing.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [FooterComponent, ScrollTopComponent, FooterLandingComponent],
  imports: [RouterModule, CoreCommonModule, TranslateModule,FormsModule,
    ReactiveFormsModule,ToastrModule,NgbModule, TranslateModule],
  exports: [FooterComponent, FooterLandingComponent]
})
export class FooterModule {}
