import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "vn",
  data: {
    GENERAL: {
      GENERAL: "Tổng quan",
      LIST_PACKAGE: "Danh sách gói bán",
      ADD_PACKAGE: "Thêm gói mới",
      UPDATE_PACKAGE: "Sửa gói bán",
      CREATE: "Tạo",
      UPDATE: "Sửa",
      DELETE: "Xóa",
      TITLE: "Tiêu đề",
      DESCRIPTION: "Mô tả",
      DISPLAY_ALLOW: "Cho phép hiển thị",
      DISPLAY_ORDER: "Thứ tự hiển thị",
      PACKAGE_FREE: "Gói dùng thử",
      ACTION: "Hành động",
      YES: "Có",
      NO: "Không",
      EDIT: {
        INFO_EDIT: "Sửa thông tin",
        PACKAGE_NAME: "Tên gói",
        PACKAGE_NAME_DES: "Tên gói sử dụng để hiển thị",
        TYPE_OF_SERVICE: "Loại dịch vụ",
        CHOOSE_PACKAGE_CONFIG: "Chọn cấu hình gói",
        DESCRIPTION: "Mô tả",
        CONTENT_HTML: "Nội dung HTML",
        ADD_TAG: "Thêm thẻ",
        PACKAGE_DEFAULT_LABEL: "Gói bán dùng thử cho dịch vụ được chọn",
        PACKAGE_DEFAULT: "Gói mặc định",
        PACKAGE_DEFAULT_DES: "Chỉ có thể chọn 1 gói dùng thử cho 1 loại dịch vụ",
        PACKAGE_DISPLAY: "Hiển thị gói đối với người dùng",
        DISPLAY: "Hiển thị",
        PACKAGE_DISPLAY_DES: "Nên ẩn các gói tùy chọn của doanh nghiệp",
        IS_FREE_TRIAL: "Là gói dùng thử",
        SUBSCRIBE_BUTTON_TEXT: "Văn bản đăng ký",
        ORDER: "Thứ tự hiển thị",
        CONFIRM: "Xác nhận",
        CANCEL: "Hủy",
        REQUIRED: "Trường này yêu cầu bắt buộc"
      },
      DETAIL: {
        VIEW: "Thông tin",
        PRODUCT_PRICE: "Giá sản phẩm",
        DETAIL: "Chi tiết",
        MONTH: "Tháng",
        YEAR: "Năm",
        DAY: "Ngày"
      },
      MODAL: {
        ACTION: "Hành động",
        DELETE_A: "Xóa gói bán",
        CANT_UNDO: "Bạn không thể hoàn tác",
        CANCEL: "Hủy",
        CONFIRM: "Xác nhận",
      },
    TOAST: {
        DELETE_R_SUC: "Xóa gói bán",
        ERROR: "Lỗi khi thực hiện",
        FAILED: "Thất bại",
        SUCCESS: "Thành công"
    },

    },
    PACKAGES_CONFIG: {
        PACKAGES_CONFIG: "Cấu hình các gói",
        PACKAGE_CONFIG: "Cấu hình gói bán",
        NAME: "Tên",
        NUMBER_OF_STREAM: "Số luồng",
        ADD_CONFIG: "Thêm cấu hình",
        EDIT: {
          EDIT_CONFIG: "Sửa cấu hình",
          NAME_CONFIG: "Tên cấu hình",
          MATCHING_THREAD_LIMIT: "Số luồng chấm điểm giới hạn",
          PARSE_THREAD_LIMIT: "Số luồng trích xuất giới hạn",
          PARSE_LIMITED: "Số lần trích xuất giới hạn",
          MATCHING_LIMITED: "Số lần chấm điểm giới hạn",
          MAX_USER: "Số lượng người dùng giới hạn"
        },
        MODAL: {
          ACTION: "Hành động",
          DELETE_A: "Xóa cấu hình gói",
          CANT_UNDO: "Bạn không thể hoàn tác",
          CANCEL: "Hủy",
          CONFIRM: "Xác nhận",
        },
        TOAST: {
            DELETE_R_SUC: "Xóa cấu hình gói",
            ERROR: "Lỗi khi thực hiện",
            FAILED: "Thất bại",
            SUCCESS: "Thành công"
        },
    },
    PRICING_CONFIG: {
        PRICING_CONFIG: "Cấu hình giá",
        TITLE: "Tiêu đề",
        PACKAGE: "Gói bán",
        DESCRIPTION: "Mô tả",
        DEFAULT_PRICING: "Giá mặc định cho gói",
        SELLER: "Người bán",
        PRICING: "Giá",
        ACTION: "Hành động",
        CONFIG_NAME_PRICING: "Tên cấu hình giá",
        DISPLAY_DEFAULT_PRICING: "Giá mặc định hiển thị với gói bán được chọn",
        DEFAULT_PRICING_LABEL: "Giá mặc định",
        PRICING_VN: "Giá: Việt Nam Đồng",
        SALE_PRICING: "Gói bán",
        CHOOSE_SALE_PRICING: "Chọn gói bán",
        PAYMENT_CYCLE: "Chu kỳ thanh toán",
        UNIT: "Đơn vị",
        PACKAGE_PRICING: "Giá gói",
        MONTH: "Tháng",
        EDIT: {
          ADD_PRICING: "Thêm giá mới",
          EDIT_PRICING: "Sửa giá"

        },
        MODAL: {
          ACTION: "Hành động",
          DELETE_A: "Xóa cấu hình giá",
          CANT_UNDO: "Bạn không thể hoàn tác",
          CANCEL: "Hủy",
          CONFIRM: "Xác nhận",
        },
        TOAST: {
            DELETE_A_SUC: "Xóa cấu hình giá",
            ERROR: "Lỗi khi thực hiện",
            FAILED: "Thất bại",
            SUCCESS: "Thành công"
        },
    },
    ADD_PLAN_COST: {
      TITLE: "Cập nhật gói cho tài khoản",
      MODAL_TITLE: "Cập nhật cấu hình giá",
      CHOOSE_COST: "Chọn cấu hình giá",
      UPDATE: "Cập nhật",
      PLAN_COST: "Chi phí kế hoạch",
      USER: "Người dùng",
      COMPANY_ACTIVITY: "Tài khoản công ty",
      TRIAL_ACTIVITY: "Tài khoản dùng thử",
      TABLE: {
        USER: "Người dùng",
        COMPANY: "Công ty",
        PACKAGE_NAME: "Cấu hình gói",
        EXPIRY_DATE: "Hạn dùng",
        CURRENT_SUBSCRIPTION_PLAN_COST: "Chi phí gói hiện tại"
      },
    }
}
};
