export const locale = {
    lang: 'vn',
    data: {
        ACCOUNT_SETTING:{
            ACCOUNT_SETTING: "Cài đặt tài khoản",
            LOGIN: {
                TITLE: "👋 Chào mừng!",
                LOGIN_ERROR: "E-mail hoặc mật khẩu không chính xác",
                WELCOME_CHR: "Chào mừng đến với CHR 👋",
                PLEASE_SIGN: "Vui lòng đăng nhập vào tài khoản của bạn và bắt đầu trải nghiệm hệ thống",
                EMAIL: "E-mail",
                EMAIL_REQUIRED: "E-mail là bắt buộc",
                EMAIL_VALID: "E-mail phải là một địa chỉ email hợp lệ",
                PASSWORD: "Mật khẩu",
                FORGOT_PASSWORD: "Quên mật khẩu?",
                FORGOT_PASSWORD_TITLE: "Quên mật khẩu?🔒",
                PASSWORD_REQUIRED: "Mật khẩu là bắt buộc",
                REMEMBER_ME: "Ghi nhớ",
                SIGN_IN: "Đăng nhập",
                NEW_PLATFORM: "Mới trên nền tảng của chúng tôi?",
                SEND_EMAIL: "Nhập email của bạn và chúng tôi sẽ gửi hướng dẫn để đặt lại mật khẩu của bạn",
                SEND_NEW_PASSWORD: "Gửi mật khẩu mới",
                BACK_TO_LOGIN: "Trở lại đăng nhập",
                CHECK_EMAIL: "Kiểm tra email của bạn để kích hoạt tài khoản!",
                
            },
            GENERAL:{
                TITLE: "Tổng quan",
                FIRST_NAME: "Tên",
                LAST_NAME: "Họ",
                EMAIL: "E-mail",
                SAVE: "Lưu",
                CANCEL: "Hủy",
                REQUIRED: "Trường này bắt buộc",
                EDIT: "Sửa"
            },
            PASSWORD: {
                TITLE: "Thay đổi mật khẩu",
                OLD_PASS: "Mật khẩu cũ",
                NEW_PASS: "Mật khẩu mới",
                NOT_MATCH: "Mật khẩu mới không khớp",
                RETYPE_PASS: "Mật khẩu mới lặp lại",
                NOT_SAME: "Mật khẩu không khớp"
            },
            RESUME_SETTINGS: {
                TITLE: "Cài đặt trích xuất tự động",
                AUTO_APPROVE: "Hồ sơ tự động được phê duyệt",
                AUTO_SYNC: "Hồ sơ được tự động trích xuất bởi e-mail",
                CENSOR_CONTACT: "Che thông tin",
                APPROVE_SCORE: "Điểm tin cậy phê duyệt",
                WEBHOOK_PARSING: "Tích hợp trích xuất",
                WEBHOOK_DEV_PARSING: "Tích hợp phát triển trích xuất",
                WEBHOOK_MATCHING: "Tích hợp ứng viên phù hợp với đăng tuyển",
                WEBHOOK_DEV_MATCHING: "Tích hợp phát triển ứng viên phù hợp với đăng tuyển",
                WEBHOOK_RECOMMEND:"Tích hợp khuyến nghị ứng viên",
                WEBHOOK_RECOMMEND_DEV_API: "Tích hợp phát triển khuyến nghị ứng viên",
                LIMIT_APPROVE_SCORE: "Giá trị phải nằm trong khoảng 0 tới 1"
            },
            CONNECT_SF4C: {
                TITLE: "Kết nối tới SAP SF",
                EDIT_CRED: "Sửa thông tin kết nối",
                UPLOAD_FILE: "Tải lên tệp PEM",
                API_KEY: "Khóa API",
                COMPANY_ID: "Mã công ty",
                USER_NAME: "Tên người dùng",
                TOKEN_URL: "API server URL",
                ACCESS_URL: "Access URL",
                INFO_CREDENTIAL: "Danh sách thông tin kết nối",
                DETAIL_CREDENTIAL: "Thông tin kết nối",
                USER: "Người dùng",
                CLIENT_ID: "Mã khách hàng",
                MODIFIED_AT: "Thời gian tạo",
                EXPIRE_IN_DAYS: "Hết hạn sau ngày",
                YOUR_CREDENTIAL: "Chủ sở hữu",
                CREATE_CREDENTIAL: "Thêm thông tin kết nối",
                MODAL: {
                    ACTION: "Hành động",
                    DELETE_C: "Xóa thông tin kết nối",
                    CANT_UNDO: "Bạn không thể hoàn tác",
                    CANCEL: "Hủy",
                    CONFIRM: "Xác nhận",
                    DELETE_M: "Xóa mapping picklist"

                  },
                  TOAST: {
                    DELETE_C_SUC: "Xóa thông tin kết nối",
                    ERROR: "Lỗi khi thực hiện",
                    FAILED: "Thất bại",
                    SUCCESS: "Thành công"
                  },

            },
            SOURCE_MAPPING: {
                TITLE: "Cấu hình trường dữ liệu",
                RESUME_CONFIG: "Cấu hình hồ sơ",
                GENDER: "Giới tính",
                ADDRESS: "Địa chỉ",
                PHONE_NUMBER: "Số điện thoại",
                FIRST_NAME: "Tên",
                LAST_NAME: "Họ",
                DATE_OF_BIRTH: "Ngày sinh",
                EMAIL: "E-mail",
                CURRENT_TITLE: "Công việc hiện tại",
                CURRENT_COMPANY: "Công ty hiện tại",
                EDUCATION: "Giáo dục",
                START_TIME: "Bắt đầu",
                END_TIME: "Kết thúc",
                MAJOR: "Chuyên ngành",
                SCHOOL: "Trường",
                DIPLOMA: "Bằng cấp",
                GPA: "Điểm tích lũy",
                EXPERIENCE: "Kinh nghiệm",
                POSITION: "Vị  trí",
                COMPANY: "Công ty",
                DETAIL: "Chi tiết",
                JOB_APP_CONFIG: "Cấu hình ứng dụng công việc",
                MATCHING_SCORE: "Điểm phù hợp",
                AUTO_UPLOAD_SCORE: "Điểm tự động tải lên",
                PICKLIST_DIPLOMA: "Picklist diploma",
                PICKLIST_INDUSTRY: "Picklist industry",
                PICKLIST_MAJOR: "Picklist major"
                
            },
            ACTIVITY_HISTORY:{
                TITLE: "Danh sách người dùng",
                USERS: "tài khoản",
                CREATE_USER: "Thêm tài khoản",
                EDIT_USER: "Cập nhật thông tin tài khoản",
                DELETE_USER: "Xóa tài khoản",
                NAME: "Tên",
                EMAIL: "E-mail",
                COMPANY: "Công ty cha",
                SUB_COMPANY: "Công ty",
                PASSWORD: "Mật khẩu",
                PASSWORD_REQUIRED: "Mật khẩu được yêu cầu",
                SUBMIT: "Thêm",
                CURRENT_PACKAGE: "Gói hiện tại",
                PERMISSION_PARSE: "Quyền trích xuất hồ sơ",
                PERMISSION_SEARCH: "Quyền tìm kiếm hồ sơ",
                PERMISSION_TALENT: "Quyền tìm truy cập kho dữ liệu",
                IS_ACTIVE: "Đang hoạt động",
                IS_SITE_ADMIN: "Là quản trị viên",
                LAST_LOGIN: "Lần gần nhất đăng nhập",
                ACTION: "Hành động",
                ASSIGN_USER: "Chỉ định người quản lý tài liệu",
                CANCEL: "Hủy",
                ACCEPT: "Chấp nhận",
                REQUIRED: "Trường này yêu cầu bắt buộc",
                EMAIL_CHECK: "Email không có giá trị",
                ITEMS_PER_PAGE: "mục trên trang",
                SHOW: "Hiển thị",
                PLACEHOLDER: {
                    SEARCH_EMAIL: "Tìm kiếm theo e-mail",
                    FILTER_COMPANY: "Lọc theo công ty"
                },
                NOTE_ASSIGN_USER: "Nếu không chỉ định người quản lý tài liệu, quyền quản lý tài liệu sẽ được chuyển cho người yêu cầu xóa"

            },
            MAPPING_PICKLIST: {
                TITLE: "Cấu hình picklist",
                HCM_SYSTEM_LABEL_ID: "Mã picklist trên SF",
                HCM_SYSTEM_ENG_LABEL: "Tên picklist trên SF (Tiếng Anh)",
                HCM_SYSTEM_VN_LABEL: "Tên picklist trên SF (Tiếng Việt)",
                COMPANY: "Công ty",
                CHR_LABEL: "Tên picklist trong CHR",
                CHR_LABEL_NAME: "Tên trường CHR",
                CHR_TYPE: "Loại trường CHR",
                REQUIRED: "Trường này bắt buộc",
                CREATE: "Thêm",
                EDIT: "Sửa",
                CANCEL: "Hủy",
                CREATE_MAPPING_PICKLIST: "Thêm cấu hình picklist",
                EDIT_MAPPING_PICKLIST: "Sửa cấu hình picklist",
                NOTE_PICKLIST: "Thông tin picklist trên hệ thống cần chính xác tránh ảnh hưởng đến vận hành hệ thống"
            }
          }
      
    }
  };
  