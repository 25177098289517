export class CV_task{
    index?:number
    id!: number
    job_title: string = ''
    location: string[] = []
    job_level: string = ''
    industry: any[] =[]
    saved_cvs: any[] = []
    job_type: string = ''
    description: string = ''
    requirement: string = ''
    years_of_experience_from: number = 0
    years_of_experience_to: number = 0
    yob_from: number = 0
    yob_to: number = 0
    gender: string = ''
    keyword_expression:string = ''
    status: string = ''
    viewed_cvs:Array<string> = []
    owner_email!: string
    num_labeled_cv!: number
    city: string[]=[]
    fulltext_bool_expression!: string
    full_name_bool_expression!: string
    experience_bool_expression!: string
    education_bool_expression!: string
    current_title_bool_expression!: string
    current_company_bool_expression!: string
    loaded: boolean = false;
    num_saved_candidates!: number
    hcm_system_jd!: string
    created_at: string;
    owner: string;
    keywords: any[]=[]
}
export const City = ['Lào Cai',
'Lai Châu',
'Phú Thọ',
'Quảng Nam',
'Thái Nguyên',
'Hải Dương',
'Bạc Liêu',
'Quảng Ninh',
'Cần Thơ',
'Bến Tre',
'Bình Dương',
'Tiền Giang',
'Lâm Đồng',
'Sóc Trăng',
'Phú Yên',
'Quảng Bình',
'Hải Phòng',
'Hưng Yên',
'Hoà Bình',
'Hậu Giang',
'Tây Ninh',
'Bình Định',
'Lạng Sơn',
'Nam Định',
'Bắc Ninh',
'Kiên Giang',
'Vĩnh Long',
'Long An',
'Nghệ An',
'Đắk Lắk',
'An Giang',
'Hà Giang',
'Bắc Giang',
'Kon Tum',
'Cà Mau',
'Quảng Trị',
'Yên Bái',
'Cao Bằng',
'Sơn La',
'Gia Lai',
'Điện Biên',
'Bà Rịa - Vũng Tàu',
'Thừa Thiên Huế',
'Bình Phước',
'Ninh Thuận',
'Khánh Hòa',
'Đắk Nông',
'Quảng Ngãi',
'Đồng Nai',
'Tuyên Quang',
'Bắc Kạn',
'Vĩnh Phúc',
'Thanh Hóa',
'Trà Vinh',
'Thái Bình',
'Bình Thuận',
'Hà Nam',
'Ninh Bình',
'Đồng Tháp',
'Hà Tĩnh',
]
export const CategoryItems = ['Sản xuất',
'Kỹ thuật',
'Kế toán / Tài chính',
'Xây dựng',
'Dịch vụ',
'Hành chính / Nhân sự',
'Bán hàng / Tiếp thị',
'Máy tính / Công nghệ thông tin',
'Truyền thông / Media',
'Khách sạn / Du lịch',
'Khoa học',
'Nhóm ngành khác',
'Chăm sóc sức khỏe',
'Giáo dục / Đào tạo',
'Hàng tiêu dùng'
]
