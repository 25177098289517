<app-share-component [popup]="popup"></app-share-component>
<div class="content-wrapper container-xxxl p-0 card" *ngIf="!popup">
    <div class="content-body card-body">
        <div>
            <ul ngbNav #nav="ngbNav" class="nav nav-tabs">

                <li ngbNavItem>
                    <a (click)="general()" ngbNavLink class="d-flex align-items-center">
                        <span class="d-none d-sm-block" [translate]="'GENERAL.GENERAL'"></span></a>
                    <ng-template ngbNavContent>
                        <!-- general Tab starts -->
                        <div class="tab-pane" id="general" aria-labelledby="general-tab" role="tabpanel">
                            <div class="row" *ngIf="detailPackage;else noDetail">
                                <div class="col-lg-9 col-12">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <h2 [translate]="'GENERAL.LIST_PACKAGE'"></h2>
                                        </div>
                                        <div class="col-md-7 col-12 row align-items-center justify-content-end mb-1">
                                            <div class="">
        
                                                <button class="btn btn-primary btn-icon mr-2" rippleEffect
                                                    (click)="modalOpenCreateSubscriptionPlan(modalSubscriptionPlan)">
                                                    <span [translate]="'GENERAL.ADD_PACKAGE'" class="d-none d-lg-inline"></span>
        
                                                </button>
                                            </div>
                                            <ng-template #modalSubscriptionPlan let-modal>
        
                                                <form (ngSubmit)="(NewSubscriptionPlanForm.form.valid)" ngNativeValidate #NewSubscriptionPlanForm="ngForm"
                                                    >
                                                    <div class="modal-header">
                                                        <h4 *ngIf="!editSubscriptionPlanBoolean;else noSubscriptionPlan" class="modal-title" [translate]="'GENERAL.ADD_PACKAGE'">
                                                        </h4>
                                                        <ng-template #noSubscriptionPlan>
                                                            <h4 class="modal-title" [translate]="'GENERAL.EDIT.INFO_EDIT'">
                                                            </h4>
                                                        </ng-template>
        
                                                        <!-- <button type="button" class="close"
                                                            (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button> -->
                                                    </div>
                                                    <div class="modal-body" ngbAutofocus>
                                                        <div class="form-group">
                                                            <label for="title"><span
                                                                    [translate]="'GENERAL.EDIT.PACKAGE_NAME'"></span><b
                                                                    style="color:red">*</b></label>
                                                            <input [class.error]="!TitleRef.valid && TitleRef.touched"
                                                            #TitleRef="ngModel" type="text" id="title" [(ngModel)]="title" class="form-control"
                                                                name="title" required/>
                                                            <span *ngIf="!TitleRef.valid && TitleRef.touched" class="invalid-form">
                                                                <small class="form-text text-danger" *ngIf="TitleRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                    ></small
                                                                >
                                                                </span>
        
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="package"><span
                                                                    [translate]="'GENERAL.EDIT.CHOOSE_PACKAGE_CONFIG'"></span><b
                                                                    style="color:red">*</b></label>
                                                            <ng-select [items]="listPackageItems"
                                                                bindLabel="name"
                                                                bindValue="id"
                                                                [placeholder]="'GENERAL.EDIT.CHOOSE_PACKAGE_CONFIG'|translate"
                                                                
                                                                name="package"
                                                                [(ngModel)]="package"
                                                                [class.error]="!PackageRef.valid && PackageRef.touched"
                                                            #PackageRef="ngModel" required>
                                                            </ng-select>
                                                            <span *ngIf="!PackageRef.valid && PackageRef.touched" class="invalid-form">
                                                                <small class="form-text text-danger" *ngIf="PackageRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                    ></small
                                                                >
                                                                </span>
        
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="description"><span
                                                                    [translate]="'GENERAL.EDIT.DESCRIPTION'"></span></label>
                                                            <textarea type="text" id="description" [(ngModel)]="description"
                                                                class="form-control" name="description"> </textarea>
        
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="title"><span
                                                                    [translate]="'GENERAL.EDIT.CONTENT_HTML'"></span><b
                                                                    style="color:red">*</b></label>
                                                            <ng-select [items]="HTMLItems" [addTag]="true" multiple="true"
                                                                [placeholder]="'GENERAL.EDIT.ADD_TAG'|translate"
                                                                [(ngModel)]="html_content" name="html_content"
                                                                [class.error]="!HtmlContentRef.valid && HtmlContentRef.touched"
                                                            #HtmlContentRef="ngModel" required>
                                                            </ng-select>
                                                            <span *ngIf="!HtmlContentRef.valid && HtmlContentRef.touched" class="invalid-form">
                                                                <small class="form-text text-danger" *ngIf="HtmlContentRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                    ></small
                                                                >
                                                                </span>
                                                        </div>
                                                        <div class="mb-2">
                                                            <div class="custom-control custom-switch">
                                                            <input type="checkbox" class="custom-control-input" [(ngModel)]="is_display"
                                                                id="is_display" name="is_display" />
                                                            <label class="custom-control-label" for="is_display"
                                                                [translate]="'GENERAL.EDIT.PACKAGE_DISPLAY'">
                                
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <div class="mb-2">
                                                            <div class="custom-control custom-switch">
                                                            <input type="checkbox" class="custom-control-input" [(ngModel)]="is_free_trial"
                                                                id="is_free_trial" name="is_free_trial" />
                                                            <label class="custom-control-label" for="is_free_trial"
                                                                [translate]="'GENERAL.EDIT.IS_FREE_TRIAL'">
                                
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="order"><span
                                                                    [translate]="'GENERAL.EDIT.ORDER'"></span><b
                                                                    style="color:red">*</b></label>
                                                            <input [class.error]="!OrderRef.valid && OrderRef.touched"
                                                            #OrderRef="ngModel" type="number" id="order" [(ngModel)]="order" class="form-control"
                                                                name="order" required/>
                                                            <span *ngIf="!OrderRef.valid && OrderRef.touched" class="invalid-form">
                                                                <small class="form-text text-danger" *ngIf="OrderRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                    ></small
                                                                >
                                                                </span>
        
                                                        </div>
        
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button (click)="modal.dismiss('Cross click')" type="reset" rippleEffect class="btn btn-secondary mr-1"
                                                            [translate]="'GENERAL.EDIT.CANCEL'"></button>
                                                        <button [disabled]="!NewSubscriptionPlanForm.form.valid" (click)="createSubscriptionPlan()" type="submit"
                                                            (click)="modal.close('Accept click')" class="btn btn-primary"
                                                            [translate]="'GENERAL.EDIT.CONFIRM'"></button>
                                                    </div>
                                                </form>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <ngx-datatable id="table-subscription-plan" #tableRowSubscriptionPlan
                                        class="bootstrap core-bootstrap" [rows]="listSubscriptionPlan" [externalPaging]="true"
                                        [count]="pageSubscriptionPlan.totalElements" [offset]="pageSubscriptionPlan.pageNumber"
                                        [limit]="pageSubscriptionPlan.size" (page)="setPageSubscriptionPlan($event)"
                                        [rowHeight]="50" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force"
                                        [headerHeight]="50" [footerHeight]="50" [scrollbarH]="false">
                                        <ngx-datatable-column [name]="'GENERAL.TITLE'|translate">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <div class="w-100">
                                                    <span>{{row.title}}</span>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [name]="'GENERAL.DESCRIPTION'|translate">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <div class="w-100">
                                                    <span>{{row.description}}</span>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [name]="'GENERAL.DISPLAY_ALLOW'|translate">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <div class="w-100">
                                                    <span class="badge bg-light-primary" *ngIf="row.is_display;else noDisplay"
                                                        [translate]="'GENERAL.YES'"></span>
                                                    <ng-template #noDisplay>
                                                        <span class="badge bg-light-danger" [translate]="'GENERAL.NO'"></span>
        
                                                    </ng-template>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column [name]="'GENERAL.DISPLAY_ORDER'|translate">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <div class="w-100">
                                                    <span class="badge bg-light-primary">{{row.order}}</span>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column
                                            [name]="'GENERAL.PACKAGE_FREE'|translate"
                                        >
                                        <ng-template ngx-datatable-cell-template let-row="row">
                                            <div class="w-100">
                                                <span class="badge bg-light-success" *ngIf="row.is_free_trial"
                                                    ><i data-feather="check"></i></span>
                                                
                                            </div>
                                        </ng-template>
                                        </ngx-datatable-column>
        
                                        <ngx-datatable-column [name]="'GENERAL.ACTION' | translate" [sortable]="false"
                                            [sortable]="false" [draggable]="false">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <div class="d-flex align-items-center">
                                                    <a type="button" href="javascript:void(0)" (click)="modalOpenCreateSubscriptionPlan(modalSubscriptionPlan,{
                                            title: row.title,
                                            description: row.description,
                                            html_content: row.html_content,
                                            is_display: row.is_display,
                                            is_free_trial: row.is_free_trial,
                                            package: row.package.id,
                                            id: row.id,
                                            order: row.order
                                        })" title="Edit" class="btn btn-icon btn-flat-warning rounded-circle" rippleEffect>
                                                        <span class="d-flex align-items-center justify-content-center"><i
                                                                class="fa fa-pencil-square-o"></i>
                                                        </span>
                                                    </a>
                                                    <a type="button" href="javascript:void(0)"
                                                        (click)="deleteSubscriptionPlan(row.id)" title="Delete"
                                                        class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                                                        <span class="d-flex align-items-center justify-content-center"><i
                                                                class="fa fa-trash-o"></i>
                                                        </span>
                                                    </a>
                                                    <a type="button" href="javascript:void(0)"
                                                        (click)="detailSubscriptionPlan(row.id)" title="Detail"
                                                        class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                                                        <span class="d-flex align-items-center justify-content-center"><i
                                                                class="fa fa-long-arrow-right"></i>
                                                        </span>
                                                    </a>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </div>
                                <div class="col-lg-3 col-12" class="main">
                                    <app-detail-package (cardVisibleEvent)="closeDetailSubscriptionPlan($event)" [detailPackage]="detailPackage"></app-detail-package>
                                </div>
                            </div>
                            <ng-template #noDetail>
                                <div class="row">
                                    <div class="col-lg-12 col-12">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <h2 [translate]="'GENERAL.LIST_PACKAGE'"></h2>
                                            </div>
                                            <div class="col-md-7 col-12 row align-items-center justify-content-end mb-1">
                                                <div class="">
            
                                                    <button class="btn btn-primary btn-icon mr-2" rippleEffect
                                                        (click)="modalOpenCreateSubscriptionPlan(modalSubscriptionPlan)">
                                                        <span [translate]="'GENERAL.ADD_PACKAGE'" class="d-none d-lg-inline"></span>
            
                                                    </button>
                                                </div>
                                                <ng-template #modalSubscriptionPlan let-modal>
            
                                                    <form (ngSubmit)="(NewSubscriptionPlanForm.form.valid)" ngNativeValidate #NewSubscriptionPlanForm="ngForm"
                                                        >
                                                        <div class="modal-header">
                                                            <h4 *ngIf="!editSubscriptionPlanBoolean;else noSubscriptionPlan" class="modal-title" [translate]="'GENERAL.ADD_PACKAGE'">
                                                            </h4>
                                                            <ng-template #noSubscriptionPlan>
                                                                <h4 class="modal-title" [translate]="'GENERAL.EDIT.INFO_EDIT'">
                                                                </h4>
                                                            </ng-template>
            
                                                            <!-- <button type="button" class="close"
                                                                (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button> -->
                                                        </div>
                                                        <div class="modal-body" ngbAutofocus>
                                                            <div class="form-group">
                                                                <label for="title"><span
                                                                        [translate]="'GENERAL.EDIT.PACKAGE_NAME'"></span><b
                                                                        style="color:red">*</b></label>
                                                                <input [class.error]="!TitleRef.valid && TitleRef.touched"
                                                                #TitleRef="ngModel" type="text" id="title" [(ngModel)]="title" class="form-control"
                                                                    name="title" required/>
                                                                <span *ngIf="!TitleRef.valid && TitleRef.touched" class="invalid-form">
                                                                    <small class="form-text text-danger" *ngIf="TitleRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                        ></small
                                                                    >
                                                                    </span>
            
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="package"><span
                                                                        [translate]="'GENERAL.EDIT.CHOOSE_PACKAGE_CONFIG'"></span><b
                                                                        style="color:red">*</b></label>
                                                                <ng-select [items]="listPackageItems"
                                                                    bindLabel="name"
                                                                    bindValue="id"
                                                                    [placeholder]="'GENERAL.EDIT.CHOOSE_PACKAGE_CONFIG'|translate"
                                                                    
                                                                    name="package"
                                                                    [(ngModel)]="package"
                                                                    [class.error]="!PackageRef.valid && PackageRef.touched"
                                                                #PackageRef="ngModel" required>
                                                                </ng-select>
                                                                <span *ngIf="!PackageRef.valid && PackageRef.touched" class="invalid-form">
                                                                    <small class="form-text text-danger" *ngIf="PackageRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                        ></small
                                                                    >
                                                                    </span>
            
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="description"><span
                                                                        [translate]="'GENERAL.EDIT.DESCRIPTION'"></span></label>
                                                                <textarea type="text" id="description" [(ngModel)]="description"
                                                                    class="form-control" name="description"> </textarea>
            
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="title"><span
                                                                        [translate]="'GENERAL.EDIT.CONTENT_HTML'"></span><b
                                                                        style="color:red">*</b></label>
                                                                <ng-select [items]="HTMLItems" [addTag]="true" multiple="true"
                                                                    [placeholder]="'GENERAL.EDIT.ADD_TAG'|translate"
                                                                    [(ngModel)]="html_content" name="html_content"
                                                                    [class.error]="!HtmlContentRef.valid && HtmlContentRef.touched"
                                                                #HtmlContentRef="ngModel" required>
                                                                </ng-select>
                                                                <span *ngIf="!HtmlContentRef.valid && HtmlContentRef.touched" class="invalid-form">
                                                                    <small class="form-text text-danger" *ngIf="HtmlContentRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                        ></small
                                                                    >
                                                                    </span>
                                                            </div>
                                                            <div class="mb-2">
                                                                <div class="custom-control custom-switch">
                                                                <input type="checkbox" class="custom-control-input" [(ngModel)]="is_display"
                                                                    id="is_display" name="is_display" />
                                                                <label class="custom-control-label" for="is_display"
                                                                    [translate]="'GENERAL.EDIT.PACKAGE_DISPLAY'">
                                    
                                                                </label>
                                                                </div>
                                                            </div>
                                                            <div class="mb-2">
                                                                <div class="custom-control custom-switch">
                                                                <input type="checkbox" class="custom-control-input" [(ngModel)]="is_free_trial"
                                                                    id="is_free_trial" name="is_free_trial" />
                                                                <label class="custom-control-label" for="is_free_trial"
                                                                    [translate]="'GENERAL.EDIT.IS_FREE_TRIAL'">
                                    
                                                                </label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="order"><span
                                                                        [translate]="'GENERAL.EDIT.ORDER'"></span><b
                                                                        style="color:red">*</b></label>
                                                                <input [class.error]="!OrderRef.valid && OrderRef.touched"
                                                                #OrderRef="ngModel" type="number" id="order" [(ngModel)]="order" class="form-control"
                                                                    name="order" required/>
                                                                <span *ngIf="!OrderRef.valid && OrderRef.touched" class="invalid-form">
                                                                    <small class="form-text text-danger" *ngIf="OrderRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                                        ></small
                                                                    >
                                                                    </span>
            
                                                            </div>
            
            
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button (click)="modal.dismiss('Cross click')" type="reset" rippleEffect class="btn btn-secondary mr-1"
                                                                [translate]="'GENERAL.EDIT.CANCEL'"></button>
                                                            <button [disabled]="!NewSubscriptionPlanForm.form.valid" (click)="createSubscriptionPlan()" type="submit"
                                                                (click)="modal.close('Accept click')" class="btn btn-primary"
                                                                [translate]="'GENERAL.EDIT.CONFIRM'"></button>
                                                        </div>
                                                    </form>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <ngx-datatable id="table-subscription-plan" #tableRowSubscriptionPlan
                                            class="bootstrap core-bootstrap" [rows]="listSubscriptionPlan" [externalPaging]="true"
                                            [count]="pageSubscriptionPlan.totalElements" [offset]="pageSubscriptionPlan.pageNumber"
                                            [limit]="pageSubscriptionPlan.size" (page)="setPageSubscriptionPlan($event)"
                                            [rowHeight]="50" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force"
                                            [headerHeight]="50" [footerHeight]="50" [scrollbarH]="false">
                                            <ngx-datatable-column [name]="'GENERAL.TITLE'|translate">
                                                <ng-template ngx-datatable-cell-template let-row="row">
                                                    <div class="w-100">
                                                        <span>{{row.title}}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column [name]="'GENERAL.DESCRIPTION'|translate">
                                                <ng-template ngx-datatable-cell-template let-row="row">
                                                    <div class="w-100">
                                                        <span>{{row.description}}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column [name]="'GENERAL.DISPLAY_ALLOW'|translate">
                                                <ng-template ngx-datatable-cell-template let-row="row">
                                                    <div class="w-100">
                                                        <span class="badge bg-light-primary" *ngIf="row.is_display;else noDisplay"
                                                            [translate]="'GENERAL.YES'"></span>
                                                        <ng-template #noDisplay>
                                                            <span class="badge bg-light-danger" [translate]="'GENERAL.NO'"></span>
            
                                                        </ng-template>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column [name]="'GENERAL.DISPLAY_ORDER'|translate">
                                                <ng-template ngx-datatable-cell-template let-row="row">
                                                    <div class="w-100">
                                                        <span class="badge bg-light-primary">{{row.order}}</span>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column
                                                [name]="'GENERAL.PACKAGE_FREE'|translate"
                                            >
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                                <div class="w-100">
                                                    <span class="badge bg-light-success" *ngIf="row.is_free_trial"
                                                        ><i data-feather="check"></i></span>
                                                    
                                                </div>
                                            </ng-template>
                                            </ngx-datatable-column>
            
                                            <ngx-datatable-column [name]="'GENERAL.ACTION' | translate" [sortable]="false"
                                                [sortable]="false" [draggable]="false">
                                                <ng-template ngx-datatable-cell-template let-row="row">
                                                    <div class="d-flex align-items-center">
                                                        <a type="button" href="javascript:void(0)" (click)="modalOpenCreateSubscriptionPlan(modalSubscriptionPlan,{
                                                title: row.title,
                                                description: row.description,
                                                html_content: row.html_content,
                                                is_display: row.is_display,
                                                is_free_trial: row.is_free_trial,
                                                package: row.package.id,
                                                id: row.id,
                                                order: row.order
                                            })" title="Edit" class="btn btn-icon btn-flat-warning rounded-circle" rippleEffect>
                                                            <span class="d-flex align-items-center justify-content-center"><i
                                                                    class="fa fa-pencil-square-o"></i>
                                                            </span>
                                                        </a>
                                                        <a type="button" href="javascript:void(0)"
                                                            (click)="deleteSubscriptionPlan(row.id)" title="Delete"
                                                            class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                                                            <span class="d-flex align-items-center justify-content-center"><i
                                                                    class="fa fa-trash-o"></i>
                                                            </span>
                                                        </a>
                                                        <a type="button" href="javascript:void(0)"
                                                            (click)="detailSubscriptionPlan(row.id)" title="Detail"
                                                            class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                                                            <span class="d-flex align-items-center justify-content-center"><i
                                                                    class="fa fa-long-arrow-right"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </ng-template>
                                            </ngx-datatable-column>
                                        </ngx-datatable>
                                    </div>
                                    
                                </div>
                            </ng-template>
                        </div>
                        <!-- general Tab ends -->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a (click)="packageConfig()" ngbNavLink class="d-flex align-items-center"><span
                            class="d-none d-sm-block" [translate]="'PACKAGES_CONFIG.PACKAGES_CONFIG'"></span></a>
                    <ng-template ngbNavContent>
                        <!-- package config Tab starts -->
                        <div class="tab-pane" id="package-config" aria-labelledby="package-config-tab" role="tabpanel">
                            <div class="row">
                                <div class="col-md-5">
                                    <h2 [translate]="'PACKAGES_CONFIG.PACKAGE_CONFIG'"></h2>
                                </div>
                                <div class="col-md-7 col-12 row align-items-center justify-content-end mb-1">
                                    <div class="">

                                        <button class="btn btn-primary btn-icon mr-2" rippleEffect
                                            (click)="modalOpenCreatePackage(modalPackage)">
                                            <span [translate]="'PACKAGES_CONFIG.ADD_CONFIG'" class="d-none d-lg-inline"></span>

                                        </button>
                                    </div>
                                    <ng-template #modalPackage let-modal>

                                        <form [formGroup]="CreatePackageForm" (ngSubmit)="createPackage()"
                                            >
                                            <div class="modal-header">
                                                <h4 *ngIf="!editPackageBoolean;else noPackage" class="modal-title" [translate]="'PACKAGES_CONFIG.ADD_CONFIG'">
                                                </h4>
                                                <ng-template #noPackage>
                                                    <h4 class="modal-title" [translate]="'PACKAGES_CONFIG.EDIT.EDIT_CONFIG'">
                                                    </h4>
                                                </ng-template>
                                                <!-- <button type="button" class="close"
                                                    (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button> -->
                                            </div>
                                            <div class="modal-body" ngbAutofocus>
                                                <div class="form-group">
                                                    <label for="name"><span
                                                            [translate]="'PACKAGES_CONFIG.EDIT.NAME_CONFIG'"></span><b
                                                            style="color:red">*</b></label>
                                                    <input
                                                    type="text" id="name" formControlName="name" class="form-control"
                                                        name="name" required [ngClass]="{
                                                            'is-invalid': f_Package.name.errors && !f_Package.name.valid && f_Package.name.touched
                                                          }"/>
                                                    <div
                                                    *ngIf="f_Package.name.errors && !f_Package.name.valid && f_Package.name.touched"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f_Package.name.errors.required" [translate]="'GENERAL.EDIT.REQUIRED'">
                                                    
                                                    </div>
                                
                                                </div>

                                                </div>
                                                <div class="form-group">
                                                    <label for="parse_thread_limit"><span
                                                            [translate]="'PACKAGES_CONFIG.EDIT.PARSE_THREAD_LIMIT'"></span><b
                                                            style="color:red">*</b></label>
                                                    <input
                                                    type="number" pattern="^\d+(\.\d+)?$" id="parse_thread_limit" formControlName="parse_thread_limit" class="form-control"
                                                        name="parse_thread_limit" required [ngClass]="{
                                                            'is-invalid': f_Package.parse_thread_limit.errors && !f_Package.parse_thread_limit.valid && f_Package.parse_thread_limit.touched
                                                          }"/>
                                                    <div
                                                    *ngIf="f_Package.parse_thread_limit.errors && !f_Package.parse_thread_limit.valid && f_Package.parse_thread_limit.touched"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f_Package.parse_thread_limit.errors.required" [translate]="'GENERAL.EDIT.REQUIRED'">
                                                    
                                                    </div>
                                
                                                </div>

                                                </div>
                                                <div class="form-group">
                                                    <label for="matching_thread_limit"><span
                                                            [translate]="'PACKAGES_CONFIG.EDIT.MATCHING_THREAD_LIMIT'"></span><b
                                                            style="color:red">*</b></label>
                                                    <input
                                                    type="number" pattern="^\d+(\.\d+)?$" id="matching_thread_limit" formControlName="matching_thread_limit" class="form-control"
                                                        name="matching_thread_limit" required [ngClass]="{
                                                            'is-invalid': f_Package.matching_thread_limit.errors && !f_Package.matching_thread_limit.valid && f_Package.matching_thread_limit.touched
                                                          }"/>
                                                    <div
                                                    *ngIf="f_Package.matching_thread_limit.errors && !f_Package.matching_thread_limit.valid && f_Package.matching_thread_limit.touched"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f_Package.matching_thread_limit.errors.required" [translate]="'GENERAL.EDIT.REQUIRED'">
                                                    
                                                    </div>
                                
                                                </div>

                                                </div>
                                                <div class="form-group">
                                                    <label for="parse_limited"><span
                                                            [translate]="'PACKAGES_CONFIG.EDIT.PARSE_LIMITED'"></span><b
                                                            style="color:red">*</b></label>
                                                    <input
                                                    type="number" pattern="^\d+(\.\d+)?$" id="parse_limited" formControlName="parse_limited" class="form-control"
                                                        name="parse_limited" required [ngClass]="{
                                                            'is-invalid': f_Package.parse_limited.errors && !f_Package.parse_limited.valid && f_Package.parse_limited.touched
                                                          }"/>
                                                    <div
                                                    *ngIf="f_Package.parse_limited.errors && !f_Package.parse_limited.valid && f_Package.parse_limited.touched"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f_Package.parse_limited.errors.required" [translate]="'GENERAL.EDIT.REQUIRED'">
                                                    
                                                    </div>
                                
                                                </div>

                                                </div>
                                                <div class="form-group">
                                                    <label for="matching_limited"><span
                                                            [translate]="'PACKAGES_CONFIG.EDIT.MATCHING_LIMITED'"></span><b
                                                            style="color:red">*</b></label>
                                                    <input
                                                    type="number" pattern="^\d+(\.\d+)?$" id="matching_limited" formControlName="matching_limited" class="form-control"
                                                        name="matching_limited" required [ngClass]="{
                                                            'is-invalid': f_Package.matching_limited.errors && !f_Package.matching_limited.valid && f_Package.matching_limited.touched
                                                          }"/>
                                                    <div
                                                    *ngIf="f_Package.matching_limited.errors && !f_Package.matching_limited.valid && f_Package.matching_limited.touched"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f_Package.matching_limited.errors.required" [translate]="'GENERAL.EDIT.REQUIRED'">
                                                    
                                                    </div>
                                
                                                </div>

                                                </div>
                                                <div class="form-group">
                                                    <label for="max_user"><span
                                                            [translate]="'PACKAGES_CONFIG.EDIT.MAX_USER'"></span><b
                                                            style="color:red">*</b></label>
                                                    <input
                                                    type="number" pattern="^\d+(\.\d+)?$" id="max_user" formControlName="max_user" class="form-control"
                                                        name="max_user" required [ngClass]="{
                                                            'is-invalid': f_Package.max_user.errors && !f_Package.max_user.valid && f_Package.max_user.touched
                                                          }"/>
                                                    <div
                                                    *ngIf="f_Package.max_user.errors && !f_Package.max_user.valid && f_Package.max_user.touched"
                                                    class="invalid-feedback"
                                                >
                                                    <div *ngIf="f_Package.max_user.errors.required" [translate]="'GENERAL.EDIT.REQUIRED'">
                                                    
                                                    </div>
                                
                                                </div>

                                                </div>


                                            </div>
                                            <div class="modal-footer">
                                                <button (click)="modal.dismiss('Cross click')" type="reset" rippleEffect class="btn btn-secondary mr-1"
                                                    [translate]="'GENERAL.EDIT.CANCEL'"></button>
                                                <button [disabled]="!CreatePackageForm.valid" type="submit"
                                                    (click)="modal.close('Accept click')" class="btn btn-primary"
                                                    [translate]="'GENERAL.EDIT.CONFIRM'"></button>
                                            </div>
                                        </form>
                                    </ng-template>
                                </div>
                            </div>
                            <ngx-datatable id="table-package" #tableRowPackage
                                class="bootstrap core-bootstrap" [rows]="listPackage" [externalPaging]="true"
                                [count]="pagePackage.totalElements" [offset]="pagePackage.pageNumber"
                                [limit]="pagePackage.size" (page)="setPagePackage($event)"
                                [rowHeight]="50" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force"
                                [headerHeight]="50" [footerHeight]="50" [scrollbarH]="false">
                                <ngx-datatable-column [name]="'PACKAGES_CONFIG.NAME'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.name}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PACKAGES_CONFIG.EDIT.PARSE_THREAD_LIMIT'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.parse_thread_limit}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PACKAGES_CONFIG.EDIT.MATCHING_THREAD_LIMIT'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.matching_thread_limit}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PACKAGES_CONFIG.EDIT.PARSE_LIMITED'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.parse_limited}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PACKAGES_CONFIG.EDIT.MATCHING_LIMITED'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.matching_limited}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PACKAGES_CONFIG.EDIT.MAX_USER'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.max_user}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column [name]="'GENERAL.ACTION' | translate" [sortable]="false"
                                    [sortable]="false" [draggable]="false">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="d-flex align-items-center">
                                            <a type="button" href="javascript:void(0)" (click)="modalOpenCreatePackage(modalPackage,{
                                    name: row.name,
                                    parse_thread_limit: row.parse_thread_limit,
                                    matching_thread_limit: row.matching_thread_limit,
                                    parse_limited: row.parse_limited,
                                    matching_limited: row.matching_limited,
                                    max_user: row.max_user,
                                    id: row.id
                                })" title="Edit" class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                                                <span class="d-flex align-items-center justify-content-center"><i
                                                        class="fa fa-pencil-square-o"></i>
                                                </span>
                                            </a>
                                            <a type="button" href="javascript:void(0)"
                                                (click)="deletePackage(row.id)" title="Delete"
                                                class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                                                <span class="d-flex align-items-center justify-content-center"><i
                                                        class="fa fa-trash-o"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                        <!-- package config Tab ends -->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a (click)="pricingConfig()" ngbNavLink class="d-flex align-items-center"><span
                            class="d-none d-sm-block" [translate]="'PRICING_CONFIG.PRICING_CONFIG'"></span></a>
                    <ng-template ngbNavContent>
                        <!-- Pricing config Tab starts -->
                        <div class="tab-pane" id="pricing-config" aria-labelledby="pricing-config-tab" role="tabpanel">
                            <div class="row">
                                <div class="col-md-5">
                                    <h2 [translate]="'PRICING_CONFIG.PRICING_CONFIG'"></h2>
                                </div>
                                <div class="col-md-7 col-12 row align-items-center justify-content-end mb-1">
                                    <div class="">

                                        <button class="btn btn-primary btn-icon mr-2" rippleEffect
                                            (click)="modalOpenCreateSubscriptionPlanCost(modalSubscriptionPlanCost)">
                                            <span [translate]="'PRICING_CONFIG.EDIT.ADD_PRICING'" class="d-none d-lg-inline"></span>

                                        </button>
                                    </div>
                                    <ng-template #modalSubscriptionPlanCost let-modal>
                                        <form (ngSubmit)="(NewSubscriptionPlanCostForm.form.valid)" ngNativeValidate #NewSubscriptionPlanCostForm="ngForm"
                                        >
                                        <div class="modal-header">
                                            <h4 *ngIf="!editSubscriptionPlanCostBoolean;else noEditCost" class="modal-title" [translate]="'PRICING_CONFIG.EDIT.ADD_PRICING'">
                                            </h4>
                                            <ng-template #noEditCost>
                                                <h4 class="modal-title" [translate]="'PRICING_CONFIG.EDIT.EDIT_PRICING'">
                                                </h4>
                                            </ng-template>
                                            <!-- <button type="button" class="close"
                                                (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button> -->
                                        </div>
                                        <div class="modal-body" ngbAutofocus>
                                            <div class="form-group">
                                                <label for="name"><span
                                                        [translate]="'PRICING_CONFIG.CONFIG_NAME_PRICING'"></span><b
                                                        style="color:red">*</b></label>
                                                <input [class.error]="!NameRef.valid && NameRef.touched"
                                                #NameRef="ngModel" type="text" id="name" [(ngModel)]="name" class="form-control"
                                                    name="name" required/>
                                                <span *ngIf="!NameRef.valid && NameRef.touched" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="NameRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                        ></small
                                                    >
                                                    </span>

                                            </div>
                                            <div class="form-group">
                                                <label for="descriptionCost"><span
                                                        [translate]="'PRICING_CONFIG.DESCRIPTION'"></span></label>
                                                <textarea type="text" id="descriptionCost" [(ngModel)]="descriptionCost"
                                                    class="form-control" name="descriptionCost"> </textarea>

                                            </div>
                                            <div class="mb-2">
                                                <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" [(ngModel)]="is_default"
                                                    id="is_default" name="is_default" />
                                                <label class="custom-control-label" for="is_default"
                                                    [translate]="'PRICING_CONFIG.DISPLAY_DEFAULT_PRICING'">
                    
                                                </label>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="cost"><span
                                                        [translate]="'PRICING_CONFIG.PRICING_VN'"></span><b
                                                        style="color:red">*</b></label>
                                                <input [class.error]="!CostRef.valid && CostRef.touched"
                                                #CostRef="ngModel" type="number" id="cost" [(ngModel)]="cost" class="form-control"
                                                    name="cost" required/>
                                                <span *ngIf="!CostRef.valid && CostRef.touched" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="CostRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                        ></small
                                                    >
                                                    </span>

                                            </div>
                                            <div class="form-group">
                                                <label for="subscription_plan"><span
                                                        [translate]="'PRICING_CONFIG.SALE_PRICING'"></span><b
                                                        style="color:red">*</b></label>
                                                <ng-select [items]="listSubscriptionPlanItems"
                                                    bindLabel="title"
                                                    bindValue="id"
                                                    [placeholder]="'PRICING_CONFIG.CHOOSE_SALE_PRICING'|translate"
                                                    
                                                    name="subscription_plan"
                                                    [(ngModel)]="subscription_plan"
                                                    [class.error]="!SubscriptionPlanRef.valid && SubscriptionPlanRef.touched"
                                                #SubscriptionPlanRef="ngModel" required>
                                                </ng-select>
                                                <span *ngIf="!SubscriptionPlanRef.valid && SubscriptionPlanRef.touched" class="invalid-form">
                                                    <small class="form-text text-danger" *ngIf="SubscriptionPlanRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                        ></small
                                                    >
                                                    </span>

                                            </div>
                                            <div class="row">
                                                <div class="form-group col-6">
                                                    <label for="recurrence_period"><span
                                                            [translate]="'PRICING_CONFIG.PAYMENT_CYCLE'"></span><b
                                                            style="color:red">*</b></label>
                                                    <input [class.error]="!RecurrencePeriodRef.valid && RecurrencePeriodRef.touched"
                                                    #RecurrencePeriodRef="ngModel" type="number" id="recurrence_period" [(ngModel)]="recurrence_period" class="form-control"
                                                        name="recurrence_period" required/>
                                                    <span *ngIf="!RecurrencePeriodRef.valid && RecurrencePeriodRef.touched" class="invalid-form">
                                                        <small class="form-text text-danger" *ngIf="RecurrencePeriodRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                            ></small
                                                        >
                                                        </span>
    
                                                </div>
                                                <div class="form-group col-6">
                                                    <label for="recurrence_unit"><span
                                                            [translate]="'PRICING_CONFIG.UNIT'"></span><b
                                                            style="color:red">*</b></label>
                                                    <ng-select
                                                        bindLabel="label"
                                                        bindValue="value"
                                                        [placeholder]="'PRICING_CONFIG.UNIT'|translate"
                                                        id="recurrence_unit"
                                                        name="recurrence_unit"
                                                        [(ngModel)]="recurrence_unit"
                                                        [class.error]="!RecurrenceUnitRef.valid && RecurrenceUnitRef.touched"
                                                    #RecurrenceUnitRef="ngModel" required>
                                                    <ng-option *ngFor="let _recurrence_unit_items of recurrence_unit_items" [value]="_recurrence_unit_items.value">
                                                        <span *ngIf="_recurrence_unit_items.value=='month'" [translate]="'GENERAL.DETAIL.MONTH'"></span>
                                                        <span *ngIf="_recurrence_unit_items.value=='year'" [translate]="'GENERAL.DETAIL.YEAR'"></span>
                                                        <span *ngIf="_recurrence_unit_items.value=='day'" [translate]="'GENERAL.DETAIL.DAY'"></span>
                                                        
                                        
                                                      </ng-option>
                                                    </ng-select>
                                                    
                                                    <span *ngIf="!RecurrenceUnitRef.valid && RecurrenceUnitRef.touched" class="invalid-form">
                                                        <small class="form-text text-danger" *ngIf="RecurrenceUnitRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                            ></small
                                                        >
                                                        </span>
    
                                                </div>
                                            </div>
                                            <div>
                                            <span [translate]="'PRICING_CONFIG.PACKAGE_PRICING'"></span><span>: {{convertCost(cost)}} / {{recurrence_period}} 
                                                <small *ngIf="recurrence_unit=='month'" [translate]="'GENERAL.DETAIL.MONTH'"></small>
                                                <small *ngIf="recurrence_unit=='year'" [translate]="'GENERAL.DETAIL.YEAR'"></small>
                                                <small *ngIf="recurrence_unit=='day'" [translate]="'GENERAL.DETAIL.DAY'"></small>

                                            </span>

                                            </div>
                                            


                                        </div>
                                        <div class="modal-footer">
                                            <button (click)="modal.dismiss('Cross click')" type="reset" rippleEffect class="btn btn-secondary mr-1"
                                                [translate]="'GENERAL.EDIT.CANCEL'"></button>
                                            <button [disabled]="!NewSubscriptionPlanCostForm.form.valid" (click)="createSubscriptionPlanCost()" type="submit"
                                                (click)="modal.close('Accept click')" class="btn btn-primary"
                                                [translate]="'GENERAL.EDIT.CONFIRM'"></button>
                                        </div>
                                    </form>
                                        
                                    </ng-template>
                                </div>
                            </div>
                            <ngx-datatable id="table-subscription-plan-cost" #tableRowSubscriptionPlanCost
                                class="bootstrap core-bootstrap" [rows]="listSubscriptionPlanCost" [externalPaging]="true"
                                [count]="pageSubscriptionPlanCost.totalElements" [offset]="pageSubscriptionPlanCost.pageNumber"
                                [limit]="pageSubscriptionPlanCost.size" (page)="setPageSubscriptionPlanCost($event)"
                                [rowHeight]="50" class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force"
                                [headerHeight]="50" [footerHeight]="50" [scrollbarH]="false">
                                <ngx-datatable-column [name]="'PRICING_CONFIG.TITLE'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.name}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PRICING_CONFIG.PACKAGE'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.subscription_plan.title}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PRICING_CONFIG.DESCRIPTION'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.description}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PRICING_CONFIG.DEFAULT_PRICING'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span class="badge bg-light-success" *ngIf="row.is_default"
                                                ><i data-feather="check"></i></span>
                                            
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PRICING_CONFIG.SELLER'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span>{{row.sale}}</span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column [name]="'PRICING_CONFIG.PRICING'|translate">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="w-100">
                                            <span class="badge bg-light-primary">{{convertCost(row.cost)}} / {{row.recurrence_period}} 
                                                <small *ngIf="row.recurrence_unit=='month'" [translate]="'GENERAL.DETAIL.MONTH'"></small>
                                                <small *ngIf="row.recurrence_unit=='year'" [translate]="'GENERAL.DETAIL.YEAR'"></small>
                                                <small *ngIf="row.recurrence_unit=='day'" [translate]="'GENERAL.DETAIL.DAY'"></small>

                                            </span>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <!-- <ngx-datatable-column
                    [name]="'GENERAL.PACKAGE_FREE'|translate"
                  >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="w-100">
                        <span>{{row.description}}</span>
                    </div>
                  </ng-template>
                  </ngx-datatable-column> -->

                                <ngx-datatable-column [name]="'GENERAL.ACTION' | translate" [sortable]="false"
                                    [sortable]="false" [draggable]="false">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <div class="d-flex align-items-center">
                                            <a type="button" href="javascript:void(0)" (click)="modalOpenCreateSubscriptionPlanCost(modalSubscriptionPlanCost,{
                                    name: row.name,
                                    descriptionCost: row.description,
                                    cost: row.cost,
                                    recurrence_period: row.recurrence_period,
                                    recurrence_unit: row.recurrence_unit,
                                    is_default: row.is_default,
                                    subscription_plan: row.subscription_plan.id,
                                    idCost: row.id


                                })" title="Edit" class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                                                <span class="d-flex align-items-center justify-content-center"><i
                                                        class="fa fa-pencil-square-o"></i>
                                                </span>
                                            </a>
                                            <a type="button" href="javascript:void(0)"
                                                (click)="deleteSubscriptionPlanCost(row.id)" title="Delete"
                                                class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                                                <span class="d-flex align-items-center justify-content-center"><i
                                                        class="fa fa-trash-o"></i>
                                                </span>
                                            </a>
                                            
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                
                            </ngx-datatable>
                        </div>
                        <!-- Pricing config Tab ends -->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink class="d-flex align-items-center"><span
                            class="d-none d-sm-block" [translate]="'ADD_PLAN_COST.TITLE'"></span></a>
                    <ng-template ngbNavContent>
                        <!-- Pricing config Tab starts -->
                        <div class="tab-pane" id="pricing-config" aria-labelledby="pricing-config-tab" role="tabpanel">
                            
                            <ul ngbNav #nav="ngbNav" class="nav nav-pills">
                                <li ngbNavItem>
                                  <a ngbNavLink><span [translate]="'ADD_PLAN_COST.COMPANY_ACTIVITY'"></span></a>
                                  <ng-template ngbNavContent>
                                    <div class="tab-pane active" id="information" aria-labelledby="information-tab" role="tabpanel">
                                        <div class="table-responsive">
                                            <table class="table align-middle table-bordered">
                                              <thead>
                                                <tr>
                
                                                  <th [translate]="'ADD_PLAN_COST.TABLE.COMPANY'"></th>
                                                  <th [translate]="'ADD_PLAN_COST.TABLE.PACKAGE_NAME'"></th>  
                                                  <th [translate]="'ADD_PLAN_COST.UPDATE'"></th>
                
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr *ngFor="let _listCompany of listCompany
                                                        | slice: (pageCompany.pageNumber - 1) * pageCompany.size:(pageCompany.pageNumber - 1) * pageCompany.size + pageCompany.size">
                                                  <td>
                                                    <div class="">{{_listCompany.name}}</div>
                                                  </td>
                                                  <td>
                                                    <div class="">{{_listCompany.current_package}}</div>
                                                  </td>
                                                  <td>
                                                    <div class="d-flex align-items-center">
                                            
                                                        <a type="button" href="javascript:void(0)" (click)="modalOpenAddPlanCostForUser(modalAddPlanCost,{
                                                            company_id: _listCompany.id
                                                        },true)" title="Edit" class="btn btn-icon btn-flat-warning rounded-circle" rippleEffect>
                                                                        <span class="d-flex align-items-center justify-content-center"><i
                                                                                class="fa fa-pencil-square-o"></i>
                                                                        </span>
                                                                    </a>
                                                    </div>
                                                  </td>
                                                </tr>
                
                                              </tbody>
                                            </table>
                
                
                                          </div>
                                          <div class="row mt-2 justify-content-between">
                                            <div class="col-auto">
                                              <div class="mr-1">{{pageCompany.totalElements}} <span
                                                [translate]="'ADD_PLAN_COST.USER'"></span> </div>
                                            </div>
                                            <div class="col-auto">
                    
                                              <nav class="mt-sm-0" aria-label="Jobs list pagination">
                
                                                <ngb-pagination [collectionSize]="pageCompany.totalElements" [(page)]="pageCompany.pageNumber" [pageSize]="pageCompany.size"
                                                  [maxSize]="2" [rotate]="true" [boundaryLinks]="true">
                
                
                                                </ngb-pagination>
                                              </nav>
                                            </div>
                                            
                                          </div>
                                      
                                    </div>
                
                                  </ng-template>
                                </li>
                                <li ngbNavItem>
                                  <a ngbNavLink><span [translate]="'ADD_PLAN_COST.TRIAL_ACTIVITY'"></span></a>
                                  <ng-template ngbNavContent>
                                    <div class="tab-pane" id="social" aria-labelledby="social-tab" role="tabpanel">
                                        <div class="table-responsive">
                                            <table class="table align-middle table-bordered">
                                              <thead>
                                                <tr>
                
                                                  <th [translate]="'ADD_PLAN_COST.TABLE.USER'"></th>
                                                  <th [translate]="'ADD_PLAN_COST.TABLE.PACKAGE_NAME'"></th>
                                                  <th [translate]="'ADD_PLAN_COST.UPDATE'"></th>
                
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr *ngFor="let _users of users
                                                        | slice: (pageTrial.pageNumber - 1) * pageTrial.size:(pageTrial.pageNumber - 1) * pageCompany.size + pageCompany.size">
                                                  <td>
                                                    <div class="">{{_users.email}}</div>
                                                  </td>
                                                  <td>
                                                    <div class="">{{_users.current_package}}</div>
                                                  </td>
                                                  <td>
                                                    <div class="d-flex align-items-center">
                                            
                                                        <a type="button" href="javascript:void(0)" (click)="modalOpenAddPlanCostForUser(modalAddPlanCost,{
                                                            user_id: _users.id
                                                            
                                                        },false)" title="Edit" class="btn btn-icon btn-flat-warning rounded-circle" rippleEffect>
                                                                        <span class="d-flex align-items-center justify-content-center"><i
                                                                                class="fa fa-pencil-square-o"></i>
                                                                        </span>
                                                                    </a>
                                                    </div>
                                                  </td>
                                                </tr>
                
                                              </tbody>
                                            </table>
                
                
                                          </div>
                                          <div class="row mt-2 justify-content-between">
                                            <div class="col-auto">
                                              <div class="mr-1">{{pageTrial.totalElements}} <span
                                                [translate]="'ADD_PLAN_COST.USER'"></span> </div>
                                            </div>
                                            <div class="col-auto">
                    
                                              <nav class="mt-sm-0" aria-label="Jobs list pagination">
                
                                                <ngb-pagination [collectionSize]="pageTrial.totalElements" [(page)]="pageTrial.pageNumber" [pageSize]="pageTrial.size"
                                                  [maxSize]="2" [rotate]="true" [boundaryLinks]="true">
                
                
                                                </ngb-pagination>
                                              </nav>
                                            </div>
                                            
                                          </div>
                                      
                                    </div>
                
                                  </ng-template>
                                </li>
                                <ng-template #modalAddPlanCost let-modal>
                                    <form (ngSubmit)="(AddPlanCostForm.form.valid)" ngNativeValidate #AddPlanCostForm="ngForm"
                                    >
                                    <div class="modal-header">
                                        <h4 class="modal-title" [translate]="'ADD_PLAN_COST.MODAL_TITLE'">

                                        </h4>
                                        <!-- <button type="button" class="close"
                                            (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button> -->
                                    </div>
                                    <div class="modal-body" ngbAutofocus style="height: 200px;">
                                        <div class="form-group">
                                            <label for="idCost"><span
                                                    [translate]="'PRICING_CONFIG.TITLE'"></span><b
                                                    style="color:red">*</b></label>
                                            <ng-select [items]="listSubscriptionPlanCostItems"
                                                bindLabel="name"
                                                bindValue="id"
                                                [placeholder]="'ADD_PLAN_COST.CHOOSE_COST'|translate"
                                                
                                                name="idCost"
                                                [(ngModel)]="idCost"
                                                [class.error]="!SubscriptionPlanRef.valid && SubscriptionPlanRef.touched"
                                            #SubscriptionPlanRef="ngModel" required>
                                            </ng-select>
                                            <span *ngIf="!SubscriptionPlanRef.valid && SubscriptionPlanRef.touched" class="invalid-form">
                                                <small class="form-text text-danger" *ngIf="SubscriptionPlanRef?.errors?.['required']" [translate]="'GENERAL.EDIT.REQUIRED'"
                                                    ></small
                                                >
                                                </span>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button (click)="modal.dismiss('Cross click')" type="reset" rippleEffect class="btn btn-secondary mr-1"
                                            [translate]="'GENERAL.EDIT.CANCEL'"></button>
                                        <button [disabled]="!AddPlanCostForm.form?.valid" (click)="addPlanCostForUser()" type="submit"
                                            (click)="modal.close('Accept click')" class="btn btn-primary"
                                            [translate]="'GENERAL.EDIT.CONFIRM'"></button>
                                    </div>
                                </form>
                                    
                                </ng-template>
                                
                              </ul>
                              <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                        <!-- Pricing config Tab ends -->
                    </ng-template>
                </li>
                
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>