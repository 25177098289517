import { saveAs } from 'file-saver';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResumeParserService } from '../services/resume-parser.service';
import { ResumeParser } from '../models/resume.model';
import { User } from 'app/auth/models';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Subscription, concat, interval } from 'rxjs';
import { Page } from '../models/paging.model';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { switchMap, filter } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as pdfjsLib from "pdfjs-dist";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { status_color, status, job_status_color, range_conf, Status } from 'app/main/talent-pool/models/resume.model';
import * as mammoth from 'mammoth';
import * as XLSX from 'xlsx';
import { CoreConfigService } from '@core/services/config.service';
import { TalentPoolService } from 'app/main/talent-pool/services/talent-pool.service';
const modalFadeAnimation = trigger("modalFadeAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
]);

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";
const QUALITY_IMAGE_PREVIEW = 0.5;
const QUALITY_PDF_PREVIEW = 0.01;
const QUALITY_WORD_PREVIEW = 0.01;
const QUALITY_EXCEL_PREVIEW = 0.01;


@Component({
  selector: 'app-manage-resume',
  templateUrl: './manage-resume.component.html',
  styleUrls: ['./manage-resume.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideFade", [
      state(
        "visible",
        style({
          bottom: "20px",
          opacity: 1,
          transform: "translateY(0%)",
        })
      ),
      state(
        "hidden",
        style({
          bottom: "-20px",
          opacity: 0,
          transform: "translateY(100%)",
        })
      ),
      transition("hidden => visible", [animate("0.5s ease-out")]),
      transition("visible => hidden", [animate("0.5s ease-in")]),
    ]),
    modalFadeAnimation,
  ],
})
export class ManageResumeComponent implements OnInit {
  public isAuthenticated = false
  public resume_parser: ResumeParser[] = []
  public myFiles: File[] = [];
  public errorMessagesResumeParsers!: any
  public errorMessagesGetFilterListResume!: any
  // public cvs_task:CV_task[]=[]
  public tag_filter_list_resume = false
  SearchForm!: FormGroup
  public file_name!: string
  // public status = status
  public status = [
    { name: "Approved", value: "Approved" },
    { name: "Duplicated", value: "Duplicated" },
    { name: "Done", value: "Done" },
    { name: "Failure", value: "Failure" },
    { name: "Processing", value: "Processing" },
    { name: "Wait to process", value: "Wait_to_process" },
    { name: "Suspended", value: "Suspended" },
    { name: "Modified", value: "Modified" },
  ]
  public sf4c_status = [

    // {name: "SF4C background failed", value: "SF4C_background_failed"},
    { name: "SF wait to upload", value: "SF4C_wait_to_upload" },
    { name: "SF failed", value: "SF4C_failed" },
    { name: "SF uploading", value: "SF4C_uploading" },
    { name: "SF uploaded", value: "SF4C_uploaded" },
    { name: "SF not upload", value: "Not_upload" },

    // {name: "Base upload failed", value: "Base_upload_failed"},
    // {name: "Base uploading", value: "Base_uploading"},
    // {name: "Base upload success", value: "Base_upload_success"},

  ]
  public types = [
    { name: "Csv", value: "csv" },
    { name: "Excel", value: "excel" },
  ]
  public statusDownload = [
    { name: "All", name_vn: "Tất cả", value: "all" },
    { name: "Approved", name_vn: "Đã phê duyệt", value: "Approved" },
    { name: "Done", name_vn: "Đã tải lên", value: "Done" },
    { name: "Modified", name_vn: "Đã sửa", value: "Modified" },
  ]
  job_boards = [
    { name: "Vietnamworks", value: "Vietnamworks" },
    { name: "Careerviet", value: "Careerviet" },
    { name: "TopCV", value: "TopCV" },
    { name: "Careerlink", value: "Careerlink" },
    { name: "Vieclam24h", value: "Vieclam24h" },
    { name: "ITViec", value: "ITViec" },
    { name: "Jobsgo", value: "Jobsgo" },
    { name: "Facebook", value: "Facebook" },
  ]
  public selectedStatus: string[] = []
  public selectedSf4cStatus: string[] = []

  public email!: string
  public phone_number!: string
  public owner__email: string[] = []
  public owner__email_items: any[] = []
  public ownerDownload__email_items: any[] = []
  public source: string[] = []
  public id: string
  public user: User = new User()
  public users: User[] = []
  public role: string
  public errorMessagesListUser: any
  basicSelectedOption = 5
  public page = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 250;
  @ViewChild(DatatableComponent) tableRowDetails: DatatableComponent;
  INTERVAL_TIME = 2000;
  intervalSubscription: Subscription;
  public isLoading = false;
  public url_dynamic = ""

  // page = 1;
  // pageSize = 5;
  // totalItems: number = 0
  // pageSizes = [5, 10, 15];
  //public status_color: status_color
  public status_color = status_color
  public job_status_color = job_status_color
  public loading_page_filter = false
  public tag_export = false
  public errorMessagesResultConnectSf4cs!: any
  public tag_resultConnectSf4c_exit = false
  public hidden_menu = false

  //upload

  public arr_checkboxJr: string[] = []
  public arr_checkbox: string[] = []
  public arr_checkbox_current_title: string[] = []


  formCheckBox = this.fb.group({
    checkResumeArray: this.fb.array([], Validators.required)
  })
  formCheckBoxJr = this.fb.group({
    checkResumeArrayJr: this.fb.array([], Validators.required)
  })

  public range_conf = range_conf
  conf_score__gte!: number
  conf_score__lte!: number
  options: Options = {
    // showTicksValues: true,
    step: 0.1,
    floor: 0,
    ceil: 1
  };
  onConfChangeEnd(changeContext: ChangeContext): void {

    this.conf_score__gte = changeContext.value;
    this.conf_score__lte = changeContext.highValue || this.conf_score__gte;
  }
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  public hasBaseDropZoneOver: boolean = false;
  public uploader = new FileUploader({
    url: environment.apiUrl,
    isHTML5: true,
  });
  public files: File[];
  public previews: any[] = [];
  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  downloadDocumentForm: FormGroup
  public selectedRows: any[] = [];
  public showActionsPopup: boolean = false;
  public SelectionType = SelectionType;
  public talentPools: any[] = []
  public addResumeToTalentPoolForm: FormGroup;
  language: string;
  careersites: any[] = []
  selectedSource: string
  selectedTalentpool: string
  public items_jr: any[] = []
  selectedJr: string;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _router: Router,
    private resumesService: ResumeParserService,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _toastrService: ToastrService,
    private elementRef: ElementRef,
    private _modalService: NgbModal,
    private _coreConfigService: CoreConfigService,
    private talentPoolService: TalentPoolService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });
    this.downloadDocumentForm = this.fb.group({
      file_name: ["", Validators.required],
      type: ["", Validators.required],
      from: [null],
      to: [null],
      status: [""],
      owner: []
    }, { validators: this.dateRangeValidator });
    this.addResumeToTalentPoolForm = this.fb.group({
      talent_pool: ["", Validators.required]
    })
  }

  // modal Basic
  modalOpen(modalBasic: any) {

    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
      scrollable: true
    });
  }
  // deleteResume(id: number){
  //   this.resumesService.deleteResume(id);
  // }
  onSubmit() {
    this.page.pageNumber = 0
    this.SearchResume();
  }
  SearchResume() {

    this.isLoading = true
    this.loading_page_filter = true
    let file_name = "&file_name=" + this.file_name
    let id = "&id="+this.id
    let url_status = ""
    if (this.selectedStatus.length > 0) {
      for (let i = 0; i < this.selectedStatus.length; i++) {
        let url_current_status = "&status=" + this.selectedStatus[i]
        url_status = url_status + url_current_status
      }
    } else {
      url_status = ""
    }
    let url_sf4c_status = ""
    if (this.selectedSf4cStatus.length > 0) {
      for (let i = 0; i < this.selectedSf4cStatus.length; i++) {
        let url_current_status = "&sf4c_status=" + this.selectedSf4cStatus[i]
        url_sf4c_status = url_sf4c_status + url_current_status
      }
    } else {
      url_sf4c_status = ""
    }
    // let status = this.selectedStatus==null?"&status=":"&status="+this.selectedStatus
    let email = "&email=" + this.email
    let phone_number = "&phone_number=" + this.phone_number
    let owner__email = ""
    if (this.owner__email.length > 0) {
      for (let i = 0; i < this.owner__email.length; i++) {
        let url_current_owner_email = "&owner__email=" + this.owner__email[i]
        owner__email = owner__email + url_current_owner_email
      }
    } else {
      owner__email = ""
    }
    // let conf_score__gte = "&conf_score__gte="+this.conf_score__gte
    // let conf_score__lte = "&conf_score__lte="+this.conf_score__lte
    let source = ""
    if (this.source.length > 0) {
      for (let i = 0; i < this.source.length; i++) {
        let url_current_source = "&source=" + this.source[i]
        source = source + url_current_source
      }
    } else {
      source = ""
    }
    // let source = "&source=" + this.source
    let url_dynamic = ''
    if (this.file_name != undefined && this.file_name !== null) {
      url_dynamic = url_dynamic + file_name
    }
    if(this.id){
      url_dynamic = url_dynamic + id
    }
    if (this.selectedStatus.length > 0) {
      url_dynamic = url_dynamic + url_status
    }
    if (this.selectedSf4cStatus.length > 0) {
      url_dynamic = url_dynamic + url_sf4c_status
    }
    if (this.email != undefined && this.email !== null) {
      url_dynamic = url_dynamic + email
    }
    if (this.phone_number !== undefined && this.phone_number !== null) {
      url_dynamic = url_dynamic + phone_number
    }
    if (this.owner__email !== undefined && this.owner__email !== null) {
      url_dynamic = url_dynamic + owner__email
    }
    if (this.source !== undefined && this.source !== null) {
      url_dynamic = url_dynamic + source
    }
    this.url_dynamic = url_dynamic
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    // console.log("page",this.page)
    const api$ = this.resumesService.getFilterListResume(url_dynamic, this.page.pageNumber, this.page.size)
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.resumesService.getFilterListResume(url_dynamic, this.page.pageNumber, this.page.size)
      )
    );
    this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
      (res) => {
        this.resume_parser = res.results;
        this.resumesService.setListResumeValue(res.results)
        // this.page.totalElements = res.count
        this.isLoading = false
        // Not update ip user is choosing files
        const currentPageNumber = this.page.pageNumber;
        const currentPageSize = this.page.size;
        this.page = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        // this.resumesService.setPageSizeValue(this.page.size);
        this.saveFilter();
        
      },
      (err) => {
        this.errorMessagesGetFilterListResume = err
        this.isLoading = false
        this.intervalSubscription.unsubscribe();
      }
    )
  }

  setPage(pageInfo) {
    this.resetTabTemporaryData();
    this.page.pageNumber = pageInfo.offset;
    console.log("page",this.page.pageNumber)
    // this.resumesService.setPageValue(this.page.pageNumber)
    this.saveFilter();
    this.SearchResume()
  }
  approveMultiResume(){
    let ids = this.selectedRows.map((row) =>
    row.id);
    this.resumesService.approveMultiResume(ids).subscribe((res)=>{
      for (let i=0;i<res.message.length;i++){
        if(this.language=='en' && res.message[i].status==200){
          this._toastrService.success( 'Resume '+res.message[i].file_name + ' '+res.message[i].en)
        }else if(this.language=='vn' && res.message[i].status==200){
          this._toastrService.success( 'Hồ sơ '+res.message[i].file_name+' '+res.message[i].vn)
        }else if(this.language=='en' && res.message[i].status==400){
          this._toastrService.error( 'Resume '+res.message[i].file_name + ' '+res.message[i].en)
        }else if(this.language=='vn' && res.message[i].status==400){
          this._toastrService.error( 'Hồ sơ '+res.message[i].file_name+' '+res.message[i].vn)
        }
      }
      this.resetTabTemporaryData();
      this.SearchResume()
    },
    (err)=>{
      // for (let i=0;i<err.error.message.length;i++){
      //   if(this.language=='en'){
      //     this._toastrService.error( 'Resume '+err.error.message[i].file_name + ' '+err.error.message[i].en)
      //   }else{
      //     this._toastrService.error( 'Hồ sơ '+err.error.message[i].file_name+' '+err.error.message[i].vn)
      //   }
      // }
      
    })
  }
  syncEmail() {
    this.resumesService.syncEmail().subscribe(
      (res) => {
        // this._toastrService.success(res.message,);
        // console.log("res",res.message)
        this.SearchResume()
      }
    )
  }
  ParseListResume() {
    this.resume_parser = []
    const resumeSubscr = this.resumesService.uploadResume(this.files, this.selectedSource, this.selectedTalentpool, this.selectedJr)
      .subscribe(
        (res) => {
          this.files = [];
          this.previews = [];
          // this._toastrService.success(
          //   this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.CREATE_R_SUC"),
          //   this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS")

          // );
          this.SearchResume();

        },
        (err) => {
          this._toastrService.error(err.error.message)
        }
      )
    this.unsubscribe.push(resumeSubscr);
  }
  isImage(url: string): boolean {
    return url.startsWith('data:image/');
  }

  isDocx(url: string): boolean {
    return url.startsWith('<html>');
  }
  public isDoc = false
  getIcon(fileName: string) {
    const extension = fileName.split(".").pop();

    switch (extension) {
      case "pdf":
        return "assets/images/files/pdf-256x256.png"
      case "doc":
        return "assets/images/files/doc.png"
      case "docx":
        return "assets/images/files/docx-file.png"
      // case "xlsx":
      // return "assets/images/files/xlsx.png"
      default:
        return "assets/images/files/photo-256x256.png"
    }
  }
  setFile($event: FileList | null) {
    if (!$event) {
      this.files = [];
      this.previews = [];
      return;
    }
    let files = Array.from(this.files || []);
    for (let i = 0; i < $event.length; i++) {
      const file = $event[i];
      if (file.type.startsWith("image") || file.type === "application/pdf"
        || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword") {
        files.push(file);
        this.files = files;
      }
      // console.log("type",file.type)
      if (file.type.startsWith("image/")) {
        // reduce image quality
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const scaleFactor = QUALITY_IMAGE_PREVIEW;
            canvas.width = image.width * scaleFactor;
            canvas.height = image.height * scaleFactor;
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            const dataUrl = canvas.toDataURL(file.type);
            this.previews.push(dataUrl);
          };
          image.src = reader.result as string;
        };
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = () => {
          pdfjsLib
            .getDocument({ data: reader.result })
            .promise.then((pdf: any) => {
              pdf.getPage(1).then((page: any) => {
                const viewport = page.getViewport({ scale: 1.0 });
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                  canvasContext: ctx,
                  viewport: viewport,
                };
                page.render(renderContext).promise.then(() => {
                  const dataUrl = canvas.toDataURL(
                    "image/jpeg",
                    QUALITY_PDF_PREVIEW
                  );

                  this.previews.push(dataUrl);
                });
              });
            });
        };
        reader.readAsArrayBuffer(file);
      }
      else if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || file.type === "application/msword") {
        this.previews.push({
          image: this.getIcon(file.name),
          name: file.name,
        })
        // this.previews.push(this.getIcon(file.name))
        // const reader = new FileReader();
        // reader.onload = () => {
        //   const arrayBuffer = reader.result as ArrayBuffer;

        //   mammoth.extractRawText({ arrayBuffer: arrayBuffer })
        //     .then((result) => {
        //       const html = result.value;

        //       // Create a temporary div to hold the HTML content
        //       const tempDiv = document.createElement("div");
        //       tempDiv.innerHTML = html;

        //       // Create a canvas for the preview
        //       const canvas = document.createElement("canvas");
        //       const ctx = canvas.getContext("2d");

        //       // Set the canvas dimensions
        //       // Measure the text content to determine canvas dimensions
        //       const textMetrics = ctx.measureText(tempDiv.innerText);
        //       const width = textMetrics.width + 20; // Add padding
        //       const height = 40; // Set your desired height or adjust as needed

        //       // Set the canvas dimensions
        //       canvas.width = width;
        //       canvas.height = height;

        //       // Draw the HTML content on the canvas
        //       ctx.fillStyle = "#ffffff"; // Set the background color
        //       ctx.fillRect(0, 0, width, height);
        //       ctx.font = "16px Arial"; // Set the font style
        //       ctx.fillStyle = "#000000"; // Set the text color
        //       ctx.fillText(tempDiv.innerText, 10, 20); // Draw the text

        //       // Convert the canvas to a data URL
        //       const dataUrl = canvas.toDataURL("image/jpeg", QUALITY_WORD_PREVIEW);

        //       // Push the preview data URL to the previews array
        //       this.previews.push(dataUrl);
        //     })
        //     .catch((error) => {
        //       console.error("Error extracting text from Word document:", error);
        //     });
        // };

        // reader.readAsArrayBuffer(file);
      }
      //   else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      //     const reader = new FileReader();
      //     reader.onload = () => {
      //         const arrayBuffer = reader.result as ArrayBuffer;

      //         // Use a library like 'xlsx' to parse the Excel file
      //         const workbook = XLSX.read(arrayBuffer, { type: 'array' });

      //         // Assume you want to extract data from the first sheet
      //         const sheetName = workbook.SheetNames[0];
      //         const worksheet = workbook.Sheets[sheetName];

      //         // Convert worksheet to JSON
      //         const jsonData = XLSX.utils.sheet_to_json(worksheet);

      //         // Convert JSON data to HTML (optional, if you want to display it as HTML)
      //         const html = jsonData.map(row => {
      //             return `<tr>${Object.values(row).map(cell => `<td>${cell}</td>`).join('')}</tr>`;
      //         }).join('');

      //         // Create a temporary div to hold the HTML content
      //         const tempDiv = document.createElement("div");
      //         tempDiv.innerHTML = `<table>${html}</table>`;

      //         // Create a canvas for the preview
      //         const canvas = document.createElement("canvas");
      //         const ctx = canvas.getContext("2d");

      //         // Set the canvas dimensions
      //         // You may adjust these dimensions based on your requirements
      //         const width = 400; // Set your desired width
      //         const height = 300; // Set your desired height

      //         // Set the canvas dimensions
      //         canvas.width = width;
      //         canvas.height = height;

      //         // Draw the HTML content on the canvas
      //         ctx.fillStyle = "#ffffff"; // Set the background color
      //         ctx.fillRect(0, 0, width, height);
      //         ctx.font = "16px Arial"; // Set the font style
      //         ctx.fillStyle = "#000000"; // Set the text color
      //         ctx.fillText(tempDiv.innerHTML, 10, 20); // Draw the text

      //         // Convert the canvas to a data URL
      //         const dataUrl = canvas.toDataURL("image/jpeg", QUALITY_EXCEL_PREVIEW);

      //         // Push the preview data URL to the previews array
      //         this.previews.push(dataUrl);
      //     };

      //     reader.readAsArrayBuffer(file);
      // }
    }
  }
  removeImage(index: number, event: Event) {
    event.preventDefault();
    this.files.splice(index, 1);
    this.previews.splice(index, 1);
  }

  modalOpenAD(modalAD) {
    this._modalService
      .open(modalAD, {
        centered: true,
        size: "lg",
      })
      .result.then((result) => { });
  }
  ngOnDestroy(): void {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
  getListUser() {
    this._authenticationService.listUsers('').subscribe(
      (res) => {
        this.users = res.results;
        for (let i = 0; i < this.users.length; i++) {
          let owner__email_item = this.users[i].last_name + ' ' + this.users[i].first_name + ': ' + this.users[i].email
          this.owner__email_items.push({ "name": owner__email_item, "value": this.users[i].email, "id": this.users[i].id })

        }
        this.owner__email_items = [...this.owner__email_items]
        // this.owner__email_items.push({"name":'All', "value": 'all', "id":999})
        // this.ownerDownload__email_items = this.owner__email_items
      },
      (err) => {
        this.errorMessagesListUser = err
      }
    )
  }
  modalOpenDownload(modalDownload: any) {
    // this.owner__email_items.push({"name":'All', "value": 'all', "id":999})
    this.ownerDownload__email_items = this.owner__email_items
    this.modalService.open(modalDownload, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
      scrollable: true
    });
  }
  get f() {
    return this.downloadDocumentForm.controls;
  }
  downloadDocument() {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    let url_dynamic = ""
    let file_name = this.downloadDocumentForm.value.file_name;
    let type = this.downloadDocumentForm.value.type;
    let from_raw = this.downloadDocumentForm.value.from
    let to_raw = this.downloadDocumentForm.value.to
    let gmtOffsetStart = 7;
    let gmtOffsetEnd = 31;

    // if(from_raw!=undefined && to_raw!=undefined && new Date(new Date(from_raw?.getFullYear(),from_raw?.getMonth(),from_raw?.getDate())?.getTime())?.toISOString()==new Date(new Date(to_raw?.getFullYear(),to_raw?.getMonth(),to_raw?.getDate())?.getTime())?.toISOString()){
    //   gmtOffsetEnd = 31;
    // }else{
    //   gmtOffsetStart = 7;
    //   gmtOffsetEnd = 31;

    // }
    let from = from_raw == undefined ? null : new Date(new Date(from_raw.getFullYear(), from_raw.getMonth(), from_raw.getDate()).getTime() + gmtOffsetStart * 60 * 60 * 1000).toISOString();
    let to = to_raw == undefined ? null : new Date(new Date(to_raw.getFullYear(), to_raw.getMonth(), to_raw.getDate()).getTime() + gmtOffsetEnd * 60 * 60 * 1000).toISOString();
    let status = this.downloadDocumentForm.value.status;
    let owner = this.downloadDocumentForm.value.owner;
    url_dynamic = 'file_name=' + file_name + '&type=' + type
    if (from != undefined && from != null) {
      url_dynamic = url_dynamic + '&from=' + from
    }
    if (to != undefined && to != null) {
      url_dynamic = url_dynamic + '&to=' + to
    }
    // else{
    //   url_dynamic = url_dynamic+'&to='+Date.now()
    // }

    let url_owner = ""
    if (owner.length > 0 && owner[0] != 999) {
      for (let i = 0; i < owner.length; i++) {
        let url_current_owner = "&owner=" + owner[i]
        url_owner = url_owner + url_current_owner
      }
    } else {
      url_owner = ""
    }
    url_dynamic = url_dynamic + url_owner
    if (status != 'all') {
      url_dynamic = url_dynamic + '&status=' + status
    }
    this.tag_export = true
    this.resumesService.downloadResume(url_dynamic).subscribe(
      (res) => {
        if (type == 'csv') {
          file_name = file_name + '.csv'
          let blob = new Blob([res], { type: 'csv;charset=utf-8' });
          this.tag_export = false;
          saveAs(blob, file_name);
          this.downloadDocumentForm.patchValue({
            file_name: 'Result',
            type: 'excel',
            from: undefined,
            to: undefined,
            status: 'all',
            owner: [],
          })
        } else {
          file_name = file_name + '.xlsx'
          let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' });
          this.tag_export = false;
          saveAs(blob, file_name);
          this.downloadDocumentForm.patchValue({
            file_name: 'Result',
            type: 'excel',
            from: undefined,
            to: undefined,
            status: 'all',
            owner: [],
          })
        }
      },
      (err) => {
        this.tag_export = false;
      }
    )
  }
  cancel() {
    this.downloadDocumentForm.patchValue({
      file_name: 'Result',
      type: 'excel',
      from: undefined,
      to: undefined,
      status: 'all',
      owner: [],
    })
  }
  export_csv() {
    this.tag_export = true
    let type_file = "csv"
    this.resumesService.downloadResume(type_file).subscribe(x => {
      const filename = `Result parse resumes.csv`;
      let blob = new Blob([x], { type: 'csv;charset=utf-8' });
      this.tag_export = false;
      saveAs(blob, filename);
    })
  }
  export_excel() {
    this.tag_export = true
    let type_file = "excel"
    this.resumesService.downloadResume(type_file).subscribe(x => {
      const filename = `Result parse resumes.xlsx`;
      let blob = new Blob([x], { type: 'application/vnd.ms-excel;charset=utf-8' });
      this.tag_export = false;
      saveAs(blob, filename);
    })
  }
  deleteResume(id: number) {
    Swal.fire({
      title: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_R"),
      text: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.resumesService.deleteResume(id).subscribe(
          (res) => {
            this._toastrService.success(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.DELETE_R_SUC"),
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.SearchResume();
          },
          (err) => {
            this._toastrService.error(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.ERROR"),
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  setIsSelectedRows(item: any) {
    return this.selectedRows.some((row) => (row.id == item.id));
  }
  getFilterSelectedRows() {
    if (this.selectedRows.filter(e => (e.status == 'Done' || e.status == 'Approved' || e.status == 'Modified')).length > 0) {
      return true;
    }
  }
  onSelect({ selected }) {
    if (selected.length == 0) {
      // unselected all rows
      const ids = this.resume_parser.map((row) => row.id);
      this.selectedRows = this.selectedRows.filter(
        (row) => !ids.includes(row.id)
      );
    } else {
      this.selectedRows = [];
      const ids = this.selectedRows.map((row) => row.id);
      selected.forEach((row) => {
        if (!ids.includes(row.id)) this.selectedRows.push(row);
      });
    }
    if (selected.length > 0) {
      this.intervalSubscription.unsubscribe();
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
      this.SearchResume();
    }
  }
  unselectAllRows() {
    if (this.tableRowDetails?.selected) this.tableRowDetails.selected = [];
    this.selectedRows = [];
    this.showActionsPopup = false;
  }
  resetTabTemporaryData() {
    this.selectedRows = [];
    this.showActionsPopup = false;
    // this.unselectAllRows();
  }
  getListTalentPool() {
    let url_dynamic = 'owner__email=' + this.user.email
    this.resumesService.listTalentPool(url_dynamic).subscribe((res) => {
      this.talentPools = res
      // this.resumeService.setListFolderValue(this.talentPools)
    })
  }
  onAddResumeToTalentPool() {
    const formData = {};
    formData['resumes'] = this.selectedRows.map((row) =>
      row.id);
    formData['talent_pool'] = this.addResumeToTalentPoolForm.get("talent_pool").value
    this.resumesService.addResumes(formData).subscribe((res) => {
      for (let i=0;i<res.message.length;i++){
        if(this.language=='en' && res.message[i].status==200){
          this._toastrService.success( 'Resume '+res.message[i].file_name + ' '+res.message[i].en)
        }else if(this.language=='vn' && res.message[i].status==200){
          this._toastrService.success( 'Hồ sơ '+res.message[i].file_name+' '+res.message[i].vn)
        }else if(this.language=='en' && res.message[i].status==400){
          this._toastrService.error( 'Resume '+res.message[i].file_name + ' '+res.message[i].en)
        }else if(this.language=='vn' && res.message[i].status==400){
          this._toastrService.error( 'Hồ sơ '+res.message[i].file_name+' '+res.message[i].vn)
        }
      }
      this.SearchResume()
      this._modalService.dismissAll();
      this.resetTabTemporaryData();

    }, (err) => {
      this._toastrService.error(err)
    })
  }
  modalOpenAddResume(modal: any) {
    this._modalService.open(modal, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }
  removeResumes() {
    var formData = {}
    formData['id'] = this.selectedRows.map((row) => row.id)
    Swal.fire({
      title: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_RESUMES"),
      text: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.resumesService.removeResumes(formData).subscribe((res) => {
          this._toastrService.success(
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_RESUMES"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
          this.SearchResume();
          this.resetTabTemporaryData();

        },
          (err) => {
            this._toastrService.error(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.ERROR"),
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          })
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  dateRangeValidator(group: FormGroup): { [key: string]: any } | boolean {
    let from = group.controls['from'].value
    let to = group.controls['to'].value

    if (from && to) {
      const fromDate = new Date(from);
      const toDate = new Date(to);

      // If from date is greater than to date, set the error
      return fromDate > toDate ? { dateRangeInvalid: true } : false;
    }
  }
  saveFilter() {
    this.resumesService.setFilterDocumentsPageValue(
      this.page,
      this.file_name,
      this.selectedStatus,
      this.owner__email,
      this.source
    );
  }
  getListCareersite(){
    this.resumesService.careersiteList().subscribe((res)=>{
      this.careersites = res;
    })
  }
  GetItemJR() {
    let owner = this.user.id
    let url_dynamic = '?owner=' + owner
    this.talentPoolService.list_all_jd(url_dynamic).subscribe(
      (res) => {
        this.items_jr = res.map((item) => ({
          jobReqId: item.id,
          jobTitle: item.id + ' - ' + item.job_title
        }));

      },
      (err) => {
        console.log(err)
      }
    )
  }
  ngOnInit(): void {
    this.user = this._authenticationService.currentUserValue
    const { page, file_name, selectedStatus, owner__email, source } = this.resumesService.getFilterDocumentsPageValue();
    this.page = page || new Page({});
    this.file_name = file_name || null;
    this.selectedStatus = selectedStatus || [];
    this.owner__email = owner__email || [];
    this.source = source || [];
    this.getListTalentPool()
    // const resizeTableObserver = new ResizeObserver((entries) => {
    //   this.widthColumn = entries[0].contentRect.width / 7;
    // });
    // resizeTableObserver.observe(
    //   this.elementRef.nativeElement.querySelector(
    //     "#ngx-datatable-row-details"
    //   )
    // );
    this.getListUser();
    this.getListCareersite();
    this.GetItemJR()
    this.SearchResume();

    this.downloadDocumentForm.patchValue({
      file_name: 'Result',
      type: 'excel',
      from: undefined,
      to: undefined,
      status: 'all',
      owner: [],
    })
  }

}
