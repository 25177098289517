import { Component, ElementRef, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResumeParserService } from '../services/resume-parser.service';
import { Jr, ResumeParser } from '../models/resume.model';
import { User } from 'app/auth/models/user';
import { Page } from '../models/paging.model';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Subscription, concat, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { ToastrService } from 'ngx-toastr';
import { job_status_color, range_conf, status_color } from 'app/main/talent-pool/models/resume.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CoreConfigService } from '@core/services/config.service';
const modalFadeAnimation = trigger("modalFadeAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
]);
@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideFade", [
      state(
        "visible",
        style({
          bottom: "20px",
          opacity: 1,
          transform: "translateY(0%)",
        })
      ),
      state(
        "hidden",
        style({
          bottom: "-20px",
          opacity: 0,
          transform: "translateY(100%)",
        })
      ),
      transition("hidden => visible", [animate("0.5s ease-out")]),
      transition("visible => hidden", [animate("0.5s ease-in")]),
    ]),
    modalFadeAnimation,
  ],
})
export class IntegrationComponent implements OnInit {
  public isAuthenticated = false
  public resume_parser: ResumeParser[] = []
  public resume_parserJA: ResumeParser[] = []

  public myFiles: File[] = [];
  public errorMessagesGetJr!: any
  public errorMessagesGetFilterListResume!: any
  SearchForm!: FormGroup
  SearchFormJA!: FormGroup

  public file_name!: string
  public current_title: string
  public selectedStatus: string[] = []
  public selectedSf4cStatus: string[] = []
  public sf4c_status = [

    // {name: "SF4C background failed", value: "SF4C_background_failed"},
    { name: "SF wait to upload", value: "SF4C_wait_to_upload" },
    { name: "SF failed", value: "SF4C_failed" },
    { name: "SF uploading", value: "SF4C_uploading" },
    { name: "SF uploaded", value: "SF4C_uploaded" },
    { name: "SF not upload", value: "Not_upload" },
  ]
  job_boards = [
    {name: "Vietnamworks", value: "Vietnamworks"},
    {name: "Careerviet", value: "Careerviet"},
    {name: "TopCV", value: "TopCV"},
    {name: "Careerlink", value: "Careerlink"},
    {name: "Vieclam24h", value: "Vieclam24h"},
    {name: "ITViec", value: "ITViec"},
    {name: "Jobsgo", value: "Jobsgo"},
    {name: "Facebook", value: "Facebook"},
]
  public owner__email: string[] = []
  public owner__email_items: any[] = []
  public file_nameJA: string
  public owner__emailJA: string[] = []
  public current_titleJA: string

  public user: User = new User()
  public users: User[] = []
  public errorMessagesListUser: any
  public searchText!: string
  public page = new Page({});
  public pageJA = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 250;
  @ViewChild(DatatableComponent) tableRowDetails: DatatableComponent;
  INTERVAL_TIME = 5000;
  intervalSubscription: Subscription;
  public isLoading = false;
  public isLoadingJA = false;
  public loadingFormJA = false;

  public url_dynamic = ""
  public url_dynamicJA = ""
  public status_color = status_color
  public job_status_color = job_status_color

  public loading = false
  public loading_page = false
  public loading_page_filter = false
  public tag_export = false
  public errorMessagesResultConnectSf4cs!: any
  public tag_resultConnectSf4c_exit = false
  public allRowsSelected: boolean = false;

  //upload

  public arr_checkboxJr: string[] = []
  public arr_checkbox: string[] = []
  public arr_checkbox_current_title: string[] = []
  public arr_checkboxValid: any[] = []



  formCheckBox = this.fb.group({
    checkResumeArray: this.fb.array([])

  })
  // formCheckBoxJr!: FormGroup;

  formCheckBoxJr = this.fb.group({
    checkResumeArrayJr: this.fb.array([]),
    importedby: [null],
    source: [null],
  })
  formCheckBoxValid = this.fb.group({
    checkResumeArrayValid: this.fb.array([]),
    importedby: [null],
    source: [null],
  })

  public errorMessagesSf4cUpload!: any
  public pageJr = 1
  public pageCheckValid = 1;
  public basicSelectedOption: number = 5;
  totalItemsJr: number = 0
  public resultJr: Jr[] = []
  public have_Jr = false
  public loading_ = false
  public status_sf4c_upload: string = ''
  public tag_list_resume_upload = false

  public tag_filter_list_resume = false

  public range_conf = range_conf
  conf_score__gte!: number
  conf_score__lte!: number
  public allSelected = false
  public resumeParserAll: ResumeParser[] = []
  public selectedRows: ResumeParser[] = [];
  public selectedRowsJA: ResumeParser[] = [];
  public SelectionType = SelectionType;
  public role: string;
  public isLoadingJr = false;
  public resultCheckValidUpload: any
  public jaStatus: any[] = []
  public jaStatusCurrent: any[] = []

  // public lengthJaStatus: number
  lengthJaStatus: number[] = [];

  public showActionsPopup: boolean = false;
  public showActionsPopupJA: boolean = false;
  public source: string[] = []
  public sf4cPicklist: any
  public selectedImportedBy: string;
  public selectedSource: string
  public selectedTab = 'integration'
  language: string
  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private fb: FormBuilder,
    private resumesService: ResumeParserService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private elementRef: ElementRef,
    private toastr: ToastrService,
    private _coreConfigService: CoreConfigService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });
    // this.formCheckBoxJr = new FormGroup({
    //   checkResumeArrayJr: new FormControl([], Validators.required),
    // })
  }
  // modal Basic
  modalOpenWarning(modalBasic: any) {

    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      backdrop: "static",
      size: 'lg',
      scrollable: true
    });
  }
  deleteResume(id: number) {
    this.resumesService.deleteResume(id);
  }
  onSubmit() {
    this.page.pageNumber = 0
    this.SearchResume();
  }
  integration() {
    // this.file_name = undefined
    // this.owner__email = []
    // this.current_title = undefined
    // this.selectedRows = [];
    // this.selectedRowsJA = [];
    // this.showActionsPopup = false;
    this.showActionsPopupJA = false
    // this.arr_checkboxJr = []
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    this.SearchResume()
  }
  updateJa() {
    // this.file_nameJA = undefined
    // this.owner__emailJA = []
    // this.current_titleJA = undefined
    // this.selectedRows = [];
    // this.selectedRowsJA = [];
    this.showActionsPopup = false;
    // this.showActionsPopupJA = false
    // this.arr_checkboxJr = []
    this.SearchResumeJA()
  }
  showPageSize(pageSize: any) {
    this.page.size = pageSize
    this.SearchResume()
  }
  showPageJaSize(pageSize: any) {
    this.pageJA.size = pageSize
    this.SearchResumeJA()
  }
  SearchResume() {
    this.isLoading = true
    let file_name = "&file_name=" + this.file_name
    let url_status = "&status=Approved&sf4c_status=Not_upload&sf4c_status=SF4C_failed&sf4c_status!=SF4C_uploaded"
    let owner__email = ""
    if(this.owner__email.length>0){
      for(let i=0;i<this.owner__email.length;i++){
        let url_current_owner_email = "&owner__email="+this.owner__email[i]
        owner__email = owner__email + url_current_owner_email
      }
    }else{
      owner__email = ""
    }

    let current_title = "&current_title=" + this.current_title

    let url_dynamic = ''
    if (this.file_name != undefined && this.file_name !== null) {
      url_dynamic = url_dynamic + file_name
    }
    url_dynamic = url_dynamic + url_status
    if (this.owner__email !== undefined && this.owner__email !== null) {
      url_dynamic = url_dynamic + owner__email
    }
    if (this.current_title !== undefined && this.current_title !== null) {
      url_dynamic = url_dynamic + current_title
    }
    this.url_dynamic = url_dynamic

    this.resumesService.getFilterListResume(url_dynamic, this.page.pageNumber, this.page.size).subscribe(
      (res) => {
        this.resume_parser = res.results;
        // if(window.location.href.includes('ja')){
        //   // this.updateJa()
        //   document.getElementById("update-ja").click();
        // }
        this.resumesService.setListResumeValue(res.results)
        const currentPageNumber = this.page.pageNumber;
        const currentPageSize = this.page.size;
        this.page = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        this.saveFilterIntegration();
        this.isLoading = false
        // const resizeTableObserver = new ResizeObserver((entries) => {
        //   this.widthColumn = entries[0].contentRect.width / 7;
        // });
        // resizeTableObserver.observe(
        //   this.elementRef.nativeElement.querySelector(
        //     "#ngx-datatable-sf4c"
        //   )
        // );
      },
      (err) => {
        this.errorMessagesGetFilterListResume = err
        
      }
    )
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.saveFilterIntegration();
    this.SearchResume()
  }
  //update JA
  onSubmitJA() {
    this.pageJA.pageNumber = 0
    this.SearchResumeJA();
  }
  SearchResumeJA() {
    this.isLoadingJA = true
    let file_name = "&file_name=" + this.file_nameJA
    let url_status = "&sf4c_status__not=Not_upload"
    let selectedSf4cStatus = "&sf4c_status=" + this.selectedSf4cStatus
    let owner__email = ""
    if(this.owner__emailJA.length>0){
      for(let i=0;i<this.owner__emailJA.length;i++){
        let url_current_owner_email = "&owner__email="+this.owner__emailJA[i]
        owner__email = owner__email + url_current_owner_email
      }
    }else{
      owner__email = ""
    }
    let current_title = "&current_title=" + this.current_titleJA
    let source = ""
    if (this.source.length > 0) {
      for (let i = 0; i < this.source.length; i++) {
        let url_current_source = "&source=" + this.source[i]
        source = source + url_current_source
      }
    } else {
      source = ""
    }

    let url_dynamic = ''
    if (this.file_nameJA != undefined && this.file_nameJA !== null) {
      url_dynamic = url_dynamic + file_name
    }
    url_dynamic = url_dynamic + url_status
    if (this.selectedSf4cStatus.length > 0) {
      url_dynamic = url_dynamic + selectedSf4cStatus
    }
    if (this.owner__emailJA !== undefined && this.owner__emailJA !== null) {
      url_dynamic = url_dynamic + owner__email
    }
    if (this.current_titleJA !== undefined && this.current_titleJA !== null) {
      url_dynamic = url_dynamic + current_title
    }
    if (this.source !== undefined && this.source !== null) {
      url_dynamic = url_dynamic + source
    }

    this.url_dynamicJA = url_dynamic
    if (this.intervalSubscription || this.url_dynamic != '') this.intervalSubscription?.unsubscribe();
    const api$ = this.resumesService.getFilterListResume(url_dynamic, this.pageJA.pageNumber, this.pageJA.size)
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.resumesService.getFilterListResume(url_dynamic, this.pageJA.pageNumber, this.pageJA.size)
      )
    );
    this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
      (res) => {
        this.resume_parserJA = res.results;
        this.lengthJaStatus = Array.from(
          { length: this.resume_parserJA.length },
          (_, i) => 0
        );
        // this.jaStatus = Array.from(
        //   { length: this.resume_parserJA.length },
        //   (_, i) => 0
        // );
        this.resumesService.setListResumeValue(res.results)
        const currentPageNumber = this.pageJA.pageNumber;
        const currentPageSize = this.pageJA.size;
        this.pageJA = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        this.saveFilterJa();
        this.isLoadingJA = false
      },
      (err) => {
        this.errorMessagesGetFilterListResume = err
        this.isLoadingJA = false
        this.intervalSubscription.unsubscribe();
      }
    )
  }
  ngOnDestroy(): void {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
  setPageJA(pageInfo) {
    this.pageJA.pageNumber = pageInfo.offset;
    this.saveFilterJa();
    this.SearchResumeJA();
  }
  modalOpenUploadSf4c(modalBasic: any, JaBoolean = false) {
    this.pageJr = 1
    this.have_Jr = false
    this.getJr(JaBoolean);
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      backdrop: "static",
      scrollable: false
    });
  }
  modalOpenCheckValidUpload(modalCheckValid: any) {
    this.modalService.open(modalCheckValid, {
      windowClass: 'modal',
      size: 'lg',
      backdrop: "static",
      scrollable: true
    });
  }

  getJr(JaBoolean: boolean) {
    this.isLoadingJr = true
    let owner = this.user.id
    this.resumesService.getJrWithQuery(owner).subscribe(
      (res) => {
        this.resultJr = res;
        if (JaBoolean) {
          if (this.selectedRowsJA.length == 1) {
            let id = this.selectedRowsJA.map((row) => row.id)
            this.resumesService.getJobApplicationStatus(id[0]).subscribe(
              (result) => {
                this.jaStatusCurrent = result.filter((e)=>e.status=="Success")
                const ids = this.jaStatusCurrent.map((row) => row.job_req_id);
                this.resultJr = this.resultJr.filter((item) =>
                  !ids.includes(item.hcm_system_jd)
                )
                this.intervalSubscription.unsubscribe();
              },
              (err) => {
                this.toastr.warning(err.error.message)
              }
            )
          }
        }
        this.resumesService.setListJrValue(this.resultJr)
        this.totalItemsJr = res.length
        this.have_Jr = true
        this.isLoadingJr = false
      },
      (err) => {
        console.error("err: " + err)
        this.isLoadingJr = false;
      }
    )
  }

  onCheckboxChangeJr(e: any) {
    const checkResumeArrayJr: FormArray = this.formCheckBoxJr.get('checkResumeArrayJr') as FormArray;
    if (e.target.checked) {

      checkResumeArrayJr.push(new FormControl(e.target.value));

      this.arr_checkboxJr.push(e.target.value)
    } else {
      let i: number = 0;
      checkResumeArrayJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJr.removeAt(i);
          this.arr_checkboxJr = this.arr_checkboxJr.filter(item => item !== e.target.value)
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeValid(e: any) {
    const checkResumeArrayValid: FormArray = this.formCheckBoxValid.get('checkResumeArrayValid') as FormArray;
    if (e.target.checked) {

      checkResumeArrayValid.push(new FormControl(e.target.value));

      this.arr_checkboxValid.push(Number(e.target.value))
    } else {
      let i: number = 0;
      checkResumeArrayValid.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayValid.removeAt(i);
          this.arr_checkboxValid = this.arr_checkboxValid.filter(item => item !== Number(e.target.value))
          return;
        }
        i++;
      });
    }
  }
  FormCheckValidUpload(modalCheckValid: any, JaBoolean=false) {
    if(JaBoolean){
      this.loading_ = true
      let arr_checkbox_cp: any = []
      for (let i = 0; i < this.selectedRowsJA.length; i++) {
        arr_checkbox_cp.push(this.selectedRowsJA[i].id)
      }
      this.resumesService.validUploadJa(arr_checkbox_cp, this.arr_checkboxJr).subscribe(
        (res) => {
          this.resultCheckValidUpload = res;   
          if (res.invalid_job_app_id.length > 0) {
            this.modalOpenCheckValidUpload(modalCheckValid)
            this.loading_ = false
          } else {
            this.FormCheckBoxJA()
            
          }
        },
        (err) => {
          this.formCheckBoxValid.reset();
          // this.errorMessagesSf4cUpload = err[0];
          this.loading_ = false
        }
      )
    }else{
      this.loading_ = true
      let arr_checkbox_cp: any = []
      for (let i = 0; i < this.selectedRows.length; i++) {
        arr_checkbox_cp.push(this.selectedRows[i].id)
      }
      this.resumesService.validUpload(arr_checkbox_cp, this.arr_checkboxJr).subscribe(
        (res) => {
          this.resultCheckValidUpload = res;
          if (res.invalid_resume_id.length > 0) {
            this.modalOpenCheckValidUpload(modalCheckValid)
            this.loading_ = false
          } else {
            this.FormCheckBoxJr()

          }
        },
        (err) => {
          this.formCheckBoxValid.reset();
          // this.errorMessagesSf4cUpload = err[0];
          this.loading_ = false
        }
      )
    }
    
  }
  FormCheckBoxJr() {
    this.loadingFormJA = true
    let formData = {}
    let arr_checkbox_upload: any = this.resultCheckValidUpload.resume_id.concat(this.arr_checkboxValid)
    let importedby = this.formCheckBoxValid.value.importedby
    formData['resume_id'] = arr_checkbox_upload;
    formData['jobReqId'] = this.arr_checkboxJr;
    if (importedby) {
      formData['importedby'] = importedby.id;
      formData['source'] = importedby.source_id;
    }
    this.resumesService.sf4c_upload(formData).subscribe(

      (res) => {
        this.selectedRows = []
        this.loading_ = false
        this.loadingFormJA = false
        this.modalService.dismissAll();
        this.formCheckBoxJr.reset();
        this.formCheckBoxValid.reset();
        this.resetTabTemporaryData();
        arr_checkbox_upload = []
        this.arr_checkboxJr = []
        this.selectedTab = 'ja'
        this.SearchResumeJA()
        // document.getElementById("update-ja").click();
        // document.getElementById("updateJa").click();
      },
      (err) => {
        arr_checkbox_upload = []
        this.arr_checkboxJr = []
        this.formCheckBoxJr.reset();
        this.formCheckBoxValid.reset();
        this.errorMessagesSf4cUpload = err[0];
        this.toastr.warning(err.error.message)
        this.loading_ = false
        this.loadingFormJA = false
      }
    )
  }
  cancelFormCheckboxValid() {
    this.searchText = ''
    this.arr_checkboxJr = []
    // this.formCheckBoxValid.reset()
  }
  cancelFormCheckBoxJr() {
    // this.searchText = ''
    this.arr_checkboxJr = []
    this.arr_checkboxValid = []
    // this.formCheckBoxJr.reset()
  }
  FormCheckBoxJA() {
    this.loadingFormJA = true
    let arr_checkbox_cp = []
    let formData = {}
    let arr_checkbox_upload: any = this.resultCheckValidUpload.resume_id.concat(this.arr_checkboxValid)
    formData['resume_id'] = arr_checkbox_upload;
    formData['jobReqId'] = this.arr_checkboxJr;
    this.resumesService.updateJA(formData).subscribe(
      (res) => {
        if (res) {
          this.loading_ = false
          this.selectedRowsJA = []
          this.loadingFormJA = false
          this.modalService.dismissAll();
          this.formCheckBoxJr.reset();
          this.formCheckBoxValid.reset();
          this.resetTabTemporaryData();
          arr_checkbox_cp = []
          this.arr_checkboxJr = []
          this.SearchResumeJA()
        }
      },
      (err) => {
        arr_checkbox_cp = []
        this.arr_checkboxJr = []
        this.formCheckBoxJr.reset();
        this.formCheckBoxValid.reset();
        this.errorMessagesSf4cUpload = err[0]
        this.loadingFormJA = false
        this.toastr.warning(err.error.message)
        this.modalService.dismissAll();

      }
    )
  }

  onCheckboxChange(e: any) {
    const checkResumeArray: FormArray = this.formCheckBox.get('checkResumeArray') as FormArray;
    if (e.target.checked) {
      let id = e.target.value.split('__')[0]
      let current_title = e.target.value.split('__')[1]
      checkResumeArray.push(new FormControl(id));
      this.arr_checkbox.push(id)
      this.arr_checkbox_current_title.push(current_title)

    } else {
      let id = e.target.value.split('__')[0]
      let current_title = e.target.value.split('__')[1]
      let i: number = 0;
      checkResumeArray.controls.forEach((item: any) => {
        if (item.value.includes(id) || item.value.includes(current_title)) {

          checkResumeArray.removeAt(i);
          this.arr_checkbox = this.arr_checkbox.filter(item => item !== id)
          this.arr_checkbox_current_title = this.arr_checkbox_current_title.filter(item => item !== current_title)
          return;
        }
        i++;
      });
    }
  }
  FormCheckBox() {
  }
  Search() {
    // alert(this.searchText)
    let resultJr_cp: any
    this.resumesService.listJr.subscribe(
      (res) => {
        resultJr_cp = res
      }
    )
    if (this.searchText !== "") {
      let searchValue = this.searchText.toLocaleLowerCase();
      this.resultJr = resultJr_cp.filter((resultJr_: any) => {
        return resultJr_.job_title.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here
      });
    }
    else {
      this.resultJr = resultJr_cp
    }
  }
  setIsSelectedRows(item: any) {
    return this.selectedRows.some((row) => row.id == item.id);
  }
  setIsSelectedRowsJA(item: any) {
    if (this.intervalSubscription) this.intervalSubscription?.unsubscribe();
    return this.selectedRowsJA.some((row) => row.id == item.id);
  }
  onSelect({ selected }) {
    if (selected.length == 0) {
      // unselected all rows
      const ids = this.resume_parser.map((row) => row.id);
      this.selectedRows = this.selectedRows.filter(
        (row) => !ids.includes(row.id)
      );
    } else {
      this.selectedRows = []
      const ids = this.selectedRows.map((row) => row.id);
      selected.forEach((row) => {
        if (!ids.includes(row.id)) this.selectedRows.push(row);
      });
    }
    if (selected.length > 0) {
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
      this.SearchResume();
    }
  }
  onSelectJA({ selected }) {
    if (this.intervalSubscription) this.intervalSubscription?.unsubscribe();
    if (selected.length == 0) {
      // unselected all rows
      const ids = this.resume_parserJA.map((row) => row.id);
      this.selectedRowsJA = this.selectedRowsJA.filter(
        (row) => !ids.includes(row.id)
      );
    } else {
      this.selectedRowsJA = []
      const ids = this.selectedRowsJA.map((row) => row.id);
      selected.forEach((row) => {
        if (!ids.includes(row.id) && (row.sf4c_status=='SF4C_uploaded')) this.selectedRowsJA.push(row);
      });
    }
    if (selected.length > 0) {
      // this.showActionsPopup = true;
      this.showActionsPopupJA = true
    } else {
      // this.showActionsPopup = false;
      this.showActionsPopupJA = false
      this.SearchResumeJA();
    }
  }
  onActivate(event) {
    // console.log('Activate Event', event);
  }
  unselectAllRows() {
    if (this.intervalSubscription) this.intervalSubscription?.unsubscribe();
    if (this.tableRowDetails?.selected) this.tableRowDetails.selected = [];
    this.selectedRows = [];
    this.showActionsPopup = false;
    this.showActionsPopupJA = false

  }
  resetTabTemporaryData() {
    this.selectedRows = [];
    this.showActionsPopup = false;
    this.showActionsPopupJA = false;
    // this.unselectAllRows();
  }
  getListUser() {
    this._authenticationService.listUsers('').subscribe(
      (res) => {
        this.users = res.results;
        for (let i = 0; i < this.users.length; i++) {
          let owner__email_item = this.users[i].last_name + ' ' + this.users[i].first_name + ': ' + this.users[i].email
          this.owner__email_items.push({ "name": owner__email_item, "value": this.users[i].email })

        }
        this.owner__email_items = [...this.owner__email_items]
      },
      (err) => {
        this.errorMessagesListUser = err
      }
    )
  }
  getCredential() {
    this.resumesService.getDetailCredential(this.user.id).subscribe(
      (res) => {
        if (res) {
          this.tag_resultConnectSf4c_exit = true
        }
      }
    )

  }
  rowDetailsToggleExpand(row, rowIndex) {
    this.resumesService.getJobApplicationStatus(row.id).subscribe(
      (res) => {
        this.lengthJaStatus[rowIndex] = res.length;
        this.jaStatusCurrent = res
        this.jaStatus[row.id] = res
        // this.lengthJaStatus = res.length
        this.tableRowDetails.rowDetail.toggleExpandRow(row);
        this.intervalSubscription.unsubscribe();
      },
      (err) => {
        this.toastr.warning(err.error.message)
      }
    )
  }
  getSf4cPicklist() {
    this.resumesService.sf4cPicklist().subscribe((res) => {
      this.sf4cPicklist = res
    })
  }
  saveFilterIntegration() {
    this.resumesService.setFilterDocumentsPageIntegrationValue(
      this.page,
      this.file_name,
      this.owner__email,
      this.current_title
    );
  }
  saveFilterJa() {
    this.resumesService.setFilterDocumentsPageJaValue(
      this.pageJA,
      this.file_nameJA,
      this.selectedSf4cStatus,
      this.owner__emailJA,
      this.current_titleJA,
      this.source
    );
  }
  ngOnInit(): void {
    const { page, file_name, owner__email, current_title } = this.resumesService.getFilterDocumentsPageIntegrationValue();
    this.page = page || new Page({});
    this.file_name = file_name || null;
    this.owner__email = owner__email || [];
    this.current_title = current_title || null;
    const { pageJA, file_nameJA, selectedSf4cStatus, owner__emailJA, current_titleJA, source } = this.resumesService.getFilterDocumentsPageJaValue();
    this.pageJA = pageJA || new Page({});
    this.file_nameJA = file_nameJA || null;
    this.selectedSf4cStatus = selectedSf4cStatus || [];
    this.owner__emailJA = owner__emailJA || [];
    this.current_titleJA = current_titleJA || null;
    this.source = source || [];
    this.user = this._authenticationService.currentUserValue
    this.getCredential()
    this.getListUser();
    if(window.location.href.includes('ja')){
      this.selectedTab = 'ja';
      this.SearchResumeJA()
    }else{
      this.SearchResume()
    }
    this.getSf4cPicklist();
  }

}
