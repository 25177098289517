<!-- <app-error *ngIf="errorDetailResume;else noError"></app-error> -->
<p *ngIf="errorDetailResume;else noError" [translate]="'TALENT_POOL.DETAIL_CANDIDATE.NOT_FOUND'"></p>
<ng-template #noError>
    <div class="blog-wrapper">
        <div class="content-wrapper container-xxl p-0">
            <!-- content-header component -->
            <app-content-header [contentHeader]="contentHeader"></app-content-header>
            <div class="content-detached content-left">
                <div class="content-body">
                    <!-- Blog Detail -->
                    <div class="blog-detail-wrapper">
                        <div class="row">
                            <!-- Blog -->
                            <div class="col-12">
                                <div class="card">
                                    <!-- <img [src]="data.blogDetail.blog.img" class="img-fluid card-img-top" alt="Blog Detail Pic" /> -->
                                    <div class="card-body">
                                        <!-- <h4 class="card-title">{{ detail_resume.current_title }}</h4> -->
                                        <div class="media">
                                            <div class="avatar mr-50">
                                                <img src="assets/images/avatars/iconPersion.png" alt="Avatar" width="24"
                                                    height="24" />
                                            </div>
                                            <div class="media-body d-flex justify-content-between align-items-center">

                                                <div class="d-flex align-items-center">
                                                    <h4 class="text-body">{{detail_resume.full_name}}</h4>

                                                    <span *ngIf="detail_resume?.resume?.sf4c_status!='Not_upload';else noSf4c"
                                                        class="badge bg-{{status_color[detail_resume?.resume?.sf4c_status]}} ml-1"
                                                        style="font-size: 15px;">
                                                        <small
                                                            *ngIf="detail_resume?.resume?.sf4c_status.replaceAll('_',' ')=='SF4C wait to upload'"
                                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS_SF4C.SF4C_WAIT'"></small>
                                                        <small
                                                            *ngIf="detail_resume?.resume?.sf4c_status.replaceAll('_',' ')=='SF4C failed'"
                                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS_SF4C.SF4C_FAILED'"></small>
                                                        <small
                                                            *ngIf="detail_resume?.resume?.sf4c_status.replaceAll('_',' ')=='SF4C uploading'"
                                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS_SF4C.SF4C_UPLOADING'"></small>
                                                        <small
                                                            *ngIf="detail_resume?.resume?.sf4c_status.replaceAll('_',' ')=='SF4C uploaded'"
                                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS_SF4C.SF4C_UPLOADED'"></small>

                                                    </span>


                                                    <ng-template #noSf4c>
                                                        <span
                                                            class="badge bg-{{status_color[detail_resume?.resume?.status]}} ml-1"
                                                            style="font-size: 15px;">
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Approved'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.APPROVED'"></small>
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Duplicated'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.DUPLICATED'"></small>
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Done'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.DONE'"></small>
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Failure'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.FAILURE'"></small>
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Processing'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.PROCESSING'"></small>
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Wait to process'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.WAIT_TO_PROCESS'"></small>
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Suspended'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.SUSPENDED'"></small>
                                                            <small
                                                                *ngIf="detail_resume?.resume?.status.replaceAll('_',' ')=='Modified'"
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.STATUS.MODIFIED'"></small>

                                                        </span>
                                                    </ng-template>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex justify-content-end" *ngIf="(user.role=='User'&& user?.email!=detail_resume?.resume?.owner 
                                            && !checkShared);else SHARED">
                                                        <button type="button"
                                                            style="background-color: #7367f0"
                                                            (click)="requestToShare()" class="btn btn-sm text-light"><span
                                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.SHARE_RESUME'"></span></button>
                                                    </div>
                                                    <ng-template #SHARED>
                                                        <div class="ml-1" *ngIf="detail_resume?.resume?.id">
                                                            <a target="_blank" class="button-link" style="text-decoration: underline;" href="javascript:void(0)"
                                                            routerLink="/manage-resume/detail-resume/{{detail_resume?.resume?.id}}"    
                                                            
                                                                [translate]="'TALENT_POOL.SEARCH_RESUME.LINK_CV'"></a>
                                                        </div>
                                                    </ng-template>
                                                    <div class="">
                                                        <button type="button" (click)="modalOpenSaveCv(modalBasicSaveCv)"
                                                            class="btn btn-sm text-light ml-1"
                                                            style="background-color: #688B58"><span
                                                                [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE_CV'"></span></button>
                                                        <!-- <button type="button" *ngIf="!saved_cv && job_description==undefined"
                                                        (click)="modalOpenSaveCv(modalBasicSaveCv)" class="btn btn-success"><span
                                                            [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE_CV'"></span></button>
                                                    <button type="button" *ngIf="!saved_cv && job_description!=undefined"
                                                        (click)="SaveResumeByJd()" class="btn btn-success"><span
                                                            class="fa fa-floppy-o"></span> <span
                                                            [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE_CV'"></span></button>
                
                                                    <button type="button" *ngIf="saved_cv" class="btn btn-success" [disabled]="true"><i
                                                            class="fa fa-check"></i><span
                                                            [translate]="'TALENT_POOL.SEARCH_RESUME.SAVED_CV'"></span></button> -->
                                                    </div>
                                                    <ng-template #modalBasicSaveCv let-modal>
                                                        <div class="modal-header">
                                                            <h4 class="modal-title"
                                                                [translate]="'TALENT_POOL.SEARCH_RESUME.SELECT_JD'">
                                                            </h4>
        
                                                        </div>
                                                        <div class="me-4 mt-1">
                                                            <div class="d-flex justify-content-end">
        
                                                                <div class="col-md-6">
        
                                                                    <input type="text" class="form-control" id="job-search"
                                                                        [placeholder]="'TALENT_POOL.SEARCH_RESUME.SEARCH_JOB' | translate"
                                                                        [(ngModel)]="searchText" (input)="Search()" />
                                                                </div>
        
                                                            </div>
                                                        </div>
                                                        <form
                                                            [formGroup]="formCheckBoxSaveCv">
                                                            <div class="modal-body" ngbAutofocus>
        
                                                                <ng-container>
        
                                                                    <table class="table align-middle table-bordered" style="height: 300px;">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="pxp-is-checkbox" style="width: 1%;">
                                                                                </th>
                                                                                <th [translate]="'TALENT_POOL.MANAGE_JOB.JOB'">
                                                                                </th>
                                                                                <th
                                                                                    [translate]="'TALENT_POOL.MANAGE_JOB.JR_ID_CHR'">
                                                                                </th>
                                                                                <th
                                                                                    [translate]="'TALENT_POOL.MANAGE_JOB.CATEGORY'">
                                                                                    Category</th>
                                                                                <th [translate]="'TALENT_POOL.MANAGE_JOB.TYPE'">
                                                                                </th>
                                                                                <th
                                                                                    [translate]="'TALENT_POOL.MANAGE_JOB.LEVEL'">
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
        
                                                                        <tbody>
                                                                            <tr
                                                                                *ngFor="let list_all_jd_ of list_all_jd| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                                                                <td *ngIf="arr_checkboxSaveCv==list_all_jd_.id;else noChecked">
                                                                                    <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)"
                                                                                        type="checkbox" class="form-check" checked>
                                            
                                                                                </td>
                                                                                <ng-template #noChecked>
                                                                                    <td>
                                                                                        <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)"
                                                                                            type="checkbox" class="form-check">
                                            
                                                                                    </td>
                                                                                </ng-template>
        
        
                                                                                <td>
                                                                                    <div class="">{{list_all_jd_.job_title}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="">{{list_all_jd_.id}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="">{{list_all_jd_.industry}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="">{{list_all_jd_.job_type}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="">{{list_all_jd_.job_level}}
                                                                                    </div>
                                                                                </td>
        
                                                                            </tr>
        
                                                                        </tbody>
                                                                    </table>
                                                                    <div class="row justify-content-between align-items-center">
                                                                        <div class="col-auto">
        
                                                                            <nav class="mt-sm-0"
                                                                                aria-label="Jobs list pagination">
        
                                                                                <ngb-pagination
                                                                                    [collectionSize]="list_all_jd.length"
                                                                                    [(page)]="page" [pageSize]="pageSize"
                                                                                    [maxSize]="10" [rotate]="true"
                                                                                    [boundaryLinks]="true">
                                                                                </ngb-pagination>
                                                                            </nav>
                                                                        </div>
        
                                                                    </div>
                                                                </ng-container>
        
                                                            </div>
        
                                                            <div class="modal-footer">
                                                                
                                                                <button [disabled]="!arr_checkboxSaveCv" type="button" class="btn btn-primary mr-1" rippleEffect
                                                                    (click)="modal.dismiss('Cross click')" (click)="FormCheckBoxSaveCv(detail_resume.resume.id)"
                                                                    [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE'">
        
                                                                </button>
                                                                <button (click)="modal.dismiss('Cross click')" type="button"
                                                                (click)="cancel()" rippleEffect
                                                                class="btn btn-secondary"
                                                                [translate]="'TALENT_POOL.CREATE_JOB.CANCEL'"></button>
                                                            </div>
                                                        </form>
                                                    </ng-template>

                                                </div>

                                            </div>

                                        </div>
                                        
                                        <!-- <div class="d-flex justify-content-end">
                                            <div class="h-100 p-0 col-auto">
                                                <nav aria-label="Page navigation example h-100">
                                                    <ul class="pagination row m-0 h-100">
                                                        <li class="page-item p-0">
                                                            <button class="page-link h-100" (click)="swapLink(currentResumeIndex - 1)">
                                                                <span data-feather="chevron-left" [size]="10"></span>
                                                            </button>
                                                        </li>
                
                                                        <li
                                                            class="page-item p-0 align-items-center d-flex flex-nowrap d-md-none d-lg-block text-center">
                                                            <span style="padding: 3px">{{ currentResumeIndex + 1 }} /
                                                                {{ totalResume }}</span>
                                                        </li>
                
                                                        <li class="page-item p-0">
                                                            <button class="page-link h-100" (click)="swapLink(currentResumeIndex + 1)">
                                                                <span data-feather="chevron-right" [size]="10"></span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div> -->

                                        <h5 class="mb-75 font-weight-bolder"
                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.OWNER'"></h5>
                                        <p class="card-text ml-1">
                                            {{detail_resume.owner}}
                                        </p>
                                        <div class="my-1 py-25">
                                            <h5 class="mb-75 font-weight-bolder"
                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.SKILL'"></h5>
                                            <a href="javascript:void(0);" *ngFor="let skill of detail_resume.skills">
                                                <div class="badge badge-pill bg-light-primary mr-50">
                                                    {{ skill }}
                                                </div>
                                            </a>
                                        </div>
                                        <h5 class="mb-75 font-weight-bolder"
                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.CURRENT_TITLE'"></h5>
                                        <p class="card-text ml-1">
                                            {{detail_resume.current_title}}
                                        </p>
                                        <h5 class="mb-75 font-weight-bolder"
                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.CURRENT_COMPANY'"></h5>
                                        <p class="card-text ml-1">
                                            {{detail_resume.current_company}}
                                        </p>
                                        <div class="mt-2">
                                            <h5 class="mb-75 font-weight-bolder"
                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.CATEGORY'"></h5>
                                            <p class="card-text ml-1">{{ detail_resume.category }}</p>
                                        </div>
                                        <div class="mt-2">
                                            <h5 class="mb-75 font-weight-bolder"
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.TOTAL_YEAR_EXP'">
                                            </h5>
                                            <p class="card-text ml-1"><span *ngIf="detail_resume?.total_year_exp"><span>{{detail_resume?.total_year_exp?.years}}
                                            </span> <span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.YEAR'"></span>
                                            {{detail_resume?.total_year_exp?.months}} <span
                                                [translate]="'RESUME_PARSER.DETAIL_RESUME.MONTH'"></span></span></p>
                                        </div>
                                        <div class="mt-2">
                                            <h5 class="mb-75 font-weight-bolder"
                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.WORK_EXPERIENCE'">
                                            </h5>

                                            <div class="card-body">
                                                <ul class="timeline"
                                                    *ngFor="let experience of detail_resume.experience">
                                                    <li class="timeline-item mt-1">
                                                        <span class="timeline-point timeline-point-indicator"></span>
                                                        <div class="timeline-event">
                                                            <div
                                                                class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                                                <div><span class="font-weight-bolder"
                                                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.POSITION'"></span>:
                                                                    {{experience.position}}</div>

                                                                <span
                                                                    class="timeline-event-time">{{experience.start_time|date: "dd/MM/yyyy"}}<span *ngIf="experience.start_time && experience.end_time"> - </span>{{experience.end_time|date: "dd/MM/yyyy"}}</span>
                                                            </div>
                                                            <div><span class="font-weight-bolder"
                                                                    [translate]="'TALENT_POOL.DETAIL_CANDIDATE.COMPANY'"></span>:
                                                                {{experience.company}}</div>
                                                            <div><span
                                                                    class="font-weight-bolder"
                                                                    [translate]="'TALENT_POOL.DETAIL_CANDIDATE.INDUSTRY'"></span>:
                                                                {{experience.industry}}</div>
                                                            
                                                            <div><span class="font-weight-bolder"
                                                                    [translate]="'TALENT_POOL.DETAIL_CANDIDATE.JOB_DESCRIPTION'"></span>:
                                                            </div>
                                                            <div class="media align-items-center">
                                                                <div class="media-body ml-1"
                                                                    [innerHTML]="experience.detail?.trim().split('\n').join('<br>')">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <h5 class="mb-75 font-weight-bolder"
                                                [translate]="'TALENT_POOL.DETAIL_CANDIDATE.EDUCATION'"></h5>
                                            <div class="card-body">
                                                <ul class="timeline" *ngFor="let education of detail_resume.education">
                                                    <li class="timeline-item">
                                                        <span class="timeline-point timeline-point-indicator"></span>
                                                        <div class="timeline-event">
                                                            <div
                                                                class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">

                                                                <div><span class="font-weight-bolder"
                                                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.MAJOR'"></span>:
                                                                    {{education.major}}</div>
                                                                <span
                                                                    class="timeline-event-time">{{education.start_time|date: "dd/MM/yyyy"}}<span *ngIf="education.start_time && education.end_time"> - </span>{{education.end_time|date: "dd/MM/yyyy"}}</span>
                                                            </div>
                                                            <div><span class="font-weight-bolder"
                                                                    [translate]="'TALENT_POOL.DETAIL_CANDIDATE.SCHOOL'"></span>:
                                                                {{education.school}}</div>
                                                            <div class="media align-items-center">
                                                                <div
                                                                    *ngIf="education.diploma!=null && education.diploma!=''">
                                                                    <span class="font-weight-bolder"
                                                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.DIPLOMA'"></span>:
                                                                    {{education.diploma}}
                                                                </div>
                                                                
                                                            </div>
                                                            <div class="media align-items-center">

                                                                <div *ngIf="education.gpa!=null && education.gpa!=''">
                                                                    <span class="font-weight-bolder"
                                                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.GPA'"></span>:
                                                                    {{education.gpa}}
                                                                </div>

                                                            </div>
                                                            <div
                                                                *ngIf="education.picklist_major!=null && education.picklist_major!=''">
                                                                <span class="font-weight-bolder"
                                                                    [translate]="'RESUME_PARSER.DETAIL_RESUME.PICKLIST_MAJOR'"></span>:
                                                                {{education.picklist_major}}
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="mt-2">
                                            <h5 class="mb-75" [translate]="'TALENT_POOL.DETAIL_CANDIDATE.REFERENCES'"></h5>
                                            <div *ngFor="let reference of detail_resume.references">
                                                <div class="ml-1">
                                                    <h6 class="font-weight-bolder">{{reference.FULLNAME}}</h6>
                                                    <p>{{reference.POSITION}} <span [translate]="'TALENT_POOL.DETAIL_CANDIDATE.AT'"></span> <span> {{reference.COMPANY}}</span></p>
                                                </div>
                                                
                                            </div>
                                            
                                        </div> -->


                                    </div>
                                </div>
                            </div>
                            <!--/ Blog -->
                        </div>
                    </div>
                    <!--/ Blog Detail -->
                </div>
            </div>
            <div class="sidebar-detached sidebar-right">
                <div class="sidebar">
                    <div class="blog-sidebar my-2 my-lg-0">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="mb-0" [translate]="'TALENT_POOL.DETAIL_CANDIDATE.INFORMATION_BASIC'"></h3>
                                <div class="mt-2">
                                    <h5 class="mb-75 font-weight-bolder"
                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.EMAIL'"></h5>
                                    <p class="card-text ml-1">{{detail_resume.email}}</p>
                                </div>
                                <div class="mt-2">
                                    <h5 class="mb-75 font-weight-bolder"
                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.PHONE_NUMBER'"></h5>
                                    <p class="card-text ml-1">{{detail_resume.phone_number}}</p>
                                </div>
                                <div class="mt-2">
                                    <h5 class="mb-75 font-weight-bolder"
                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.ADDRESS'"></h5>
                                    <p class="card-text ml-1">{{detail_resume.city}}</p>
                                </div>
                                <div class="mt-2">
                                    <h5 class="mb-75 font-weight-bolder"
                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.GENDER'"></h5>
                                    <p class="card-text ml-1">
                                        <span *ngIf="detail_resume.gender=='Female'"
                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.GENDER_.FEMALE'"></span><span
                                            *ngIf="detail_resume.gender=='Male'"
                                            [translate]="'TALENT_POOL.DETAIL_CANDIDATE.GENDER_.MALE'"></span>

                                    </p>
                                </div>
                                <div class="mt-2">
                                    <h5 class="mb-75 font-weight-bolder"
                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.DATE_OF_BIRTH'"></h5>
                                    <p class="card-text ml-1">{{detail_resume.date_of_birth|date}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>