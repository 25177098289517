import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "vn",
  data: {
    PAYMENT: {
      TITLE: "Chọn gói phù hợp với bạn nhất",
      PARSE: "Trích xuất",
      FULL_SERVICE: "Tất cả dịch vụ",
      PACKAGE_NAME: "Tên gói",
      PARSE_THREAD_LIMIT: "Số luồng trích xuất giới hạn",
      MATCHING_THREAD_LIMIT: "Số luồng chấm điểm giới hạn",
      PARSE_LIMITED: "Số lần trích xuất giới hạn",
      MATCHING_LIMITED: "Số lần chấm điểm giới hạn",
      MAX_USER: "Số lượng người dùng giới hạn",
      MONTH: "Tháng",
      YEAR: "Năm",
      DAY: "Phút",
      CONTACT: "Vui lòng liên hệ",
      CANCEL: "Hủy",
      CONFIRM: "Chấp nhận",
      DESCRIPTION:
        "",
        PLAN_1: {
          BASIC: "Cơ bản",
          SUB_BASIC: "Khách hàng doanh nghiệp thường",
          COUNT_BASIC: "tài liệu",
          PRICING_BASIC_PARSING: "9.999.000đ/tháng",
          PRICING_BASIC: "15.999.000đ/tháng",
          FEATURE_BASIC1: "Bóc tách 14 trường thông tin CV ",
          FEATURE_BASIC2: "Hỗ trợ định dạng: PDF, Doc, Docx, png, jpeg",
          FEATURE_BASIC3: "3 người dùng",
          FEATURE_BASIC4: "20 CV được trích xuất đồng thời ",
          FEATURE_BASIC5: "Cấu hình callback khi trích xuất xong CV",
          FEATURE_BASIC6: "Quản lý mô tả công việc",
          FEATURE_BASIC7: "Gợi ý ứng viên phù hợp với yêu cầu tuyển dụng",
          FEATURE_BASIC8: "Chấm điểm CV theo các tiểu chí: học vấn, kỹ năng ,kinh nghiệm làm việc",
          FEATURE_BASIC9: "Chấm điểm nâng cao",

        },
        PLAN_2: {
          STANDARD: "Nâng cao ",
          SUB_STANDARD: "Khách hàng doanh nghiệp tư vấn tuyển dụng",
          COUNT_STANDARD: "tài liệu",
          PRICING_STANDARD_PARSING: "19.999.000đ/tháng",
          PRICING_STANDARD: "24.999.000đ/tháng",
          FEATURE_STANDARD1: "Bóc tách 14 trường thông tin CV ",
          FEATURE_STANDARD2: "Hỗ trợ định dạng: PDF, Doc, Docx, png, jpeg",
          FEATURE_STANDARD3: "5 người dùng",
          FEATURE_STANDARD4: "50 CV được trích xuất đồng thời ",
          FEATURE_STANDARD5: "Che thông tin liên hệ",
          FEATURE_STANDARD6: "Cấu hình callback khi trích xuất xong CV",
          FEATURE_STANDARD7: "Hỗ trợ kĩ thuật",
          FEATURE_STANDARD8: "Quản lý mô tả công việc",
          FEATURE_STANDARD9: "Gợi ý ứng viên phù hợp với yêu cầu tuyển dụng",
          FEATURE_STANDARD10: `Chấm điểm CV theo các tiểu chí: học vấn, kỹ năng ,kinh nghiệm làm việc,
                                `,
          FEATURE_STANDARD11: "Chấm điểm nâng cao",
          
          MOST_POPULAR: "Thông dụng nhất",
          },
        PLAN_3: {
          ENTERPRISE: "Cao cấp",
          SUB_ENTERPRISE: "Lý tưởng cho các doanh nghiệp cần xử lý nhiều loại tài liệu khác nhau, huấn luyện dữ liệu của họ và triển khai quy trình làm việc tùy chỉnh.",
          COUNT_ENTERPRISE: "Liên hệ",
          PRICING_ENTERPRISE: "Giá Theo Yêu Cầu",
          FEATURE_ENTERPRISE1: "Bóc tách 14 trường thông tin CV ",
          FEATURE_ENTERPRISE2: "Hỗ trợ định dạng: PDF, Doc, Docx, png, jpeg",
          FEATURE_ENTERPRISE3: "Người dùng không giới hạn",
          FEATURE_ENTERPRISE4: "50 CV Parse đồng thời",
          FEATURE_ENTERPRISE5: "Che thông tin liên hệ",
          FEATURE_ENTERPRISE6: "Hỗ trợ kĩ thuật",
          FEATURE_ENTERPRISE7: "Hỗ trợ Tích hợp SAP, CRM ",
          FEATURE_ENTERPRISE8: "Hỗ trợ đồng bộ CSDL khách hàng",
  
        },
      PRICING: "Giá Theo Yêu Cầu",
      BASIC: "Cơ bản",
      DOCUMENTS: "Tài liệu",
      MOST_POPULAR: "Thông dụng nhất",
      STANDARD: "Tiêu chuẩn",
      BUSINESS: "Thương mại",
      BUY_NOW: "Mua ngay",
      FAQ: {
        DESCRIPTION: "Hãy để chúng tôi giúp trả lời các câu hỏi phổ biến nhất.",
        Q1: "Hệ thống có tính phí cho tệp không thành công không?",
        A1: "Bạn sẽ không bị tính phí khi upload tệp không thành công.",
        Q2: "Tôi có thể sử dụng hệ thống khi hết hạn mức không?",
        A2: "Bạn vẫn có quyền truy cập vào các tệp đã xử lý của mình khi hết dung lượng.",
      },
    },
    NOTE: {
        TITLE: "Lưu ý khi thanh toán",
        ONE: "1. Người dùng nạp tiền để mua hạn mức, khi hết hạn mức sẽ không được tải lên tài liệu.",
        TWO: "2. Mỗi gói có giá và hạn mức khác nhau. Vui lòng kiểm tra trước khi thanh toán."
      },
      OPTIONS: {
        TITLE: "Phương thức thanh toán",
        VNPAY: "Thanh toán qua ví VNPay",
        MOMO: "Thanh toán qua ví MoMo",
        PAYPAL: "Thanh toán qua ví Paypal"
  
      },
      DETAILS: {
        TITLE: "Chi tiết thanh toán",
        BUYER: "Người mua",
        EMAIL: "Email",
        PACKAGE: "Gói mua",
        NUM_PAGE_PARSING: "Giới hạn trích xuất",
        NUM_PAGE_MATCHING: "Giới hạn phù hợp",
        NUM_PAGE: "Hạn mức",
        PAGE: "tài liệu",
        PRICE: "Giá tiền",
        TOTAL_AMOUNT: "Tổng tiền",
        PAY: "Thanh toán",
        COMPANY_INFO: "Thông tin tên công ty",
        COMPANY_NAME: "Tên công ty",
        REQUIRED: "Trường này yêu cầu bắt buộc",
        CANCEL: "Huỷ",
        CONFIRM: "Chấp nhận"

      },
      PAYMENT_STATUS: {
        SUCCESS: "Thành công",
        PENDING: "Đang chờ",
        ERROR: "Lỗi",
        TITLE: "Giao dịch tại ",
        QUOTA: "Hạn mức",
        PAYMENT_ID: "Mã thanh toán",
        DATE: "Ngày",
        TIME: "Thời gian",
        VIA: "Thanh toán qua",
        TOTAL: "Tổng cộng",
        CREATE_NEW: "Tạo giao dịch mới",
      },
      BILLING:{
        PACKAGE: "Tên gói",
        TYPE: "Kênh thanh toán",
        COMPANY: "Công ty",
        PREV_QUOTA: "Hạn mức trước",
        UPDATED_QUOTA: "Hạn mức sau",
        STATUS: "Trạng thái",
        CREATED_BY: "Người tạo",
        CREATED_AT: "Thời gian tạo",
        UPDATED_AT: "Thời gian cập nhật",
        SHOW: "Hiện",
        ENTRIES: "tài liệu",
        TOTAL_DUE: "Tổng số tiền phải trả",
        PAYMENT_DETAIL: "Chi tiết thanh toán",
        TRANSACTION_TO: "Giao dịch tới",
        TRANSACTION: "Giao dịch",
        COST: "Chi phí",
        MONTH: "Tháng",
        YEAR: "Năm",
        DAY: "Ngày"
      }
  },
};
