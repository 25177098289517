import { Component, Input, OnInit } from '@angular/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as enLocale } from "./i18n/en";
import { locale as vnLocal } from "./i18n/vn";
@Component({
  selector: 'app-share-component',
  templateUrl: './share-component.component.html',
  styleUrls: ['./share-component.component.scss']
})
export class ShareComponentComponent implements OnInit {
  @Input() popup: boolean;
  constructor(
    public _coreTranslationService: CoreTranslationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
   }

  ngOnInit(): void {
  }

}
