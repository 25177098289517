<div class="slideout-content">
    <!-- Modal to add new user starts-->
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog modal-content pt-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="toggleSidebar('shared-user')">
                ×
            </button>
            <div class="modal-header mb-1">
                <h4 class="modal-title
                " [translate]="'RESUME_PARSER.DETAIL_RESUME.REMOVE_SHARED'">Remove shared user</h4>

            </div>
            <div class="modal-body flex-grow-1">
                <!-- Sidebar Users start -->
                <div id="users-list" class="chat-user-list-wrapper list-group">
                    <ul class="list-group list-group-flush" style="max-height: 90vh; overflow-y: auto;">
                        <li *ngFor="let _listShared of listShared;let i=index" class="list-group-item" id="history-search">
                            <div 
                                style="color: black;">
                                <div class="row align-items-center">
                                    <div class="col-2 mb-1">
                                        <span style="font-weight: 800">{{i+1}}</span>
                                    </div>
                                    <div class="col-8 mb-1">
                                        <span>{{_listShared?.share_to}}</span>
                                    </div>
                                    <div class="col-2 mb-1">
                                        <button class="btn btn-outline-danger text-nowrap px-1"
                                            (click)="removeSharedUser(_listShared)" rippleEffect title="Delete" type="button">
                                            <i data-feather="x" class="mr"></i>
                                            <!-- <span translate="DATA_MATCHING.DELETE"></span> -->
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </li>

                    </ul>
                </div>


            </div>
        </div>
    </div>
    <!-- Modal to add new user Ends-->
</div>