import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-search-history',
  templateUrl: './search-history.component.html',
  styleUrls: ['./search-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchHistoryComponent implements OnInit {
  @Input() historySearch;
  public users: User[] = []
  @Output() detailHistorySearch = new EventEmitter<any>();

  constructor(
    private _modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService,
    private _authenticationService: AuthenticationService,

  ) {
    
   }
   getListUser(){
    this._authenticationService.listUsers('').subscribe(
      (res)=>{
        this.users = res.results;
      }
    )
  }
  getUserEmail(userId: number) {
    var userEmail = "";
    this.users.forEach((user) => {
      if (user.id == userId) {
        userEmail = user.email;
      }
    });
    return userEmail;
  }
  detailSearchHistory(detailHistorySearch: any){
    this.detailHistorySearch.emit(detailHistorySearch)
  }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  ngOnInit(): void {
    this.getListUser()
  }

}
