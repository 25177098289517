import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { CoreConfigService } from "@core/services/config.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-auth-noti',
  templateUrl: './auth-noti.component.html',
  styleUrls: ['./auth-noti.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthNotiComponent implements OnInit {
  _unsubscribeAll: Subject<any>;
  coreConfig: any;
  constructor(private _coreConfigService: CoreConfigService) {
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  ngOnInit(): void {
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

}
