import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchResume } from '../models/resume.model';
import { CV_task, City } from '../models/job.model';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TalentPoolService } from '../services/talent-pool.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service/authentication.service';
const modalFadeAnimation = trigger("modalFadeAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
]);
@Component({
  selector: 'app-search-resume',
  templateUrl: './search-resume.component.html',
  styleUrls: ['./search-resume.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideFade", [
      state(
        "visible",
        style({
          bottom: "20px",
          opacity: 1,
          transform: "translateY(0%)",
        })
      ),
      state(
        "hidden",
        style({
          bottom: "-20px",
          opacity: 0,
          transform: "translateY(100%)",
        })
      ),
      transition("hidden => visible", [animate("0.5s ease-out")]),
      transition("visible => hidden", [animate("0.5s ease-in")]),
    ]),
    modalFadeAnimation,
  ],
})
export class SearchResumeComponent implements OnInit {
  public resultSearchCv: SearchResume[] = []

  public selectedCity: string[] = []
  public job_title: string = ''
  public city = [];

  public fulltext_bool_expression: string = ''
  public experience_bool_expression: string = ''
  public education_bool_expression: string = ''
  public current_title_bool_expression: string[] = []
  public customTitle: string[] = []

  public current_company_bool_expression: string[] = []
  public customCompany: string[] = []

  full_name_bool_expression: string = ''
  public skill: string[] = []
  public selectedCategory: string[] = []

  public page_num: number = 1
  public num_result = 0
  public num_page = 0
  public pageSizeNum = 12

  public errorMessagesSearchResume!: any
  public next_page: boolean = false;
  public page = 1
  public pageSize = 5
  public list_task: CV_task[] = []
  public save_cv: string = ''
  public loading = false
  public positive = false
  public negative = false
  public errorMessagesListTasksSave: any
  public errorMessagesSaveCv: any
  public arr_checkboxListTasks: string[] = []
  public hidden_menu = false

  formCheckBoxListTasks = this.fb.group({
    checkResumeArrayListTasks: this.fb.array([], Validators.required)
  })
  public arr_checkboxSaveCv: any

  formCheckBoxSaveCv = this.fb.group({
    checkResumeArraySaveCv: this.fb.array([], Validators.required)
  })
  historySearch: any[] = []
  public categoryItems: string[] = []
  public customSkill: string[] = [];
  public keyword: string[] = [];
  public customKeyword: string[] = []
  public years_of_experience_from: number = null
  public years_of_experience_to: number = null
  saveCandidatesForm = this.fb.group({
    checkCandidateArray: this.fb.array([])
  })
  public idCandidates: any[] = []
  public showActionsPopup: boolean = false;
  public list_all_jd: CV_task[] = []
  public searchText: string
  public user: User = new User()
  talent_pool = null
  talentPools: any
  formData: any
  constructor(
    private _router: Router,
    private fb: FormBuilder,
    private talentPoolService: TalentPoolService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _coreSidebarService: CoreSidebarService,
    private modalService: NgbModal,
    private resumesService: ResumeParserService,
    private toastr: ToastrService,
    private _authenticationService: AuthenticationService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
  }
  onSubmit() {
    this.page_num = 1
    this.SearchCv()
  }
  getListTalentPool() {
    let url_dynamic = 'owner__email=' + this.user.email
    this.resumesService.listTalentPool(url_dynamic).subscribe((res) => {
      this.talentPools = res
      // this.resumeService.setListFolderValue(this.talentPools)
    })
  }
  SearchCv() {
    this.loading = true
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.num_result = 0;
    this.resultSearchCv = []
    let formData = {}
    formData['page_num'] = this.page_num
    if (this.job_title) {
      formData['job_title'] = this.job_title
    }
    if (this.selectedCity.length > 0) {
      formData['city'] = this.selectedCity
    }
    if(this.talent_pool){
      formData['user_talentpool'] = this.talent_pool
    }
    if (this.fulltext_bool_expression) {
      formData['fulltext_bool_expression'] = this.fulltext_bool_expression
    }
    if (this.full_name_bool_expression) {
      formData['full_name_bool_expression'] = this.full_name_bool_expression
    }
    if (this.education_bool_expression) {
      formData['education_bool_expression'] = this.education_bool_expression
    }
    if (this.experience_bool_expression) {
      formData['experience_bool_expression'] = this.experience_bool_expression
    }
    if (this.current_title_bool_expression.length>0) {
      formData['current_title_list'] = this.current_title_bool_expression
    }
    if (this.current_company_bool_expression.length>0) {
      formData['current_company_list'] = this.current_company_bool_expression
    }
    if (this.selectedCategory.length > 0) {
      formData['category'] = this.selectedCategory
    }
    if (this.skill.length > 0) {
      formData['skills'] = this.skill
    }
    if(this.keyword.length > 0){
      formData['keywords'] = this.keyword
    }
    if (this.years_of_experience_from != null) {
      formData['years_of_experience_from'] = this.years_of_experience_from
    }
    if (this.years_of_experience_to != null) {
      formData['years_of_experience_to'] = this.years_of_experience_to
    }
    this.formData = formData
    if (this.job_title || this.talent_pool || this.selectedCity.length > 0 || this.selectedCategory.length>0 || this.fulltext_bool_expression || this.full_name_bool_expression || this.education_bool_expression || this.experience_bool_expression
      || this.current_title_bool_expression.length>0 || this.current_company_bool_expression.length>0 || this.skill.length>0 || this.years_of_experience_from || this.years_of_experience_to || this.keyword.length>0) {
      this.talentPoolService.search_resume(formData).subscribe(
        (res) => {
          this.resultSearchCv = res.result;
          this.talentPoolService.setNewListCandidate(res.result)
          this.next_page = res.next_page;
          this.num_result = res.num_result;
          this.num_page = res.num_result;
          this.saveFilter()
          this.loading = false
        },
        (err) => {
          this.errorMessagesSearchResume = err
        }
      )
    } else {
      this.talentPoolService.search_resume_raw(formData).subscribe(
        (res) => {
          this.resultSearchCv = res.result;
          this.talentPoolService.setNewListCandidate(res.result)
          this.next_page = res.next_page;
          this.num_result = res.num_result;
          this.num_page = res.num_result;
          this.saveFilter();
          this.loading = false
        },
        (err) => {
          this.errorMessagesSearchResume = err
        }
      )
    }
  }
  pageChangeEventGetCv(event: number) {
    this.page_num = event;
    this.saveFilter()
    this.SearchCv();
  }
  // searchCvRaw() {
  //   this.loading = true
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //   });
  //   this.num_result = 0;
  //   this.resultSearchCv = []
  //   this.talentPoolService.search_resume_raw().subscribe(
  //     (res) => {
  //       this.resultSearchCv = res.result;
  //       this.talentPoolService.setNewListCandidate(res.result)
  //       this.next_page = res.next_page;
  //       this.num_result = res.num_result;
  //       this.num_page = Math.min(res.num_result, 1200);
  //       this.loading = false
  //     },
  //     (err) => {
  //       this.errorMessagesSearchResume = err
  //     }
  //   )
  // }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    this.searchHistory();
  }
  searchDetailHistory(event: any) {
    this.loading = true
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.num_result = 0;
    this.resultSearchCv = []
    let formData = {}
    this.selectedCategory = event.category
    this.selectedCity = event.city
    this.fulltext_bool_expression = event.fulltext_bool_expression
    this.full_name_bool_expression = event.full_name_bool_expression
    this.education_bool_expression = event.education_bool_expression
    this.experience_bool_expression = event.experience_bool_expression
    this.current_title_bool_expression = event.current_title_bool_expression
    this.current_company_bool_expression = event.current_company_bool_expression
    this.skill = event.skills
    this.years_of_experience_from = event.years_of_experience_from
    this.years_of_experience_to = event.years_of_experience_to
    this.talent_pool = event.talent_pool
    this.keyword = event.keyword
    formData['page_num'] = this.page_num
    if (event.job_title) {
      formData['job_title'] = event.job_title
    }
    if (event.city.length > 0) {
      formData['city'] = event.city
    }
    if(event.talent_pool){
      formData['user_talentpool'] = event.talent_pool
    }
    if (event.fulltext_bool_expression) {
      formData['fulltext_bool_expression'] = event.fulltext_bool_expression
    }
    if (event.full_name_bool_expression) {
      formData['full_name_bool_expression'] = event.full_name_bool_expression
    }
    if (event.education_bool_expression) {
      formData['education_bool_expression'] = event.education_bool_expression
    }
    if (event.experience_bool_expression) {
      formData['experience_bool_expression'] = event.experience_bool_expression
    }
    if (event.current_title_bool_expression.length>0) {
      formData['current_title_bool_expression'] = event.current_title_bool_expression
    }
    if (event.current_company_bool_expression.length>0) {
      formData['current_company_bool_expression'] = event.current_company_bool_expression
    }
    if (event.category.length > 0) {
      formData['category'] = event.category
    }
    if (event.skills.length > 0) {
      formData['skills'] = event.skills
    }
    if (event.keywords.length > 0) {
      formData['keywords'] = event.keywords
    }
    if (event.years_of_experience_from != null) {
      formData['years_of_experience_from'] = event.years_of_experience_from
    }
    if (event.years_of_experience_to != null) {
      formData['years_of_experience_to'] = event.years_of_experience_to
    }
    this.formData = formData
    if (event.job_title || event.talent_pool || event.keywords.length>0 || event.city.length > 0 || event.category.length>0 || event.fulltext_bool_expression || event.full_name_bool_expression || event.education_bool_expression
      || event.experience_bool_expression || event.current_title_bool_expression.length>0 || event.current_company_bool_expression.length>0
      || event.skills.length>0 || event.years_of_experience_from || event.years_of_experience_to) {
      this.talentPoolService.search_resume(formData).subscribe(
        (res) => {
          this.resultSearchCv = res.result;
          this.talentPoolService.setNewListCandidate(res.result)
          this.next_page = res.next_page;
          this.num_result = res.num_result;
          this.num_page = res.num_result;
          this.saveFilter();
          this.loading = false
        },
        (err) => {
          this.errorMessagesSearchResume = err
        }
      )
    } else {
      this.talentPoolService.search_resume_raw(formData).subscribe(
        (res) => {
          this.resultSearchCv = res.result;
          this.talentPoolService.setNewListCandidate(res.result)
          this.next_page = res.next_page;
          this.num_result = res.num_result;
          this.num_page = res.num_result;
          this.saveFilter();
          this.loading = false
        },
        (err) => {
          this.errorMessagesSearchResume = err
        }
      )
    }
  }
  searchHistory() {
    this.talentPoolService.searchHistory().subscribe(
      (res) => {
        this.historySearch = res
      }
    )
  }
  returnManageResumePage() {
    this._router.navigate([`/manage-resume`]);
  }
  getCategory() {
    let url_dynamic_category = "category"
    this.resumesService.get_CHR_picklist(url_dynamic_category).subscribe(x => {
      this.categoryItems = x.map((e) => e.vn_label)
    })
  }
  selectAll(){
    this.idCandidates = this.resultSearchCv.map(x=>x.id)
    if (this.idCandidates.length > 0) {
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
    }
  }
  unSelectAll(){
    this.idCandidates = []
    if (this.idCandidates.length > 0) {
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
    }
  }
  onCheckboxChange(e: any) {
    let checkCandidateArray: FormArray = this.saveCandidatesForm.get('checkCandidateArray') as FormArray;
    if (e.target.checked) {
      checkCandidateArray.push(new FormControl(e.target.value));
      this.idCandidates.push(e.target.value)
    } else {
      let i: number = 0;
      checkCandidateArray.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkCandidateArray.removeAt(i);
          this.idCandidates = this.idCandidates.filter((item) => item !== e.target.value)
          return;
        }
        i++;
      })
    }
    if (this.idCandidates.length > 0) {
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
    }
  }
  resetTabTemporaryData() {
    this.idCandidates = [];
    this.showActionsPopup = false;
  }
  ListAllJd() {
    let owner = this.user.id
    let url_dynamic = '?owner=' + owner
    this.talentPoolService.list_all_jd(url_dynamic).subscribe(
      (res) => {
        this.list_all_jd = res;
        this.talentPoolService.setNewListJd(res)

      },
      (err) => {
        console.log("err", err)
      }
    )
  }
  modalOpenSaveCv(modalBasicSaveCv: any) {
    this.ListAllJd()
    this.modalService.open(modalBasicSaveCv, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  Search() {
    // alert(this.searchText)
    let resultJr_cp: any
    this.talentPoolService.listJd.subscribe(
      (res) => {
        resultJr_cp = res
      }
    )
    if (this.searchText !== "") {
      let searchValue = this.searchText.toLocaleLowerCase();
      this.list_all_jd = resultJr_cp.filter((resultJr_: any) => {
        return resultJr_.job_title.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here
      });
    }
    else {
      this.list_all_jd = resultJr_cp
    }
  }
  onCheckboxChangeSaveCv(e: any) {
    let checkResumeArraySaveCv: any = this.formCheckBoxSaveCv.get('checkResumeArraySaveCv');
    if (e.target.checked) {
      checkResumeArraySaveCv = new FormControl(e.target.value);
      this.arr_checkboxSaveCv = e.target.value
    } else {
      checkResumeArraySaveCv = new FormControl()
      this.arr_checkboxSaveCv = null
    }
  }
  FormCheckBoxSaveCv() {
    let job_description= parseInt(this.arr_checkboxSaveCv)
    this.talentPoolService.save_resume_by_jd(job_description, this.idCandidates).subscribe(
      (res) => {
        this.searchText = ''
        this.unselectAllRows()
      },
      (err) => {
        this.arr_checkboxSaveCv = null
        this.searchText = ''
        err.error.detail ? this.toastr.error(
          this._translateService.instant("TALENT_POOL.SEARCH_RESUME.NOT_FOUND")
        ) : this.toastr.error(err.error.message)
      }
    )
  }
  cancel() {
    this.searchText = ''
    this.arr_checkboxSaveCv = null
    this.idCandidates = []
  }
  unselectAllRows() {
    this.idCandidates = [];
    this.showActionsPopup = false;
    this.SearchCv()
  }
  saveFilter() {
    this.talentPoolService.setFilterCandidatesPageSearchValue(
      this.page_num,
      this.selectedCategory,
      this.skill,
      this.selectedCity,
      this.years_of_experience_from,
      this.years_of_experience_to,
      this.fulltext_bool_expression,
      this.full_name_bool_expression,
      this.experience_bool_expression,
      this.education_bool_expression,
      this.current_title_bool_expression,
      this.current_company_bool_expression,
      this.talent_pool,
      this.keyword
    );
  }
  ngOnInit(): void {
    this.user = this._authenticationService.currentUserValue
    const { pageSearch, selectedCategory, skill, selectedCity, years_of_experience_from,
      years_of_experience_to, fulltext_bool_expression, full_name_bool_expression, experience_bool_expression
      ,education_bool_expression, current_title_bool_expression, current_company_bool_expression, talent_pool, keyword
    } = this.talentPoolService.getFilterCandidatesPageSearchValue();
    this.page_num = pageSearch || 1;
    this.selectedCategory = selectedCategory || [];
    this.skill = skill || [];
    this.selectedCity = selectedCity || [];
    this.years_of_experience_from = years_of_experience_from || null;
    this.years_of_experience_to = years_of_experience_to || null;
    this.fulltext_bool_expression = fulltext_bool_expression || null;
    this.full_name_bool_expression = full_name_bool_expression || null;
    this.experience_bool_expression = experience_bool_expression || null;
    this.education_bool_expression = education_bool_expression || null;
    this.current_title_bool_expression = current_title_bool_expression || [];
    this.current_company_bool_expression = current_company_bool_expression || [];
    this.talent_pool = talent_pool || null;
    this.keyword = keyword || []
    // this.SearchCv()
    this.getCategory()
    this.getListTalentPool()
    this.SearchCv()
    City.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    let city = [{ "name": 'Hà Nội', "value": "Hà Nội" }, { "name": 'Hồ Chí Minh', "value": "Hồ Chí Minh" }, { "name": 'Đà Nẵng', "value": "Đà Nẵng" }]
    for (let i = 0; i < City.length; i++) {
      city.push({ "name": City[i], "value": City[i] })
    }
    city.push({"name": 'Khác', "value": 'Khác'})
    this.city = city
  }

}
