import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role, UserPermissionTalentPool } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { locale as enLocale } from "../../main/pages/account-settings/i18n/en"
import { locale as vnLocale } from "../../main/pages/account-settings/i18n/vn"
import { CoreTranslationService } from '@core/services/translation.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private BASE_URL = `${environment.apiUrl}/organization/user`;

  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  //public
  public token: Observable<string>;
  private tokenSubject: BehaviorSubject<string>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, 
    private _toastrService: ToastrService, 
    private router: Router,
    public _translateService: TranslateService,
    private _coreTranslateService: CoreTranslationService,
    ) {
      this._coreTranslateService.translate(enLocale);
      this._coreTranslateService.translate(vnLocale);
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("currentUser")||"{}"));
    this.currentUser = this.currentUserSubject.asObservable();

    this.tokenSubject = new BehaviorSubject<string>(localStorage.getItem("token"));
    this.token = this.tokenSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public setCurrentUserValue(value: User){
    this.currentUserSubject.next(value)
    localStorage.setItem("currentUser", JSON.stringify(value));

  }
  get tokenValue(): string{
    return this.tokenSubject.value;
  }
  public setTokenValue(value: string){
    this.tokenSubject.next(value)
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.User;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  // public methods
  login(email: string, password: string, remember: boolean) {
    const url = `${this.BASE_URL}/get-token/`;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return this._http.post<any>(url,formData, {
      headers: {
        Accept: "application/json; indent=4",
      },
    }).pipe(
      map((auth) => {
        
        //const result = this.setAuthFromLocalStorage_(auth);
        this.currentUserSubject.next(auth);
        if(remember){
          localStorage.setItem('email', auth.email);
          localStorage.setItem('password',password);
        }
        localStorage.setItem("currentUser", JSON.stringify(auth));
        // localStorage.setItem("first_name", auth.first_name);
        // localStorage.setItem("last_name", auth.last_name);
        // localStorage.setItem("email", auth.email);
        localStorage.setItem("token", auth.access);
        // localStorage.setItem("role", auth.role);
        // localStorage.setItem("company", auth.company);
        this.tokenSubject.next(auth.access);
        setTimeout(
          () =>
            this._toastrService.success(
              `${auth.email} 🎉`,
              this._translateService.instant("ACCOUNT_SETTING.LOGIN.TITLE"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            ),
          500
        );
        return auth;
      }),
      // switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        this._toastrService.error(this._translateService.instant("ACCOUNT_SETTING.LOGIN.LOGIN_ERROR"), "", {
          toastClass: "toast ngx-toastr",
          closeButton: true,
        });
        return of(undefined);
      })
    );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('currentPackage');
    localStorage.removeItem('listResume');
    localStorage.removeItem('talentPool');
    localStorage.removeItem('filter_documents_page_integration');
    localStorage.removeItem('list_user');
    localStorage.removeItem('filter_job_page_manage_job');
    localStorage.removeItem('filter_documents_page_data');
    localStorage.removeItem('filter_documents_page_talentpool');
    localStorage.removeItem('listJr');
    localStorage.removeItem('list_candidate');
    localStorage.removeItem('filter_candidates_page_search');
    localStorage.removeItem('loglevel');
    localStorage.removeItem('filter_candidates_page_detail_job');
    localStorage.removeItem('saved_cvs');
    localStorage.removeItem('filter_candidates_page_saved');
    localStorage.removeItem('filter_candidates_page_recommend');
    localStorage.removeItem('config');
    localStorage.removeItem('filter_documents_page_ja');
    localStorage.removeItem('idJd');
    localStorage.removeItem('optionFilterRecommendForm');
    localStorage.removeItem('list_jd');
    localStorage.removeItem('token');
    localStorage.removeItem('resultConnectSf4cBoolean');

    // notify
    // localStorage.clear();
    this.tokenSubject.next('');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login'], {
      queryParams: {},
    });
  }
  listUsers(dynamic_string: any): Observable<any>{
    const url = `${this.BASE_URL}`+`${dynamic_string}`;
    return this._http.get<any>(url)
  }
  changePassword(formData: any):Observable<any>{
    const url = `${this.BASE_URL}/change-password`;
    return this._http.put<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
            this._translateService.instant("ACCOUNT_SETTING.PASSWORD.TITLE"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          localStorage.removeItem('password');
          this.logout()
          return respData;
      }),
    )
  }
  updateUser(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
            this._translateService.instant("ACCOUNT_SETTING.ACTIVITY_HISTORY.EDIT_USER"),
              
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  detailUser(id: number): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.get<any>(url) 
  }
  resetPassword(id: number): Observable<any>{
    const url = `${this.BASE_URL}/${id}/reset-password`;
    return this._http.put<any>(url,{})
  }
  createUser(formData: any): Observable<any>{
    const url = `${this.BASE_URL}/register/`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
            this._translateService.instant("ACCOUNT_SETTING.ACTIVITY_HISTORY.CREATE_USER"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  adminUpdateUser(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}`;
    return this._http.put<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
            this._translateService.instant("ACCOUNT_SETTING.ACTIVITY_HISTORY.EDIT_USER"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  deleteUser(id: any, assign_to: any): Observable<any>{
    const url = `${this.BASE_URL}/${id}/delete?assign_to=${assign_to}`;
    return this._http.delete<any>(url).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("ACCOUNT_SETTING.CONNECT_SF4C.TOAST.SUCCESS"),
            this._translateService.instant("ACCOUNT_SETTING.ACTIVITY_HISTORY.DELETE_USER"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Talent pool
  update_user_permission_talent_pool(id: any, is_accessible: boolean): Observable<UserPermissionTalentPool>{
    const url = `${environment.apiUrl}/talent-pool/user-setting/${id}/`;
    return this._http.put<UserPermissionTalentPool>(url,{is_accessible}).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
              "Success",
              `Update user permission talent pool`,
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //List company
  list_company(): Observable<any>{
    const url = `${environment.apiUrl}/organization/company`;
    return this._http.get<any>(url)
  }
  listSubCompany(company_id: any): Observable<any>{
    const url = `${environment.apiUrl}/organization/sub_company?company_id=${company_id}`;
    return this._http.get<any>(url)
  }
  updateCompany(id: any, formData: any): Observable<any>{
    const url = `${environment.apiUrl}/organization/company/${id}`;
    return this._http.put<any>(url, formData)
  }
  forgotPassword(email: string) {
    const formData = new FormData();
    formData.append("email", email);
    return this._http.post(`${environment.apiUrl}/organization/user/reset-password`, formData);
  }
}
