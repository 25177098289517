export enum Role {
  Admin = 'Admin',
  SuperUser = 'Super user',
  User = 'User',
  FreeTrial = 'Free trial user',
  SuperAdmin = 'Super admin'
}
// export enum Permissions {
//   SF4C_PERMISSIONS = true,
//   // SEARCH_ENGINE = 'search_engine'
// }
export enum CurrentPackage { 
  FREE_TRIAL = 'Free trial',
  BASIC_PARSING = 'Basic Parsing',
  BASIC = 'Basic',
  ADVANCED_PARSING = 'Advanced Parsing',
  ADVANCED = 'Advanced',
  ENTERPRISE = 'Enterprise'
}