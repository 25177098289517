<div class="row justify-content-between align-items-start">
    <div class="row col-md-8 col-xl-9 col-xxl-9">
        <div class="row w-100">
            <div class="col-md-3 col-xxl-3">
                <div
                    *ngIf="ResultSearchCv.avatar!=null && !ResultSearchCv.avatar.includes('https://media-exp1.licdn.com'); else customAvatar">

                    <img [src]="ResultSearchCv.avatar" [alt]="ResultSearchCv.avatar" height="80px" width="80px"
                        style="border-radius: 50%;object-fit: cover;" />
                </div>

                <ng-template #customAvatar>
                    <img src="assets/images/avatars/iconPersion.png" height="80px" width="80px"
                        style="border-radius: 50%;object-fit: cover;" />
                </ng-template>
            </div>

            <div class="col-md-9 col-xxl-9">
                <a type="button" class="text-primary mr-1"
                        *ngIf="jd_id && hcm_system_jd && pageSaved"
                        (click)="toDetailResume(ResultSearchCv.id)">{{ResultSearchCv.last_name}}
                        {{ResultSearchCv.first_name}}</a>
                    <a class="mr-1"
                        *ngIf="jd_id && hcm_system_jd && !pageSaved"
                        routerLink="/manage-job/{{jd_id}}/recommend-resume/detail-resume/{{ResultSearchCv.id}}">{{ResultSearchCv.last_name}}
                        {{ResultSearchCv.first_name}}</a>
                    <a type="button" class="text-primary mr-1"
                        *ngIf="jd_id && !hcm_system_jd && pageSaved"
                        (click)="toDetailResume(ResultSearchCv.id)">{{ResultSearchCv.last_name}}
                        {{ResultSearchCv.first_name}}</a>
                    <a class="mr-1"
                        *ngIf="jd_id && !hcm_system_jd && !pageSaved"
                        routerLink="/manage-job/{{jd_id}}/recommend-resume/detail-resume/{{ResultSearchCv.id}}">{{ResultSearchCv.last_name}}
                        {{ResultSearchCv.first_name}}</a>
                    <a class="mr-1" *ngIf="!jd_id"
                        routerLink="/search-resume/detail-resume/{{ResultSearchCv.id}}">{{ResultSearchCv.last_name}}
                        {{ResultSearchCv.first_name}}</a>
                        <span *ngIf="!ResultSearchCv.is_seen && jd_id && pageSaved"
                        class="badge badge-pill badge-light-warning font-weight-bolder"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.NEW'"></span>
                    <span *ngIf="ResultSearchCv.score_detail" class="badge badge-pill badge-light-{{status_color[ResultSearchCv.score_detail.matching_status]}}"
                        style="font-size: small">
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Approved'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.APPROVED'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Duplicated'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.DUPLICATED'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Done'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.DONE'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Failure'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.FAILURE'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Processing'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.PROCESSING'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Wait to process'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.WAIT_TO_PROCESS'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Suspended'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.SUSPENDED'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Modified'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.MODIFIED'"></span>

                    </span>
                <!-- <p *ngIf="ResultSearchCv.last_name && ResultSearchCv.first_name"
                    style="font-weight: 600;margin-bottom: 0px;">{{ResultSearchCv.last_name}}
                    {{ResultSearchCv.first_name}} <span *ngIf="!ResultSearchCv.is_seen"
                        class="badge badge-pill badge-light-warning font-weight-bolder"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.NEW'"></span>
                    <span *ngIf="ResultSearchCv.score_detail" class="badge badge-pill badge-light-{{status_color[ResultSearchCv.score_detail.matching_status]}}"
                        style="font-size: small">
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Approved'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.APPROVED'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Duplicated'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.DUPLICATED'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Done'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.DONE'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Failure'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.FAILURE'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Processing'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.PROCESSING'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Wait to process'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.WAIT_TO_PROCESS'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Suspended'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.SUSPENDED'"></span>
                        <span *ngIf="ResultSearchCv.score_detail.matching_status.replaceAll('_',' ')=='Modified'"
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS_COLOR.MODIFIED'"></span>

                    </span>
                </p> -->
                <!-- <ng-container *ngFor="let currentTitle of currentTitles;let idx =index;">
                    <p style="font-weight: 600;margin-bottom: 0px;">{{currentTitle}} <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.AT'"></span> {{currentCompanies[idx]}}</p>
                </ng-container> -->
                <br>
                <span *ngIf="ResultSearchCv.address" class="fa fa-globe" style="margin-right: 5px"></span><span
                   >{{ResultSearchCv.address}}</span>
            </div>
        </div>
        <div class="row w-100 mt-1">
            <div class="col-md-3 col-xxl-3">
                <p class="" style="font-weight: 600;" [translate]="'TALENT_POOL.RESUME_TALENT_POOL.EXPERIENCE'"></p>
            </div>
            <div class="col-md-9 col-xxl-9">
                <div class="" *ngIf="ResultSearchCv.experience.length>0">
                    <div *ngFor="let experience of ResultSearchCv.experience">
                        <p style="margin-bottom: 0px;">- <span class="font-weight-bolder"
                                *ngIf="experience.position;else NO_POSITION">{{experience.position}} </span>
                            <ng-template #NO_POSITION>
                                <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.WORK'"></span>
                            </ng-template>
                            <span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.AT'"></span> <span
                                class="font-weight-bolder" *ngIf="experience.company"> {{experience.company}} </span>
                            <span *ngIf="experience.start_time||experience.end_time">({{experience.start_time|date: "dd/MM/yyyy"}}<span
                                    *ngIf="experience.end_time">-</span>{{experience.end_time|date: "dd/MM/yyyy"}})</span>
                        </p>

                    </div>
                    <div>
                        <a (click)="modalOpenDetailExperience(modalBasicDetailExperience)" style="text-decoration: underline;" href="javascript:void(0)">
                            Xem thêm
                        </a>
                        <ng-template #modalBasicDetailExperience let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" [translate]="'TALENT_POOL.DETAIL_CANDIDATE.WORK_EXPERIENCE'"></h4>
                                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="card-body" style="overflow-y: auto;">
                                <ul class="timeline"
                                    *ngFor="let experience of ResultSearchCv.experience">
                                    <li class="timeline-item mt-1">
                                        <span class="timeline-point timeline-point-indicator"></span>
                                        <div class="timeline-event">
                                            <div
                                                class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                                <div><span class="font-weight-bolder"
                                                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.POSITION'"></span>:
                                                    {{experience.position}}</div>

                                                <span
                                                    class="timeline-event-time">{{experience.start_time|date: "dd/MM/yyyy"}}<span *ngIf="experience.start_time && experience.end_time"> - </span>{{experience.end_time|date:"dd/MM/yyyy"}}</span>
                                            </div>
                                            <div><span class="font-weight-bolder"
                                                    [translate]="'TALENT_POOL.DETAIL_CANDIDATE.COMPANY'"></span>:
                                                {{experience.company}}</div>
                                            <div><span
                                                    class="font-weight-bolder"
                                                    [translate]="'TALENT_POOL.DETAIL_CANDIDATE.INDUSTRY'"></span>:
                                                {{experience.industry}}</div>
                                            
                                            <div><span class="font-weight-bolder"
                                                    [translate]="'TALENT_POOL.DETAIL_CANDIDATE.JOB_DESCRIPTION'"></span>:
                                            </div>
                                            <div class="media align-items-center">
                                                <div class="media-body ml-1"
                                                    [innerHTML]="experience.detail?.trim().split('\n').join('<br>')">
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </ng-template>
                    </div>
                    
                </div>
            </div>

        </div>
        <!-- <div class="col-md-3 col-xxl-3">
            <p style="font-weight: 600;" [translate]="'TALENT_POOL.RESUME_TALENT_POOL.EDUCATION'"></p>
        </div>
        <div class="col-md-9 col-xxl-9">
            <div *ngFor="let education of ResultSearchCv.educations">
                <div [innerHtml]="education" class="candidate-education"></div>
            </div>
        </div> -->
    </div>
    <div class="text-center col-xl-3 col-md-4 col-xxl-3">
        <div class="row justify-content-center">
            <div class="mt-1" *ngIf="user?.email!=ResultSearchCv?.owner && !ResultSearchCv.is_shared;else SHARED">
                <button type="button" style="background-color: #7367f0" (click)="requestToShare()"
                    class="btn btn-detail btn-sm text-light"><span
                        [translate]="'TALENT_POOL.DETAIL_CANDIDATE.SHARE_RESUME'"></span></button>
            </div>
            <ng-template #SHARED>
                <div class="mt-1" style="margin-right: 2px;">
                    <a class="btn btn-primary btn-detail btn-sm" type="button"
                        *ngIf="jd_id && hcm_system_jd && pageSaved"
                        (click)="toDetailResume(ResultSearchCv.id)"><span
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
                    <a class="btn btn-primary btn-detail btn-sm" type="button"
                        *ngIf="jd_id && hcm_system_jd && !pageSaved"
                        routerLink="/manage-job/{{jd_id}}/recommend-resume/detail-resume/{{ResultSearchCv.id}}"><span
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
                    <a class="btn btn-primary btn-detail btn-sm" type="button"
                        *ngIf="jd_id && !hcm_system_jd && pageSaved"
                        (click)="toDetailResume(ResultSearchCv.id)"><span
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
                    <a class="btn btn-primary btn-detail btn-sm" type="button"
                        *ngIf="jd_id && !hcm_system_jd && !pageSaved"
                        routerLink="/manage-job/{{jd_id}}/recommend-resume/detail-resume/{{ResultSearchCv.id}}"><span
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
                    <a class="btn btn-primary btn-detail btn-sm" type="button" *ngIf="!jd_id"
                        routerLink="/search-resume/detail-resume/{{ResultSearchCv.id}}"><span
                            [translate]="'TALENT_POOL.RESUME_TALENT_POOL.DETAIL'"></span></a>
                </div>
            </ng-template>
            <!-- <br *ngIf="ResultSearchCv.full_name!=null">
            <ng-container *ngIf="ResultSearchCv.full_name!=null && !saved"><span [translate]="'TALENT_POOL.RESUME_TALENT_POOL.STATUS'"> </span><span class="badge bg-{{status_color[ResultSearchCv.status]}}">{{ResultSearchCv.status}}</span></ng-container> -->


            <div class="mt-1" style="margin-right: 2px;">
                <button *ngIf="jd_id!=null && !saved && !pageSaved" type="button"
                    (click)="SaveResumeByJd(ResultSearchCv.id)" class="btn btn-success btn-sm btn-save"><span
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.SAVE'"></span></button>
                <button *ngIf="pageSaved" type="button" (click)="UnsaveResumeByJd(ResultSearchCv.id)"
                    class="btn btn-danger btn-sm btn-delete"><span
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.UNSAVE'"></span></button>
                <p *ngIf="jd_id!=null && saved" class="badge bg-success"
                    [translate]="'TALENT_POOL.RESUME_TALENT_POOL.SAVED'"></p>

                <button *ngIf="jd_id==null && !saved" type="button" (click)="modalOpenSaveCv(modalBasicSaveCv)"
                    class="btn btn-success btn-sm btn-save"><span
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.SAVE'"></span></button>
            </div>
            <div class="mt-1">
                <button type="button" (click)="modalSf4cStatusCandidate(modalBasicSf4cStatus,{id: ResultSearchCv.candidate})"
                    class="btn btn-info btn-sm btn-save"><span [translate]="'TALENT_POOL.SEARCH_RESUME.STATUS'">
                    </span><i class="fa fa-info-circle" style="margin-left: 1px;"
                        [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.TITLE_SF4C_STATUS'|translate"></i></button>
            </div>
            <ng-template #modalBasicSaveCv let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" [translate]="'TALENT_POOL.SEARCH_RESUME.SELECT_JD'"></h4>
                    <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
                </div>
                <div class="me-4 mt-3">
                    <div class="d-flex justify-content-end">

                        <div class="col-md-6">
                            <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                            <input type="text" class="form-control" id="job-search"
                                [placeholder]="'TALENT_POOL.SEARCH_RESUME.SEARCH_JOB' | translate"
                                [(ngModel)]="searchText" (input)="Search()" />
                        </div>

                    </div>
                </div>
                <form [formGroup]="formCheckBoxSaveCv">
                    <div class="modal-body" ngbAutofocus>
                        <!-- <ng-container *ngIf="list_all_jd.length===0">
                            <p [translate]="'TALENT_POOL.SEARCH_RESUME.NOT_JOB'"> <a routerLink="/manage-job/create-job" [translate]="'TALENT_POOL.SEARCH_RESUME.CREATE_JOB'"></a> </p>
                        </ng-container> -->
                        <ng-container>

                            <table class="table align-middle table-bordered">
                                <thead>
                                    <tr>
                                        <th class="pxp-is-checkbox" style="width: 1%;"></th>
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.JOB'"></th>
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.JR_ID_CHR'">
                                        </th>
                                        <!-- <th [translate]="'TALENT_POOL.MANAGE_JOB.CATEGORY'"></th> -->
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.TYPE'"></th>
                                        <th [translate]="'TALENT_POOL.MANAGE_JOB.LEVEL'"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr
                                        *ngFor="let list_all_jd_ of list_all_jd| slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize">
                                        <td *ngIf="arr_checkboxSaveCv==list_all_jd_.id;else noChecked">
                                            <input [value]="list_all_jd_.id" (change)="onCheckboxChangeSaveCv($event)"
                                                type="checkbox" class="form-check" checked>

                                        </td>
                                        <ng-template #noChecked>
                                            <td>
                                                <input [value]="list_all_jd_.id"
                                                    (change)="onCheckboxChangeSaveCv($event)" type="checkbox"
                                                    class="form-check">

                                            </td>
                                        </ng-template>


                                        <td>
                                            <div class="">{{list_all_jd_.job_title}}</div>
                                        </td>
                                        <td>
                                            <div class="">{{list_all_jd_.id}}
                                            </div>
                                        </td>
                                        <!-- <td>
                                            <div class="">{{list_all_jd_.industry}}</div>
                                        </td> -->
                                        <td>
                                            <div class="">{{list_all_jd_.job_type}}</div>
                                        </td>
                                        <td>
                                            <div class="">{{list_all_jd_.job_level}}</div>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">

                                    <nav class="mt-2 mt-sm-0" aria-label="Jobs list pagination">

                                        <ngb-pagination [collectionSize]="list_all_jd.length" [(page)]="page"
                                            [pageSize]="pageSize" [maxSize]="2" [rotate]="true" [boundaryLinks]="true">


                                        </ngb-pagination>
                                    </nav>
                                </div>

                            </div>
                        </ng-container>

                    </div>

                    <div class="modal-footer">

                        <button [disabled]="!arr_checkboxSaveCv" (click)="FormCheckBoxSaveCv(ResultSearchCv.id)"
                            class="btn btn-primary mr-1" rippleEffect (click)="modal.dismiss('Cross click')"
                            [translate]="'TALENT_POOL.SEARCH_RESUME.SAVE'">

                        </button>
                        <button (click)="modal.dismiss('Cross click')" type="button" (click)="cancel()" rippleEffect
                            class="btn btn-secondary" [translate]="'TALENT_POOL.CREATE_JOB.CANCEL'"></button>

                    </div>
                </form>
            </ng-template>
            <ng-template #modalBasicSf4cStatus let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" [translate]="'TALENT_POOL.SEARCH_RESUME.TITLE_SF4C_STATUS'"></h4>
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="me-4 mt-3">
                    <div id="users-list" class="chat-user-list-wrapper list-group">
                        <div *ngIf="isLoading;else noLoading" id="loading" style="margin: 50px auto">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <ng-template #noLoading>
                            <ul class="list-group list-group-flush" style="max-height: 90vh; overflow-y: auto;"
                                *ngIf="sf4cCandidate.length>0;else noResult">

                                <li *ngFor="let _sf4cCandidate of sf4cCandidate;let i=index" class="list-group-item"
                                    id="history-search">
                                    <div style="color: black;">
                                        <div class="row align-items-center">
                                            <div class="col-2 mb-1 font-weight-bolder">
                                                <span>{{i+1}}</span>
                                            </div>
                                            <div class="col-10 mb-1">
                                                <div class="d-flex align-items-center">
                                                    <div class="col-4 font-weight-bolder">
                                                        <p [translate]="'TALENT_POOL.SEARCH_RESUME.JOB_ID'"></p>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{_sf4cCandidate?.job_id}}</p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="col-4 font-weight-bolder">
                                                        <p [translate]="'TALENT_POOL.SEARCH_RESUME.JOB_NAME'"></p>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{_sf4cCandidate?.job_name}}</p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="col-4 font-weight-bolder">
                                                        <p [translate]="'TALENT_POOL.SEARCH_RESUME.JOB_STATUS'"></p>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{_sf4cCandidate?.job_status}}</p>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="col-4 font-weight-bolder">
                                                        <p [translate]="'TALENT_POOL.SEARCH_RESUME.CANDIDATE_STATUS'">
                                                        </p>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{_sf4cCandidate?.candidate_status}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ng-template #noResult>
                                <p class="text-center" [translate]="'TALENT_POOL.SEARCH_RESUME.NO_RESULT'"></p>
                            </ng-template>
                        </ng-template>

                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button (click)="modal.dismiss('Cross click')" type="button" (click)="cancel()" rippleEffect
                        class="btn btn-secondary mr-1" [translate]="'TALENT_POOL.CREATE_JOB.CANCEL'"></button>
                    <button type="submit" class="btn btn-primary" rippleEffect (click)="modal.dismiss('Cross click')"
                        [translate]="'TALENT_POOL.CREATE_JOB.SUBMIT'">
    
                    </button> -->

                </div>
            </ng-template>
            
        </div>
        <div class="mt-1" *ngIf="!pageSaved">
            <button (click)="deleteReportUnmatch()" rippleEffect *ngIf="liked==true && search_history" placement="bottom"
                container="body" [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.MATCHED' | translate" class="btn btn-icon btn-sm btn-light liked">
                <i data-feather="thumbs-up" size="20"></i>
            </button>
            <button *ngIf="liked!=true && search_history" (click)="reportUnmatchResume(true)" rippleEffect class="btn btn-icon btn-sm btn-light" placement="bottom"
                container="body" [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.MATCH' | translate">
                <i data-feather="thumbs-up" size="20"></i>
            </button>
            <button (click)="deleteReportUnmatch()" rippleEffect *ngIf="liked==false && search_history" style="margin-left: 2px;" placement="bottom"
                container="body" [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.NOT_MATCHED' | translate" class="btn btn-icon btn-sm btn-light liked">
                <i data-feather="thumbs-down" size="20"></i>
            </button>
            <button *ngIf="liked!=false && search_history" style="margin-left: 2px;" (click)="modalReportUnmatch(modalBasicReportUnmatch)" rippleEffect class="btn btn-icon btn-sm btn-light" placement="bottom"
                container="body" [ngbTooltip]="'TALENT_POOL.SEARCH_RESUME.NOT_MATCH' | translate">
                <i data-feather="thumbs-down" size="20"></i>
            </button>
            <!-- <button *ngIf="(liked==true || liked==false) && search_history" style="margin-left: 2px;" (click)="deleteReportUnmatch()" rippleEffect class="btn btn-icon btn-sm btn-light" placement="bottom"
                container="body" [ngbTooltip]="'TALENT_POOL.MANAGE_JOB.MODAL.DELETE_REPORT' | translate">
                <i data-feather="x" size="20"></i>
            </button> -->
        </div>
        <ng-template #modalBasicReportUnmatch let-modal>
            <div class="modal-header">
                <h4 class="modal-title" [translate]="'TALENT_POOL.SEARCH_RESUME.REPORT_INFO'"></h4>
            </div>
            <form (ngSubmit)="(ReportUnmatch.form.valid)" #ReportUnmatch="ngForm">
                <div class="modal-body" ngbAutofocus>
                    <ng-container>
                        <div class="form-group mb-0">
                            
                            <div>
                                <textarea (keydown.enter)="reportUnmatchResume(false)" rows="3" [(ngModel)]="comment" type="text"
                                    class="form-control" id="comment"
                                    name="comment"
                                ></textarea>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button (click)="reportUnmatchResume(false)"
                        class="btn btn-primary mr-1" rippleEffect
                        [translate]="'TALENT_POOL.SEARCH_RESUME.EVALUATE'">
                    </button>
                    <button (click)="modal.dismiss('Cross click')" type="button" (click)="cancelReport()" rippleEffect
                        class="btn btn-secondary" [translate]="'TALENT_POOL.CREATE_JOB.CANCEL'"></button>
                </div>
            </form>
        </ng-template>
        <div class="mt-1">
            <span class="font-weight-bolder" [translate]="'TALENT_POOL.RESUME_TALENT_POOL.OWNER'"></span> <br>
            <span>{{ResultSearchCv.owner}}</span>

        </div>
        <div class="mt-1">
            <span class="font-weight-bolder" [translate]="'TALENT_POOL.RESUME_TALENT_POOL.MODIFIED_AT'"></span> <span class="font-weight-bolder">:</span> <br>
            <span>{{ResultSearchCv.modified_at|date: "dd/MM/yyyy"}}</span>

        </div>
        <div class="mt-1" *ngIf="jd_id!=null && ResultSearchCv.score_detail && pageSaved">
            
            <div class="row mt-1" *ngIf="ResultSearchCv.score_detail">
                <div class="col-6">
                    <div class="text-end font-weight-bolder row justify-content-center"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.OVERALL_SCORE'">
                    </div>
                    <div class="row justify-content-center">
                        <app-circle-chart [overallScore]="ResultSearchCv.score_detail.overall_score*100">
                        </app-circle-chart>
                    </div>
                </div>
                <div class="col-6">
                    <div class="text-end font-weight-bolder row justify-content-center"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.EXPERIENCE_SCORE'">
                    </div>
                    <div class="progress-wrapper">
                        <ngb-progressbar showValue="true" [type]="{
                    'success':ResultSearchCv.score_detail.experience_score>0.8,
                    'warning':ResultSearchCv.score_detail.experience_score<0.8 && ResultSearchCv.score_detail.experience_score>0.5,
                    'danger':ResultSearchCv.score_detail.experience_score<0.5
                }" [ngClass]="{
                    'progress-bar-success':ResultSearchCv.score_detail.experience_score>0.8,
                    'progress-bar-warning':ResultSearchCv.score_detail.experience_score<0.8 && ResultSearchCv.score_detail.experience_score>0.5,
                    'progress-bar-danger':ResultSearchCv.score_detail.experience_score<0.5
                }" [height]="progressbarHeight" [max]="1"
                            [value]="ResultSearchCv.score_detail.experience_score | number: '1.2-2'"></ngb-progressbar>
                    </div>
                    <div class="text-end font-weight-bolder row justify-content-center mt-1"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.EDUCATION_SCORE'">
                    </div>
                    <div class="progress-wrapper">
                        <ngb-progressbar showValue="true" [type]="{
                    'success':ResultSearchCv.score_detail.education_score>0.8,
                    'warning':ResultSearchCv.score_detail.education_score<0.8 && ResultSearchCv.score_detail.education_score>0.5,
                    'danger':ResultSearchCv.score_detail.education_score<0.5
                }" [ngClass]="{
                    'progress-bar-success':ResultSearchCv.score_detail.education_score>0.8,
                    'progress-bar-warning':ResultSearchCv.score_detail.education_score<0.8 && ResultSearchCv.score_detail.education_score>0.5,
                    'progress-bar-danger':ResultSearchCv.score_detail.education_score<0.5
                }" [height]="progressbarHeight" [max]="1"
                            [value]="ResultSearchCv.score_detail.education_score | number: '1.2-2'"></ngb-progressbar>
                    </div>
                    <div class="text-end font-weight-bolder row justify-content-center mt-1"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.LANGUAGE_SCORE'">
                    </div>
                    <div class="progress-wrapper">
                        <ngb-progressbar showValue="true" [type]="{
                    'success':ResultSearchCv.score_detail.language_score>0.8,
                    'warning':ResultSearchCv.score_detail.language_score<0.8 && ResultSearchCv.score_detail.language_score>0.5,
                    'danger':ResultSearchCv.score_detail.language_score<0.5
                }" [ngClass]="{
                    'progress-bar-success':ResultSearchCv.score_detail.language_score>0.8,
                    'progress-bar-warning':ResultSearchCv.score_detail.language_score<0.8 && ResultSearchCv.score_detail.language_score>0.5,
                    'progress-bar-danger':ResultSearchCv.score_detail.language_score<0.5
                }" [height]="progressbarHeight" [max]="1"
                            [value]="ResultSearchCv.score_detail.language_score | number: '1.2-2'"></ngb-progressbar>
                    </div>
                </div>
            </div>



        </div>
        <div class="mt-1" *ngIf="jd_id!=null && ResultSearchCv.score_detail && !pageSaved">
            

            <div class="mt-1" *ngIf="ResultSearchCv.score_detail && !pageSaved">
                <div class="">
                    <div class="text-end font-weight-bolder row justify-content-center"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.OVERALL_SCORE'">
                    </div>
                    <div class="row justify-content-center">
                        <app-circle-chart [overallScore]="ResultSearchCv.score_detail.overall_score*100">
                        </app-circle-chart>
                    </div>
                </div>
                <div class="">
                    <div class="text-end font-weight-bolder row justify-content-center"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.EXPERIENCE_SCORE'">
                    </div>
                    <div class="progress-wrapper">
                        <ngb-progressbar showValue="true" [type]="{
                    'success':ResultSearchCv.score_detail.experience_score>0.8,
                    'warning':ResultSearchCv.score_detail.experience_score<0.8 && ResultSearchCv.score_detail.experience_score>0.5,
                    'danger':ResultSearchCv.score_detail.experience_score<0.5
                }" [ngClass]="{
                    'progress-bar-success':ResultSearchCv.score_detail.experience_score>0.8,
                    'progress-bar-warning':ResultSearchCv.score_detail.experience_score<0.8 && ResultSearchCv.score_detail.experience_score>0.5,
                    'progress-bar-danger':ResultSearchCv.score_detail.experience_score<0.5
                }" [height]="progressbarHeight" [max]="1"
                            [value]="ResultSearchCv.score_detail.experience_score | number: '1.2-2'"></ngb-progressbar>
                    </div>
                    <div class="text-end font-weight-bolder row justify-content-center mt-1"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.EDUCATION_SCORE'">
                    </div>
                    <div class="progress-wrapper">
                        <ngb-progressbar showValue="true" [type]="{
                    'success':ResultSearchCv.score_detail.education_score>0.8,
                    'warning':ResultSearchCv.score_detail.education_score<0.8 && ResultSearchCv.score_detail.education_score>0.5,
                    'danger':ResultSearchCv.score_detail.education_score<0.5
                }" [ngClass]="{
                    'progress-bar-success':ResultSearchCv.score_detail.education_score>0.8,
                    'progress-bar-warning':ResultSearchCv.score_detail.education_score<0.8 && ResultSearchCv.score_detail.education_score>0.5,
                    'progress-bar-danger':ResultSearchCv.score_detail.education_score<0.5
                }" [height]="progressbarHeight" [max]="1"
                            [value]="ResultSearchCv.score_detail.education_score | number: '1.2-2'"></ngb-progressbar>
                    </div>
                    <div class="text-end font-weight-bolder row justify-content-center mt-1"
                        [translate]="'TALENT_POOL.RESUME_TALENT_POOL.LANGUAGE_SCORE'">
                    </div>
                    <div class="progress-wrapper">
                        <ngb-progressbar showValue="true" [type]="{
                    'success':ResultSearchCv.score_detail.language_score>0.8,
                    'warning':ResultSearchCv.score_detail.language_score<0.8 && ResultSearchCv.score_detail.language_score>0.5,
                    'danger':ResultSearchCv.score_detail.language_score<0.5
                }" [ngClass]="{
                    'progress-bar-success':ResultSearchCv.score_detail.language_score>0.8,
                    'progress-bar-warning':ResultSearchCv.score_detail.language_score<0.8 && ResultSearchCv.score_detail.language_score>0.5,
                    'progress-bar-danger':ResultSearchCv.score_detail.language_score<0.5
                }" [height]="progressbarHeight" [max]="1"
                            [value]="ResultSearchCv.score_detail.language_score | number: '1.2-2'"></ngb-progressbar>
                    </div>
                </div>
            </div>



        </div>

    </div>
</div>