<!-- <div class="overlay-header position-relative">
    <div
      class="container d-flex justify-content-center align-items-center block1"
      style="flex-direction: column"
    >
      
      <div class="row d-flex">
        <h1 class="under-nav text-white" [translate]="'INTRODUCE.TITLE'"></h1>
        <h4 class="mb-5 text-white" [translate]="'INTRODUCE.SUB_TITLE'"></h4>
        <div class="row d-flex">
          <img class="video" src="/assets/images/landing-page/bgr3.png" alt="img-video" />
        </div>
      
      </div>
    </div>
</div> -->
  <div class="overlay-header" style="background-image: url('/assets/images/landing-page/Recruitment.avif')">
    <div class="title-text">
      <h1 class="under-nav text-white" style="font-size: 56px;" [translate]="'INTRODUCE.TITLE'"></h1>
        <h3 class="mb-5 text-white" [translate]="'INTRODUCE.SUB_TITLE'"></h3>
    </div>
  </div>