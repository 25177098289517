import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {
  private BASE_URL = `${environment.apiUrl}`;

  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    public _translateService: TranslateService,

  ) { }
  //get list api key
  listApiKey(): Observable<any>{
    const url = `${this.BASE_URL}/organization/access-token`;
    return this._http.get<any>(url)
  }
  //Obtain access token
  obtainAccessToken(formData:any): Observable<any>{
    const url = `${this.BASE_URL}/organization/obtain-access-token`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("CREATE_API_KEY.CREATE"),
            this._translateService.instant("TOASTR.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Delete api key
  deleteApiKey(id:string): Observable<any>{
    const url = `${this.BASE_URL}/organization/access-token/${id}`;
    return this._http.delete<any>(url).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("MODAL.DELETE_A"),
            this._translateService.instant("TOASTR.SUCCESS"), 
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
}
