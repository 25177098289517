import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DetailResume, ListResumeMatching, ResultMatchingCV, ResultSearchResume, ResultSyncSf4cJr, SaveResumeByJd, SearchResume } from '../models/resume.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { CV_task } from '../models/job.model';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { Page } from 'app/main/resume-parser/models/paging.model';
const FILTER_CANDIDATES_PAGE_SEARCH = "filter_candidates_page_search";
const FILTER_CANDIDATES_PAGE_RECOMMEND = "filter_candidates_page_recommend";
const FILTER_CANDIDATES_PAGE_SAVED = "filter_candidates_page_saved";
const FILTER_JOB_PAGE_MANAGE_JOB = "filter_job_page_manage_job";
const FILTER_CANDIDATES_PAGE_DETAIL_JOB = "filter_candidates_page_detail_job";


@Injectable({
  providedIn: 'root'
})
export class TalentPoolService {
  private BASE_URL = `${environment.apiUrl}`;
  private listJdSubject: BehaviorSubject<CV_task[]>;
  public listJd: Observable<CV_task[]>;
  private saved_cvsSubject: BehaviorSubject<SearchResume[]>;
  public saved_cvs: Observable<SearchResume[]>;
  private listCandidateSubject: BehaviorSubject<SearchResume[]>;
  public listCandidate: Observable<SearchResume[]>;
  private optionFilterRecommendFormSubject: BehaviorSubject<{}>;
  public optionFilterRecommendForm: Observable<{}>;
  private idJdSubject: BehaviorSubject<string>;
  public idJd: Observable<string>;
  private hcmSystemJdSubject: BehaviorSubject<string>;
  public hcmSystemJd: Observable<string>;
  private filterCandidatesPageSearchSubject: BehaviorSubject<any>;
  private filterCandidatesPageRecommendSubject: BehaviorSubject<any>;
  private filterCandidatesPageSavedSubject: BehaviorSubject<any>;
  private filterJobPageManageJobSubject: BehaviorSubject<any>;
  private filterCandidatesPageDetailJobSubject: BehaviorSubject<any>;

  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    public _translateService: TranslateService,
    private _coreTranslateService: CoreTranslationService,
  ) {
    this._coreTranslateService.translate(enLocale);
    this._coreTranslateService.translate(vnLocal);
    this.listJdSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("list_jd"))
    );
    this.listJd = this.listJdSubject.asObservable();
    this.saved_cvsSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("saved_cvs"))
    );
    this.saved_cvs = this.saved_cvsSubject.asObservable();

    this.listCandidateSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("list_candidate"))
    );
    this.listCandidate = this.listCandidateSubject.asObservable();
    this.optionFilterRecommendFormSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("optionFilterRecommendForm"))
    );
    this.optionFilterRecommendForm = this.optionFilterRecommendFormSubject.asObservable();
    this.idJdSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("idJd"))
    );
    this.idJd = this.idJdSubject.asObservable();
    this.hcmSystemJdSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("hcmSystemJd"))
    );
    this.hcmSystemJd = this.hcmSystemJdSubject.asObservable();
    this.filterCandidatesPageSearchSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_CANDIDATES_PAGE_SEARCH)) || {}
    );
    this.filterCandidatesPageRecommendSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_CANDIDATES_PAGE_RECOMMEND)) || {}
    );
    this.filterCandidatesPageSavedSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_CANDIDATES_PAGE_SAVED)) || {}
    );
    this.filterJobPageManageJobSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_JOB_PAGE_MANAGE_JOB)) || {}
    );
    this.filterCandidatesPageDetailJobSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(FILTER_CANDIDATES_PAGE_DETAIL_JOB)) || {}
    );
  }
  setNewListJd(list: any[]) {
    this.listJdSubject.next(list);
    localStorage.setItem("list_jd", JSON.stringify(list));
  }
  setNewSavedCvs(list: any[]) {
    this.saved_cvsSubject.next(list);
    localStorage.setItem("saved_cvs", JSON.stringify(list));
  }
  setNewListCandidate(list: any[]) {
    this.listCandidateSubject.next(list);
    localStorage.setItem("list_candidate", JSON.stringify(list));
  }
  setNewOptionFilterRecommendForm(list: {}) {
    this.optionFilterRecommendFormSubject.next(list);
    localStorage.setItem("optionFilterRecommendForm", JSON.stringify(list));
  }
  setNewIdJd(id: string) {
    this.idJdSubject.next(id);
    localStorage.setItem("idJd", JSON.stringify(id));
  }
  setNewHcmSystemJd(id: string) {
    this.hcmSystemJdSubject.next(id);
    localStorage.setItem("hcmSystemJd", JSON.stringify(id));
  }
  //
  setFilterCandidatesPageSearchValue(
    pageSearch: number = null,
    selectedCategory: string[] = [],
    skill: string[] = [],
    selectedCity: string[] = [],
    years_of_experience_from: number = null,
    years_of_experience_to: number = null,
    fulltext_bool_expression: string = null,
    full_name_bool_expression: string = null,
    experience_bool_expression: string = null,
    education_bool_expression: string = null,
    current_title_bool_expression: string[] = [],
    current_company_bool_expression: string[] = [],
    talent_pool: string = null,
    keyword: string[] = []
  ) {
    this.filterCandidatesPageSearchSubject.next({
      pageSearch,
      selectedCategory,
      skill,
      selectedCity,
      years_of_experience_from,
      years_of_experience_to,
      fulltext_bool_expression,
      full_name_bool_expression,
      experience_bool_expression,
      education_bool_expression,
      current_title_bool_expression,
      current_company_bool_expression,
      talent_pool,
      keyword
    });

    // save page size, and docTypeId to local storage
    const pageCopy = pageSearch;
    localStorage.setItem(
      FILTER_CANDIDATES_PAGE_SEARCH,
      JSON.stringify(pageCopy)
    );
  }

  getFilterCandidatesPageSearchValue() {
    return this.filterCandidatesPageSearchSubject.value;
  }
  setFilterCandidatesPageRecommendValue(
    pageRecommend: number = null,
    fulltext_bool_expression: string = null,
    full_name_bool_expression: string = null,
    experience_bool_expression: string = null,
    education_bool_expression: string = null,
    current_title_bool_expression: string[] = [],
    current_company_bool_expression: string[] = []
  ) {
    this.filterCandidatesPageRecommendSubject.next({
      pageRecommend,
      fulltext_bool_expression,
      full_name_bool_expression,
      experience_bool_expression,
      education_bool_expression,
      current_title_bool_expression,
      current_company_bool_expression
    });

    // save page size, and docTypeId to local storage
    const pageCopy = pageRecommend;
    localStorage.setItem(
      FILTER_CANDIDATES_PAGE_RECOMMEND,
      JSON.stringify(pageCopy)
    );
  }

  getFilterCandidatesPageRecommendValue() {
    return this.filterCandidatesPageRecommendSubject.value;
  }
  //ja
  setFilterCandidatesPageSavedValue(
    pageSaved: number = null,
    first_name: string = null,
    last_name: string = null,
    selectedGender: string = null,
    selectedCity: string = null,

  ) {
    this.filterCandidatesPageSavedSubject.next({
      pageSaved,
      first_name,
      last_name,
      selectedGender,
      selectedCity
    });

    // save page size, and docTypeId to local storage
    const pageCopy = pageSaved;
    localStorage.setItem(
      FILTER_CANDIDATES_PAGE_SAVED,
      JSON.stringify(pageCopy)
    );
  }

  getFilterCandidatesPageSavedValue() {
    return this.filterCandidatesPageSavedSubject.value;
  }
  setFilterJobPageManageJobValue(
    pageJob: Partial<Page>,
    owner__email: string[] = [],
  ) {
    this.filterJobPageManageJobSubject.next({
      pageJob,
      owner__email
    });

    // save page size, and docTypeId to local storage
    const pageCopy = { ...pageJob, pageNumber: 0 };
    localStorage.setItem(
      FILTER_JOB_PAGE_MANAGE_JOB,
      JSON.stringify({page: pageCopy,})
    );
  }

  getFilterJobPageManageJobValue() {
    return this.filterJobPageManageJobSubject.value;
  }
  setFilterCandidatesPageDetailJobValue(
    page: Partial<Page>,
  ) {
    this.filterCandidatesPageDetailJobSubject.next({
      page
    });

    // save page size, and docTypeId to local storage
    const pageCopy = { ...page, pageNumber: 0 };
    localStorage.setItem(
      FILTER_CANDIDATES_PAGE_DETAIL_JOB,
      JSON.stringify(pageCopy)
    );
  }

  getFilterCandidatesPageDetailJobValue() {
    return this.filterCandidatesPageDetailJobSubject.value;
  }
  //Talent Pool
  search_resume_raw(formData) {
    const url = `${this.BASE_URL}/candidate/search/`;
    return this._http.post<ResultSearchResume>(url, formData)
      .pipe(
        // map((res) => {
        //   let processedResult: SearchResume[] = []
        //   res['result'].forEach((r) => {
        //     let processed_r = { ...r }
        //     let experiences = r.experience_fulltext.split("\n\n");
        //     let processed_experiences = experiences.map((e) => (`<div><span class="candidate-position">${e.split('\n')[0]}</span> - <span class="candidate-company">${e.split('\n')[1]}</span></div><span>${e.split('\n').slice(2, 4).join("<br>") + '</br> ...'}</span>`))
        //     processed_experiences = processed_experiences.slice(0, 2)
        //     let educations = r.education_fulltext ? r.education_fulltext.split("\n\n") : [];
        //     let processed_educations = educations.map((e) => (`<div><span class="candidate-school">${e.split('\n')[0]}</span></div>${e.split('\n').slice(1, 3).join("<br>") + '<br>...'}`))
        //     processed_educations = processed_educations.slice(0, 1)
        //     processed_r = { ...processed_r, "experiences": processed_experiences, "educations": processed_educations }
        //     processedResult.push(processed_r);
        //   })
        //   res = { ...res, result: processedResult }
        //   return res;
        // }
        // )
      )
  }
  //Search cv
  search_resume(formData): Observable<ResultSearchResume> {
    const url = `${this.BASE_URL}/candidate/search/`;
    return this._http.post<ResultSearchResume>(url, formData)
      .pipe(
        // map((res) => {
        //   let processedResult: SearchResume[] = []
        //   res['result'].forEach((r) => {
        //     let processed_r = { ...r }
        //     let experiences = r.experience_fulltext.split("\n\n");
        //     let processed_experiences = experiences.map((e) => (`<div><span class="candidate-position">${e.split('\n')[0]}</span> - <span class="candidate-company">${e.split('\n')[1]}</span></div><span>${e.split('\n').slice(2, 4).join("<br>") + '</br> ...'}</span>`))
        //     processed_experiences = processed_experiences.slice(0, 2)
        //     let educations = r.education_fulltext ? r.education_fulltext.split("\n\n") : [];
        //     let processed_educations = educations.map((e) => (`<div><span class="candidate-school">${e.split('\n')[0]}</span></div>${e.split('\n').slice(1, 3).join("<br>") + '<br>...'}`))
        //     processed_educations = processed_educations.slice(0, 1)
        //     processed_r = { ...processed_r, "experiences": processed_experiences, "educations": processed_educations }
        //     processedResult.push(processed_r);
        //   })
        //   res = { ...res, result: processedResult }
        //   return res;
        // }
        // )
      )
  }

  //Detail resume
  detail_resume(resume_id: any): Observable<DetailResume> {
    const url = `${this.BASE_URL}/candidate/${resume_id}`;
    return this._http.get<DetailResume>(url)
  }
  //Recommend resume
  recommend_resume(url_dynamic: string, formData): Observable<ResultSearchResume> {
    const url = `${this.BASE_URL}/candidate/recommend-cvs/` + `${url_dynamic}`;
    return this._http.post<ResultSearchResume>(url, formData)
      .pipe(
        // map((res) => {
        //   let processedResult: SearchResume[] = []
        //   res['result'].forEach((r) => {
        //     let processed_r = { ...r }
        //     let experiences = r.experience_fulltext.split("\n\n");
        //     let processed_experiences = experiences.map((e) => (`<div><span class="candidate-position">${e.split('\n')[0]}</span> - <span class="candidate-company">${e.split('\n')[1]}</span></div><span>${e.split('\n').slice(2, 4).join("<br>") + '</br> ...'}</span>`))
        //     processed_experiences = processed_experiences.slice(0, 2)
        //     let educations = r.education_fulltext ? r.education_fulltext.split("\n\n") : [];
        //     let processed_educations = educations.map((e) => (`<div><span class="candidate-school">${e.split('\n')[0]}</span></div>${e.split('\n').slice(1, 3).join("<br>") + '<br>...'}`))
        //     processed_educations = processed_educations.slice(0, 1)
        //     processed_r = { ...processed_r, "experiences": processed_experiences, "educations": processed_educations }
        //     processedResult.push(processed_r);
        //   })
        //   res = { ...res, result: processedResult }
        //   return res;
        // }
        // )
      )
  }
  //List Saved Resume By Jd
  list_saved_resume_by_jd(id: number, url_dynamic: string): Observable<SearchResume[]> {
    const url = `${this.BASE_URL}/job-description/saved-resumes?job_description=${id}`+`${url_dynamic}`;
    return this._http.get<SearchResume[]>(url)
      .pipe(
        // map((res) => {
        //   let processedResult: SearchResume[] = []
        //   res.forEach((r) => {
        //     let processed_r = { ...r }
        //     let experiences = r.experience_fulltext ? r.experience_fulltext.split("\n\n") : [];
        //     let processed_experiences = experiences.map((e) => (`<div><span class="candidate-position">${e.split('\n')[0]}</span> - <span class="candidate-company">${e.split('\n')[1]}</span></div><span>${e.split('\n').slice(2, 4).join("<br>") + '</br> ...'}</span>`))
        //     processed_experiences = processed_experiences.slice(0, 2)
        //     let educations = r.education_fulltext ? r.education_fulltext.split("\n\n") : [];
        //     let processed_educations = educations.map((e) => (`<div><span class="candidate-school">${e.split('\n')[0]}</span></div>${e.split('\n').slice(1, 3).join("<br>") + '<br>...'}`))
        //     processed_educations = processed_educations.slice(0, 1)
        //     processed_r = { ...processed_r, "experiences": processed_experiences, "educations": processed_educations }
        //     processedResult.push(processed_r);
        //   });
        //   res = [...processedResult]
        //   return res;
        // }
        // )
      )
  }
  save_resume_by_jd(job_description: number, resume: number[]): Observable<SaveResumeByJd> {
    const url = `${this.BASE_URL}/job-description/saved-multi-resumes`;
    return this._http.post<SaveResumeByJd>(url, { job_description, resume }).pipe(
      map((respData) => {
        for (let i=0;i<respData.message.length;i++){
          setTimeout(() => {
            if(respData.message[i].status=='Success'){
              this._toastrService.success(
                this._translateService.instant("TALENT_POOL.SEARCH_RESUME.SAVE_CV")+': '+`${respData.message[i].resume_name}`,
                this._translateService.instant("TALENT_POOL.RECOMMEND_RESUME.TOASTR.SUCCESS"),
                {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                }
              );
            }else{
              this._toastrService.error(
                this._translateService.instant("TALENT_POOL.SEARCH_RESUME.RESUME")+': '+`${respData.message[i].resume_name}`+ ' '+ "TALENT_POOL.SEARCH_RESUME.BEFORE_SAVED",
                '',
                {
                  toastClass: "toast ngx-toastr",
                  closeButton: true,
                }
              );
            }
          }, 300);
        }
        return respData;
      }),
    )
  }
  unSaveCandidate(job_description: any, resume: any): Observable<any> {
    const url = `${this.BASE_URL}/job-description/unsave/${job_description}/${resume}`;
    return this._http.delete<any>(url).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.DELETE_R"),
            this._translateService.instant("TALENT_POOL.RECOMMEND_RESUME.TOASTR.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //Job Description Management
  //List All Jd
  list_all_jd(url_dynamic: string): Observable<CV_task[]> {
    const url = `${this.BASE_URL}/job-description/${url_dynamic}`;
    return this._http.get<CV_task[]>(url)
  }
  list_all_jd_pagination(page: number, page_size: number, url_dynamic: string): Observable<any>{
    const url = `${this.BASE_URL}/job-description/?page=${page+1}&page_size=${page_size}` + `${url_dynamic}`;
    return this._http.get<any>(url)
  }
  //Create New Jd
  create_new_jd(job_title: string, location: string[], job_level: string, job_type: string, description: string, requirement: string, years_of_experience_from: number, years_of_experience_to: number, keyword_expression: string, hcm_system_jd: any, keywords: any[]): Observable<CV_task> {
    const url = `${this.BASE_URL}/job-description/`;
    return this._http.post<CV_task>(url, { job_title, location, job_level, job_type, description, requirement, years_of_experience_from, years_of_experience_to, keyword_expression, hcm_system_jd, keywords }).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("TALENT_POOL.MANAGE_JOB.TOAST.CREATE_J_SUC"),
            this._translateService.instant("TALENT_POOL.RECOMMEND_RESUME.TOASTR.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //Detail Jd
  detail_jd(jd_id: any): Observable<CV_task> {
    const url = `${this.BASE_URL}/job-description/${jd_id}`;
    return this._http.get<CV_task>(url)
  }
  //Edit Jd
  edit_jd(jd_id: any, job_title: string, location: string[], job_level: string, job_type: string, description: string, requirement: string, years_of_experience_from: number, years_of_experience_to: number, keyword_expression: string, keywords: any[]): Observable<CV_task> {
    const url = `${this.BASE_URL}/job-description/${jd_id}`;
    return this._http.put<CV_task>(url, { job_title, location, job_level, job_type, description, requirement, years_of_experience_from, years_of_experience_to, keyword_expression, keywords }).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("TALENT_POOL.MANAGE_JOB.TOAST.EDIT_J_SUC"),
            this._translateService.instant("TALENT_POOL.RECOMMEND_RESUME.TOASTR.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  deleteJob(jd_id: any): Observable<any> {
    const url = `${this.BASE_URL}/job-description/${jd_id}`;
    return this._http.delete<any>(url)
  }
  //List match resume
  list_match_resume(page: number, page_size: number, url_dynamic: string): Observable<ListResumeMatching> {
    const url = `${this.BASE_URL}/resume-matching/sf4c?page=${page}&page_size=${page_size}` + `${url_dynamic}`;
    return this._http.get<ListResumeMatching>(url)
  }
  //Sync sf4c JR
  sync_sf4c_jr(): Observable<ResultSyncSf4cJr> {
    const url = `${this.BASE_URL}/job-description/sf4c_jr`;
    return this._http.post<ResultSyncSf4cJr>(url, {}).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("TALENT_POOL.MANAGE_JOB.SYNC_SUCCESSFACTORS"),
            this._translateService.instant("TALENT_POOL.RECOMMEND_RESUME.TOASTR.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  //Matching CV-JD
  matching_cv_jd_object(resume: any, job_description: any): Observable<ResultMatchingCV> {
    const url = `${this.BASE_URL}/resume-matching/score`;
    return this._http.post<ResultMatchingCV>(url, { resume, job_description })
    // .pipe(
    //   map((respData)=>{
    //     setTimeout(() => {
    //       this._toastrService.success(
    //           `${resume}`,
    //           `Scoring success with candidate whose id is: `,
    //           {
    //           toastClass: "toast ngx-toastr",
    //           closeButton: true,
    //           }
    //       );
    //       }, 300);
    //       return respData;
    //   }),
    // )
  }
  matching_cv_jd(formData: any): Observable<any> {
    const url = `${this.BASE_URL}/resume-matching/default`;
    return this._http.post<any>(url, formData)
  }
  matching_cv_linkJD(formData: any): Observable<any> {
    const url = `${this.BASE_URL}/resume-matching/cv-link`
    return this._http.post<any>(url, formData)
  }
  matching_cv_fileJD(file: File, job_description: string): Observable<any> {
    const url = `${this.BASE_URL}/resume-matching/cv-file`;
    const formData = new FormData()
    formData.append("file", file)
    formData.append("job_description", job_description)
    return this._http.post<any>(url, formData)
  }
  getDetailTask(id_task: any): Observable<any> {
    const url = `${this.BASE_URL}/resume-matching/task/${id_task}`;
    return this._http.get<any>(url)
  }
  searchHistory(): Observable<any> {
    const url = `${this.BASE_URL}/candidate/search/history`;
    return this._http.get<any>(url)
  }
  sf4cSf4cCandidate(id: number): Observable<any> {
    const url = `${this.BASE_URL}/candidate/${id}/sf4c_status`;
    return this._http.get<any>(url)
  }
  listSf4cCandidate(id: any): Observable<any>{
    const url = `${this.BASE_URL}/job-description/sf4c-candidates?hcm_system_id=${id}`;
    return this._http.get<any>(url)
  }
  requestToShare(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/resume/${id}/request-to-share`;
    return this._http.post<any>(url, formData).pipe(
      map((respData) => {
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("TALENT_POOL.DETAIL_CANDIDATE.REQUEST_TO_SHARE"),
            ``,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      }),
    )
  }
  seen_resumes(job_description: any, resume: any): Observable<any>{
    const url = `${this.BASE_URL}/job-description/seen-resumes`;
    return this._http.post<any>(url, {job_description, resume})
  }
  report_unmatch_resume(formData: any): Observable<any>{
    const url = `${this.BASE_URL}/candidate/unmatch-resume`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("TALENT_POOL.SEARCH_RESUME.REPORT_ERROR"),
            ``,
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        }, 300);
        return respData;
      })
    )
  }
}
