<div class="card position-relative">
  <button class="close-btn" (click)="closeCard()" *ngIf="cardVisible">×</button>
    <div class="card-body">
      <h2 class="font-weight-bolder" [translate]="'GENERAL.DETAIL.VIEW'"></h2>
      <h3 class="">{{detailPackage?.title}}</h3>
      <hr>
      <div class="mt-2">
        <h3 class="mb-75" [translate]="'GENERAL.DETAIL.PRODUCT_PRICE'"></h3>
        <span class="font-weight-bolder" [translate]="'PRICING_CONFIG.PACKAGE_PRICING'"></span><span>: {{detailPackage?.default_cost?.cost|number}} <small *ngIf="detailPackage?.default_cost?.cost">đ / </small>{{detailPackage?.default_cost?.recurrence_period}} 
            <small *ngIf="detailPackage.default_cost?.recurrence_unit=='6'" [translate]="'GENERAL.DETAIL.MONTH'"></small>
            <small *ngIf="detailPackage.default_cost?.recurrence_unit=='7'" [translate]="'GENERAL.DETAIL.YEAR'"></small>
            <small *ngIf="detailPackage.default_cost?.recurrence_unit=='4'" [translate]="'GENERAL.DETAIL.DAY'"></small>
        </span>

      </div>
      <hr>
      <div class="mt-2">
        <h3 class="mb-75" [translate]="'GENERAL.DETAIL.DETAIL'"></h3>
        <div><span class="font-weight-bolder" [translate]="'GENERAL.EDIT.PACKAGE_NAME'"></span><span>: {{detailPackage?.package?.name}}</span></div>
        <div><span class="font-weight-bolder" [translate]="'PACKAGES_CONFIG.EDIT.PARSE_THREAD_LIMIT'"></span><span>: {{detailPackage?.package?.parse_thread_limit}}</span></div>
        <div><span class="font-weight-bolder" [translate]="'PACKAGES_CONFIG.EDIT.PARSE_LIMITED'"></span><span>: {{detailPackage?.package?.parse_limited}}</span></div>
        <div><span class="font-weight-bolder" [translate]="'PACKAGES_CONFIG.EDIT.MATCHING_LIMITED'"></span><span>: {{detailPackage?.package?.matching_limited}}</span></div>
        <div><span class="font-weight-bolder" [translate]="'PACKAGES_CONFIG.EDIT.MAX_USER'"></span><span>: {{detailPackage?.package?.max_user}}</span>
      
    </div>
  </div>