export const locale = {
    lang: 'en',
    data: {
        DASHBOARD:{
            CHOOSE_COMPANY: "Choose parent company",
            CHOOSE_SUB_COMPANY: "Choose company",
            ALL_COMPANY: "All companys",
            FILTER: "Filter",
            RESET: "Reset",
            RESUME_UPLOADED: "Resume Uploaded",
            RESUME_UPLOADED_DAY: "Resume Uploaded in last 7 days",
            AVERAGE_CONF_SCORE: "Average Parsing Confidence Score",
            RESUME_STATUS: "The number of candidate profiles uploaded by status",
            RESUME_OWNER: "The number of candidate profiles uploaded by users",
            YEAR_EXP: "Resume by year experience",
            GROUP_CATEGORY: "Resume by industry",
            GROUP_SOURCE: "Resume by source",
            RESUME_STATUS_DAY: "Resume status by Day in last 14 days",
            
            RESUME_STATUS_SF4C: "Resume status uploaded to SuccessFactors",
            RESUME_STATUS_SF4C_DAY: "Resume status uploaded to SuccessFactors by Day in last 14 days",
            
            RESUME_MATCHING: "Resume Matching Uploaded",
            RESUME_MATCHING_DAY: "Resume Matching Uploaded in last 7 days",
            RESUME_MATCHING_SF4C: "Resume matching status uploaded to SuccessFactors",
            RESUME_MATCHING_SF4C_DAY: "Resume matching status uploaded to SuccessFactors by Day in last 14 days",
            ACTIVE_USER_BY_DAY: "Active user by day",
            JOB_ID: "Job Id",
            CREATE_AT: "Create at",
            CREATE_BY: "Created by",
            COUNT_RECOMMEND: "Count recommend",
            RESUME_ID: "Resume Id",
            OVERALL_SCORE: "Overall score",
            NAME_JOB: "Name job",
            ITEM_PAGE: "Items per page",
            CHOOSE_PERIOD: "Choose period",
            FREE_TRIAL: "Free trial users",
            PAID_USERS: "Paid users",
            PACKAGE: "Number of packages used",
            COMPANY_ACTIVITY: "Corporate package",
            TRIAL_ACTIVITY: "Trial package",
            REVENUE: "Revenue by day",
            INFO_PACKAGE: "User list by package",
            PARSE_NUM_LEFT: "Remaining extraction quota",
            MATCHING_NUM_LEFT: "Remaining matching quota",
            USER_NUM_LEFT: "Current active users",
            TABLE: {
              USER: "Users",
              PARSE_NUM: "Parse",
              MATCHING_NUM: "Matching",
              START_DATE: "Start date",
              COMPANY: "Company",
              PACKAGE_NAME: "Package",
              EXPIRY_DATE: "Expiry date",
              PRICE: "Price",
              TOTAL: "Number of times used",
              DETAIL: "Detail",
              EXPAND: "Expand/Collapse Row",
              COMMENT: "Comment",
              SEARCH_HISTORY: "Search history",
              LEFT_PARSE_NUM: "Parse left",
              LEFT_MATCHING_NUM: "Matching left",
              NUM_ACTIVE_USER: "Number of active users",
              PACKAGE_EXPIRY: "Expired"
            },
            COMPANY_SEARCH_TAB: "Search",
            COMPANY_RECOMMEND_TAB: "Recommend",
            ACCEPT: "Accept",
            UNMATCH_REPORT: "Unmatch report",
            COMPANY_SEARCH: "Search and recommend activities of companies",
            COMPANY_RECOMMEND: "Recommend activities of companies",
            COMPANY_REPORT: "Report inappropriate app activity when looking for recommendations",
            COMPANY_ACTIVITY_CURR: "Company activity",
            USER_ACTIVITY_CURR: "User activity",
            YAXIS_TITLE: "Resume (count)",
            XAXIS_TITLE: "Day",
            YAXIS_COUNT_NUMBER: "Count (number)",
            YAXIS_REVENUE: "Revenue (dong)",
            UPGRADE_PACKAGE: "Upgrade package"
          }
      
    }
  };
  