import { Injectable } from '@angular/core';
import { PaymentInfo } from '../models/payment.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private paymentInfo: Observable<PaymentInfo>;
  private paymentSubject: BehaviorSubject<PaymentInfo>;
  public paymentOption: Observable<string>;
  private paymentOptionSubject: BehaviorSubject<string>;
  // private csrfToken: string;
  constructor(
    private _httpClient: HttpClient,
  ) {
      const id = localStorage.getItem('id');
      const costId = localStorage.getItem('costId');

      this.paymentSubject = new BehaviorSubject<PaymentInfo>(
        new PaymentInfo(id, costId)
      );
      this.paymentInfo = this.paymentSubject.asObservable();
  }

  public get paymentInfoValue(): PaymentInfo {
    return this.paymentSubject.value;
  }
  public setPaymentInfoValue(value: PaymentInfo){
    this.paymentSubject.next(value)
  }

  public buyPackageFreeTrial(cost_id: any, company_name: string, paymentMethod: string): Observable<any> {
    const formData = new FormData();
    formData.append("cost_id", cost_id);
    formData.append("company_name", company_name);
    return this._httpClient
      .post<any>(`${environment.apiUrl}/payment/${paymentMethod}/payment_url`, formData)
      .pipe(first());
  }
  public buyPackageCompany(cost_id: any, paymentMethod: string): Observable<any> {
    const formData = new FormData();
    formData.append("cost_id", cost_id);
    return this._httpClient
      .post<any>(`${environment.apiUrl}/payment/${paymentMethod}/payment_url`, formData)
      .pipe(first());
  }

  public getTransactionInfo(transaction_link: string): any{
    return this._httpClient.get(`${environment.apiUrl}/payment/vnpay/payment_ipn${transaction_link}`);
  }
  //billing
  getBilling(){
    return this._httpClient.get<any>(`${environment.apiUrl}/payment/transaction`);
  }
  public getTransaction(id: any): any{
    return this._httpClient.get(`${environment.apiUrl}/payment/transaction/${id}`);
  }
}
