import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageJobComponent } from './manage-job/manage-job.component';
import { CreateJobComponent } from './manage-job/create-job/create-job.component';
import { DetailJobComponent } from './manage-job/detail-job/detail-job.component';
import { SearchResumeComponent } from './search-resume/search-resume.component';
import { DetailResumeComponent } from './search-resume/detail-resume/detail-resume.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreCommonModule } from '@core/common.module';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { RouterModule, Routes } from '@angular/router';
import { JwtInterceptor } from 'app/auth/helpers';
import { NouisliderModule } from 'ng2-nouislider';
import { QuillModule } from 'ngx-quill';
import { RecommendResumeComponent } from './search-resume/recommend-resume/recommend-resume.component';
import { ResumeTalentPoolComponent } from './search-resume/component/resume-talent-pool/resume-talent-pool.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CachedSrcDirective } from './search-resume/detail-resume/iframe.component';
import { SaveResumeJdComponent } from './search-resume/save-resume-jd/save-resume-jd.component';
import { ResumeMatchingComponent } from './search-resume/resume-matching/resume-matching.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CircleChartComponent } from './search-resume/component/circle-chart/circle-chart.component';
import { SearchHistoryComponent } from './search-resume/search-history/search-history.component';
import { CoreSidebarModule } from '@core/components/core-sidebar/core-sidebar.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { MiscellaneousModule } from '../pages/miscellaneous/miscellaneous.module';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
const routes: Routes = [
  {
    path: "manage-job",
    component: ManageJobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "manage-job/detail-job/:id",
    component: DetailJobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "manage-job/detail-job/:id/tab-candidates",
    component: DetailJobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "manage-job/create-job",
    component: CreateJobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "manage-job/edit-job/:id",
    component: CreateJobComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "search-resume",
    component: SearchResumeComponent,
    canActivate: [AuthGuard],
  },
  { path: 'manage-job/:id/detail-candidate/:id', component: DetailResumeComponent, canActivate: [AuthGuard],},

  { path: 'search-candidate/detail-candidate/:id', component: DetailResumeComponent, canActivate: [AuthGuard],},
    
  { path: 'manage-job/:id/recommend-candidate/detail-candidate/:id', component: DetailResumeComponent, canActivate: [AuthGuard]},
  { path: 'manage-job/:id/saved-candidate/detail-candidate/:id', component: DetailResumeComponent, canActivate: [AuthGuard]},
  
  { path: 'manage-job/recommend-candidate/detail-candidate/:id', component: DetailResumeComponent, canActivate: [AuthGuard]},
  { path: 'manage-job/saved-cvs/detail-candidate/:id', component: DetailResumeComponent, canActivate: [AuthGuard]},
  { path: 'manage-job/recommend-resume/:id', component: RecommendResumeComponent,canActivate: [AuthGuard]},
  { path: 'manage-job/saved-cvs/:id', component: SaveResumeJdComponent,canActivate: [AuthGuard]},
  { path: 'matching-cv-jd', component: ResumeMatchingComponent,canActivate: [AuthGuard]}

]


@NgModule({
  declarations: [
    ManageJobComponent,
    CreateJobComponent,
    DetailJobComponent,
    SearchResumeComponent,
    DetailResumeComponent,
    RecommendResumeComponent,
    ResumeTalentPoolComponent,
    CachedSrcDirective,
    SaveResumeJdComponent,
    ResumeMatchingComponent,
    CircleChartComponent,
    SearchHistoryComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule,
    NgbNavModule,
    HttpClientModule,
    CoreCommonModule,
    NgbModule,
    NouisliderModule,
    QuillModule.forRoot(),
    NgxPaginationModule,
    NgApexchartsModule,
    CoreSidebarModule,
    BsDatepickerModule,
    ContentHeaderModule,
    MiscellaneousModule,
    Ng2FlatpickrModule
  ],
  exports: [CachedSrcDirective, CircleChartComponent],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
})
export class TalentPoolModule { }
