import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page.component';
import { IntroduceComponent } from './introduce/introduce.component';
import { ChoseComponent } from './chose/chose.component';
import { ListCustomerComponent } from './list-customer/list-customer.component';
import { PricingComponent } from './pricing/pricing.component';
import { SolutionComponent } from './solution/solution.component';
import { HeaderModule } from 'app/layout/components/header/header.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: "about-us",
    component: AboutUsComponent,
  },
  
];

@NgModule({
  declarations: [
    LandingPageComponent,
    IntroduceComponent,
    ChoseComponent,
    ListCustomerComponent,
    PricingComponent,
    SolutionComponent,
    AboutUsComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    SlickCarouselModule,
    NgbCarouselModule,
    TranslateModule,
    NgbModule,
    CoreCommonModule,
    RouterModule.forChild(routes),
  ]
})
export class LandingPageModule { }
