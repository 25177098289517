import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchResume, status_color } from '../../models/resume.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TalentPoolService } from '../../services/talent-pool.service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { City } from '../../models/job.model';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { CoreConfigService } from '@core/services/config.service';
import { Subscription, concat, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-save-resume-jd',
  templateUrl: './save-resume-jd.component.html',
  styleUrls: ['./save-resume-jd.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaveResumeJdComponent implements OnInit {
  public saved_cvs: SearchResume[] = []
  savedCvsCount: any
  public jd_id!: any

  public errorMessagesListSavedResumeByJd: any
  public page = 1
  public pageSize = 10
  public num_page = 0
  public searchFullname: string = '';
  public searchAddress: string = '';
  public searchCurrentTitle: string = '';
  public searchCurrentCompany: string = '';
  public list_form_table = false
  public href: string = ""
  public status_color = status_color
  // public have_hcm_system_id = false
  formCheckBoxValid = this.fb.group({
    importedby: [''],
    source: [''],
  })
  public sf4cPicklist: any
  public first_name: string;
  public last_name: string;

  public gender = [
    { name: "Male", name_vn: "Nam", value: "Male" },
    { name: "Female", name_vn: "Nữ", value: "Female" }
  ]
  public selectedGender!: string;
  public selectedCity!: string;
  public city = [];
  contentHeader: ContentHeader;
  public language: string;
  INTERVAL_TIME = 5000;
  intervalSubscription: Subscription;
  constructor(
    private _router: Router,
    private talentPoolService: TalentPoolService,
    private resumeParserService: ResumeParserService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _coreConfigService: CoreConfigService,
    private route: ActivatedRoute,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
  }
  modalOpenUploadSf4c(modalBasic: any) {
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      backdrop: "static",
      scrollable: true
    });
  }
  upload_sf4c() {
    let id_approved: number[] = []
    for (let i = 0; i < this.saved_cvs.length; i++) {
      if (this.saved_cvs[i].status == "Approved") {
        id_approved.push(this.saved_cvs[i].id)
      }
    }
    let formData = {}
    let importedby = this.formCheckBoxValid.value.importedby
    let source = this.formCheckBoxValid.value.source
    formData['resume_id'] = id_approved;
    formData['jobReqId'] = this.jd_id.toString()
    if (importedby) {
      formData['importedby'] = importedby;
    }
    if (source) {
      formData['source'] = source;
    }
    this.resumeParserService.sf4c_upload(formData).subscribe(
      (res) => {
        if (res.status) {
          this._router.navigateByUrl("/manage-resume");
        }
      },
      (err) => {
        console.error("Error: " + err)
      }
    )
  }
  SearchFullname() {
    // alert(this.searchText)
    let saved_cvs_cp: any
    this.talentPoolService.saved_cvs.subscribe(
      (res) => {
        saved_cvs_cp = res
      }
    )
    if (this.searchFullname !== "") {
      let searchValue = this.searchFullname.toLocaleLowerCase();

      this.saved_cvs = saved_cvs_cp.filter((saved_cvs_: any) => {
        return saved_cvs_.full_name.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.saved_cvs = saved_cvs_cp
    }
  }
  SearchAddress() {
    // alert(this.searchText)
    if (this.searchAddress !== "") {
      let searchValue = this.searchAddress.toLocaleLowerCase();

      this.saved_cvs = this.saved_cvs.filter((saved_cvs_: any) => {
        return saved_cvs_.address.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.GetSavedCvs()
    }
  }
  SearchCurrentTitle() {
    // alert(this.searchText)
    if (this.searchCurrentTitle !== "") {
      let searchValue = this.searchCurrentTitle.toLocaleLowerCase();

      this.saved_cvs = this.saved_cvs.filter((saved_cvs_: any) => {
        return saved_cvs_.current_title.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.GetSavedCvs()
    }
  }
  SearchCurrentCompany() {
    // alert(this.searchText)
    if (this.searchCurrentCompany !== "") {
      let searchValue = this.searchCurrentCompany.toLocaleLowerCase();

      this.saved_cvs = this.saved_cvs.filter((saved_cvs_: any) => {
        return saved_cvs_.current_company.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.GetSavedCvs()
    }
  }
  onSubmit(){
    this.GetSavedCvs();
  }
  GetSavedCvs() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.route.paramMap.subscribe((params)=>{
      this.jd_id = params.get("id");
      this.talentPoolService.setNewIdJd(this.jd_id.toString())
    })
    // if (href.split("?hcm_system_jd=")[1] != '') {
    //   this.have_hcm_system_id = true
    // }
    let url_dynamic = ''
    let first_name = this.first_name ? "&first_name=" + this.first_name : null;
    let last_name = this.last_name ? "&last_name=" + this.last_name : null;
    let gender = this.selectedGender ? "&gender=" + this.selectedGender : null;
    let city = this.selectedCity ? "&city=" + this.selectedCity : null;

    if (first_name) {
      url_dynamic = url_dynamic + first_name;
    }
    if (last_name) {
      url_dynamic = url_dynamic + last_name;
    }
    if (gender) {
      url_dynamic = url_dynamic + gender;
    }
    if (city) {
      url_dynamic = url_dynamic + city;
    }
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    const api$ = this.talentPoolService.list_saved_resume_by_jd(Number(this.jd_id), url_dynamic)
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.talentPoolService.list_saved_resume_by_jd(Number(this.jd_id), url_dynamic)
      )
    );
    this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
      (res) => {
        this.saved_cvs = res
        this.talentPoolService.setNewListCandidate(res)
        this.talentPoolService.setNewSavedCvs(this.saved_cvs)
        this.savedCvsCount = res.length
        this.saveFilter();
      },
      (err) => {
        this.errorMessagesListSavedResumeByJd = err
        this.intervalSubscription.unsubscribe()
      }
    )
  }
  UnsaveResumeByJd(resume: number) {
    Swal.fire({
      title: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.DELETE_R"),
      text: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("TALENT_POOL.SAVED_RESUME.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.talentPoolService.unSaveCandidate(this.jd_id, resume).subscribe(
          (res) => {
            // this.toastr.success('Delete Success');
            this.savedCvsCount = this.savedCvsCount - 1
            this.saved_cvs = this.saved_cvs.filter(x => x.id != resume)
          },
          (err) => {
            console.log(err)
          }
        )
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });

  }
  removeItem(item: any) {
    this.savedCvsCount = this.savedCvsCount - 1
    this.saved_cvs = this.saved_cvs.filter(x => x.id != item)
  }
  toggle_list_form_table() {
    this.list_form_table = !this.list_form_table
  }
  getSf4cPicklist() {
    this.resumeParserService.sf4cPicklist().subscribe((res) => {
      this.sf4cPicklist = res
    })
  }
  saveFilter() {
    this.talentPoolService.setFilterCandidatesPageSavedValue(
      this.page,
      this.first_name,
      this.last_name,
      this.selectedGender,
      this.selectedCity,
    );
  }
  toDetailResume(id_resume: any){
    let job_description = Number(this.jd_id);
    let resume = id_resume
    this.talentPoolService.seen_resumes(job_description, resume).subscribe((res)=>{
      this._router.navigate([`/manage-job/${this.jd_id}/saved-resume/detail-resume/${id_resume}`]);
    },
    (err)=>{
      this._router.navigate([`/manage-job/${this.jd_id}/saved-resume/detail-resume/${id_resume}`]);
    });
    
  }
  ngOnDestroy(): void {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
  ngOnInit(): void {
    const { pageSaved, first_name, last_name, selectedGender, selectedCity
    } = this.talentPoolService.getFilterCandidatesPageSavedValue();
    this.page = pageSaved || 0;
    this.first_name = first_name || null;
    this.last_name = last_name || null;
    this.selectedGender = selectedGender || null;
    this.selectedCity = selectedCity || null;
    this.GetSavedCvs()
    this.getSf4cPicklist()
    City.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    let city = [{ "name": 'Hà Nội', "value": "Hà Nội" }, { "name": 'Hồ Chí Minh', "value": "Hồ Chí Minh" }, { "name": 'Đà Nẵng', "value": "Đà Nẵng" }]
    for (let i = 0; i < City.length; i++) {
      city.push({ "name": City[i], "value": City[i] })
    }
    this.city = city
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
      this.contentHeader = {
        headerTitle: this.language == 'vn' ? "Hồ sơ đã lưu" : "Saved candidates",
        actionButton: false,
        breadcrumb: {
          type: "",
          links: [
            {
              name: this.language == 'vn' ? "Trang trước" : "Previous page",
              isLink: true,
              link: "/manage-job"
            },
            {
              name: this.language == 'vn' ? 'Danh sách ứng viên':
              "Candidates",
              isLink: false,
            },
          ],
        },
      };
    });
  }

}
