<div class="container-fluid block4" id="chose">
    <div class="container">
      <div class="">
        <h2 class="" style="font-size: 48px" translate="CHOSE.TITLE"></h2>
        <span style="font-size: 48px; color: #00a5f4">CHR</span>
        <span style="font-size: 48px">? </span>
      </div>
  
      <div class="row height-choose">
        <div
          class="col-md d-flex justify-content-center"
          style="flex-direction: column"
        >
          <div class="d-flex align-items-center mb-3">
            <img src="../../../../assets/images/landing-page/verify.svg" alt="" veryfy />
            <h2 class="ms-2 mb-0" translate="CHOSE.REASON_1.TITLE"></h2>
          </div>
          <p class="mb-3" translate="CHOSE.REASON_1.SUB_TITLE"></p>
          <!-- <a
            class="mb-3"
            style="color: #00a5f4; text-decoration: none; cursor: pointer"
            >Find out more<span class="mx-2"
              ><img src="../assets/images/Arrow Right.svg" alt="Arrow" /></span
          ></a> -->
        </div>
        <div class="col-md">
          <img
            class="img-solution"
            src="../../../../assets/images/landing-page/img-search.svg"
            alt="img-search"
          />
        </div>
      </div>
      <div class="row height-choose2">
        <div class="col-md">
          <img
            class="img-solution"
            src="../../../../assets/images/landing-page/image 10.svg"
            alt="img-search"
            width="772px"
          />
        </div>
        <div
          class="col-md d-flex justify-content-center"
          style="flex-direction: column"
        >
          <div class="d-flex align-items-center mb-3">
            <img src="../../../../assets/images/landing-page/verify.svg" alt="" veryfy />
            <h2 class="ms-2 mb-0" translate="CHOSE.REASON_2.TITLE"></h2>
          </div>
          <p class="mb-3" translate="CHOSE.REASON_2.SUB_TITLE"></p>
          <!-- <a
            class="mb-3"
            style="color: #00a5f4; text-decoration: none; cursor: pointer"
            >Find out more<span class="mx-2"
              ><img src="../assets/images/Arrow Right.svg" alt="Arrow" /></span
          ></a> -->
        </div>
      </div>
      <div class="row height-choose3">
        <div
          class="col-md d-flex justify-content-center"
          style="flex-direction: column"
        >
          <div class="d-flex align-items-center mb-3">
            <img src="../../../../assets/images/landing-page/verify.svg" alt="" veryfy />
            <h2 class="ms-2 mb-0" translate="CHOSE.REASON_3.TITLE"></h2>
          </div>
          <p class="mb-3" translate="CHOSE.REASON_3.SUB_TITLE"></p>
          <!-- <a
            class="mb-3"
            style="color: #00a5f4; text-decoration: none; cursor: pointer"
            >Find out more<span class="mx-2"
              ><img src="../assets/images/Arrow Right.svg" alt="Arrow" /></span
          ></a> -->
        </div>
        <div class="col-md">
          <img
            class="img-solution"
            src="../../../../assets/images/landing-page/image 11.svg"
            alt="img-search"
          />
        </div>
      </div>
    </div>
  </div>
  