<div id="footer-landing">
    <div class="container-fluid block7" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-md">
            
            <div style="color: #e9f8ff">
              <img width="182px" src="../../../../../assets/images/landing-page/logo.svg" alt="logo" />
              
              <p translate="FOOTER.ADDRESS">
                
              </p>
              <p>Email: ati@cmc.com.vn</p>
              <p><span [translate]="'FOOTER.PHONE'"></span><span>: 0986 111 027</span></p>

            </div>
  
            <div class="row">
              <div class="col-md-4 text-white contain-product">
                <h3 class="text-white" translate="FOOTER.ABOUT"></h3>
                <p style="margin-bottom: 0"><a target="_blank" style="color: #fff !important;" class="nav-link" href="https://cmcati.vn/" translate="FOOTER.INTRO"></a></p>
                <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link"
                    href="#contact" translate="FOOTER.CONTACT"></a></p>
                <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link"
                    href="#pricing" translate="FOOTER.PRICING"></a></p>
                <!-- <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link"
                    href="/terms-of-service" translate="FOOTER.TERMS"></a></p> -->
  
              </div>
              <div class="col-md-5 text-white contain-product">
                <h3 class="text-white">CHR</h3>
                <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link" [routerLink]="'/parse-resume'" translate="FOOTER.EXTRACT"></a></p>
                <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link" [routerLink]="'/search-resume'" translate="FOOTER.SEARCH"></a></p>
                
              </div>
              <div class="col-md-3 text-white contain-product">
                <h5 class="text-white" translate="FOOTER.PARTNER"></h5>
                <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link"
                    href="https://recruitery.co/vi">Recruitery</a></p>
                <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link"
                    href="https://jobs.thgroupglobal.com/">TH</a></p>
                    <p style="margin-bottom: 0"><a style="color: #fff !important;" class="nav-link"
                      href="https://www.cmctssg.vn/">CMC TSSG</a></p>
  
              </div>
            </div>
            <div class="d-flex mt-2 mb-3">
              <div class="me-2" style="cursor: pointer">
                <a href="http://cmcati.vn/"><span class="text-white" [data-feather]="'globe'"></span> </a>
              </div>
              
              <div class="me-2" style="cursor: pointer">
                <a href="https://www.facebook.com/VienNghiencuuUngdungCongngheCMC"><i class="text-white" [data-feather]="'facebook'"></i></a>
              </div>
            </div>
          </div>
          <form class="col-md form-register p-5" [formGroup]="customerForm" (ngSubmit)="createCustomer()">
            <h3 style="
                line-height: 32px;
                color: #05294e !important;
                font-weight: 500;
              " translate="FOOTER.LET_TAKL">
              
            </h3>
            <div class="contain-inp">
              <div class="input1 mb-4">
                <div class="col-6" style="margin-right: 20px">
                  <input class="input" id="name" name="name" [placeholder]="'FOOTER.NAME' | translate" formControlName="name"/>
                </div>
                <div class="col-6">
                  <input class="input" id="email" name="email" [placeholder]="'FOOTER.EMAIL' | translate" formControlName="email" required/>
                  <div *ngIf="
                              (customerForm.controls['email'].invalid &&
                              customerForm.controls['email'].touched) || (customerForm.controls['email'].invalid && isSubmited)
                            " class="invalid-form">
                            <small class="form-text text-danger" *ngIf="customerForm.controls['email'].errors.required"
                              [translate]="'AUTH.AUTH_LOGIN.REQUIRED'"></small>
                </div>
                  <div *ngIf="
                        (customerForm.controls['email'].touched && customerForm.controls['email'].errors)
                        " class="invalid-form">
                        <small class="form-text text-danger" *ngIf="customerForm.controls['email'].errors"
                          [translate]="'AUTH.AUTH_LOGIN.EMAIL_REQUIRED'"></small>
              </div>
                </div>
                
              </div>
              <div class="input1 mb-4">
                <div class="col-6" style="margin-right: 20px">
                  <input class="input" id="phone_number" name="phone_number" style="margin-right: 20px" [placeholder]="'FOOTER.PHONE_NUMBER' | translate" formControlName="phone_number" required/>
                <div *ngIf="
                              (customerForm.controls['phone_number'].invalid &&
                              customerForm.controls['phone_number'].touched) || (customerForm.controls['phone_number'].invalid && isSubmited)
                            " class="invalid-form">
                            <small class="form-text text-danger" *ngIf="customerForm.controls['phone_number'].errors.required"
                            [translate]="'AUTH.AUTH_LOGIN.REQUIRED'"></small>
                </div>
                <div *ngIf="
                        customerForm.controls['phone_number'].touched && customerForm.controls['phone_number'].errors
                        " class="invalid-form">
                        <small class="form-text text-danger" *ngIf="customerForm.controls['phone_number'].errors"
                          [translate]="'AUTH.AUTH_LOGIN.PHONE_REQUIRED'"></small>
              </div>
                </div>
                <div class="col-6">
                  <input class="input" id="company" name="company" [placeholder]="'FOOTER.COMPANY' | translate" formControlName="company"/>
                </div>
                
              </div>
        
              <div class="input2 mb-4">
                <div class="col-6">
                  <input class="input" id="position" name="position" [placeholder]="'FOOTER.POSITION' | translate" formControlName="position"/>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button *ngIf="loading;else noLoading" class="btn btn-primary ms-3" type="submit" disabled style="border-radius: 40px">
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                <span translate="FOOTER.REGISTER"></span>
              </button>
              <ng-template #noLoading>
                <button
                  class="btn btn-primary ms-3"
                  rippleEffect
                  style="border-radius: 40px"
                  type="button"
                  [disabled]="!customerForm.valid"
                >
                <span translate="FOOTER.REGISTER"> </span>
              </button>
              
              </ng-template>
              
            </div>
              <ngb-alert style="height: 40px;padding: 8px;" *ngIf="registerSuccess" [type]="'success'" [dismissible]="false" class="mt-2">
                <div class="alert-body">
                    <p translate="FOOTER.REGISTER_SUC"></p>
                </div>
              </ngb-alert>
              <ngb-alert style="height: 40px;padding: 8px;" *ngIf="registerFailure" [type]="'danger'" [dismissible]="false" class="mt-2">
                <div class="alert-body">
                    <p translate="FOOTER.REGISTER_FAIL"></p>
                </div>
              </ngb-alert>
          </form>
          
        </div>
      </div>
    </div>
  </div>