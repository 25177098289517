<div class="slideout-content">
    <!-- Modal to add new user starts-->
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog modal-content pt-0 w-sidebar">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="toggleSidebar('search-history')">
                ×
            </button>
            <div class="modal-header mb-1">
                <h4 class="modal-title
                " translate="TALENT_POOL.SEARCH_HISTORY.TITLE"></h4>

            </div>
            <div class="modal-body flex-grow-1">
                <!-- Sidebar Users start -->
                <div id="users-list" class="chat-user-list-wrapper list-group">
                    <ul class="list-group list-group-flush" style="max-height: 90vh; overflow-y: auto;">
                        <li *ngFor="let _historySearch of historySearch" class="list-group-item" id="history-search">
                            <a type="button" href="javascript:void(0)" (click)="toggleSidebar('search-history')"
                                (click)="detailSearchHistory(_historySearch)" style="color: black;">
                                <div class="row">
                                    <div class="col-12 mb-1"><span class="text-dark"
                                            data-feather="calendar"></span><span style="width:fit-content">
                                            {{_historySearch.created_at|date:'d/M/yyyy, h:mm a'}}</span></div>
                                    <div class="col-4 mb-1" *ngIf="_historySearch?.full_name_bool_expression">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.FULLNAME_FILTER"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.full_name_bool_expression">
                                        <span>{{_historySearch?.full_name_bool_expression}}</span>
                                    </div>

                                    <div class="col-4 mb-1" *ngIf="_historySearch?.fulltext_bool_expression">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.FULLTEXT_FILTER"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.fulltext_bool_expression">
                                        <span>{{_historySearch?.fulltext_bool_expression}}</span>
                                    </div>

                                    <div class="col-4 mb-1" *ngIf="_historySearch?.experience_bool_expression">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.EXPERIENCE_FILTER"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.experience_bool_expression">
                                        <span>{{_historySearch?.experience_bool_expression}}</span>
                                    </div>

                                    <div class="col-4 mb-1" *ngIf="_historySearch?.education_bool_expression">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.EDUCATION_FILTER"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.education_bool_expression">
                                        <span>{{_historySearch?.education_bool_expression}}</span>
                                    </div>

                                    <div class="col-4 mb-1" *ngIf="_historySearch?.current_title_bool_expression">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.CURRENT_TITLE_FILTER"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.current_title_bool_expression">
                                        <span>{{_historySearch?.current_title_bool_expression}}</span>
                                    </div>

                                    <div class="col-4 mb-1" *ngIf="_historySearch?.current_company_bool_expression">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.CURRENT_COMPANY_FILTER"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.current_company_bool_expression">
                                        <span>{{_historySearch?.current_company_bool_expression}}</span>
                                    </div>
                                    <div class="col-4 mb-1" *ngIf="_historySearch?.category?.length>0">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.CATEGORY"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.category?.length>0">
                                        <span>{{_historySearch?.category.toString()}}</span>
                                    </div>
                                    <div class="col-4 mb-1" *ngIf="_historySearch?.skills?.length>0">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.SKILL"></span>
                                    </div>
                                    
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.skills?.length>0">
                                        <span>{{_historySearch?.skills.toString()}}</span>
                                    </div>
                                    <div class="col-4 mb-1" *ngIf="_historySearch?.keywords?.length>0">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.CREATE_JOB.KEYWORDS"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.keywords?.length>0">
                                        <span>{{_historySearch?.keywords.toString()}}</span>
                                    </div>
                                    <div class="col-4 mb-1" *ngIf="_historySearch?.user_talentpool">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.TALENT_POOL"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.user_talentpool">
                                        <span>{{_historySearch?.user_talentpool}}</span>
                                    </div>
                                    <div class="col-4 mb-1"
                                        >
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.YEARS_OF_EXPERIENCE"></span>
                                    </div>
                                    <div class="col-8 mb-1"
                                        >
                                        <div [ngSwitch]="true">
                                            <div
                                                *ngSwitchCase="(_historySearch.years_of_experience_from==0 || _historySearch.years_of_experience_from==null) &&
                                                                (_historySearch.years_of_experience_to==0 || _historySearch.years_of_experience_to==null)">
                                                
                                                <span
                                                        [translate]="'TALENT_POOL.CREATE_JOB.NOT_REQUIRED'"></span>
                                                
                                            </div>
                                            <div
                                                *ngSwitchCase="_historySearch.years_of_experience_from!=null && _historySearch.years_of_experience_from!=0 && _historySearch.years_of_experience_to==null">
                                                
                                               <span
                                                        [translate]="'TALENT_POOL.CREATE_JOB.MINIMUM'"></span><span>
                                                        {{_historySearch.years_of_experience_from}} </span> <span
                                                        [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXP'"></span>
                                            </div>
                                            <div
                                                *ngSwitchCase="(_historySearch.years_of_experience_to!=null && _historySearch.years_of_experience_to!=0) &&
                                                                (_historySearch.years_of_experience_from != _historySearch.years_of_experience_to)">
                                                
                                               
                                                    <span>{{_historySearch.years_of_experience_from||0}}</span><span>-</span><span>{{_historySearch.years_of_experience_to}}</span>
                                                
                                            </div>
                                            <div
                                                *ngSwitchCase="(_historySearch.years_of_experience_from == _historySearch.years_of_experience_to) && 
                                            (_historySearch.years_of_experience_from!=null && _historySearch.years_of_experience_from!=0) &&
                                            (_historySearch.years_of_experience_to!=null && _historySearch.years_of_experience_to!=0)">
                                                
                                                
                                                    <span>{{_historySearch.years_of_experience_from}}</span>
                                                
                                            </div>
                                        </div>
                                        <!-- <span>{{_historySearch?.years_of_experience_from}}</span><span>-</span><span>{{_historySearch?.years_of_experience_to}}</span> -->
                                    </div>
                                    <div class="col-4 mb-1" *ngIf="_historySearch?.city?.length>0">
                                        <span style="font-weight: 800"
                                            translate="TALENT_POOL.SEARCH_RESUME.ADDRESS"></span>
                                    </div>
                                    <div class="col-8 mb-1" *ngIf="_historySearch?.city?.length>0">
                                        <span>{{_historySearch?.city.toString()}}</span>
                                    </div>

                                </div>
                            </a>


                        </li>

                    </ul>
                </div>


            </div>
        </div>
    </div>
    <!-- Modal to add new user Ends-->
</div>