import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { locale as localeEn } from "../i18n/en";
import { locale as localeVn } from "../i18n/vn";
import { CoreConfigService } from '@core/services/config.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../services/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from "ngx-clipboard";
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { AuthenticationService } from 'app/auth/service/authentication.service';

enum PaymentStatus {
  SUCCESS = "00",
  FAILED = "01",
}
@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {

  public coreConfig: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  public transactionInfo: any;

  public PaymentStatus = PaymentStatus;
  public linkImage: string = "";
  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _CoreTranslateService: CoreTranslationService,
    private route: ActivatedRoute,
    public _paymentService: PaymentService,
    private _translateService: TranslateService,
    private _clipboardService: ClipboardService,
    private _toastrService: ToastrService,
    private _authenticationService: AuthenticationService,

  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };

    this._CoreTranslateService.translate(localeEn);
    this._CoreTranslateService.translate(localeVn);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    var hrefResultTransaction = window.location.href.split('payment_return')[1]

    this._paymentService
      .getTransactionInfo(hrefResultTransaction)
      .subscribe((transactionData) => {
        this.transactionInfo = transactionData

        // this.transactionInfo = {
        //   ...transactionData,
        //   amount: transactionData.amount.toLocaleString("en-US", {
        //     maximumFractionDigits: 0,
        //   }),
        //   date: moment(transactionData.updated_at).format("DD/MM/YYYY"),
        //   time: moment(transactionData.updated_at).format("HH:mm A"),
        // };
        
        this.linkImage =
          this.transactionInfo?.RspCode === PaymentStatus.SUCCESS
            ? "assets/images/icons/check.png"
            // : this.transactionInfo?.RspCode == PaymentStatus.PENDING
            // ? "assets/images/icons/pending-icon.svg"
            : 
              this.transactionInfo?.RspCode === PaymentStatus.FAILED
            ? "assets/images/icons/error.png"
            : "assets/images/icons/error.png";
        this.getDetailUser(this._authenticationService.currentUserValue.id);
      });
  }
  getDetailUser(id:any){
    this._authenticationService.detailUser(id).subscribe((res)=>{
      this._authenticationService.setCurrentUserValue(res)
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  copyToThisClipboard(content: string) {
    this._clipboardService.copy(content);
    this._toastrService.success(`${content}`);
  }

}
