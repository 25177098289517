import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "en",
  data: {
    TITLE: "No permission to view this page",
    DESCRIPTION: "Please upgrade your package to have the permission to view this page!",
    UPGRADE_PACKAGE: "Upgrade package"
}
};
