import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "vn",
  data: {
    TITLE: "Không có quyền xem trang này",
    DESCRIPTION: "Bạn hãy nâng cấp gói để có quyền xem trang này!",
    UPGRADE_PACKAGE: "Nâng cấp gói"

}
};
