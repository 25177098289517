import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SearchResume } from 'app/main/talent-pool/models/resume.model';

@Component({
  selector: 'app-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrls: ['./circle-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CircleChartComponent implements OnInit {
  @ViewChild('overScoreChartRef') overScoreChartRef: any;
  @Input() overallScore: any;

  public overScoreChartoptions;
  constructor() {
    this.getOverScore();
   }
  getOverScore(){
    // Goal Overview  Chart
    this.overScoreChartoptions = {
      chart: {
        height: 130,
        type: 'radialBar',
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1
        }
      },
      colors: ['#20c997'],
      plotOptions: {
        radialBar: {
          
          hollow: {
            size: '77%'
          },
          track: {
            background: 'ebe9f1',
            strokeWidth: '50%'
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              color: '#5e5873',
              fontSize: '2rem',
              padding: '5px',
              fontWeight: '500',
              formatter: function (val) {
                return val.toFixed(2);
              }
            }
          }
        }
      },
      
      stroke: {
        lineCap: 'round'
      },
      grid: {
        padding: {
          bottom: 5
        }
      }
    };
  }
  gaugeColor(value:number) {
    if (value > 70) {
      return '#198754'
    } else if(value > 50) {
      return '#ffc107'
    } else{
      return '#dc3545'
    }
  }
  ngOnInit(): void {
    this.overScoreChartoptions.series = [this.overallScore];
    this.overScoreChartoptions.colors = [this.gaugeColor(this.overallScore)]
  }

}
