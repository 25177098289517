<div id="overScoreChartoptions" #overScoreChartRef>
    <!-- Apexchart component-->
    <apx-chart
      [chart]="{
        height: 130,
        
        type: 'radialBar',
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1
        }
      }"
      [colors]="overScoreChartoptions.colors"
      [plotOptions]="overScoreChartoptions.plotOptions"
      [fill]="overScoreChartoptions.fill"
      [series]="overScoreChartoptions.series"
      [stroke]="overScoreChartoptions.stroke"
      [grid]="overScoreChartoptions.grid"
    ></apx-chart>
    <!-- / Apexchart component-->
  </div>