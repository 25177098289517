import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  private BASE_URL = `${environment.apiUrl}`;

  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    public _translateService: TranslateService,
  ) { }
  //Get list package
  listPackage(page: number, page_size: number): Observable<any>{
    const url = `${this.BASE_URL}/package/?page=${page+1}&page_size=${page_size}`;
    return this._http.get<any>(url)
  }
  //Detail package
  detailPackage(id: any): Observable<any>{
    const url = `${this.BASE_URL}/package/${id}`;
    return this._http.get<any>(url)
  }
  //Create package
  createPackage(formData:any): Observable<any>{
    const url = `${this.BASE_URL}/package/`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.ADD_PACKAGE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Update package
  updatePackage(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/package/${id}`;
    return this._http.put<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.UPDATE_PACKAGE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Delete package
  deletePackage(id:any): Observable<any>{
    const url = `${this.BASE_URL}/package/${id}/delete`;
    return this._http.delete<any>(url).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.TOAST.DELETE_R_SUC"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Get list subscription plan
  listSubscriptionPlan(page:number, page_size: number): Observable<any>{
    const url = `${this.BASE_URL}/package/plan?page=${page+1}&page_size=${page_size}`;
    return this._http.get<any>(url)
  }
  //Detail subscription plan
  detailSubscriptionPlan(id: any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan/${id}`;
    return this._http.get<any>(url)
  }
  //Create subscription plan
  createSubscriptionPlan(formData:any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.CREATE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Update subscription plan
  updateSubscriptionPlan(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan/${id}`;
    return this._http.put<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.UPDATE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Delete subscription plan
  deleteSubscriptionPlan(id:any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan/${id}/delete`;
    return this._http.delete<any>(url).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.DELETE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Get list subscription plan cost
  listSubscriptionPlanCost(page: number, page_size: number): Observable<any>{
    const url = `${this.BASE_URL}/package/plan-cost?page=${page+1}&page_size=${page_size}`;
    return this._http.get<any>(url)
  }
  //Detail subscription plan cost
  detailSubscriptionPlanCost(id: any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan-cost/${id}`;
    return this._http.get<any>(url)
  }
  //Create subscription plan cost
  createSubscriptionPlanCost(formData:any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan-cost`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.CREATE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Update subscription plan cost
  updateSubscriptionPlanCost(id: any, formData: any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan-cost/${id}`;
    return this._http.put<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.UPDATE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Delete subscription plan cost
  deleteSubscriptionPlanCost(id:any): Observable<any>{
    const url = `${this.BASE_URL}/package/plan-cost/${id}/delete`;
    return this._http.delete<any>(url).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.DELETE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Add plan cost for company
  addPlanCostForCompany(formData: any):Observable<any>{
    const url = `${this.BASE_URL}/package/plan-cost/company-free-trial`;
    return this._http.post<any>(url,formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.CREATE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
  //Add plan cost for company
  addPlanCostForFreeTrial(formData: any):Observable<any>{
    const url = `${this.BASE_URL}/package/plan-cost/company-free-trial`;
    return this._http.post<any>(url, formData).pipe(
      map((respData)=>{
        setTimeout(() => {
          this._toastrService.success(
            this._translateService.instant("GENERAL.CREATE"),
            this._translateService.instant("GENERAL.TOAST.SUCCESS"),
              {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              }
          );
          }, 300);
          return respData;
      }),
    )
  }
}
