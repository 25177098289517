<!-- Checkout Payment Starts -->
<form id="checkout-payment" class="list-view product-checkout">
    <div class="payment-type">
        <div class="card">
            <div class="card-header flex-column align-items-start">
                <h4 class="card-title" translate="NOTE.TITLE"></h4>
                <br>
                <!-- <p class="card-text text-muted mt-25">Be sure to click on correct payment option</p> -->
                <p class="card-text text-muted mt-25" translate="NOTE.ONE"></p>
                <p class="card-text text-muted mt-25" translate="NOTE.TWO"></p>
            </div>
            <div class="card-body">
                <!-- <h6 class="card-holder-name my-75">John Doe</h6>
                <div class="custom-control custom-radio">
                    <input type="radio" id="customColorRadio1" name="paymentOptions" class="custom-control-input"
                        checked />
                    <label class="custom-control-label" for="customColorRadio1">
                        US Unlocked Debit Card 12XX XXXX XXXX 0000
                    </label>
                </div>
                <div class="customer-cvv mt-1">
                    <div class="form-inline">
                        <label class="mb-50" for="card-holder-cvv">Enter CVV:</label>
                        <input type="password" class="form-control ml-sm-75 ml-0 mb-50 input-cvv" name="input-cvv"
                            id="card-holder-cvv" />
                        <button type="button" class="btn btn-primary btn-cvv ml-0 ml-sm-1 mb-50" rippleEffect>
                            Continue
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="card">
            <div class="card-header flex-column align-items-start">
                <h4 class="card-title" translate="OPTIONS.TITLE"></h4>
            </div>
            <form action="" [formGroup]="paymentForm">
            <div class="card-body">
                <hr class="my-2" />
                <div class="py-50">
                    <div class="d-flex align-items-center" style="height: 70px;">
                        <div class="custom-control custom-radio payment-option">
                        
                            <input type="checkbox" id="vnpayPaymentOption" name="paymentOptions"
                            class="custom-control-input" formControlName="optionVnpay" [checked]="currentlyChecked === check_box_type.VNPAY" (click)="selectCheckBox(check_box_type.VNPAY)"/>
                            <label class="custom-control-label width-label" for="vnpayPaymentOption" translate="OPTIONS.VNPAY">                            
                            </label> 
                    </div>
                    <div><img src="assets/images/payment/vnpay.svg" alt="VNPay" width="50" height="50"/></div>
                    </div>
                </div>
                <div class="py-50">
                    <div class="d-flex align-items-center" style="height: 70px;">
                        <div class="custom-control custom-radio payment-option">
                        
                            <input type="checkbox" id="momoPaymentOption" name="paymentOptions"
                            class="custom-control-input" formControlName="optionMomo" [checked]="currentlyChecked === check_box_type.MOMO" (click)="selectCheckBox(check_box_type.MOMO)"/>
                            <label class="custom-control-label width-label" for="momoPaymentOption" translate="OPTIONS.MOMO"> 
                            </label> 
                    </div>
                    <div><img src="assets/images/payment/momo.svg" alt="MoMo" width="40" height="40" /></div>
                    </div>
                </div>
                <div class="py-50">
                    <div class="d-flex align-items-center">
                        <div class="custom-control custom-radio payment-option">
                        
                            <input type="checkbox" id="paypalPaymentOption" name="paymentOptions"
                            class="custom-control-input" formControlName="optionPaypal" [checked]="currentlyChecked === check_box_type.PAYPAL" (click)="selectCheckBox(check_box_type.PAYPAL)"/>
                            <label class="custom-control-label width-label" for="paypalPaymentOption" translate="OPTIONS.PAYPAL"> 
                            </label>
                    </div>
                    <div><img src="assets/images/payment/paypal.svg" alt="Paypal" width="70" height="70" /></div>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
    <div class="amount-payable checkout-options">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title" translate="DETAILS.TITLE"></h4>
            </div>
            <div class="card-body">
                <ul class="list-unstyled price-details">
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.BUYER"></span></div>
                        <div class="detail-amt">{{currentUser.first_name}} {{currentUser.last_name}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.EMAIL"></span></div>
                        <div class="detail-amt">{{currentUser.email}}</div>
                    </li>
                    <!-- <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.PACKAGE"></span></div>
                        <div class="detail-amt">Free</div>
                    </li> -->
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.NUM_PAGE_PARSING"></span></div>
                        <div class="detail-amt">{{quotaParsing|number:'1.0-0'}} {{'DETAILS.PAGE' | translate}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.NUM_PAGE_MATCHING"></span></div>
                        <div class="detail-amt">{{quotaMatching|number:'1.0-0'}} {{'DETAILS.PAGE' | translate}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="PAYMENT.PARSE_THREAD_LIMIT"></span></div>
                        <div class="detail-amt">{{quotaParseThread|number:'1.0-0'}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="PAYMENT.MATCHING_THREAD_LIMIT"></span></div>
                        <div class="detail-amt">{{quotaMatchingThread|number:'1.0-0'}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="PAYMENT.MAX_USER"></span></div>
                        <div class="detail-amt">{{quotaMaxUser|number:'1.0-0'}}</div>
                    </li>
                    <li class="price-detail">
                        <div class="details-title"><span translate="DETAILS.PRICE"></span></div>
                        <div class="detail-amt">
                            <strong>{{displayAmount}}</strong>
                        </div>
                    </li>
                </ul>
                <hr />
                <ul class="list-unstyled price-details">
                    <li class="price-detail" style="font-size: 18px; font-weight: 600;">
                        <div class="details-title" ><span translate="DETAILS.TOTAL_AMOUNT"></span></div>
                        <div class="detail-amt font-weight-bolder" style="color: #219cd7;">{{displayAmount}}</div>
                    </li>
                </ul>
                
                <div class="place-order-block">
                    
                    <button *ngIf="isLoading;else noLoading" class="btn btn-primary btn-icon mr-2 place-order" disabled>
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        <span translate="DETAILS.PAY"></span>
                      </button>
                      <ng-template #noLoading>
                        <button
                            class="btn btn-primary btn-icon mr-2 place-order"
                            rippleEffect
                            (click)="submitPaymentForm()"
                            [disabled]="!payFormValid"
                            *ngIf="currentUser.role !== 'Free trial user';else noFreeTrial"
                        >
                        <span translate="DETAILS.PAY"> </span>
                      </button>
                        <ng-template #noFreeTrial>
                            <button
                                class="btn btn-primary btn-icon mr-2 place-order"
                                rippleEffect
                                (click)="modalOpenCompanyName(modalCompanyName)"
                                [disabled]="!payFormValid"
                                >
                                <span translate="DETAILS.PAY"> </span>
                            </button>
                            <ng-template #modalCompanyName let-modal>
            
                                <form (ngSubmit)="(NewCompanyNameForm.form.valid)" ngNativeValidate #NewCompanyNameForm="ngForm"
                                    >
                                    <div class="modal-header">
                                        <h4 class="modal-title" [translate]="'DETAILS.COMPANY_INFO'">
                                        </h4>

                                        <!-- <button type="button" class="close"
                                            (click)="modal.dismiss('Cross click')" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button> -->
                                    </div>
                                    <div class="modal-body" ngbAutofocus>
                                        <div class="form-group">
                                            <label for="title"><span
                                                    [translate]="'DETAILS.COMPANY_NAME'"></span><b
                                                    style="color:red">*</b></label>
                                            <input autocomplete="off" [class.error]="!CompanyName.valid && CompanyName.touched"
                                            #CompanyName="ngModel" type="text" id="company_name" [(ngModel)]="company_name" class="form-control"
                                                name="company_name" required/>
                                            <span *ngIf="!CompanyName.valid && CompanyName.touched" class="invalid-form">
                                                <small class="form-text text-danger" *ngIf="CompanyName?.errors?.['required']" [translate]="'DETAILS.REQUIRED'"
                                                    ></small
                                                >
                                                </span>

                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button (click)="modal.dismiss('Cross click')" type="reset" rippleEffect class="btn btn-secondary mr-1"
                                            [translate]="'DETAILS.CANCEL'"></button>
                                        <button [disabled]="!NewCompanyNameForm.form.valid" (click)="submitPaymentForm()" type="button"
                                            (click)="modal.close('Accept click')" class="btn btn-primary"
                                            [translate]="'DETAILS.CONFIRM'"></button>
                                    </div>
                                </form>
                            </ng-template>
                        </ng-template>
                      </ng-template>
                </div>
            </div>
        </div>
    </div>
</form>
