import { sf4c_status } from './../../../talent-pool/models/resume.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Jr, ResumeParser } from '../../models/resume.model';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ResumeParserService } from '../../services/resume-parser.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ActivatedRoute } from '@angular/router';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { status_color } from 'app/main/talent-pool/models/resume.model';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import Swal from 'sweetalert2';
import { Page } from '../../models/paging.model';
import { Subscription, concat, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
const modalFadeAnimation = trigger("modalFadeAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
]);
@Component({
  selector: 'app-detail-talent-pool',
  templateUrl: './detail-talent-pool.component.html',
  styleUrls: ['./detail-talent-pool.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideFade", [
      state(
        "visible",
        style({
          bottom: "20px",
          opacity: 1,
          transform: "translateY(0%)",
        })
      ),
      state(
        "hidden",
        style({
          bottom: "-20px",
          opacity: 0,
          transform: "translateY(100%)",
        })
      ),
      transition("hidden => visible", [animate("0.5s ease-out")]),
      transition("visible => hidden", [animate("0.5s ease-in")]),
    ]),
    modalFadeAnimation,
  ],
})
export class DetailTalentPoolComponent implements OnInit {
  public resumes:ResumeParser[]=[]
  widthColumn: number = 250;
  public basicSelectedOption: number = 5;
  public page = new Page({});
  public ColumnMode = ColumnMode;
  @ViewChild(DatatableComponent) tableResumeTalentPool: DatatableComponent;
  public selectedRows: any[] = [];
  public showActionsPopup: boolean = false;
  public SelectionType = SelectionType;
  assignUsersBoolean: boolean = false;
  isLoading = false;
  public idTalentPool: string
  public idTalentPoolChange: string
  public user: User = new User()
  //upload
  public arr_checkboxJr:string[]=[]
  public arr_checkbox:string[]=[]
  public arr_checkbox_current_title:string[]=[]
  public arr_checkboxValid:any[]=[]



  formCheckBox = this.formBuilder.group({
    checkResumeArray: this.formBuilder.array([], Validators.required)

  })
  // formCheckBoxJr!: FormGroup;

  formCheckBoxJr = this.formBuilder.group({
    checkResumeArrayJr: this.formBuilder.array([],Validators.required)
  })
  formCheckBoxValid = this.formBuilder.group({
    checkResumeArrayValid: this.formBuilder.array([]),
    importedby: [null],
    source: [null],
  })
  public searchText!: string
  public pageJr=1
  public pageCheckValid = 1;
  totalItemsJr: number = 0
  public resultJr: Jr[]=[]
  public have_Jr = false
  public loading_=false
  public isLoadingJr = false;
  public resultCheckValidUpload:any
  public status_color=status_color
  contentHeader: ContentHeader;
  private language: string;
  public sf4cPicklist: any
  INTERVAL_TIME = 5000;
  intervalSubscription: Subscription;
  SearchForm!: FormGroup
  public file_name!: string
  // public status = status
  public status=[ 
    {name: "Approved", value: "Approved"},
    {name: "Done", value: "Done"},
    {name: "Failure", value: "Failure"},
    {name: "Processing", value: "Processing"},
    {name: "Wait to process", value: "Wait_to_process"},
    {name: "Suspended", value: "Suspended"},
    {name: "Modified", value: "Modified"},
  ]
  public sf4c_status=[ 
    {name: "SF wait to upload", value: "SF4C_wait_to_upload"},
    {name: "SF failed", value: "SF4C_failed"},
    {name: "SF uploading", value: "SF4C_uploading"},
    {name: "SF uploaded", value: "SF4C_uploaded"},
    {name: "SF not upload", value: "Not_upload"},
  ]
  job_boards = [
    { name: "Vietnamworks", value: "Vietnamworks" },
    { name: "Careerviet", value: "Careerviet" },
    { name: "TopCV", value: "TopCV" },
    { name: "Careerlink", value: "Careerlink" },
    { name: "Vieclam24h", value: "Vieclam24h" },
    { name: "ITViec", value: "ITViec" },
    { name: "Jobsgo", value: "Jobsgo" },
    { name: "Facebook", value: "Facebook" },
  ]
  public selectedStatus:string[]=[]
  public selectedSf4cStatus:string[]=[]
  public owner__email: string[] = []
  public owner__email_items: any[]=[]
  public source: string[] = []
  public url_dynamic = ""
  public users: User[] = []
  tag_resultConnectSf4c_exit = false;
  public talentPools: any[] = []
  constructor(
    private resumeService: ResumeParserService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _coreTranslateService: CoreTranslationService,
    private _translateService: TranslateService,
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,

  ) {
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });
    this.contentHeader = {
      headerTitle: "",
      actionButton: false,
      breadcrumb: {
        type: "",
        links: [
          {
            name: this.language=='vn'?"Quay lại":"Come back",
            isLink: true,
            link: "/talent-pool"
          },
          {
            name: this.language=='vn'?"Danh sách ứng viên":"Candidates",
            isLink: false,
          },
        ],
      },
    };
  }
  onSubmit(){
    this.page.pageNumber=0
    this.getDetailTalentPool();
  }
  getNameTalentPool(){
    this.resumeService.detailTalentPool(this.idTalentPool).subscribe((res)=>{
      this.contentHeader.headerTitle = res.name
      this.resumeService.setTalentPoolValue(res)
    })
  }
  getListUser(){
    this._authenticationService.listUsers('').subscribe(
      (res)=>{
        this.users = res.results;
        for (let i=0;i<this.users.length;i++){
          let owner__email_item = this.users[i].last_name + ' '+ this.users[i].first_name + ': '+ this.users[i].email
          this.owner__email_items.push({"name":owner__email_item, "value": this.users[i].email, "id":this.users[i].id})
          
        }
        this.owner__email_items = [...this.owner__email_items]
        // this.owner__email_items.push({"name":'All', "value": 'all', "id":999})
        // this.ownerDownload__email_items = this.owner__email_items
      },
      (err)=>{
        console.log(err)
      }
    )
  }
  getDetailTalentPool(){
    this.isLoading = true
    let file_name = "&file_name="+this.file_name
    let url_status = ""
    if(this.selectedStatus.length>0){
      for(let i=0;i<this.selectedStatus.length;i++){
        let url_current_status = "&status="+this.selectedStatus[i]
        url_status = url_status + url_current_status
      }
    }else{
      url_status = ""
    }
    let url_sf4c_status =""
    if(this.selectedSf4cStatus.length>0){
      for(let i=0;i<this.selectedSf4cStatus.length;i++){
        let url_current_status = "&sf4c_status="+this.selectedSf4cStatus[i]
        url_sf4c_status = url_sf4c_status + url_current_status
      }
    }else{
      url_sf4c_status = ""
    }
    // let status = this.selectedStatus==null?"&status=":"&status="+this.selectedStatus
    let owner__email = ""
    if(this.owner__email.length>0){
      for(let i=0;i<this.owner__email.length;i++){
        let url_current_owner_email = "&owner__email="+this.owner__email[i]
        owner__email = owner__email + url_current_owner_email
      }
    }else{
      owner__email = ""
    }
    // let conf_score__gte = "&conf_score__gte="+this.conf_score__gte
    // let conf_score__lte = "&conf_score__lte="+this.conf_score__lte
    let source = ""
    if (this.source.length > 0) {
      for (let i = 0; i < this.source.length; i++) {
        let url_current_source = "&source=" + this.source[i]
        source = source + url_current_source
      }
    } else {
      source = ""
    }
    let url_dynamic = ''
    if(this.file_name!=undefined&&this.file_name!==null){
      url_dynamic = url_dynamic + file_name
    }
    if(this.selectedStatus.length>0){
      url_dynamic = url_dynamic + url_status
    }
    if(this.selectedSf4cStatus.length>0){
      url_dynamic = url_dynamic + url_sf4c_status
    }
    if(this.owner__email!==undefined&&this.owner__email!==null){
      url_dynamic = url_dynamic+owner__email
    }
    if(this.source!==undefined&&this.source!==null){
      url_dynamic = url_dynamic+source
    }
    this.url_dynamic = url_dynamic
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    const api$ = this.resumeService.searchResumes(this.idTalentPool,this.page.pageNumber,this.page.size, url_dynamic)
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.resumeService.searchResumes(this.idTalentPool,this.page.pageNumber,this.page.size, url_dynamic)
      )
    );
    this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
      (res)=>{
        this.resumes = res.results
        this.resumeService.setListResumeValue(res.results)
        const currentPageNumber = this.page.pageNumber;
          const currentPageSize = this.page.size;
          this.page = new Page({
            size: currentPageSize,
            totalElements: res.count,
            pageNumber: currentPageNumber,
          });
          this.saveFilter();
        this.isLoading = false
      },
      (err)=>{
        this.isLoading = false
      }
    )
  }
  ngOnDestroy(): void {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.saveFilter();
    this.getDetailTalentPool()
  }
  setIsSelectedRows(item: any) {
    return this.selectedRows.some((row) => row.id == item.id);
  }
  getFilterSelectedRows(){
    if(this.selectedRows.filter(e=>(e.status=='Approved' || e.sf4c_status=='SF4C_failed')).length>0){
      return true;
    } 
  }
  onSelect({ selected }) {
    if (selected.length == 0) {
      // unselected all rows
      const ids = this.resumes.map((row) => row.id);
      this.selectedRows = this.selectedRows.filter(
        (row) => !ids.includes(row.id)
      );
    } else {
      this.selectedRows = [];
      const ids = this.selectedRows.map((row) => row.id);
      selected.forEach((row) => {
        if (!ids.includes(row.id)) this.selectedRows.push(row);
      });
      // selected.filter(e=>(e.status=='Approved' || e.sf4c_status=='SF4C_failed')).forEach((row) => {
      //   if (!ids.includes(row.id)) this.selectedRows.push(row);
      // });
    }
    if (selected.length > 0) {
      this.intervalSubscription.unsubscribe();
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
      this.getDetailTalentPool();
    }
  }
  unselectAllRows() {
    if (this.tableResumeTalentPool?.selected) this.tableResumeTalentPool.selected = [];
    this.selectedRows = [];
    this.showActionsPopup = false;
  }
  resetTabTemporaryData() {
    this.selectedRows = [];
    this.showActionsPopup = false;
    // this.unselectAllRows();
  }
  changeTalentpoolResume(){
    var formData = {}
    formData['resumes']=this.selectedRows.map((row)=>(row.id))
    formData['talent_pool']=this.idTalentPoolChange
    this.resumeService.changeTalentpoolResume(this.idTalentPool, formData).subscribe((res)=>{
      this.toastr.success(
        this._translateService.instant("RESUME_PARSER.TALENT_POOL.CHANGE_RESUMES"),
        this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
        {
          toastClass: "toast ngx-toastr",
          closeButton: true,
        }
      );
      this.getDetailTalentPool()
      this.modalService.dismissAll();
      this.resetTabTemporaryData();
    },(err)=>{
      this.toastr.error(
        this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.ERROR"),
        this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.FAILED"),
        {
          toastClass: "toast ngx-toastr",
          closeButton: true,
        }
      );
      this.modalService.dismissAll();
    })
  }
  removeResumes(){
    var formData = {}
    formData['resumes']=this.selectedRows.map((row)=>(row.id))
    // formData['talent_pool']=this.idTalentPool
    Swal.fire({
      title: this._translateService.instant("RESUME_PARSER.TALENT_POOL.REMOVE_RESUMES"),
      text: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.resumeService.removeResumeInTalentPool(this.idTalentPool, formData).subscribe((res)=>{
          this.toastr.success(
            this._translateService.instant("RESUME_PARSER.TALENT_POOL.REMOVE_RESUMES"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
          this.getDetailTalentPool()
          this.resetTabTemporaryData();
    
        },
        (err)=>{
          this.toastr.error(
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.ERROR"),
            this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.FAILED"),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            }
          );
        })
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }

  modalOpenUploadSf4c(modalBasic:any) {
    this.pageJr = 1
    this.have_Jr = false
    this.getJr();
    this.modalService.open(modalBasic, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: false,
      backdrop: "static",
    });
    }
    modalOpenCheckValidUpload(modalCheckValid:any){
      this.modalService.open(modalCheckValid, {
        windowClass: 'modal',
        size: 'lg',
        scrollable: true
      });
    }
    getListTalentPool() {
      let url_dynamic = ''
      this.resumeService.listTalentPool(url_dynamic).subscribe((res) => {
        this.talentPools = res.filter((e)=>e.id!=this.idTalentPool)
      })
    }
    modalOpenChangeTalentpool(modalChangeTalentpool: any){
      this.getListTalentPool()
      this.modalService.open(modalChangeTalentpool, {
        windowClass: 'modal',
        size: 'lg',
        scrollable: false,
        backdrop: "static",
      });
    }
    getJr(){
      this.isLoadingJr = true
      let owner = this.user.id
     this.resumeService.getJrWithQuery(owner).subscribe(
      (res)=>{
        this.resultJr= res;
        this.resumeService.setListJrValue(this.resultJr)
        this.totalItemsJr = res.length
        this.have_Jr = true
        this.isLoadingJr = false
      },
      (err)=>{
        console.error("err: "+err)
        this.isLoadingJr = false;
      }
     ) 
    }
  
  onCheckboxChangeJr(e:any) {
    const checkResumeArrayJr: FormArray = this.formCheckBoxJr.get('checkResumeArrayJr') as FormArray;
    if (e.target.checked) {

      checkResumeArrayJr.push(new FormControl(e.target.value));
      
      this.arr_checkboxJr.push(e.target.value)
    } else {
      let i: number = 0;
      checkResumeArrayJr.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayJr.removeAt(i);
          this.arr_checkboxJr=this.arr_checkboxJr.filter(item=>item!==e.target.value)
          return;
        }
        i++;
      });
    }
  }
  onCheckboxChangeValid(e:any) {
    const checkResumeArrayValid: FormArray = this.formCheckBoxValid.get('checkResumeArrayValid') as FormArray;
    if (e.target.checked) {

      checkResumeArrayValid.push(new FormControl(e.target.value));
      
      this.arr_checkboxValid.push(Number(e.target.value))
    } else {
      let i: number = 0;
      checkResumeArrayValid.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkResumeArrayValid.removeAt(i);
          this.arr_checkboxValid=this.arr_checkboxValid.filter(item=>item!==Number(e.target.value))
          return;
        }
        i++;
      });
    }
  }
  FormCheckValidUpload(modalCheckValid: any){
    this.loading_ = true
    let arr_checkbox_cp:any=[]
    let selectedRows = this.selectedRows.filter((e)=>e.status=='Approved' || e.sf4c_status=='SF4C_failed')
    for (let i=0;i<selectedRows.length;i++){
      arr_checkbox_cp.push(selectedRows[i].id)
    }
    this.resumeService.validUpload(arr_checkbox_cp,this.arr_checkboxJr).subscribe(
      (res)=>{
        this.resultCheckValidUpload = res;
        this.loading_ = false
        if(res.invalid_resume_id.length>0){
          this.modalOpenCheckValidUpload(modalCheckValid)
        }else{
          this.FormCheckBoxJr()
        }
      },
      (err)=>{
        console.log("err",err)
        this.formCheckBoxValid.reset();
        // this.errorMessagesSf4cUpload = err[0];
        this.loading_ = false
      }
    )
  }
  FormCheckBoxJr(){
    this.loading_ = true
    let formData = {}
    let arr_checkbox_upload: any = this.resultCheckValidUpload.resume_id.concat(this.arr_checkboxValid)
    let importedby = this.formCheckBoxValid.value.importedby
    formData['resume_id'] = arr_checkbox_upload;
    formData['jobReqId'] = this.arr_checkboxJr;
    formData['CHRTalentpool'] = this.contentHeader.headerTitle;
    if (importedby) {
      formData['importedby'] = importedby.id;
      formData['source'] = importedby.source_id;
    }
    this.resumeService.sf4c_upload(formData).subscribe(

      (res)=>{
        this.selectedRows = []
        this.loading_ = false
        this.modalService.dismissAll();
        this.formCheckBoxJr.reset();
        this.formCheckBoxValid.reset();
        this.resetTabTemporaryData();
        arr_checkbox_upload=[]
        this.arr_checkboxJr = []
        this.getDetailTalentPool();
      },
      (err)=>{
        this.formCheckBoxJr.reset();
        this.formCheckBoxValid.reset();
        this.toastr.warning(err.error.message)
        this.loading_ = false
      }
    )
  }
  cancel(){
    this.searchText=''
    this.arr_checkboxJr = []
  }
  cancelFormCheckboxValid() {
    // this.searchText = ''
    this.searchText=''
    this.arr_checkboxJr = []
    // this.formCheckBoxValid.reset()
  }
  cancelFormCheckBoxJr() {
    // this.searchText = ''
    this.arr_checkboxJr = []
    this.arr_checkboxValid = []
    // this.formCheckBoxJr.reset()
  }
  onCheckboxChange(e:any) {
    const checkResumeArray: FormArray = this.formCheckBox.get('checkResumeArray') as FormArray;
    if (e.target.checked) {
      let id = e.target.value.split('__')[0]
      let current_title = e.target.value.split('__')[1]
      checkResumeArray.push(new FormControl(id));
      this.arr_checkbox.push(id)
      this.arr_checkbox_current_title.push(current_title)
      
    } else {
      let id = e.target.value.split('__')[0]
      let current_title = e.target.value.split('__')[1]
      let i: number = 0;
      checkResumeArray.controls.forEach((item: any) => {
        if (item.value.includes(id) || item.value.includes(current_title)) {

          checkResumeArray.removeAt(i);
          this.arr_checkbox=this.arr_checkbox.filter(item=>item!==id)
          this.arr_checkbox_current_title = this.arr_checkbox_current_title.filter(item=>item!==current_title)      
          return;
        }
        i++;
      });
    }
  }
  FormCheckBox(){
  }
  getSf4cPicklist(){
    this.resumeService.sf4cPicklist().subscribe((res)=>{
      this.sf4cPicklist = res
    })
  }
  saveFilter() {
    this.resumeService.setFilterDocumentsPageTalentpoolValue(
      this.page,
      this.file_name,
      this.selectedStatus,
      this.owner__email,
      this.source
    );
  }
  getDetailCredential() {
    this.resumeService.getDetailCredential(this.user.id).subscribe(
      (res) => {
        if (res) {
          this.tag_resultConnectSf4c_exit = true
        }
      }
    )
  }
  ngOnInit(): void {
    
    this.route.paramMap.subscribe((params)=>{
      this.user = this._authenticationService.currentUserValue
      this.idTalentPool = params.get("id")
      const { page, file_name, selectedStatus, owner__email, source } = this.resumeService.getFilterDocumentsPageTalentpoolValue();
      this.page = page || new Page({});
      this.file_name = file_name || null;
      this.selectedStatus = selectedStatus || [];
      this.owner__email = owner__email || [];
      this.source = source || [];
      this.getDetailCredential();
      this.getListUser();
      this.getDetailTalentPool();
      this.getNameTalentPool();
      this.getSf4cPicklist();
      // const resizeTableObserver = new ResizeObserver((entries) => {
      //   this.widthColumn = entries[0].contentRect.width / 11;
      // });
      // resizeTableObserver.observe(
      //   this.elementRef.nativeElement.querySelector("#ngx-datatable-row-talentpool")
      // );
  
    })
  }

}
