import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityStat, CompanyActivity, ResultPackage, Stat, StatResumeMatching } from 'app/main/resume-parser/models/resume.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private BASE_URL = `${environment.apiUrl}`;
  public currentPackage: Observable<ResultPackage>;
  private currentPackageSubject: BehaviorSubject<ResultPackage>;
  constructor(
    private _http: HttpClient,
  ) {
    this.currentPackageSubject = new BehaviorSubject<ResultPackage>(JSON.parse(localStorage.getItem("currentPackage")));
    this.currentPackage = this.currentPackageSubject.asObservable();
   }
  get currentPackageValue(): ResultPackage{
    return this.currentPackageSubject.value;
  }
  public setCurrentPackageValue(value: ResultPackage){
    this.currentPackageSubject.next(value)
    localStorage.setItem("currentPackage", JSON.stringify(value));

  }
  //Get stat
  getStat(url_dynamic: string): Observable<Stat>{
    const url = `${this.BASE_URL}/resume/stat?`+`${url_dynamic}`;
    return this._http.get<Stat>(url);
  }
  //Get stat resume matching
  getStatResumeMatching(url_dynamic: string): Observable<Stat>{
    const url = `${this.BASE_URL}/resume-matching/sf4c/stat?`+`${url_dynamic}`;
    return this._http.get<Stat>(url)
  }
  //Stat resume matching recruitery
  getStatMatchingRecruitery(page: number, page_size: number): Observable<StatResumeMatching>{
    const url = `${this.BASE_URL}/resume-matching/stat?page=${page}&page_size=${page_size}`;
    return this._http.get<StatResumeMatching>(url)
  }
  //acivity stat
  getStatActivity(url_dynamic: string): Observable<ActivityStat>{
    const url = `${this.BASE_URL}/activity/stat?`+`${url_dynamic}`;
    return this._http.get<any>(url)
  }
  //company acivity list
  companyAcivity(url_dynamic: string, page: number, page_size: number): Observable<CompanyActivity>{
    const url = `${this.BASE_URL}/activity/?page=${page+1}&page_size=${page_size}`+`${url_dynamic}`;
    return this._http.get<any>(url)
  }
  //free trial activity list
  trialActivity(url_dynamic: string,page: number, page_size: number): Observable<CompanyActivity>{
    const url = `${this.BASE_URL}/activity/user/?page=${page+1}&page_size=${page_size}`+`${url_dynamic}`;
    return this._http.get<any>(url)
  }
  //company activity current
  companyActivityCurrent(): Observable<ResultPackage>{
    const url = `${this.BASE_URL}/activity/current`;
    return this._http.get<any>(url)
  }
  userActivityCurrent(): Observable<any>{
    const url = `${this.BASE_URL}/activity/user/current`;
    return this._http.get<any>(url)
  }
  //download stat
  statSearchMatch(): Observable<any>{
    const url = `${this.BASE_URL}/job-description/stat`;
    return this._http.get<any>(url)
  }
  reportUnmatchResume(page: number, page_size: number, company: any):Observable<any>{
    const url = `${this.BASE_URL}/candidate/unmatch-resume?page=${page+1}&page_size=${page_size}&company=${company}`;
    return this._http.get<any>(url)
  }
}
