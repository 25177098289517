export const locale = {
    lang: 'en',
    data: {
      TALENT_POOL: {
        MANAGE_JOB: {
            JOB: "Job",
            JR_ID_CHR: "CHR Job requirement ID",
            ADD_JOB: "Add job",
            SHOW: "Show",
            CATEGORY: "Category",
            TYPE: "Type",
            LEVEL: "Level",
            APPLICATION1: "Recommend candidates",
            APPLICATION2: "Saved candidates",
            APPLICATION3: "Recruitment candidates",
            RECRUITMENT: "recruitment",
            NUM_SAVED_RESUMES: "Number saved resumes",
            HCM_SYSTEM_ID: "SAP SF id",
            OWNER: "Owner",
            ITEMS_PER_PAGE: "Items per page",
            SYNC_SUCCESSFACTORS: "Sync SAP SF JR",
            PLACEHOLDER: 'Search job title',
            RECOMMEND: "Recommend",
            SAVED: "Saved",
            EDIT: "Edit",
            ACTION: "Action",
            MODAL: {
                ACTION: "Action",
                DELETE_J: "Delete job",
                CANT_UNDO: "You won't be able to undo this",
                CANCEL: "Cancel",
                CONFIRM: "Confirm",
                DELETE_REPORT: "Delete report resume"
              },
              TOAST: {
                EDIT_J_SUC: "Edit job",
                CREATE_J_SUC: "Create job",
                DELETE_J_SUC: "Delete job",
                ERROR: "Error",
                FAILED: "Failed",
                SUCCESS: "Success"
              },
              CHOICE_OWNER: "Owner email",
        },
        CREATE_JOB: {
            NEW_JOB: "New Job Offer",
            ADD_JOB: "Add a new job to your company's jobs list.",
            CHANGE_JOB: "Change Job Offer",
            
            REQUIRED: "This field is required!",
            LOCATION: "Location",
            JOB_LEVEL: "Job level",
            INDUSTRY: "Industry",
            JOB_TYPE: "Job type",
            GENDER: "Gender",
            YEARS_OF_EXPERIENCE: "Years of experience",
            YOB: "Yob",
            JOB_DESCRIPTION: "Job description",
            JOB_REQUIREMENT: "Job requirement",
            SUBMIT: "Submit",
            CANCEL: "Cancel",
            SUBMIT_ADD_JOB: "Add job posting",
            SUBMIT_EDIT_JOB: "Edit job posting",
            PLACEHOLDER_INDUSTRY: "Industry- Multiple Choice",
            BASIC_INFO: "Basic information",
            BASIC_REQUIRED: "Basic condition",
            REQUIRES_EXPERTISE: "Requires expertise",
            MINIMUM: "Minimum",
            LESS_THAN: "Less than",
            YEARS_OF_EXP: "years of experience",
            NOT_REQUIRED: "Not required",
            PLACEHOLDER:{
                YOB_FROM: " from",
                YOB_TO: " to",
                YEAR_OF_EXPERIENCE_FROM: " from",
                YEAR_OF_EXPERIENCE_TO: " to"
            },
            PATTERN: "The field may only contain numeric characters.",
            INVALID: "The following value must be greater than the previous value",
            VALID_NUMBER: "The value must be greater than or equal to 0",
            JOB_LEVEL_: {
                STUDENT_INTERNSHIP: "Student/Internship",
                ENTRY_LEVEL: "Entry Level",
                STAFF: "Staff",
                SPECIALIST: "Specialist",
                TEAM_LEADER: "Team Leader/Supervisor",
                DIRECTOR: "Director",
            },
            JOB_TYPE_: {
                FULL_TIME: "Full time",
                PART_TIME: "Part time",
                CONTRACT: "Contract",
                REMOTE: "Remote",
                INTERN: "Intern",
            },
            JOB_TITLE_REQUIRED: "Job posting not created due to lack of job title",
            JOB_DESCRIPTION_REQUIRED: "Job posting not created due to lack of job description",
            JOB_REQUIREMENT_REQUIRED: "Job posting not created due to lack of job requirement",
            KEYWORDS: "Keywords",
            PLACEHOLDER_KEYWORDS: "--Enter keywords--"
        },
        DETAIL_JOB: {
            EDIT_JOB: "Edit job",
            RECOMMEND_RESUME: "Recommended resume",
            SAVED_RESUME: "Saved resume",
            SEARCH_RESUME: "Search candidate",
            DESCRIPTION: "Description",
            REQUIREMENTS: "Requirements",
            KEYWORD_EXPRESSION: "Keyword expression",
            EXPERIENCE: "Experience",
            WORK_LEVEL: "Work level",
            EMPLOYMENT_TYPE: "Employment type",
            BIRTHDAY: "Birthday",
            ALL: "All",
            FULL_NAME: "Full name",
            FIRST_NAME: "First name",
            LAST_NAME: "Last name",
            EMAIL: "Email",
            PHONE_NUMBER: "Phone number",
            MATCHING_SCORE: "Matching score",
            EXPERIENCE_SCORE: "Experience score",
            EDUCATION_SCORE: "Education score",
            PROFILE: "Profile",
            CANDIDATES: "Candidates"
        },
        SEARCH_RESUME: {
            JOB_TITLE: "Job Title",
            FULLTEXT_FILTER: "Fulltext Filter",
            FULLNAME_FILTER: "Fullname Filter",
            EXPERIENCE_FILTER: "Position and experience description filter",
            EDUCATION_FILTER: "Education Filter",
            CURRENT_TITLE_FILTER: "Title Filter",
            CURRENT_COMPANY_FILTER: "Company Filter",
            TALENT_POOL: "Talent pool",
            SKILL: "Skill",
            CATEGORY: "Category",
            ADDRESS: "Address",
            SEARCH: "Search",
            LOADING: "Loading",
            FOUND: "found",
            RESUMES: "candidates found",
            CANDIDATES: "candidates",
            OWNER: "Owner",
            SKILL_SET: "Skillset",
            SAVE_CV: "Save CV",
            RESUME: "Resume",
            BEFORE_SAVED: "already saved to that task",
            SAVED_CV: "Saved CV",
            SELECT_JD: "Select JD",
            NOT_JOB: "You have not created any job request yet, create more here:",
            CREATE_JOB: "Create job",
            PLACEHOLDER_TEXTAREA: "Fill in keywords",
            PLACEHOLDER: "--Enter keyword--",
            PLACEHOLDER_ADDRESS: "--Select province/city--",
            PLACEHOLDER_CATEGORY: "--Select category--",
            PLACEHOLDER_SKILL: "--Add skill--",
            PLACEHOLDER_GENDER: "--Select gender--",
            SEARCH_JOB: "Search job title",
            YEARS_OF_EXPERIENCE: "Years of experience",
            YEARS_OF_EXPERIENCE_FROM_PLACEHOLDER: "From",
            YEARS_OF_EXPERIENCE_TO_PLACEHOLDER: "To",
            LINK_CV: "Link CV",
            JOB_NAME: "Job name - SF",
            JOB_STATUS: "Job status - SF",
            CANDIDATE_STATUS: "Candidate status - SF",
            STATUS: "Status",
            TITLE_SF4C_STATUS: "Candidate's application status",
            JOB_ID: "Job id - SF",
            NO_RESULT: "The candidate does not have SuccessFactors status", 
            NOT_FOUND: "Not found CV",
            SAVE: "Save",
            SELECT_ALL: "Select all candidates",
            EXTRACT_TOOLTIP: "Search exactly by keyword",
            SIMILARITY_TOOLTIP: "Similarity search by keywords",
            REPORT_ERROR: "Your feedback has been saved",
            UPDATE_REPORT: "Your feedback has been edited",
            COMMENT: "Comment",
            REPORT_TEXT: "Click suitable if the profile matches and unsuitable if the profile does not match",
            MATCH: "Match",
            NOT_MATCH: "Not match",
            REPORT: "Report profile",
            REPORT_INFO: "Write reason why you think this resume is not match",
            MATCHED: "Evaluated accordingly",
            NOT_MATCHED: "Evaluated inappropriate",
            EVALUATE: "Evaluate",
            CREATED_BY_EVALUATE: "Reviewer",
            CREATED_TIME_EVALUATE: "Evaluation time",
            JOB: "Job",
            TOOLTIP_RESULT_SEARCH: "Searching data includes successfully parsed resume and company's talentpool"
        },
        RESUME_MATCHING: {
            CHOOSE_STATUS: "--Choose status--",
            CHOOSE_BY_EMAIL: "--Choose created by email--",
            RESUME_MATCH: "recommend times",
            CREATE_AT: "Created at",
            OVERALL_SCORE: "Overall score",
            STATUS: "Status",
            ACTION: "Action",
            JOB_ID: "Job id",
            RESUME_ID: "Resume id",
            CREATED_BY: "Created by",
            RESUME_STATUS: "Resume status",
            JOB_APP_STATUS: "Job application status",
            FULL_NAME: "Full name",
            CURRENT_TITLE: "Current title",
            CURRENT_COMPANY: "Current company",
            FILTER: "Filter",
            CV_LINK: "CV link",
            TITLE: "Title",
            DESCRIPTION: "Description",
            REQUIREMENTS: "Requirements",
            SUBMIT: "Scoring",
            RESET: "Reset",
            REQUIRE: "This field is required",
            OVERSCORE: "Matching score",
            MATCHING_CV: "CV file",
            CV_FILE: "Tải lên CV",
            MATCHING_CV_LINK: "CV link",
            NO_RESULT: "No result"
        },
        RECOMMEND_RESUME: {
            RECOMMENDED_RESUMES: "Recommended candidates",
            FOR_THE_JOB_OF: "for the job of",
            SAVED: "saved",
            
            SAVED_RESUMES: "Saved Resumes",
            RESUME_HAD_SAVED: "Resume number has been saved:",
            TOASTR: {
                SCORE: "The system starts scoring compatibility",
                SUCCESS: "Success",
                FAIL: "Fail"
            },
            FROM: "From",
            TO: "To",
            TIME: "Time",
            SEARCH_OPTION: "Filter by keyword",
            SEARCH_REQUIRED: "Filter before suggesting for",
            RECOMMEND: "Recommend",
            COMPANY_TALENTPOOL: "Filter through the entire candidate pool",
            RECOMMENDATION_FILE: "Recommendation file",
            PERSONAL_PROFILE: "Personal profile",
            ALL_RECORDS: "All resume in talent pool",
            TALENT_POOL: "Talent pool",
            CHOOSE_TALENT_POOL: "--Choose talent pool--"
        },
        SAVED_RESUME: {
            UPLOAD_SF4C: "Upload SuccessFactors",
            SAVED_RESUMES: "Saved resumes",
            PLACEHOLDER: {
                SEARCH: "Search full name",
                SELECTED_IMPORTEDBY: "--Select imported by--",
                SELECTED_SOURCE: "--Select source--"
            },
            MODAL: {
                ACTION: "Action",
                DELETE_R: "Don't save candidate",
                CANT_UNDO: "You won't be able to undo this",
                CANCEL: "Cancel",
                CONFIRM: "Confirm",
                IMPORT: "Import",
                TITLE: "Upload file to extract"
  
              },
              TOAST: {
                EDIT_R_SUC: "Edit resume",
                CREATE_R_SUC: "Create resume",
                DELETE_R_SUC: "Delete resume",
                ERROR: "Error",
                FAILED: "Failed",
                SUCCESS: "Success"
              },
        },
        RESUME_TALENT_POOL: {
            AT: "at",
            WORK: "Work ",
            DETAIL: "Details",
            SAVE: "Save",
            UNSAVE: "Unsave",
            STATUS: "Status:",
            OVERALL_SCORE: "Overall score: ",
            EXPERIENCE_SCORE: "Experience: ",
            EDUCATION_SCORE: "Education: ",
            LANGUAGE_SCORE: "Language: ",
            SKILL_SCORE: "Skill:",
            INFO_SCORE: "Scoring information",
            LOADING: "Calculating the candidate's suitability score",
            EXPERIENCE: "Experience",
            EDUCATION: "Education",
            SAVED: "Saved",
            OWNER: "Owner:",
            MODIFIED_AT: "Modified at",
            NEW: "New",
            STATUS_COLOR: {
              APPROVED: "Approved",
              DONE: "Done",
              FAILURE: "Failure",
              PROCESSING: "Processing",
              WAIT_TO_PROCESS: "Wait to process",
              SUSPENDED: "Suspended",
              MODIFIED: "Modified"
            },
            DETAIL_SCORE: "Detail score",
            DETAIL_EXPERIENCE: {
              TEXT_IN_JD: "Text in JD",
              TYPE: "Properties",
              TEXT_IN_CANDIDATE: "Text in candidate",
              SCORE: "Score"
            },
            DETAIL_SKILL: {
              TEXT_IN_JD: "Text in JD",
              TEXT_IN_CANDIDATE: "Text in candidate"
            } 
        },
        DETAIL_CANDIDATE: {
            INFORMATION_BASIC: "Personal information",
            NOT_FOUND: "No profile found or not allow to access",
            ADDRESS: "Location",
            EMAIL: "Email",
            PHONE_NUMBER: "Phone number",
            GENDER: "Gender",
            DATE_OF_BIRTH: "Date Of Birth",
            CURRENT_COMPANY: "Current Company",
            CURRENT_TITLE: "Current Title",
            YEAR_EXPERIENCE: "Year experience",
            EDUCATION: "Education & Training",
            WORK_EXPERIENCE: "Work Experience",
            CATEGORY: "Category",
            REFERENCES: "References",
            DIPLOMA: "Diploma",
            INDUSTRY: "Industry",
            POSITION: "Position",
            COMPANY: "Company",
            JOB_DESCRIPTION: "Job description",
            MAJOR: "Major",
            SCHOOL: "School",
            GPA: "GPA",
            AT: "at",
            SKILL: "Skill",
            OWNER: "Owner",
            SHARE_RESUME: "Request to share profile",
            REQUEST_TO_SHARE: "Request to share profile successfully. Check email!",
            SHARED: "Profile has been shared",
            STATUS: {
                APPROVED: "Approved",
                DONE: "Done",
                FAILURE: "Failure",
                PROCESSING: "Processing",
                WAIT_TO_PROCESS: "Wait to process",
                SUSPENDED: "Suspended",
                MODIFIED: "Modified",
                DUPLICATED: "Duplicated"
              },
              STATUS_SF4C:{
                SF4C_BACKGROUND_FAILED: "SF background failed",
                SF4C_WAIT: "SF wait to upload",
                SF4C_FAILED: "SF failed",
                SF4C_UPLOADING: "SF uploading",
                SF4C_UPLOADED: "SF uploaded",
                SF4C_NOT_UPLOAD: "SF not upload"
              },
              GENDER_:{
                MALE: "Male",
                FEMALE: "Female",
              },
        },
        SEARCH_HISTORY: {
          SEARCH_HISTORY: "Search history",
          TITLE: "Candidate search history",
          CREATE_BY: "Create by"
        }

      }
      
    }
  };
  