import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "vn",
  data: {
    SHOW: "Hiển thị",
    NAME: "Tên API key",
    CREATED: "Ngày tạo",
    EXPIRED: "Ngày hết hạn",
    STATUS: "Trạng thái",
    ACTION: "Hành động",
    WORK: "Hoạt động",
    NO_WORK: "Không hoạt động",
    NO_LIMIT: "Không giới hạn",

    CREATE_API_KEY: {
        CREATE: "Tạo API key",
        PASSWORD: "Nhập mật khẩu để xác thực",
        EXPIRED_CREATE: "Thời gian hết hạn (Có thể để trống)",
        CANCEL: "Hủy",
        SAVE: "Lưu thay đổi",
        PASSWORD_REQUIRED: "Mật khẩu yêu cầu bắt buộc",
        NAME_REQUIRED: "Tên API key yêu cầu bắt buộc",
    },
    TOASTR: {
        COPY_TO_CLIPBOARD:
        "API key đã sao chép",
        SUCCESS: "Thành công",
        SAME: "Trùng tên API key"
    },
    MODAL: {
        ACTION: "Hành động",
        DELETE_A: "Xóa API key",
        CANT_UNDO: "Bạn không thể hoàn tác",
        CANCEL: "Hủy",
        CONFIRM: "Xác nhận",
      },
    TOAST: {
        DELETE_A_SUC: "Xóa API key",
        ERROR: "Lỗi khi thực hiện",
        FAILED: "Thất bại",
        SUCCESS: "Thành công"
    },
}
};
