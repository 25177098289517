<div class="content-wrapper container-xxxl p-0">
    <div class="card">
      <div class="content-body card-body">
        <h3 [translate]="'RESUME_PARSER.TALENT_POOL.TITLE'">Talent pool</h3>
        <div class="row align-items-center justify-content-between">
          <div class="row my-3 ml-0 col-10">
            <div class="col-4 col-xxl-4 col-md-4">
              <div class="form-group input-group input-group-merge mb-0" id="search_input">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i data-feather="search"></i></span>
                </div>
                <input type="text" class="form-control" id="icons-search" [(ngModel)]="searchText"
                  (input)="searchFolder()" [placeholder]="'RESUME_PARSER.TALENT_POOL.PLACEHOLDER_SEARCH' | translate" />
              </div>
            </div>
            <div class="col-4 col-xxl-4 col-md-4 pl-0" *ngIf="user.role=='Site Admin' ||user.role=='Super admin' || user.role=='Admin' || user.is_superuser">

              <ng-select [multiple]="true" [items]="owner__email_items" bindLabel="name" bindValue="value"
                [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate" name="owner__email"
                [(ngModel)]="owner__email" id="owner__email" (ngModelChange)="searchFolder()">
  
              </ng-select>
  
            </div>
            <div class="col-3 col-xxl-3 col-md-3 pl-0">
              <button id="add_folder_btn" class="btn btn-primary m-0"
                (click)="addModalFolder(modalFolder)" [translate]="'RESUME_PARSER.TALENT_POOL.ADD_FOLDER'">
              </button>
            </div>
  
          </div>
        </div>
  
        <div class="row" id="list_folder">
          <ng-container *ngFor="let folder of talentPools; let i = index">
            <div class="col col-sm-6 col-md-4 col-lg-3 col-xxl-2">
              <div class="w-md-1 btn mt-2 card justify-content-between a-folder" style="
                    margin-bottom: 0rem;
                    display: flex;
                    flex-direction: row;
                    box-shadow: none;
                  " [tabindex]="i + 1" (keydown)="onKeyDown($event, i)" (click)="toListDocument(folder.id)">
                <div 
                  class="d-flex align-items-center justify-content-between" style="width: 90%">
                  <a class="d-flex align-items-center text-truncate col-11" [ngbTooltip]="folder.name"
                    id="color-text-folder">
                    <i class="fa fa-folder" aria-hidden="true" style="margin-right: 8px; font-size: large"></i>
  
                    <span>{{ folder.name }}</span>
                  </a>
                </div>
  
                <div ngbDropdown style="width: 10%" (click)="toggle($event)">
                  <button ngbDropdownToggle type="button" class="btn icon-btn hide-arrow btn-sm px-0" rippleEffect>
                    <i data-feather="more-vertical" size="18" class="cursor-pointer"></i>
                  </button>
                  <div ngbDropdownMenu>
                    <a ngbDropdownItem (click)="
                          addModalFolder(modalFolder, {
                            name: folder.name,
                            id: folder.id
                          })
                        "><i data-feather="edit" class="mr-1"></i><span
                        [translate]="'RESUME_PARSER.TALENT_POOL.EDIT_FOLDER'">Edit folder name</span></a>
                    <a 
                      (click)="deleteTalentPool(folder.id)" ngbDropdownItem><i data-feather="trash" class="mr-1"></i><span
                        [translate]="'RESUME_PARSER.TALENT_POOL.DELETE_FOLDER'">Delete folder</span></a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #modalFolder let-modal>
          <ng-modal class="modalAddFolder">
            <modal-header class="modal-header">
              <h4 class="modal-title" [translate]="editFolderBoolean?'RESUME_PARSER.TALENT_POOL.EDIT_FOLDER':'RESUME_PARSER.TALENT_POOL.ADD_FOLDER'">
                Add folder
              </h4>
            </modal-header>
            <modal-content>
              <form class="form form-vertical" [formGroup]="createFolderForm">
                <div class="modal-body flex-grow-1" tabindex="-99">
                  <div class="form-group">
                    <label class="form-label" for="basic-icon-default-fullname"><span
                        [translate]="'RESUME_PARSER.TALENT_POOL.NAME_FOLDER'">Name folder</span><span
                        style="color: red; padding-left: 5px">*</span></label>
                    <input type="text" class="form-control dt-full-name" id="name" formControlName="name"
                      placeholder="" autocomplete="off" />
                      <span
                      *ngIf="
                        createFolderForm.controls.name.invalid &&
                        createFolderForm.controls.name.touched
                      "
                      class="invalid-form"
                    >
                      <small
                        class="form-text text-danger"
                        *ngIf="createFolderForm.controls.name.errors.required"
                        
                        [translate]="'RESUME_PARSER.TALENT_POOL.REQUIRED'">This field is required</small>
                    </span>
                  </div>

                  <button (click)="onCreateNewOrUpdateFolder()" [disabled]="!createFolderForm.valid" class="btn btn-primary mr-1">
                    <span [translate]="editFolderBoolean?'RESUME_PARSER.TALENT_POOL.EDIT':'RESUME_PARSER.TALENT_POOL.CREATE'">Create</span>
                  </button>
                  <button type="reset" class="btn btn-outline-secondary" (click)="_modalService.dismissAll()"
                    rippleEffect>
                    <span [translate]="'RESUME_PARSER.TALENT_POOL.CANCEL'">Cancel</span>
                  </button>
                </div>
              </form>
            </modal-content>
          </ng-modal>
        </ng-template>
      </div>
    </div>
  </div>