export const locale = {
    lang: 'en',
    data: {
        ACCOUNT_SETTING:{
            ACCOUNT_SETTING: "Account settings",
            LOGIN: {
                TITLE: "👋 Welcome!",
                LOGIN_ERROR: "Email or password incorrect",
                WELCOME_CHR: "Welcome to CHR!👋",
                PLEASE_SIGN: "Please sign-in to your account and start the adventure",
                EMAIL: "Email",
                EMAIL_REQUIRED: "Email is required",
                EMAIL_VALID: "Email must be a valid email address",
                PASSWORD: "Password",
                FORGOT_PASSWORD: "Forgot password?",
                FORGOT_PASSWORD_TITLE: "Forgot password?🔒",
                PASSWORD_REQUIRED: "Password is required",
                REMEMBER_ME: "Remember Me",
                SIGN_IN: "Sign in",
                NEW_PLATFORM: "New on our platform?",
                SEND_EMAIL: "Enter your email and we'll send you instructions to reset your password",
                SEND_NEW_PASSWORD: "Send new password",
                BACK_TO_LOGIN: "Back to login",
                CHECK_EMAIL: "Check your email to activate account!",
            },
            GENERAL:{
                TITLE: "General",
                FIRST_NAME: "First name",
                LAST_NAME: "Last name",
                EMAIL: "Email",
                SAVE: "Save",
                CANCEL: "Cancel",
                REQUIRED: "This field is required",
                EDIT: "Edit"
            },
            PASSWORD: {
                TITLE: "Change password",
                OLD_PASS: "Old password",
                NEW_PASS: "New password",
                NOT_MATCH: "New password not matches",
                RETYPE_PASS: "Retype new password",
                NOT_SAME: "Passwords do not match"
            },
            RESUME_SETTINGS: {
                TITLE: "Resume settings",
                AUTO_APPROVE: "Auto approve done resume",
                AUTO_SYNC: "Auto sync email",
                CENSOR_CONTACT: "Censor contact",
                APPROVE_SCORE: "Approve score",
                WEBHOOK_PARSING: "Webhook parsing api",
                WEBHOOK_DEV_PARSING: "Webhook dev parsing api",
                WEBHOOK_MATCHING: "Webhook matching api",
                WEBHOOK_DEV_MATCHING: "Webhook dev matching api",
                WEBHOOK_RECOMMEND:"Webhook recommend",
                WEBHOOK_RECOMMEND_DEV_API: "Webhook recommend dev api",
                LIMIT_APPROVE_SCORE: "Value must be between 0 and 1",
            },
            CONNECT_SF4C: {
                TITLE: "Connect to SAP SF",
                EDIT_CRED: "Edit credential",
                UPLOAD_FILE: "Upload file PEM",
                API_KEY: "API key",
                COMPANY_ID: "Company id",
                USER_NAME: "User name",
                TOKEN_URL: "API server URL",
                ACCESS_URL: "Access URL",
                INFO_CREDENTIAL: "Credential list",
                DETAIL_CREDENTIAL: "Information credential",
                USER: "User",
                CLIENT_ID: "Client id",
                MODIFIED_AT: "Created at",
                EXPIRE_IN_DAYS: "Expire in days",
                YOUR_CREDENTIAL: "Your credential",
                CREATE_CREDENTIAL: "Create credential",
                MODAL: {
                    ACTION: "Action",
                    DELETE_C: "Delete credential",
                    CANT_UNDO: "You won't be able to undo this",
                    CANCEL: "Cancel",
                    CONFIRM: "Confirm",
                    DELETE_M: "Delete mapping picklist"
                  },
                  TOAST: {
                    DELETE_C_SUC: "Delete credential",
                    ERROR: "Error",
                    FAILED: "Failed",
                    SUCCESS: "Success"
                  },
            },
            SOURCE_MAPPING: {
                TITLE: "Source mapping",
                RESUME_CONFIG: "Resume config",
                GENDER: "Gender",
                ADDRESS: "Address",
                PHONE_NUMBER: "Phone number",
                FIRST_NAME: "First name",
                LAST_NAME: "Last name",
                DATE_OF_BIRTH: "Date of birth",
                EMAIL: "Email",
                CURRENT_TITLE: "Current title",
                CURRENT_COMPANY: "Current company",
                EDUCATION: "Education",
                START_TIME: "Start time",
                END_TIME: "End time",
                MAJOR: "Major",
                SCHOOL: "School",
                DIPLOMA: "Diploma",
                GPA: "Gpa",
                EXPERIENCE: "Experience",
                POSITION: "Position",
                COMPANY: "Company",
                DETAIL: "Detail",
                JOB_APP_CONFIG: "Job application config",
                MATCHING_SCORE: "Matching score",
                AUTO_UPLOAD_SCORE: "Auto upload score",
                PICKLIST_DIPLOMA: "Picklist diploma",
                PICKLIST_INDUSTRY: "Picklist industry",
                PICKLIST_MAJOR: "Picklist major"

                
            },
            ACTIVITY_HISTORY:{
                TITLE: "User list",
                USERS: "users",
                CREATE_USER: "Create user",
                EDIT_USER: "Update user",
                DELETE_USER: "Delete user",
                NAME: "Name",
                EMAIL: "Email",
                COMPANY: "Parent company",
                SUB_COMPANY: "Company",
                PASSWORD: "Password",
                PASSWORD_REQUIRED: "Password is required",
                SUBMIT: "Create",
                CURRENT_PACKAGE: "Current package",
                PERMISSION_PARSE: "Permission parse resume",
                PERMISSION_SEARCH: "Permission search engine",
                PERMISSION_TALENT: "Permissions talent pool",
                IS_ACTIVE: "Is active",
                IS_SITE_ADMIN: "Is site admin",
                LAST_LOGIN: "Last login",
                ACTION: "Action",
                ASSIGN_USER: "Assign to new user",
                CANCEL: "Cancel",
                ACCEPT: "Accept",
                REQUIRED: "This field is required",
                EMAIL_CHECK: "Email must be a valid email address",
                ITEMS_PER_PAGE: "Items per page",
                SHOW: "Show",
                PLACEHOLDER: {
                    SEARCH_EMAIL: "Search email"
                },
                NOTE_ASSIGN_USER: "If a document manager is not designated, the document management rights will be transferred to the person requesting the deletion"
            },
            MAPPING_PICKLIST: {
                TITLE: "Mapping picklist",
                HCM_SYSTEM_LABEL_ID: "SAP SF id",
                HCM_SYSTEM_ENG_LABEL: "SAP SF name (English)",
                HCM_SYSTEM_VN_LABEL: "SAP SF name (Vietnamese)",
                COMPANY: "Company",
                CHR_LABEL: "CHR picklist name",
                CHR_LABEL_NAME: "CHR name",
                CHR_TYPE: "CHR picklist type",
                REQUIRED: "This field is required",
                CREATE: "Create",
                EDIT: "Edit",
                CANCEL: "Cancel",
                CREATE_MAPPING_PICKLIST: "Create mapping picklist",
                EDIT_MAPPING_PICKLIST: "Edit mapping picklist",
                NOTE_PICKLIST: "The picklist information on the system needs to be accurate to avoid impacting system operations"
            }
          }
      
    }
  };
  