<div class="content-wrapper container-xxxl p-0">
  <div class="content-body card-body">
    <div id="user-profile">
      <!-- profile header -->
      <div class="row">
        <div class="col-12">
          <div class="card profile-header mb-2">
            <!-- tabs pill -->
            <div class="profile-header-nav">
              <!-- navbar -->
              <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">


                <!-- collapse  -->
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <div class="profile-tabs d-flex justify-content-end flex-wrap mt-1 mt-md-0">
                    <!-- edit button -->
                    <a class="btn btn-warning mr-1" routerLink="/manage-job/edit-job/{{detail_jd.id}}"
                      [translate]="'TALENT_POOL.DETAIL_JOB.EDIT_JOB'">

                    </a>
                    <a class="btn btn-success mr-1" (click)="modalOpenFilterRecommend(modalBasicFilterRecommend)"
                      [translate]="'TALENT_POOL.DETAIL_JOB.RECOMMEND_RESUME'">

                    </a>
                    <ng-template #modalBasicFilterRecommend let-modal>
                      <form>
                        <div class="modal-header">
                          <h4 class="modal-title">
                            <span [translate]="'TALENT_POOL.RECOMMEND_RESUME.SEARCH_REQUIRED'"></span>
                            <span class="font-weight-bolder"> {{detail_jd.job_title}}</span>

                          </h4>
                        </div>
                        <div class="modal-body" ngbAutofocus>
                          <div class="form-group">
                            <div class="col-form-label">
                              <label for="job_title"><b [translate]="'TALENT_POOL.RECOMMEND_RESUME.TIME'"></b></label>
                            </div>
                            <div class="row align-items-center">
                              <div class="col-6">
                                <ng2-flatpickr [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.FROM'|translate"
                                  [config]="dateTimeOptions" [(ngModel)]="from" name="from" id="from"></ng2-flatpickr>
                                <!-- <input [(ngModel)]="from" bsDatepicker type="text" class="form-control" id="from" name="from"
                                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" autocomplete="off"
                                  [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.FROM'|translate" /> -->
                              </div>

                              <div class="col-6">
                                <ng2-flatpickr [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.TO'|translate"
                                  [config]="dateTimeOptions" [(ngModel)]="to" name="to" id="to"></ng2-flatpickr>

                                <!-- <input [(ngModel)]="to" bsDatepicker type="text" class="form-control" id="to" name="to"
                                  [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" autocomplete="off"
                                  [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.TO'|translate" /> -->
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="row align-items-center">
                              <div class="col-6">
                                <div class=" col-form-label">
                                  <label for="selectedCity"><b
                                      [translate]="'TALENT_POOL.SEARCH_RESUME.ADDRESS'"></b></label>
                                </div>
                                <div class="row">
                                  <div class="col-md-12 col-12">
                                    <ng-select [items]="city" bindLabel="name" bindValue="value"
                                      [placeholder]="'TALENT_POOL.SEARCH_RESUME.PLACEHOLDER_ADDRESS'|translate"
                                      [(ngModel)]="selectedCity" [multiple]="true" name="selectedCity">
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                  <label for="selectedType"><b
                                      [translate]="'TALENT_POOL.RECOMMEND_RESUME.RECOMMENDATION_FILE'">Kho du
                                      lieu</b></label>

                                  <div *ngFor="let type of typeTalentPoolItems" class="ml-2">
                                    <input type="radio" name="selectedType" [id]="type.name" class="form-check-input"
                                      [(ngModel)]="selectedType" [value]="type.id" />

                                    <label for="selectedType" *ngIf="language=='vn';else EN">{{type.name}}</label>
                                    <ng-template #EN>
                                      <label for="selectedType">{{type.name_en}}</label>
                                    </ng-template>
                                  </div>
                                  <ng-select *ngIf="selectedType==3 && language=='vn';else talentpool_EN"
                                    [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.CHOOSE_TALENT_POOL'|translate"
                                    [searchable]="false" [items]="talentPools" bindLabel="name" bindValue="id"
                                    [(ngModel)]="talent_pool" name="talent_pool">
                                  </ng-select>
                                  <ng-template #talentpool_EN>
                                    <ng-select
                                      [placeholder]="'TALENT_POOL.RECOMMEND_RESUME.CHOOSE_TALENT_POOL'|translate"
                                      [searchable]="false" [items]="talentPools" bindLabel="name_en" bindValue="id"
                                      [(ngModel)]="talent_pool" name="talent_pool">
                                    </ng-select>
                                  </ng-template>

                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="modal-footer">
                          <button (click)="modal.close('Accept click')" type="reset" rippleEffect
                            class="btn btn-secondary mr-1"
                            [translate]="'TALENT_POOL.MANAGE_JOB.MODAL.CANCEL'">Cancel</button>
                          <button [translate]="'TALENT_POOL.RECOMMEND_RESUME.RECOMMEND'" type="button"
                            (click)="filterRecommend()" (click)="modal.close('Accept click')"
                            class="btn btn-primary">Recommend</button>
                        </div>
                      </form>
                    </ng-template>

                    <a class="btn btn-info mr-1" routerLink="/manage-job/saved-cvs/{{detail_jd.id}}"
                      [translate]="'TALENT_POOL.DETAIL_JOB.SAVED_RESUME'">

                    </a>
                    <!-- <a class="btn btn-primary" routerLink="/search-resume" [translate]="'TALENT_POOL.DETAIL_JOB.SEARCH_RESUME'">
                          
                        </a> -->
                  </div>
                </div>
                <!--/ collapse  -->
              </nav>
              <!--/ navbar -->
            </div>
          </div>
        </div>
      </div>
      <!--/ profile header -->

      <!-- profile info section -->
      <section id="profile-info">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <ul ngbNav #nav="ngbNav" class="nav nav-tabs" [(activeId)]="selectedTab">
          <li [ngbNavItem]="'profile'">
            <a ngbNavLink (click)="clickTab('profile')" class="d-flex align-items-center tab-metadata">
              <span id="tab-object" class="d-none d-sm-block"
                [translate]="'TALENT_POOL.DETAIL_JOB.PROFILE'">Detail</span></a>
            <ng-template ngbNavContent>

              <!-- labels Tab starts -->
              <div class="tab-pane" id="labels" aria-labelledby="labels-tab" role="tabpanel">
                <div class="row">
                  <!-- left profile info section -->
                  <div class="col-lg-9 col-12 order-2 order-lg-1">
                    <!-- about -->
                    <div class="card">
                      <div class="card-body">
                        <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.SEARCH_RESUME.JOB_TITLE'"></h5>
                        <p class="card-text ml-1">
                          {{detail_jd.job_title}}
                        </p>
                        <div class="mt-2">
                          <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.SEARCH_RESUME.OWNER'"></h5>
                          <p class="card-text ml-1">{{ detail_jd.owner }}</p>
                        </div>
                        <div class="mt-2">
                          <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.RESUME_MATCHING.CREATE_AT'">
                          </h5>
                          <p class="card-text ml-1">{{ detail_jd.created_at|date: 'dd/MM/yyyy h:mm a' }}</p>
                        </div>
                        <div class="mt-2">
                          <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.CREATE_JOB.KEYWORDS'">
                          </h5>
                          <a href="javascript:void(0);" *ngFor="let keyword of detail_jd.keywords">
                            <div class="badge badge-pill bg-light-primary mr-50">
                              {{ keyword }}
                            </div>
                          </a>
                        </div>
                        <div class="mt-2">
                          <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.CREATE_JOB.JOB_DESCRIPTION'">
                          </h5>
                          <p *ngIf="detail_jd.description;else noJobDescription" class="card-text ml-1"
                            [innerHTML]="detail_jd.description">
                          </p>
                          <ng-template #noJobDescription>

                          </ng-template>
                        </div>
                        <div class="mt-2">
                          <h5 class="mb-50 font-weight-bolder" [translate]="'TALENT_POOL.CREATE_JOB.JOB_REQUIREMENT'">
                          </h5>
                          <p *ngIf="detail_jd.requirement;else noRequirements" class="card-text ml-1"
                            [innerHTML]="detail_jd.requirement">
                          </p>
                          <ng-template #noRequirements>

                          </ng-template>
                        </div>

                      </div>
                    </div>
                    <!--/ about -->
                  </div>
                  <!--/ left profile info section -->

                  <!-- right profile info section -->
                  <div class="col-lg-3 col-12 order-3">
                    <!-- latest profile pictures -->
                    <div class="card">
                      <div class="card-body">
                        <h3 class="mb-0" [translate]="'TALENT_POOL.CREATE_JOB.BASIC_REQUIRED'"></h3>
                        <div [ngSwitch]="true" class="mt-2">
                          <div
                            *ngSwitchCase="(detail_jd.years_of_experience_from==0 || detail_jd.years_of_experience_from==null) &&
                                              (detail_jd.years_of_experience_to==0 || detail_jd.years_of_experience_to==null)">
                            <h5 class="mb-75 font-weight-bolder"
                              [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXPERIENCE'"></h5>
                            <p class="card-text ml-1"><span [translate]="'TALENT_POOL.CREATE_JOB.NOT_REQUIRED'"></span>
                            </p>
                          </div>
                          <div
                            *ngSwitchCase="detail_jd.years_of_experience_from!=null && detail_jd.years_of_experience_from!=0 && detail_jd.years_of_experience_to==null">
                            <h5 class="mb-75 font-weight-bolder"
                              [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXPERIENCE'"></h5>
                            <p class="card-text ml-1"><span [translate]="'TALENT_POOL.CREATE_JOB.MINIMUM'"></span><span>
                                {{detail_jd.years_of_experience_from}} </span> <span
                                [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXP'"></span></p>
                          </div>
                          <div
                            *ngSwitchCase="(detail_jd.years_of_experience_to!=null && detail_jd.years_of_experience_to!=0) &&
                                              (detail_jd.years_of_experience_from != detail_jd.years_of_experience_to)">
                            <h5 class="mb-75 font-weight-bolder"
                              [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXPERIENCE'"></h5>
                            <p class="card-text ml-1">
                              <span>{{detail_jd.years_of_experience_from||0}}</span><span>-</span><span>{{detail_jd.years_of_experience_to}}</span>
                            </p>
                          </div>
                          <div *ngSwitchCase="(detail_jd.years_of_experience_from == detail_jd.years_of_experience_to) && 
                          (detail_jd.years_of_experience_from!=null && detail_jd.years_of_experience_from!=0) &&
                          (detail_jd.years_of_experience_to!=null && detail_jd.years_of_experience_to!=0)">
                            <h5 class="mb-75 font-weight-bolder"
                              [translate]="'TALENT_POOL.CREATE_JOB.YEARS_OF_EXPERIENCE'"></h5>
                            <p class="card-text ml-1">
                              <span>{{detail_jd.years_of_experience_from}}</span>
                            </p>
                          </div>
                        </div>
                        <div class="mt-2">
                          <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.DETAIL_JOB.WORK_LEVEL'"></h5>
                          <p *ngIf="detail_jd.job_level=='Student/Internship'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL_.STUDENT_INTERNSHIP'"></p>
                          <p *ngIf="detail_jd.job_level=='Entry Level'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL_.ENTRY_LEVEL'"></p>
                          <p *ngIf="detail_jd.job_level=='Staff'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL_.STAFF'"></p>
                          <p *ngIf="detail_jd.job_level=='Specialist'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL_.SPECIALIST'"></p>
                          <p *ngIf="detail_jd.job_level=='Team Leader/Supervisor'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL_.TEAM_LEADER'"></p>
                          <p *ngIf="detail_jd.job_level=='Director'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_LEVEL_.DIRECTOR'"></p>
                          <p *ngIf="detail_jd.job_level==null" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.NOT_REQUIRED'"></p>

                        </div>
                        <div class="mt-2">
                          <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.DETAIL_JOB.EMPLOYMENT_TYPE'">
                          </h5>
                          <p *ngIf="detail_jd.job_type=='full_time'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_TYPE_.FULL_TIME'"></p>
                          <p *ngIf="detail_jd.job_type=='part_time'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_TYPE_.PART_TIME'"></p>
                          <p *ngIf="detail_jd.job_type=='contract'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_TYPE_.CONTRACT'"></p>
                          <p *ngIf="detail_jd.job_type=='remote'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_TYPE_.REMOTE'"></p>
                          <p *ngIf="detail_jd.job_type=='intern'" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.JOB_TYPE_.INTERN'"></p>
                          <p *ngIf="detail_jd.job_type==null" class="card-text ml-1"
                            [translate]="'TALENT_POOL.CREATE_JOB.NOT_REQUIRED'"></p>
                        </div>
                        <!-- <div class="mt-2">
                              <h5 class="mb-75" [translate]="'TALENT_POOL.CREATE_JOB.INDUSTRY'"></h5>
                              <p class="card-text ml-1">{{detail_jd.industry}}</p>
                            </div> -->

                        <!-- <div class="mt-2">
                              <h5 class="mb-75 font-weight-bolder" [translate]="'TALENT_POOL.CREATE_JOB.LOCATION'"></h5>
                              <p class="card-text ml-1">{{detail_jd.location}}</p>
                            </div> -->
                        <!-- <div class="mt-2">
                              <h5 class="mb-75" [translate]="'TALENT_POOL.CREATE_JOB.GENDER'"></h5>
                              <p class="card-text" *ngIf="detail_jd.gender=='';else noAll" [translate]="'TALENT_POOL.CREATE_JOB.ALL'"></p>
                              <ng-template #noAll><p class="card-text ml-1">{{detail_jd.gender}}</p></ng-template>
                              
                            </div> -->
                      </div>
                    </div>
                    <!--/ latest profile pictures -->

                    <!-- suggestion -->
                    <!-- <div class="card">
                          <div class="card-body">
                            <h5 class="mb-2">Information</h5>
                            <div class="mt-2">
                              <h5 class="mb-75">CMC</h5>
                              <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                            </div>
                            <div class="mt-2">
                              <h5 class="mb-75">Years Of Experience</h5>
                              <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                            </div>
                            <div class="mt-2">
                              <h5 class="mb-75">Years Of Experience</h5>
                              <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                            </div>
                            <div class="mt-2">
                              <h5 class="mb-75">Years Of Experience</h5>
                              <p class="card-text">From {{detail_jd.years_of_experience_from}} year to {{detail_jd.years_of_experience_to}} year</p>
                            </div>
                            </div>
                          </div>
                        </div> -->
                    <!--/ suggestion -->
                  </div>
                  <!--/ right profile info section -->
                </div>
              </div>
              <!-- labels Tab ends -->
            </ng-template>
          </li>
          <li [ngbNavItem]="'candidates'" *ngIf="detail_jd.hcm_system_jd">
            <a ngbNavLink (click)="clickTab('candidates')" class="d-flex align-items-center tab-metadata">
              <span id="candidates" class="d-none d-sm-block"
                [translate]="'TALENT_POOL.DETAIL_JOB.CANDIDATES'">Candidates</span></a>
            <ng-template ngbNavContent>
              <!-- object Tab starts -->
              <div class="tab-pane" id="objects" aria-labelledby="objects-tab" role="tabpanel">
                <div class="content-wrapper container-xxxl p-0 card">
                  <div class="content-body card-body" id="ngx-datatable-row-details">
                    <div class="d-flex justify-content-center" *ngIf="isLoading; else no_loading" id="loading"
                      style="margin: 50px auto">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <ng-template #no_loading>
                      <ngx-datatable id="table-user" #tableSf4cCandidates class="bootstrap core-bootstrap"
                        [rows]="resultSf4cCandidates" [limit]="basicSelectedOption" [rowHeight]="50"
                        class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force" [headerHeight]="50"
                        [footerHeight]="50" [scrollbarH]="true" [count]="page.totalElements" [offset]="page.pageNumber"
                        [limit]="page.size" (page)="setPage($event)">
                        <ngx-datatable-column [width]="widthColumn"
                          [name]="'TALENT_POOL.DETAIL_JOB.FULL_NAME'|translate" prop="candidate.full_name">
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="w-100">
                              <a routerLink="/manage-job/{{detail_jd.id}}/detail-candidate/{{row.candidate.id}}"
                                data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.candidate.full_name}}"
                                class="d-inline-block text-truncate max-width-custom w-100 text-primary">{{ row.candidate.full_name
                                }}
                                <!-- <span *ngIf="!row.is_seen"
                                class="badge badge-pill badge-light-warning font-weight-bolder"
                                [translate]="'TALENT_POOL.RESUME_TALENT_POOL.NEW'"></span> -->
                              </a>
                              
                            </div>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="widthColumn" [name]="'TALENT_POOL.DETAIL_JOB.EMAIL'|translate"
                          prop="candidate.email">
                          <!-- <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="w-100">
                              <span>{{ row.candidate.email }}</span>
                            </div>
                          </ng-template> -->
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="widthColumn"
                          [name]="'TALENT_POOL.DETAIL_JOB.PHONE_NUMBER'|translate" prop="candidate.phone_number">
                          <!-- <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="w-100">
                              <span>{{ row.candidate.phone_number }}</span>
                            </div>
                          </ng-template> -->
                        </ngx-datatable-column>

                        <ngx-datatable-column [width]="widthColumn"
                          [name]="'TALENT_POOL.DETAIL_JOB.MATCHING_SCORE'|translate" prop="matching_score">
                          <!-- <ng-template ngx-datatable-cell-template let-row="row">
                            <div class="w-100">
                              <span>{{ row.matching_score*100|number:'1.2-2' }}%</span>
                            </div>
                          </ng-template> -->
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="widthColumn"
                          [name]="'TALENT_POOL.DETAIL_JOB.EXPERIENCE_SCORE'|translate" prop="experience_score">
                        </ngx-datatable-column>
                        <ngx-datatable-column [width]="widthColumn"
                          [name]="'TALENT_POOL.DETAIL_JOB.EDUCATION_SCORE'|translate" prop="education_score">
                        </ngx-datatable-column>

                        <!-- <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.ACTION' | translate" [sortable]="false"
                      [sortable]="false" [width]="widthColumn" [draggable]="false" *ngIf="!user.is_superuser">
                      <ng-template ngx-datatable-cell-template let-row="row">
                          <div class="d-flex align-items-center">
                              <a type="button" href="javascript:void(0)"
                                  routerLink="/manage-resume/edit-resume/{{row.id}}" title="Edit"
                                  class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                                  <span class="d-flex align-items-center justify-content-center"><i
                                          class="fa fa-pencil-square-o"></i>
                                  </span>
                              </a>
                              <a type="button" href="javascript:void(0)" (click)="deleteResume(row.id)" title="Delete"
                                  class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                                  <span class="d-flex align-items-center justify-content-center"><i
                                          class="fa fa-trash-o"></i>
                                  </span>
                              </a>
                          </div>
                      </ng-template>
                  </ngx-datatable-column> -->
                      </ngx-datatable>
                    </ng-template>

                  </div>
                </div>

              </div>
              <!-- object Tab ends -->
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>

      </section>
      <!--/ profile info section -->
    </div>
  </div>
</div>