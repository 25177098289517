import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { User } from 'app/auth/models/user';
import { CV_task, City } from '../models/job.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { TalentPoolService } from '../services/talent-pool.service';
import { Page } from 'app/main/resume-parser/models/paging.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import Swal from 'sweetalert2';
import { Role } from 'app/auth/models';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { CoreConfigService } from '@core/services/config.service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';
@Component({
  selector: 'app-manage-job',
  templateUrl: './manage-job.component.html',
  styleUrls: ['./manage-job.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageJobComponent implements OnInit {
  public isAuthenticated = false
  public errorMessagesListAllJd!: any
  public errorMessagesSyncSf4cJr!: any
  public list_all_jd: any[] = []
  public owner__email: string[] = []
  public owner__email_items: any[] = []
  public users: User[] = []
  public errorMessagesListUser: any
  public tag_list_user = false
  public tag_filter_user = false
  public basicSelectedOption = 10
  // page = 1;
  // pageSize = 5;
  public page = new Page({});
  ColumnMode = ColumnMode;
  widthColumn: number = 0;
  @ViewChild(DatatableComponent) tableJd: DatatableComponent;

  public searchText: string = '';
  public searchOwner: string = ''
  public status: string = ''
  public loading = false
  from: any;
  to: any;
  public selectedCity: string[] = []
  public city = [];
  public user: User = new User()
  public dateTimeOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true
  };
  company_talentpool: boolean = false
  language: any;
  public tag_resultConnectSf4c_exit = false
  typeTalentPoolItems = [{id:1,name:'Tập hồ sơ của cá nhân', name_en: 'Personal profile'},
  {id:2,name:'Toàn bộ hồ sơ trong kho', name_en: 'All resume in talent pool'},
  {id:3,name:'Kho ứng viên', name_en: 'Talent pool'}]
  selectedType = 2
  talent_pool = null
  talentPools: any
  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private toastr: ToastrService,
    private _authenticationService: AuthenticationService,
    private resumesService: ResumeParserService,
    private talentPoolService: TalentPoolService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private elementRef: ElementRef,
    private _coreConfigService: CoreConfigService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
  }
  getListTalentPool() {
    let url_dynamic = 'owner__email=' + this.user.email
    this.resumesService.listTalentPool(url_dynamic).subscribe((res) => {
      this.talentPools = res
      // this.resumeService.setListFolderValue(this.talentPools)
    })
  }

  getCredential() {
    this.resumesService.getDetailCredential(this.user.id).subscribe(
      (res) => {
        if (res) {
          this.tag_resultConnectSf4c_exit = true
        }
      }
    )

  }
  modalOpenFilterRecommend(modalBasicFilterRecommend:any) {
    this.modalService.open(modalBasicFilterRecommend, {
      windowClass: 'modal',
      size: 'lg',
    });
  }
  convertTime(dateRaw: string){
    const date = new Date(dateRaw);

    // Adjusting the date to be in UTC
    const adjustedDate = new Date(date.getTime()); // Subtracting 7 hours for GMT+0700

    // Adding 7 hours to the adjusted date
    adjustedDate.setHours(adjustedDate.getHours() + 7);

    const isoString = adjustedDate.toISOString();
    return isoString;
  }
  filterRecommend(row){
    let formData = {}
    formData['selectedCity'] = this.selectedCity
    formData['from'] = this.from?this.convertTime(this.from[0]):''
    formData['to'] = this.to?this.convertTime(this.to[0]):''
    if(this.selectedType==1){
      formData['company_talentpool'] = false
    }else if(this.selectedType==2){
      formData['company_talentpool'] = true
    }else{
      if(this.talent_pool){
        formData['user_talentpool'] = this.talent_pool
      }
    }
    this.talentPoolService.setNewOptionFilterRecommendForm(formData)
    this._router.navigate([`/manage-job/recommend-resume/${row.id}`])
  }
  Search() {
    // alert(this.searchText)
    let list_jd_cp: any;
    this.talentPoolService.listJd.subscribe(
      (res)=>{
        list_jd_cp = res
      }
    )
    if (this.searchText !== "") {
      let searchValue = this.searchText.toLocaleLowerCase();

      this.list_all_jd = list_jd_cp.filter((cv_task: any) => {
        return cv_task.job_title.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.list_all_jd = list_jd_cp
    }
  }
  SearchOwner() {
    // alert(this.searchOwner)
    if (this.searchOwner !== "") {
      let searchValue = this.searchOwner.toLocaleLowerCase();

      this.list_all_jd = this.list_all_jd.filter((cv_task: any) => {
        return cv_task.owner_email.toLocaleLowerCase().match(searchValue)
          ;
        // you can keep on adding object properties here

      });

    }
    else {
      this.ListAllJd();
    }
  }

  getListUser() {
    this._authenticationService.listUsers('').subscribe(
      (res) => {
        this.users = res.results;
        for (let i = 0; i < this.users.length; i++) {
          let owner__email_item = this.users[i].last_name + ' ' + this.users[i].first_name + ': ' + this.users[i].email
          this.owner__email_items.push({ "name": owner__email_item, "value": this.users[i].email })

        }
        this.owner__email_items = [...this.owner__email_items]
      },
      (err) => {
        this.errorMessagesListUser = err
      }
    )
  }

  ListAllJd() {
    let url_dynamic = ""
    let owner__email = ""
    if(this.owner__email.length>0){
      for(let i=0;i<this.owner__email.length;i++){
        let url_current_owner_email = "&owner__email="+this.owner__email[i]
        owner__email = owner__email + url_current_owner_email
      }
    }else{
      owner__email = ""
    }
    if(this.owner__email!==undefined&&this.owner__email!==null){
      url_dynamic = url_dynamic+owner__email
    }
    this.talentPoolService.list_all_jd_pagination(this.page.pageNumber, this.page.size, url_dynamic).subscribe(
      (res) => {
        this.list_all_jd = res.results;
        this.talentPoolService.setNewListJd(this.list_all_jd);
        const currentPageNumber = this.page.pageNumber;
        const currentPageSize = this.page.size;
        this.page = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        this.saveFilter();
      },
      (err) => {
        this.errorMessagesListAllJd = err
      }
    )
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    // this.resumesService.setPageValue(this.page.pageNumber)
    this.saveFilter();
    this.ListAllJd()
  }
  sync_sf4c_jr() {
    this.loading = true
    this.talentPoolService.sync_sf4c_jr().subscribe(
      (res) => {
        if (res.message != undefined) {
          this.loading = false
          // this.toastr.success('Sync SuccessFactors JR Success');
          this.ListAllJd()
          this.searchText = ""
        }
      },
      (err) => {
        this.toastr.warning(err.error.message)
        this.errorMessagesSyncSf4cJr = err
        this.loading = false
      }
    )
  }
  deleteJob(id: number) {
    Swal.fire({
      title: this._translateService.instant("TALENT_POOL.MANAGE_JOB.MODAL.DELETE_J"),
      text: this._translateService.instant("TALENT_POOL.MANAGE_JOB.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("TALENT_POOL.MANAGE_JOB.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("TALENT_POOL.MANAGE_JOB.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.talentPoolService.deleteJob(id).subscribe(
          (res) => {
            this.toastr.success(
              this._translateService.instant("TALENT_POOL.MANAGE_JOB.TOAST.DELETE_J_SUC"),
              this._translateService.instant("TALENT_POOL.MANAGE_JOB.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.ListAllJd();
            this.searchText = ""
          },
          (err) => {
            this.toastr.error(
              this._translateService.instant("TALENT_POOL.MANAGE_JOB.TOAST.ERROR"),
              this._translateService.instant("TALENT_POOL.MANAGE_JOB.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  saveFilter() {
    this.talentPoolService.setFilterJobPageManageJobValue(
      this.page,
      this.owner__email,
    );
  }
  changeTypeEvent(){
    console.log("AA",this.selectedType)
  }
  ngOnInit(): void {
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
    });
    this.user = this._authenticationService.currentUserValue
    this.getListTalentPool()
    this.getCredential()
    const resizeTableObserver = new ResizeObserver((entries) => {
      this.widthColumn = entries[0].contentRect.width / 7;
    });
    resizeTableObserver.observe(
      this.elementRef.nativeElement.querySelector(
        "#ngx-datatable-row-details"
      )
    );
    City.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    let city = [{"name":'Hà Nội', "value":"Hà Nội"}, {"name":'Hồ Chí Minh',"value":"Hồ Chí Minh"}, {"name":'Đà Nẵng',"value":"Đà Nẵng"}]
    for(let i=0;i<City.length;i++){
      city.push({"name":City[i],"value":City[i]})
    }
    city.push({"name": 'Khác', "value": 'Khác'})
    this.city = city
    if(this.user.role!=Role.FreeTrial&&this.user.role!=Role.User){
      this.getListUser()
    }
    const { pageJob, owner__email
    } = this.talentPoolService.getFilterJobPageManageJobValue();
    this.page = pageJob || new Page({});
    this.owner__email = owner__email || [];
    this.ListAllJd()
  }

}
