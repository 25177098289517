export const locale = {
    lang: 'vn',
    data: {
      TALENT_POOL: {
        MANAGE_JOB: {
            JOB: "Công việc",
            JR_ID_CHR: "ID công việc CHR",
            ADD_JOB: "Thêm đăng tuyển",
            SHOW: "Hiển thị",
            CATEGORY: "Loại",
            TYPE: "Loại hình công việc",
            LEVEL: "Trình độ",
            APPLICATION1: "Gợi ý hồ sơ",
            APPLICATION2: "Hồ sơ đã lưu",
            APPLICATION3: "Hồ sơ ứng tuyển",
            RECRUITMENT: "ứng tuyển",
            NUM_SAVED_RESUMES: "Số hồ sơ đã lưu",
            ITEMS_PER_PAGE: "Mục trên trang",
            HCM_SYSTEM_ID: "id SAP SF",
            OWNER: "Chủ sở hữu",
            SYNC_SUCCESSFACTORS: "Đồng bộ đăng tuyển trên SAP SF",
            PLACEHOLDER: 'Tìm kiếm tiêu đề ',
            RECOMMEND: "Khuyến nghị",
            SAVED: "Đã lưu",
            EDIT: "Sửa",
            ACTION: "Hành động",
            MODAL: {
                ACTION: "Hành động",
                DELETE_J: "Xóa đăng tuyển",
                CANT_UNDO: "Bạn không thể hoàn tác",
                CANCEL: "Hủy",
                CONFIRM: "Xác nhận",
                DELETE_REPORT: "Xóa báo cáo phù hợp"
              },
              TOAST: {
                EDIT_J_SUC: "Sửa đăng tuyển",
                CREATE_J_SUC: "Tạo đăng tuyển",
                DELETE_J_SUC: "Xóa đăng tuyển",
                ERROR: "Lỗi khi thực hiện",
                FAILED: "Thất bại",
                SUCCESS: "Thành công"
              },
              CHOICE_OWNER: "Chủ sở hữu",
        },
        CREATE_JOB: {
            NEW_JOB: "Tạo công việc mới",
            ADD_JOB: "Thêm một công việc mới vào danh sách công việc của công ty bạn.",
            CHANGE_JOB: "Thay đổi công việc",   
            REQUIRED: "Trường yêu cầu bắt buộc",
            LOCATION: "Địa điểm",
            JOB_LEVEL: "Cấp độ công việc",
            INDUSTRY: "Lĩnh vực",
            JOB_TYPE: "Loại hình công việc",
            GENDER: "Giới tính",

            YEARS_OF_EXPERIENCE: "Số năm kinh nghiệm",
            YOB: "Năm sinh",
            JOB_DESCRIPTION: "Mô tả công việc",
            JOB_REQUIREMENT: "Yêu cầu công việc",
            SUBMIT: "Gửi",
            CANCEL: "Huỷ",
            SUBMIT_ADD_JOB: "Thêm đăng tuyển",
            SUBMIT_EDIT_JOB: "Sửa đăng tuyển",

            PLACEHOLDER_INDUSTRY: "Lĩnh vực - Nhiều lựa chọn",
            BASIC_INFO: "Thông tin cơ bản",
            BASIC_REQUIRED: "Yêu cầu cơ bản",
            REQUIRES_EXPERTISE: "Yêu cầu chuyên môn ",
            MINIMUM: "Tối thiểu",
            LESS_THAN: "Dưới",
            YEARS_OF_EXP: "năm kinh nghiệm",
            NOT_REQUIRED: "Không yêu cầu",
            PLACEHOLDER:{
                YOB_FROM: " từ",
                YOB_TO: " tới",
                YEAR_OF_EXPERIENCE_FROM: "từ",
                YEAR_OF_EXPERIENCE_TO: "tới"
            },
            PATTERN: "Trường này chỉ có thể chứa các ký tự số.",
            INVALID: "Giá trị sau phải lớn hơn giá trị trước",
            VALID_NUMBER: "Giá trị phải lớn hơn hoặc bằng 0",
            JOB_LEVEL_: {
                STUDENT_INTERNSHIP: "Thực tập sinh",
                ENTRY_LEVEL: "Mới tốt nghiệp",
                STAFF: "Nhân viên",
                SPECIALIST: "Chuyên viên",
                TEAM_LEADER: "Trưởng phòng/Giám sát",
                DIRECTOR: "Giám đốc và cấp cao hơn",
            },
            JOB_TYPE_:{
                FULL_TIME: "Toàn thời gian",
                PART_TIME: "Bán Thời Gian",
                CONTRACT: "Hợp Đồng",
                REMOTE: "Làm Từ Xa",
                INTERN: "Thực Tập"
            },
            JOB_TITLE_REQUIRED: "Chưa tạo được đăng tuyển vì thiếu vị trí công việc",
            JOB_DESCRIPTION_REQUIRED: "Chưa tạo được đăng tuyển vì thiếu mô tả công việc",
            JOB_REQUIREMENT_REQUIRED: "Chưa tạo được đăng tuyển vì thiếu yêu cầu công việc",
            KEYWORDS: "Từ khóa",
            PLACEHOLDER_KEYWORDS: "Điền từ khóa"
        },
        DETAIL_JOB: {
            EDIT_JOB: "Sửa đổi công việc",
            RECOMMEND_RESUME: "Khuyến nghị hồ sơ",
            SAVED_RESUME: "Hồ sơ đã lưu",
            SEARCH_RESUME: "Tìm kiếm ứng viên",
            DESCRIPTION: "Mô tả",
            REQUIREMENTS: "Yêu cầu",
            KEYWORD_EXPRESSION: "Biểu thức từ khóa",
            EXPERIENCE: "Kinh nghiệm",
            WORK_LEVEL: "Trình độ công việc",
            EMPLOYMENT_TYPE: "Loại việc làm",
            BIRTHDAY: "Ngày sinh",
            ALL: "Tất cả",
            FULL_NAME: "Họ và tên",
            FIRST_NAME: "Tên",
            LAST_NAME: "Họ",
            EMAIL: "E-mail",
            PHONE_NUMBER: "Số điện thoại",
            MATCHING_SCORE: "Điểm phù hợp",
            EXPERIENCE_SCORE: "Điểm kinh nghiệm",
            EDUCATION_SCORE: "Điểm học vấn",
            PROFILE: "Thông tin",
            CANDIDATES: "Danh sách ứng viên"
        },
        SEARCH_RESUME: {
            JOB_TITLE: "Vị trí công việc",
            FULLTEXT_FILTER: "Thông tin chung",
            FULLNAME_FILTER: "Họ và tên",
            EXPERIENCE_FILTER: "Mô tả vị trí và kinh nghiệm",
            EDUCATION_FILTER: "Học vấn",
            CURRENT_TITLE_FILTER: "Vị trí công việc",
            CURRENT_COMPANY_FILTER: "Công ty",
            TALENT_POOL: "Kho dữ liệu",
            SKILL: "Kỹ năng",
            CATEGORY: "Ngành nghề",
            ADDRESS: "Địa chỉ",
            SEARCH: "Tìm kiếm",
            LOADING: "Đang tải",
            FOUND: "Đã tìm",
            RESUMES: "ứng viên được tìm thấy",
            RESUME: "Hồ sơ",
            CANDIDATES: "ứng viên",
            OWNER: "Chủ sở hữu",
            SKILL_SET: "Kỹ năng",
            SAVE_CV: "Lưu CV",
            SAVED_CV: "CV đã lưu",
            SELECT_JD: "Lựa chọn JD",
            NOT_JOB: "Bạn chưa tạo yêu cầu công việc nào, hãy tạo thêm tại đây:",
            CREATE_JOB: "Tạo công việc",
            PLACEHOLDER_TEXTAREA: "Điền từ khóa",
            PLACEHOLDER: "--Điền từ khóa--",
            PLACEHOLDER_ADDRESS: "--Chọn tỉnh/thành phố --",
            PLACEHOLDER_CATEGORY: "--Chọn loại ngành--",
            PLACEHOLDER_GENDER: "--Chọn giới tính--",
            SEARCH_JOB: "Tìm kiếm vị trí công việc",
            PLACEHOLDER_SKILL: "--Điền kỹ năng--",
            YEARS_OF_EXPERIENCE: "Năm kinh nghiệm",
            YEARS_OF_EXPERIENCE_FROM_PLACEHOLDER: "Từ",
            YEARS_OF_EXPERIENCE_TO_PLACEHOLDER: "Đến",
            LINK_CV: "Đường dẫn CV",
            JOB_NAME: "Tên công việc - SF",
            JOB_STATUS: "Trạng thái công việc - SF",
            CANDIDATE_STATUS: "Trạng thái ứng viên - SF",
            STATUS: "Trạng thái",
            TITLE_SF4C_STATUS: "Trạng thái ứng tuyển của ứng viên",
            NO_RESULT: "Ứng viên không có thông tin ứng tuyển", 
            JOB_ID: "Mã công việc - SF",
            NOT_FOUND: "Không tìm thấy CV",
            SAVE: "Lưu",
            SELECT_ALL: "Chọn tất cả ứng viên",
            EXTRACT_TOOLTIP: "Tìm kiếm chính xác theo từ khóa",
            SIMILARITY_TOOLTIP: "Tìm kiếm tương đồng theo từ khóa",
            REPORT_ERROR: "Đánh giá của bạn đã được ghi nhận",
            UPDATE_REPORT: "Đánh giá của bạn đã được sửa",
            COMMENT: "Nhận xét",
            REPORT_TEXT: "Nhấn phù hợp nếu hồ sơ phù hợp và không phù hợp nếu hồ sơ không phù hợp",
            MATCH: "Phù hợp",
            NOT_MATCH: "Không phù hợp",
            REPORT: "Báo cáo hồ sơ",
            REPORT_INFO: "Nêu lý do không phù hợp",
            MATCHED: "Đã đánh giá phù hợp",
            NOT_MATCHED: "Đã đánh giá không phù hợp",
            EVALUATE: "Đánh giá",
            CREATED_BY_EVALUATE: "Người đánh giá",
            CREATED_TIME_EVALUATE: "Ngày đánh giá",
            JOB: "Công việc",
            TOOLTIP_RESULT_SEARCH: "Dữ liệu tìm kiếm gồm những hồ sơ được bóc tách thành công và kho hồ sơ của doanh nghiệp"
        },
        RESUME_MATCHING: {
            CHOOSE_STATUS: "--Chọn trạng thái--",
            CHOOSE_BY_EMAIL: "--Chọn e-mail chủ sở hữu--",
            RESUME_MATCH: "lượt gợi ý ứng viên",
            CREATE_AT: "Thời gian tạo",
            OVERALL_SCORE: "Tổng điểm",
            STATUS: "Trạng thái",
            ACTION: "Hành động",
            JOB_ID: "Id công việc",
            RESUME_ID: "Id hồ sơ",
            CREATED_BY: "Tạo bởi",
            RESUME_STATUS: "Trạng thái hồ sơ",
            JOB_APP_STATUS: "Trạng thái ứng dụng công việc",
            FULL_NAME: "Họ và tên",
            CURRENT_TITLE: "Vị trí công việc hiện tại",
            CURRENT_COMPANY: "Công ty hiện tại",
            FILTER: "Lọc",
            CV_LINK: "Đường dẫn CV",
            TITLE: "Vị trí đăng tuyển",
            DESCRIPTION: "Mô tả đăng tuyển",
            REQUIREMENTS: "Yêu cầu đăng tuyển",
            SUBMIT: "Chấm điểm",
            RESET: "Hủy",
            REQUIRE: "Trường này yêu cầu bắt buộc",
            OVERSCORE: "Điểm phù hợp",
            MATCHING_CV: "Tệp CV",
            CV_FILE: "Tải lên CV",
            MATCHING_CV_LINK: "Đường dẫn CV",
            NO_RESULT: "Không có kết quả"

        },
        RECOMMEND_RESUME: {
            RECOMMENDED_RESUMES: "Khuyến nghị ứng viên",
            FOR_THE_JOB_OF: "cho công việc của",
            SAVED: "đã lưu",      
            SAVED_RESUMES: "Hồ sơ đã lưu",
            RESUME_HAD_SAVED: "Số hồ sơ đã được lưu:",
            TOASTR: {
                SCORE: "Hệ thống bắt đầu tính điểm phù hợp",
                SUCCESS: "Thành công",
                FAIL: "Thất bại"
            },
            FROM: "Từ",
            TO: "Đến",
            TIME: "Thời gian",
            SEARCH_OPTION: "Lọc theo từ khóa",
            SEARCH_REQUIRED: "Lọc trước khi gợi ý cho",
            RECOMMEND: "Gợi ý",
            COMPANY_TALENTPOOL: "Lọc trong toàn bộ kho ứng viên",
            RECOMMENDATION_FILE: "Tập hồ sơ khuyến nghị",
            PERSONAL_PROFILE: "Tập hồ sơ của cá nhân",
            ALL_RECORDS: "Toàn bộ hồ sơ trong kho",
            TALENT_POOL: "Kho ứng viên",
            CHOOSE_TALENT_POOL: "--Chọn kho ứng viên--"
        },
        SAVED_RESUME: {
            UPLOAD_SF4C: "Tải lên SuccessFactors",
            SAVED_RESUMES: "Hồ sơ đã lưu",
            PLACEHOLDER: {
                SEARCH: "Tìm kiếm họ và tên",
                SELECTED_IMPORTEDBY: "--Lựa chọn người tải lên--",
                SELECTED_SOURCE: "--Lựa chọn nguồn--"
            },
            MODAL: {
                ACTION: "Hành động",
                DELETE_R: "Bỏ lưu hồ sơ",
                CANT_UNDO: "Bạn không thể hoàn tác",
                CANCEL: "Hủy",
                CONFIRM: "Xác nhận",
                IMPORT: "Tải lên",
                TITLE: "Tải file lên để trích xuất"
              },
              TOAST: {
                EDIT_R_SUC: "Sửa hồ sơ",
                CREATE_R_SUC: "Tạo hồ sơ",
                DELETE_R_SUC: "Xóa hồ sơ",
                ERROR: "Lỗi khi thực hiện",
                FAILED: "Thất bại",
                SUCCESS: "Thành công"
              },
        },
        RESUME_TALENT_POOL: {
            AT: "tại",
            WORK: "Làm việc ",
            DETAIL: "Chi tiết",
            SAVE: "Lưu",
            UNSAVE: "Bỏ lưu",
            STATUS: "Trạng thái:",
            OVERALL_SCORE: "Điểm tổng quát: ",
            EXPERIENCE_SCORE: "Kinh nghiệm: ",
            EDUCATION_SCORE: "Học vấn: ",
            SKILL_SCORE: "Kỹ năng:",
            LANGUAGE_SCORE: "Ngôn ngữ: ",
            INFO_SCORE: "Thông tin chấm điểm",
            LOADING: "Đang tính điểm phù hợp của ứng viên",
            EXPERIENCE: "Kinh nghiệm",
            EDUCATION: "Học vấn",
            SAVED: "Đã lưu",
            OWNER: "Chủ sở hữu:",
            NEW: "Mới",
            MODIFIED_AT: "Thời gian sửa",
            STATUS_COLOR: {
              APPROVED: "Đã phê duyệt",
              DONE: "Đã chấm điểm",
              FAILURE: "Thất bại",
              PROCESSING: "Đang xử lý",
              WAIT_TO_PROCESS: "Chờ xử lý",
              SUSPENDED: "Hoãn lại",
              MODIFIED: "Đã sửa"
            },
            DETAIL_SCORE: "Chi tiết chấm điểm",
            DETAIL_EXPERIENCE: {
              TEXT_IN_JD: "Thông tin JD",
              TYPE: "Thuộc tính",
              TEXT_IN_CANDIDATE: "Thông tin ứng viên",
              SCORE: "Điểm"
            },
            DETAIL_SKILL: {
              TEXT_IN_JD: "Thông tin JD",
              TEXT_IN_CANDIDATE: "Thông tin ứng viên"
            } 
        },
        DETAIL_CANDIDATE: {
            INFORMATION_BASIC: "Thông tin cá nhân",
            NOT_FOUND: "Hồ sơ không tìm thấy hoặc không có quyền truy cập",
            ADDRESS: "Địa chỉ",
            EMAIL: "E-mail",
            PHONE_NUMBER: "Số điện thoại",
            GENDER: "Giới tính",
            DATE_OF_BIRTH: "Ngày sinh",
            CURRENT_COMPANY: "Công ty hiện tại",
            CURRENT_TITLE: "Vị trí hiện tại",
            YEAR_EXPERIENCE: "Năm kinh nghiệm",
            EDUCATION: "Học vấn và đào tạo",
            WORK_EXPERIENCE: "Kinh nghiệm làm việc",
            CATEGORY: "Ngành nghề",
            REFERENCES: "Người giới thiệu",
            DIPLOMA: "Bằng cấp",
            POSITION: "Vị trí",
            INDUSTRY: "Lĩnh vực",
            COMPANY: "Công ty",
            GPA: "Điểm tích lũy",
            MAJOR: "Chuyên ngành",
            SCHOOL: "Trường",
            AT: "tại",
            JOB_DESCRIPTION: "Mô tả công việc",
            SKILL: "Kỹ năng",
            OWNER: "Chủ sở hữu",
            SHARE_RESUME: "Yêu cầu chia sẻ hồ sơ",
            REQUEST_TO_SHARE: "Yêu cầu chia sẻ hồ sơ thành công. Kiểm tra e-mail!",
            SHARED: "Hồ sơ đã được chia sẻ",
            STATUS: {
                APPROVED: "Đã phê duyệt",
                DONE: "Đã tải lên",
                FAILURE: "Tải lên thất bại",
                PROCESSING: "Đang xử lý",
                WAIT_TO_PROCESS: "Chờ xử lý",
                SUSPENDED: "Hoãn lại",
                MODIFIED: "Đã sửa",
                DUPLICATED: "Trùng lặp"
              },
              STATUS_SF4C:{
                SF4C_BACKGROUND_FAILED: "SF background failed",
                SF4C_WAIT: "Chờ xử lý",
                SF4C_FAILED: "Tải lên thất bại",
                SF4C_UPLOADING: "Đang xử lý",
                SF4C_UPLOADED: "Đã tải lên",
                SF4C_NOT_UPLOAD: "Chưa tải lên"
              },
              GENDER_:{
                MALE: "Nam",
                FEMALE: "Nữ",
              },
        },
        SEARCH_HISTORY: {
          SEARCH_HISTORY: "Lịch sử tìm kiếm",
          TITLE: "Lịch sử tìm kiếm ứng viên",
          CREATE_BY: "Người tạo"
        }
      }
      
    }
  };
  