export const locale = {
    lang: 'vn',
    data: {
      MENU: {
        DASHBOARD: {
          COLLAPSIBLE: 'TỔNG QUAN',
          BADGE: '2',
          ANALYTICS: 'Phân tích',
          ACTIVITY_HISTORY: 'Lịch sử hoạt động'
        },
        RESUME_PARSER: {
          SECTION: 'HỒ SƠ',
          PARSE_RESUME: 'Thêm hồ sơ',
          MANAGE_RESUME: 'Quản lý hồ sơ',
          INTEGRATION: 'Tích hợp SAP SF',
          UPDATE_JA: 'Cập nhật công việc',
          TALENT_POOL: "Kho ứng viên"

        },
        TALENT_POOL: {
          SECTION: 'CÔNG VIỆC & ỨNG VIÊN',
          CREATE_JOB: 'Tạo tin tuyển dụng',
          MANAGE_JOB: 'Quản lý đăng tuyển',
          SEARCH_CV: 'Tìm kiếm ứng viên',
          MONITOR_UPLOAD: 'Chấm điểm phù hợp'
        },
        MANAGE_PACKAGE: {
          SECTION: 'Gói bán',
          MANAGE_PACKAGE: 'Quản lý gói bán'
        }
      }
    }
  };
  