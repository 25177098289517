<div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
        <!-- Forgot Password v1 -->
        <div class="card mb-0">
            <div class="card-body" style="margin: 0 auto">
                <a href="javascript:void(0);" class="brand-logo">
                    <img *ngIf="coreConfig.app.appLogoImage" src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo"
                        height="40" />
                </a>
                <img src="assets/images/pages/email-verify.png" height="150px" width="100%" alt=""
                    style="margin-bottom: 20px" />
                <h2 class="card-title text-center fw-bold" style="width: 80%; margin-left: auto; margin-right: auto" translate="ACCOUNT_SETTING.LOGIN.CHECK_EMAIL">
                    
                </h2>

                <p class="text-center mt-2">
                    <a routerLink="/login" translate="ACCOUNT_SETTING.LOGIN.BACK_TO_LOGIN">
                        <i data-feather="chevron-left" class="mb-25"></i> 
                    </a>
                </p>
            </div>
        </div>
        <!-- /Forgot Password v1 -->
    </div>
</div>