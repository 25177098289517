import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CV_task, City } from '../../models/job.model';
import { TalentPoolService } from '../../services/talent-pool.service';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from 'app/main/resume-parser/models/paging.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrOptions } from 'ng2-flatpickr';
@Component({
  selector: 'app-detail-job',
  templateUrl: './detail-job.component.html',
  styleUrls: ['./detail-job.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailJobComponent implements OnInit {
  public errorMessagesDetailJd!: any
  public detail_jd: CV_task = new CV_task()
  public language: string;
  contentHeader: ContentHeader;
  public resultSf4cCandidates: any[] = []
  widthColumn: number = 0;
  public ColumnMode = ColumnMode;
  @ViewChild(DatatableComponent) tableSf4cCandidates: DatatableComponent;
  public selectedRows: any[] = [];
  isLoading = false;
  public basicSelectedOption = 10
  tabCurrent='profile'
  from: any;
  to: any;
  public selectedCity: string[] = []
  public city = [];
  public dateTimeOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true
  };
  company_talentpool: boolean = false
  selectedTab = 'profile'
  public page = new Page({});
  typeTalentPoolItems = [{id:1,name:'Tập hồ sơ của cá nhân', name_en: 'Personal profile'},
  {id:2,name:'Toàn bộ hồ sơ trong kho', name_en: 'All resume in talent pool'},
  {id:3,name:'Kho ứng viên', name_en: 'Talent pool'}]
  selectedType = 1
  talent_pool = null
  talentPools: any
  constructor(
    private talentpoolService: TalentPoolService,
    private route: ActivatedRoute,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private _router: Router,
    private talentPoolService: TalentPoolService,
  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    
  }
  getJob() {
    const jd_id = Number(this.route.snapshot.paramMap.get('id'))
    this.talentpoolService.detail_jd(jd_id).subscribe(
      (res) => {
        this.detail_jd = res;
        if(this.detail_jd.hcm_system_jd){
          this.listSf4cCandidate()
        }
      },
      (err) => {
        this.errorMessagesDetailJd = err
      }
    )
  }
  modalOpenFilterRecommend(modalBasicFilterRecommend:any) {
    this.modalService.open(modalBasicFilterRecommend, {
      windowClass: 'modal',
      size: 'lg',
    });
  }
  convertTime(dateRaw: string){
    const date = new Date(dateRaw);

    // Adjusting the date to be in UTC
    const adjustedDate = new Date(date.getTime()); // Subtracting 7 hours for GMT+0700

    // Adding 7 hours to the adjusted date
    adjustedDate.setHours(adjustedDate.getHours() + 7);

    const isoString = adjustedDate.toISOString();
    return isoString;
  }
  filterRecommend(){
    let formData = {}
    formData['selectedCity'] = this.selectedCity
    formData['from'] = this.from?this.convertTime(this.from[0]):''
    formData['to'] = this.to?this.convertTime(this.to[0]):''
    if(this.selectedType==1){
      formData['company_talentpool'] = false
    }else if(this.selectedType==2){
      formData['company_talentpool'] = true
    }else{
      if(this.talent_pool){
        formData['user_talentpool'] = this.talent_pool
      }
    }
    this.talentpoolService.setNewOptionFilterRecommendForm(formData)
    this._router.navigate([`/manage-job/recommend-resume/${this.detail_jd.id}`])
  }
  listSf4cCandidate(){
    this.talentpoolService.listSf4cCandidate(this.detail_jd.hcm_system_jd).subscribe(
      (res)=>{
        this.resultSf4cCandidates = res.results;
        this.resultSf4cCandidates = this.resultSf4cCandidates.filter((e)=>e.candidate)
        this.resultSf4cCandidates = this.resultSf4cCandidates.map((e)=>{
          let matching_score = (e.matching_score && e.matching_score!=0)?Number(e.matching_score*100).toFixed(2):0;
          let experience_score = (e.experience_score && e.experience_score!=0)?Number(e.experience_score*100).toFixed(2):0;
          let education_score = (e.education_score && e.education_score!=0)?Number(e.education_score*100).toFixed(2):0;
          return{
            ...e,
            matching_score: matching_score,
            experience_score: experience_score,
            education_score: education_score
          }
        })
      },
      (err)=>{
        
      }
    )
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    // this.resumesService.setPageValue(this.page.pageNumber)
    this.saveFilter();
  }
  clickTab(tab: any){
    this.tabCurrent = tab
  }
  saveFilter() {
    this.talentpoolService.setFilterCandidatesPageDetailJobValue(
      this.page,
    );
  }
  toDetailResume(row: any){
    let job_description = Number(this.detail_jd.id);
    let resume = row.latest_resume_id
    this.talentPoolService.seen_resumes(job_description, resume).subscribe((res)=>{
      this._router.navigate([`/manage-job/${this.detail_jd.id}/detail-candidate/${row.candidate.id}`])
    },
    (err)=>{
      this._router.navigate([`/manage-job/${this.detail_jd.id}/detail-candidate/${row.candidate.id}`])
    });
    
  }
  ngOnInit(): void {
    if(window.location.href.includes("tab-candidates")){
      this.selectedTab = 'candidates'
    }
    const { page
    } = this.talentpoolService.getFilterCandidatesPageDetailJobValue();
    this.page = page || new Page({});
    this.getJob();
    City.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    let city = [{"name":'Hà Nội', "value":"Hà Nội"}, {"name":'Hồ Chí Minh',"value":"Hồ Chí Minh"}, {"name":'Đà Nẵng',"value":"Đà Nẵng"}]
    for(let i=0;i<City.length;i++){
      city.push({"name":City[i],"value":City[i]})
    }
    this.city = city
    this._coreConfigService.getConfig().subscribe((res) => {
      this.language = res.app.appLanguage;
      this.contentHeader = {
        headerTitle: this.language == 'vn' ? "Thông tin đăng tuyển" : "Recruitment information",
        actionButton: false,
        breadcrumb: {
          type: "",
          links: [
            {
              name: this.language == 'vn' ? "Trang trước" : "Previous page",
              isLink: true,
              link: "/manage-job"
            },
            {
              name: this.language == 'vn' && this.tabCurrent == 'profile' ? "Thông tin" : (this.language == 'vn' && this.tabCurrent == 'candidates')?'Danh sách ứng viên':
              (this.language == 'en' && this.tabCurrent == 'profile')?"Profile":"Candidates",
              isLink: false,
            },
          ],
        },
      };
    });
  }

}
