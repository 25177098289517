export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: 'Overview',
        BADGE: '2',
        ANALYTICS: 'Analytics',
        ACTIVITY_HISTORY: 'Activity history'
      },
      RESUME_PARSER: {
        SECTION: 'Parsing',
        PARSE_RESUME: 'Add resume',
        MANAGE_RESUME: 'Manage resume',
        INTEGRATION: 'Integration SAP SF',
        UPDATE_JA: 'Update JA SAP SF',
        TALENT_POOL: "Talent pool"
      },
      TALENT_POOL: {
        SECTION: 'Candidate matching',
        CREATE_JOB: 'Create job',
        MANAGE_JOB: 'Manage job',
        SEARCH_CV: 'Search candidate',
        MONITOR_UPLOAD: 'Matching CV-JD'
      },
      MANAGE_PACKAGE: {
        SECTION: 'Package',
        MANAGE_PACKAGE: 'Manage package'
      }

    }
  }
};
