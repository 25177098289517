import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { locale as enLocale } from "./../../../app/main/dashboard/i18n/en";
import { locale as vnLocal } from "./../../../app/main/dashboard/i18n/vn";
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { AuthenticationService } from 'app/auth/service';
import { User } from 'app/auth/models';
import { DashboardService } from 'app/main/dashboard/services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { ResultPackage } from 'app/main/resume-parser/models/resume.model';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  public currentUser: User = new User()
  public companyActivityCurrent: ResultPackage = new ResultPackage()
  public userActivityCurrent: any
  public userRole: string;
  public userName: string;
  public tag_resultConnectSf4c_exit:boolean
  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
    constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService,
    private dashboardService: DashboardService,
    private resumeService: ResumeParserService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.currentUser = this._authenticationService.currentUserValue
    this.companyActivityCurrent = this.dashboardService.currentPackageValue
    // this.tag_resultConnectSf4c_exit = this.resumeService.resultConnectSf4cBooleanValue=='true'?true:false
    // console.log("AA",this.tag_resultConnectSf4c_exit)
    this.userRole = localStorage.getItem("role")
    this.userName = localStorage.getItem("username")
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);

  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  getCompanyActivityCurrent(){
    this.dashboardService.companyActivityCurrent().subscribe(
      (res)=>{
        this.companyActivityCurrent = res
        this.dashboardService.setCurrentPackageValue(res)
      }
    )
  }
  getUserActivityCurrent(){
    this.dashboardService.userActivityCurrent().subscribe(
      (res)=>{
        this.userActivityCurrent = res
        this.dashboardService.setCurrentPackageValue(res)
      }
    )
  }
  getDetailCredential() {
    this.resumeService.getDetailCredential(this.currentUser.id).subscribe(
      (res) => {
        this.tag_resultConnectSf4c_exit = true
        this.resumeService.setResultConnectSf4cBooleanValue('true')
        this._changeDetectorRef.detectChanges()
      },(err)=>{
        this.tag_resultConnectSf4c_exit = false

      }
    )
  }
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();
    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // this.currentUser = this._coreMenuService.currentUser;
      this.getDetailCredential();

      if(this.currentUser.role=='Super admin' ||this.currentUser.is_site_admin||this.currentUser.role=='User'){
        this.getCompanyActivityCurrent()
        this.dashboardService.currentPackage.subscribe(
          (res)=>{
            this.companyActivityCurrent = res
          }
        )
      }else if(this.currentUser.role=='Free trial user'){
        this.getUserActivityCurrent()
        this.dashboardService.currentPackage.subscribe(
          (res)=>{
            this.userActivityCurrent = res
          }
        )
      }
      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();
      
      this._changeDetectorRef.markForCheck();
    });
  }
}
