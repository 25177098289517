<app-content-header [contentHeader]="contentHeader"></app-content-header>
<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body" id="ngx-datatable-row-talentpool">
        <div>
            <form (ngSubmit)="(SearchResume.form.valid)" #SearchResume="ngForm">
                <div class="row mb-2">
                    <div class="col-md-2">
                        <ng-select (keydown.enter)="onSubmit()" tabindex="1" bindLabel="name" bindValue="value"
                            [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_STATUS'|translate" name="selectedStatus"
                            [(ngModel)]="selectedStatus" id="selectedStatus" [multiple]="true">
                            <ng-option *ngFor="let _status of status" [value]="_status.value">
                                <span *ngIf="_status.name=='Approved'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.APPROVED'"></span>
                                <span *ngIf="_status.name=='Done'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DONE'"></span>
                                <span *ngIf="_status.name=='Failure'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.FAILURE'"></span>
                                <span *ngIf="_status.name=='Processing'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.PROCESSING'"></span>
                                <span *ngIf="_status.name=='Wait to process'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.WAIT_TO_PROCESS'"></span>
                                <span *ngIf="_status.name=='Suspended'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.SUSPENDED'"></span>
                                <span *ngIf="_status.name=='Modified'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.MODIFIED'"></span>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-3 pl-0"
                        *ngIf="user.role=='Super admin' || user.role=='Admin' || user.is_superuser">
                        <ng-select (keydown.enter)="onSubmit()" tabindex="2" [multiple]="true" [items]="owner__email_items" bindLabel="name"
                            bindValue="value" [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate"
                            name="owner__email" [(ngModel)]="owner__email" id="owner__email">
                        </ng-select>
                    </div>
                    <div class="col-md-3 pl-0">
                        <div class="input-group">
                            <input (keydown.enter)="onSubmit()" tabindex="3" type="text" class="form-control" id="job-search"
                                [placeholder]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate" name="file_name"
                                [(ngModel)]="file_name" />
                        </div>
                    </div>
                    <div class="col-md-2 pl-0">
                        <ng-select (keydown.enter)="onSubmit()" tabindex="4" [multiple]="true" [items]="job_boards" bindLabel="name"
                            bindValue="value" [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_SOURCE'|translate"
                            name="source" [(ngModel)]="source" id="source">
                        </ng-select>
                    </div>
                    <div class="col-md-2 pl-0">
                        <button tabindex="5" style="height: 38px;padding:6px" (click)="onSubmit()"
                            class="btn fw-bold btn-primary" rippleEffect><span
                                [translate]="'RESUME_PARSER.MANAGE_RESUME.SEARCH'"></span> </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="d-flex justify-content-center" *ngIf="isLoading; else no_loading" id="loading"
            style="margin: 50px auto">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <ng-template #no_loading>
            <div class="row">
                <div class="col-md-3 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center"><span
                                [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                            <select class="form-control mx-25" [(ngModel)]="page.size">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                        </label>
                    </div>
                </div>
            </div>
            <ngx-datatable id="table-user" #tableResumeTalentPool class="bootstrap core-bootstrap" [rows]="resumes"
                [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size"
                [selectionType]="SelectionType.checkbox" (select)="onSelect($event)" (page)="setPage($event)"
                [selected]="selectedRows" [rowHeight]="50" class="bootstrap core-bootstrap"
                [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true">
                <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                        let-selectFn="selectFn">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                (change)="selectFn(!allRowsSelected)" id="headerFolderChkbxRef" />
                            <label class="custom-control-label" for="headerFolderChkbxRef"></label>
                        </div>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value"
                        let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"
                                [checked]="setIsSelectedRows(row)"
                                
                                (change)="onCheckboxChangeFn($event)" id="rowFolderChkbxRef{{ rowIndex }}" />
                            <label class="custom-control-label" for="rowFolderChkbxRef{{ rowIndex }}"></label>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [width]="widthColumn" [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                    prop="file_name">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <a routerLink="/talent-pool/detail-resume/{{row.id}}" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="{{row.file_name}}"
                                class="d-inline-block text-truncate max-width-custom w-100" style="max-width: 200px;">{{
                                row.file_name }}</a>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.STATUS'|translate" [width]="widthColumn*2/3"
                    prop="status">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span class="badge bg-{{status_color[row?.status]}}" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="{{row?.message}}">
                                <span *ngIf="row?.status?.replaceAll('_',' ')=='Approved'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.APPROVED'"></span>
                                <span *ngIf="row?.status?.replaceAll('_',' ')=='Done'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.DONE'"></span>
                                <span *ngIf="row?.status?.replaceAll('_',' ')=='Failure'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.FAILURE'"></span>
                                <span *ngIf="row?.status?.replaceAll('_',' ')=='Processing'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.PROCESSING'"></span>
                                <span *ngIf="row?.status?.replaceAll('_',' ')=='Wait to process'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.WAIT_TO_PROCESS'"></span>
                                <span *ngIf="row?.status?.replaceAll('_',' ')=='Suspended'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.SUSPENDED'"></span>
                                <span *ngIf="row?.status?.replaceAll('_',' ')=='Modified'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS.MODIFIED'"></span>

                            </span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'|translate"
                    [width]="widthColumn*2/3" prop="sf4c_status">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span class="badge bg-{{status_color[row.sf4c_status]}}" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="{{row.sf4c_message}}">
                                <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C wait to upload'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_WAIT'"></span>
                                <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C failed'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_FAILED'"></span>
                                <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C uploading'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADING'"></span>
                                <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C uploaded'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADED'"></span>
                                <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C not upload'"
                                    [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_NOT_UPLOAD'"></span>

                            </span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate"
                    [width]="widthColumn" prop="current_title">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span class="d-inline-block text-truncate max-width-custom w-100" style="max-width: 200px;"
                                data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_title}}">{{
                                row.current_title }}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [width]="widthColumn" [name]="'RESUME_PARSER.PARSE_RESUME.EMAIL'|translate"
                    prop="email">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span>{{ row.email }}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [width]="widthColumn*2/3"
                    [name]="'RESUME_PARSER.PARSE_RESUME.PHONE_NUMBER'|translate" prop="phone_number">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span>{{ row.phone_number }}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.SOURCE'|translate" [width]="widthColumn"
                    prop="source">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span>{{ row.source }}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [width]="widthColumn" [name]="'RESUME_PARSER.MANAGE_RESUME.OWNER'|translate"
                    *ngIf="user.role=='Super user' || user.role=='Admin'||user.role=='Super admin'" prop="owner">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span>{{ row.owner }}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [width]="widthColumn*2/3"
                    [name]="'RESUME_PARSER.PARSE_RESUME.MODIFIED_AT'|translate" prop="modified_at">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                            <span>{{ row.modified_at|date:'d/M/yyyy, h:mm a' }}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>


            </ngx-datatable>
        </ng-template>
    </div>
</div>
<div class="popup" [@slideFade]="showActionsPopup ? 'visible' : 'hidden'">
    <div class="button-container" *ngIf="showActionsPopup">
        <button type="button" class="btn btn-link action-btn rounded-left" style="margin: 0"
            (click)="unselectAllRows()">
            <span data-feather="x"></span>
        </button>

        <div>
            <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
                id="dropdownMenuButton" rippleEffect (click)="modalOpenUploadSf4c(modalBasic)"
                [disabled]="!getFilterSelectedRows()" *ngIf="tag_resultConnectSf4c_exit">
                <div style="margin: 0 auto 5px auto">
                    <span data-feather="upload"></span>
                </div>
                <span [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD'"></span>
            </button>
            <ng-template #noConnectSf4c>
                <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
                    id="dropdownMenuButton" rippleEffect (click)="modalOpenWarning(modalBasicWarning)">
                    <div style="margin: 0 auto 5px auto">
                        <span data-feather="upload"></span>
                    </div>
                    <span [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD'"></span>
                </button>
            </ng-template>
            <ng-template #modalBasicWarning let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" [translate]="'RESUME_PARSER.INTEGRATION.NOTI'"></h4>

                </div>
                <div class="modal-body" ngbAutofocus>
                    <span [translate]="'RESUME_PARSER.INTEGRATION.NOTI_TITLE'"></span><a routerLink="/account-settings"
                        (click)="modal.dismiss('Cross click')"><span
                            [translate]="'RESUME_PARSER.INTEGRATION.SET_UP'"></span></a>
                </div>

            </ng-template>
            <ng-template #modalBasic let-modal>

                <div class="modal-header">
                    <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>

                </div>
                <div class="form-group" style="margin:1.3rem">
                    <label class="form-label" for="basic-icon-default-fullname"><span
                            [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></span></label>
                </div>
                <div class="me-4">
                    <div class="d-flex justify-content-end">

                        <div class="input-group col-md-6">

                            <input type="text" class="form-control" id="job-search"
                                [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SEARCH' | translate"
                                aria-label="Search..." aria-describedby="job-search" [(ngModel)]="searchText"
                                (input)="Search()" />
                        </div>

                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div *ngIf="isLoadingJr" id="loading" style="margin: 50px auto">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                </div>

                <form [formGroup]="formCheckBoxValid">
                    <div class="modal-body" ngbAutofocus style="overflow-y: visible;">


                        <table class="table align-middle table-bordered">
                            <thead>
                                <tr>
                                    <th style="width: 1%;"></th>
                                    <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                                    <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let resultJr_ of resultJr
                                                | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                                    <td *ngIf="arr_checkboxJr.includes(resultJr_.hcm_system_jd);else noChecked">
                                        <input [value]="resultJr_.hcm_system_jd" (change)="onCheckboxChangeJr($event)"
                                            type="checkbox" class="form-check" checked>

                                    </td>
                                    <ng-template #noChecked>
                                        <td>
                                            <input [value]="resultJr_.hcm_system_jd"
                                                (change)="onCheckboxChangeJr($event)" type="checkbox"
                                                class="form-check">

                                        </td>
                                    </ng-template>

                                    <td>
                                        <div class="">{{resultJr_.hcm_system_jd}}</div>
                                    </td>
                                    <td>
                                        <div class="">{{resultJr_.job_title}}</div>
                                    </td>


                                </tr>

                            </tbody>
                        </table>
                        <div class="row justify-content-end align-items-center" *ngIf="resultJr.length>0">
                            <div class="col-auto">

                                <nav class="mt-sm-0" aria-label="Jobs list pagination">

                                    <ngb-pagination [collectionSize]="resultJr.length" [(page)]="pageJr"
                                        [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true"
                                        [boundaryLinks]="true">


                                    </ngb-pagination>
                                </nav>
                            </div>

                        </div>
                        <div class="form-group"
                            *ngIf="sf4cPicklist?.importedby?.length>0 && (user.role=='Super admin' || user.role=='Admin')">
                            <label class="form-label" for="basic-icon-default-fullname"><span
                                    [translate]="'RESUME_PARSER.INTEGRATION.IMPORTEDBY'">Imported by</span></label>
                            <ng-select type="text" id="importedby"
                                [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SELECTED_IMPORTEDBY'|translate"
                                formControlName="importedby">
                                <ng-option *ngFor="let importedby of sf4cPicklist.importedby"
                                    [value]="importedby">{{importedby.value}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="formCheckBoxValid.value.importedby">
                            <label [translate]="'RESUME_PARSER.INTEGRATION.SOURCE'"></label><span>:
                            </span><span>{{formCheckBoxValid.value?.importedby?.source_value}}</span>
                        </div>
                    </div>
                    <div class="modal-footer">

                        <button *ngIf="loading_;else noLoadingUpload" (click)="FormCheckValidUpload(modalCheckValid)"
                            class="btn btn-primary ms-3 mr-1" type="button" disabled>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                        </button>
                        <ng-template #noLoadingUpload>
                            <button *ngIf="resultCheckValidUpload?.invalid_resume_id?.length>0;else noArr"
                                class="btn btn-primary ms-3 mr-1" rippleEffect type="button"
                                (click)="FormCheckValidUpload(modalCheckValid)">
                                <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                            </button>
                            <ng-template #noArr>
                                <button class="btn btn-primary ms-3 mr-1" rippleEffect type="button"
                                    (click)="FormCheckValidUpload(modalCheckValid)">
                                    <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                                </button>
                            </ng-template>
                        </ng-template>
                        <button (click)="modal.dismiss('Cross click')" (click)="cancelFormCheckboxValid()" type="reset"
                            rippleEffect class="btn btn-secondary"
                            [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
                    </div>
                </form>
            </ng-template>
            <ng-template #modalCheckValid let-modal>

                <div class="modal-header">
                    <h4 class="modal-title" [translate]="'RESUME_PARSER.INTEGRATION.TITLE_PROCESS'"></h4>

                </div>



                <form [formGroup]="formCheckBoxJr">
                    <div class="modal-body" ngbAutofocus>


                        <table class="table align-middle table-bordered">
                            <thead>
                                <tr>
                                    <th [translate]="'RESUME_PARSER.INTEGRATION.OVERWRITE'"></th>
                                    <th [translate]="'RESUME_PARSER.INTEGRATION.RESUME_ID'"></th>
                                    <th [translate]="'RESUME_PARSER.INTEGRATION.JOB_REQ_ID'"></th>
                                    <th [translate]="'RESUME_PARSER.INTEGRATION.ERROR'"></th>



                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let _resultCheckValidUpload of resultCheckValidUpload?.invalid_resume_id
                                                | slice: (pageCheckValid - 1) * basicSelectedOption:(pageCheckValid - 1) * basicSelectedOption + basicSelectedOption">
                                    <td *ngIf="!_resultCheckValidUpload.overwrite;else Overwrite">
                                        <input [value]="_resultCheckValidUpload.id"
                                            (change)="onCheckboxChangeValid($event)" type="checkbox" class="form-check"
                                            disabled>
                                    </td>
                                    <ng-template #Overwrite>
                                        <td
                                            *ngIf="arr_checkboxValid.includes(_resultCheckValidUpload.id);else noChecked">
                                            <input [value]="_resultCheckValidUpload.id"
                                                (change)="onCheckboxChangeValid($event)" type="checkbox"
                                                class="form-check" checked>

                                        </td>
                                        <ng-template #noChecked>
                                            <td>
                                                <input [value]="_resultCheckValidUpload.id"
                                                    (change)="onCheckboxChangeValid($event)" type="checkbox"
                                                    class="form-check">

                                            </td>
                                        </ng-template>
                                    </ng-template>

                                    <td>
                                        <div class="">{{_resultCheckValidUpload.id}}</div>
                                    </td>
                                    <td>
                                        <div class="">{{_resultCheckValidUpload.job_id}}</div>
                                    </td>
                                    <td>
                                        <div *ngIf="language=='en';else VN" class="">
                                            {{_resultCheckValidUpload.error.en}}</div>
                                        <ng-template #VN>
                                            <div class="">{{_resultCheckValidUpload.error.vn}}</div>

                                        </ng-template>
                                    </td>


                                </tr>

                            </tbody>
                        </table>
                        <div class="row justify-content-between align-items-center"
                            *ngIf="resultCheckValidUpload?.invalid_resume_id.length>0">
                            <div class="col-auto">

                                <nav class="mt-sm-0" aria-label="Jobs list pagination">

                                    <ngb-pagination [collectionSize]="resultCheckValidUpload?.invalid_resume_id.length"
                                        [(page)]="pageCheckValid" [pageSize]="basicSelectedOption" [maxSize]="10"
                                        [rotate]="true" [boundaryLinks]="true">


                                    </ngb-pagination>
                                </nav>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">

                        <button *ngIf="loadingFormJA;else noLoadingUploadJA" (click)="FormCheckBoxJr()"
                            class="btn btn-primary ms-3 mr-1" type="button" disabled>
                            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                        </button>
                        <ng-template #noLoadingUploadJA>
                            <button
                                [disabled]="arr_checkboxValid.length==0 && resultCheckValidUpload.resume_id.length==0"
                                class="btn btn-primary ms-3 mr-1" rippleEffect type="button" (click)="FormCheckBoxJr()">
                                <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                            </button>
                        </ng-template>
                        <button (click)="modal.dismiss('Cross click')" (click)="cancelFormCheckBoxJr()" type="reset"
                            rippleEffect class="btn btn-secondary"
                            [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
                    </div>
                </form>
            </ng-template>
        </div>
        <div>
            <div>
                <button class="btn btn-warning d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
                    id="dropdownMenuButton" rippleEffect (click)="modalOpenChangeTalentpool(modalChangeTalentpool)">
                    <div style="margin: 0 auto 5px auto">
                        <span data-feather="refresh-cw"></span>
                    </div>
                    <span [translate]="'RESUME_PARSER.TALENT_POOL.CHANGE_RESUMES'">Remove resumes</span>
                </button>
                <ng-template #modalChangeTalentpool let-modal>
                    <ng-modal class="modalAddResume">
                        <modal-header class="modal-header">
                            <h4 class="modal-title" [translate]="'RESUME_PARSER.TALENT_POOL.CHANGE_RESUMES'">
                                Add resume to Talent pool
                            </h4>
                        </modal-header>
                        <modal-content>
                            <form #ChangeTalentpoolForm="ngForm">
                                <div class="modal-body flex-grow-1" tabindex="1" style="overflow-y: visible;">
                                    <div class="form-group">
                                        <label class="form-label" for="basic-icon-default-fullname"><span
                                                [translate]="'RESUME_PARSER.TALENT_POOL.NAME_FOLDER'">Name talent
                                                pool</span><span style="color: red; padding-left: 5px">*</span></label>
                                        <ng-select [searchable]="false" [items]="talentPools" bindLabel="name"
                                            bindValue="id" [(ngModel)]="idTalentPoolChange" name="idTalentPoolChange" required>
                                        </ng-select>
                                        <!-- <span *ngIf="
                              addResumeToTalentPoolForm.controls.talent_pool.invalid &&
                              addResumeToTalentPoolForm.controls.talent_pool.touched
                            " class="invalid-form">
                                <small class="form-text text-danger"
                                  *ngIf="addResumeToTalentPoolForm.controls.talent_pool.errors.required"
                                  [translate]="'RESUME_PARSER.TALENT_POOL.REQUIRED'"></small>
                              </span> -->


                                    </div>

                                    <button tabindex="2" (click)="changeTalentpoolResume()" [disabled]="!ChangeTalentpoolForm.form.valid"
                                        class="btn btn-primary mr-1">
                                        <span [translate]="'RESUME_PARSER.TALENT_POOL.CHANGE'">Add resume to talent
                                            pool</span>
                                    </button>
                                    <button type="reset" class="btn btn-outline-secondary"
                                    (click)="modal.dismiss('Cross click')" rippleEffect>
                                        <span [translate]="'RESUME_PARSER.TALENT_POOL.CANCEL'">Cancel</span>
                                    </button>
                                </div>
                            </form>
                        </modal-content>
                    </ng-modal>
                </ng-template>
            </div>
        </div>
        <div>
            <div>
                <button class="btn btn-danger d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
                    id="dropdownMenuButton" rippleEffect (click)="removeResumes()">
                    <div style="margin: 0 auto 5px auto">
                        <span data-feather="trash"></span>
                    </div>
                    <span [translate]="'RESUME_PARSER.TALENT_POOL.REMOVE_RESUMES'">Remove resumes</span>
                </button>
            </div>
        </div>
    </div>
</div>