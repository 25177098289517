import { Locale } from "@core/services/translation.service";

export const locale: Locale = {
  lang: "en",
  data: {
    GENERAL: {
      GENERAL: "General",
      LIST_PACKAGE: "Package list",
      ADD_PACKAGE: "Add package",
      UPDATE_PACKAGE: "Update package",
      CREATE: "Create",
      UPDATE: "Update",
      DELETE: "Delete",
      TITLE: "Title",
      DESCRIPTION: "Description",
      DISPLAY_ALLOW: "Display allow",
      DISPLAY_ORDER: "Display order",
      PACKAGE_FREE: "Package free",
      ACTION: "Action",
      YES: "Yes",
      NO: "No",
      EDIT: {
        INFO_EDIT: "Edit information",
        PACKAGE_NAME: "Package name",
        PACKAGE_NAME_DES: "Display Name of the Package",
        TYPE_OF_SERVICE: "Type of service",
        CHOOSE_PACKAGE_CONFIG: "Choose config package",
        DESCRIPTION: "Description",
        CONTENT_HTML: "HTML content",
        ADD_TAG: "Add tags",
        PACKAGE_DEFAULT_LABEL: "Trial Package for the Selected Service",
        PACKAGE_DEFAULT: "Package default",
        PACKAGE_DEFAULT_DES: "You can only select one trial package for one type of service",
        PACKAGE_DISPLAY: "Show package to users",
        DISPLAY: "Display",
        PACKAGE_DISPLAY_DES: "Hide optional business packages",
        IS_FREE_TRIAL: "Is free trial",
        SUBSCRIBE_BUTTON_TEXT: "Subscribe text",
        ORDER: "Display order",
        CONFIRM: "Confirm",
        CANCEL: "Cancel",
        REQUIRED: "This field is required"

      },
      DETAIL: {
        VIEW: "Information",
        PRODUCT_PRICE: "Product price",
        DETAIL: "Detail",
        MONTH: "Month",
        YEAR: "Year",
        DAY: "Day"
      },
      MODAL: {
        ACTION: "Action",
        DELETE_A: "Delete package",
        CANT_UNDO: "You won't be able to undo this",
        CANCEL: "Cancel",
        CONFIRM: "Confirm",
      },
      TOAST: {
        DELETE_A_SUC: "Delete package",
        ERROR: "Error",
        FAILED: "Failed",
        SUCCESS: "Success"
      },

    },
    PACKAGES_CONFIG: {
        PACKAGES_CONFIG: "Packages config",
        PACKAGE_CONFIG: "Package config",
        NAME: "Name",
        NUMBER_OF_STREAM: "Number of stream",
        ADD_CONFIG: "Add config",
        EDIT: {
          EDIT_CONFIG: "Edit config",
          NAME_CONFIG: "Config name",
          MATCHING_THREAD_LIMIT: "Matching thread limited",
          PARSE_THREAD_LIMIT: "Parse thread limited",
          PARSE_LIMITED: "Parse limited",
          MATCHING_LIMITED: "Matching limited",
          MAX_USER: "User limited"
        },
        MODAL: {
          ACTION: "Action",
          DELETE_A: "Delete package config",
          CANT_UNDO: "You won't be able to undo this",
          CANCEL: "Cancel",
          CONFIRM: "Confirm",
        },
        TOAST: {
          DELETE_A_SUC: "Delete package config",
          ERROR: "Error",
          FAILED: "Failed",
          SUCCESS: "Success"
        },
    },
    PRICING_CONFIG: {
        PRICING_CONFIG: "Pricing config",
        TITLE: "Title",
        PACKAGE: "Package",
        DESCRIPTION: "Description",
        DEFAULT_PRICING: "Default Price for Package",
        SELLER: "Seller",
        PRICING: "Pricing",
        ACTION: "Action",
        CONFIG_NAME_PRICING: "Price Configuration Name",
        DISPLAY_DEFAULT_PRICING: "Default Price Displayed with the Selected Sales Package",
        DEFAULT_PRICING_LABEL: "Default pricing",
        PRICING_VN: "Price: Vietnamese Dong",
        SALE_PRICING: "Sales Package",
        CHOOSE_SALE_PRICING: "Choose sale package",
        PAYMENT_CYCLE: "Payment cycle",
        UNIT: "Unit",
        PACKAGE_PRICING: "Pricing package",
        MONTH: "Month",
        EDIT: {
          ADD_PRICING: "Add pricing",
          EDIT_PRICING: "Edit pricing"
        },
        MODAL: {
          ACTION: "Action",
          DELETE_A: "Delete pricing config",
          CANT_UNDO: "You won't be able to undo this",
          CANCEL: "Cancel",
          CONFIRM: "Confirm",
        },
        TOAST: {
          DELETE_A_SUC: "Delete pricing config",
          ERROR: "Error",
          FAILED: "Failed",
          SUCCESS: "Success"
        },
    },
    ADD_PLAN_COST: {
      TITLE: "Update plan cost for account",
      MODAL_TITLE: "Update plan cost",
      UPDATE: "Update",
      CHOOSE_COST: "Choose plan cost",
      PLAN_COST: "Plan cost",
      USER: "User",
      COMPANY_ACTIVITY: "Corporate package",
      TRIAL_ACTIVITY: "Trial package",
      TABLE: {
        USER: "Users",
        COMPANY: "Company",
        PACKAGE_NAME: "Package",
        EXPIRY_DATE: "Expiry date",
        PRICE: "Price"
      },
    }
}
};
