export const locale = {
    lang: 'vn',
    data: {
        DASHBOARD:{
            CHOOSE_COMPANY: "Chọn công ty cha",
            CHOOSE_SUB_COMPANY: "Chọn công ty",
            ALL_COMPANY: "Tất cả",
            FILTER: "Lọc",
            RESET: "Bỏ lọc",
            RESUME_UPLOADED: "Hồ sơ đã tải lên",
            RESUME_UPLOADED_DAY: "Hồ sơ đã tải lên 7 ngày gần nhất",
            AVERAGE_CONF_SCORE: "Điểm tin cậy trung bình",
            RESUME_STATUS: "Số lượng hồ sơ ứng viên đã tải lên theo trạng thái",
            RESUME_OWNER: "Số lượng hồ sơ ứng viên đã tải lên của người dùng",
            YEAR_EXP: "Thống kê hồ sơ theo năm kinh nghiệm",
            GROUP_CATEGORY: "Thống kê hồ sơ theo nhóm ngành",
            GROUP_SOURCE: "Thống kê hồ sơ theo nguồn",
            RESUME_STATUS_DAY: "Trạng thái hồ sơ theo ngày trong 14 ngày qua",
            
            RESUME_STATUS_SF4C: "Trạng thái hồ sơ đã tải lên SuccessFactors",
            RESUME_STATUS_SF4C_DAY: "Trạng thái hồ sơ đã tải lên SuccessFactors theo ngày trong 14 ngày qua",
            
            RESUME_MATCHING: "Hồ sơ phù hợp đã tải lên",
            RESUME_MATCHING_DAY: "Hồ sơ phù hợp đã tải lên trong 7 ngày qua",
            RESUME_MATCHING_SF4C: "Hồ sơ phù hợp đã tải lên SuccessFactors",
            RESUME_MATCHING_SF4C_DAY: "Hồ sơ phù hợp đã tải lên SuccessFactors theo ngày trong 14 ngày qua",
            ACTIVE_USER_BY_DAY: "Hoạt động của người dùng theo ngày",
            JOB_ID: "Id công việc",
            CREATE_AT: "Thời gian tạo",
            CREATE_BY: "Người tạo",
            COUNT_RECOMMEND: "Số lượng khuyến nghị",
            RESUME_ID: "Id CV",
            OVERALL_SCORE: "Điểm tổng",
            NAME_JOB: "Tên công việc",
            ITEM_PAGE: "Mục trên trang",
            CHOOSE_PERIOD: "Chọn giai đoạn",
            FREE_TRIAL: "Người dùng thử miễn phí",
            PAID_USERS: "Người dùng trả phí",
            PACKAGE: "Số lượng gói dùng",
            COMPANY_ACTIVITY: "Gói công ty",
            TRIAL_ACTIVITY: "Gói dùng thử",
            REVENUE: "Doanh thu theo ngày",
            INFO_PACKAGE: "Danh sách người dùng theo gói",
            PARSE_NUM_LEFT: "Số lần trích xuất còn lại",
            MATCHING_NUM_LEFT: "Số lần chấm điểm còn lại",
            USER_NUM_LEFT: "Số người dùng hoạt động hiện tại",
            TABLE: {
              USER: "Tài khoản",
              PARSE_NUM: "Trích xuất",
              MATCHING_NUM: "Chấm điểm",
              START_DATE: "Bắt đầu",
              COMPANY: "Công ty",
              PACKAGE_NAME: "Gói",
              EXPIRY_DATE: "Hạn dùng",
              PRICE: "Giá",
              TOTAL: "Số lượt sử dụng",
              DETAIL: "Chi tiết",
              EXPAND: "Mở rộng/ thu gọn hàng",
              COMMENT: "Báo cáo",
              SEARCH_HISTORY: "Lịch sử tìm kiếm",
              LEFT_PARSE_NUM: "Trích xuất còn lại",
              LEFT_MATCHING_NUM: "Chấm điểm còn lại",
              NUM_ACTIVE_USER: "Số người dùng hoạt động",
              PACKAGE_EXPIRY: "Hết hạn"
            },
            COMPANY_SEARCH_TAB: "Tìm kiếm",
            COMPANY_RECOMMEND_TAB: "Khuyến nghị",
            ACCEPT: "Chấp nhận",
            UNMATCH_REPORT: "Báo cáo ứng viên không phù hợp",
            COMPANY_SEARCH: "Hoạt động tìm kiếm và khuyến nghị ứng viên của các công ty",
            COMPANY_RECOMMEND: "Hoạt động gợi ý ứng viên của các công ty",
            COMPANY_REPORT: "Hoạt động báo cáo ứng viên không phù hợp khi tìm kiếm khuyến nghị",
            COMPANY_ACTIVITY_CURR: "Hoạt động của công ty",
            USER_ACTIVITY_CURR: "Hoạt động của người dùng",
            YAXIS_TITLE: "Hồ sơ",
            XAXIS_TITLE: "Ngày",
            YAXIS_COUNT_NUMBER: "Người dùng",
            YAXIS_REVENUE: "Doanh thu (đồng)",
            UPGRADE_PACKAGE: "Nâng cấp gói"
            
          }
      
    }
  };
  