import { CoreMenu } from '@core/types';
import { CurrentPackage, Role } from '../auth/models/role'
//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: 'Tổng quan',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'section',
    role: [Role.SuperUser, Role.Admin, Role.SuperAdmin],
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: 'home',
    // badge: {
    //   title: '2',
    //   translate: 'MENU.DASHBOARD.BADGE',
    //   classes: 'badge-light-warning badge-pill'
    // },
    children: [
      {
        id: 'history',
        title: 'Lịch sử hoạt động',
        translate: 'MENU.DASHBOARD.ACTIVITY_HISTORY',
        type: 'item',
        // role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'airplay',
        url: 'dashboard/activity',
        permissions: false,
        role: [Role.SuperUser, Role.Admin, Role.SuperAdmin],
        
      },
      {
        // If role is not assigned will be display to all
        id: 'analytics',
        title: 'Phân tích',
        translate: 'MENU.DASHBOARD.ANALYTICS',
        type: 'item',
        icon: 'activity',
        url: 'dashboard/analytic',
        permissions: false,
        role: [Role.Admin, Role.SuperUser, Role.SuperAdmin],
      }
    ]
  },
  // Resume parser tools
  {
    id: 'apps',
    type: 'section',
    title: 'HỒ SƠ',
    translate: 'MENU.RESUME_PARSER.SECTION',
    icon: 'package',
    children: [
      // {
      //   id: 'parse-resume',
      //   title: 'Parse Resume',
      //   translate: 'MENU.RESUME_PARSER.PARSE_RESUME',
      //   type: 'item',
      //   icon: 'file',
      //   url: '/parse-resume',
      //   permissions: Permissions.PARSE_CV
      // },
      {
        id: 'manage-resume',
        title: 'Quản lý hồ sơ',
        translate: 'MENU.RESUME_PARSER.MANAGE_RESUME',
        type: 'item',
        icon: 'folder',
        url: '/manage-resume',
        permissions: false,
        // permissions: Permissions.PARSE_CV,
        current_package: [CurrentPackage.FREE_TRIAL, CurrentPackage.BASIC_PARSING, CurrentPackage.BASIC,CurrentPackage.ADVANCED_PARSING,CurrentPackage.ADVANCED, CurrentPackage.ENTERPRISE],
        role: [Role.Admin, Role.SuperAdmin, Role.SuperUser, Role.User, Role.FreeTrial],

      },
      {
        id: 'integration',
        title: 'Tích hợp SAP SF',
        translate: 'MENU.RESUME_PARSER.INTEGRATION',
        type: 'item',
        icon: 'upload',
        url: '/integration',
        permissions: true,
        role: [Role.Admin, Role.SuperAdmin, Role.User, Role.SuperUser],
        current_package: [CurrentPackage.ENTERPRISE],

      },
      {
        id: 'talent-pool',
        title: 'Kho ứng viên',
        translate: 'MENU.RESUME_PARSER.TALENT_POOL',
        type: 'item',
        icon: 'database',
        url: '/talent-pool',
        permissions: false,
        // permissions: Permissions.PARSE_CV,
        role: [Role.Admin, Role.SuperAdmin, Role.User],

      },
    ]
  },
  // Talent pool tools
  {
    id: 'talent-pool',
    type: 'section',
    title: 'CÔNG VIỆC & ỨNG VIÊN',
    translate: 'MENU.TALENT_POOL.SECTION',
    icon: 'package',
    children: [
      {
        id: 'manage-job',
        title: 'Quản lý đăng tuyển',
        translate: 'MENU.TALENT_POOL.MANAGE_JOB',
        type: 'item',
        icon: 'folder',
        url: '/manage-job',
        permissions: false,
        // permissions: Permissions.SEARCH_ENGINE,
        current_package: [ CurrentPackage.BASIC, CurrentPackage.ADVANCED, CurrentPackage.ENTERPRISE],
        role: [Role.Admin, Role.SuperAdmin, Role.User],

      },
      {
        id: 'search-cv',
        title: 'Tìm kiếm ứng viên',
        translate: 'MENU.TALENT_POOL.SEARCH_CV',
        type: 'item',
        icon: 'search',
        url: '/search-resume',
        permissions: false,
        // permissions: Permissions.SEARCH_ENGINE,
        current_package: [ CurrentPackage.BASIC, CurrentPackage.ADVANCED, CurrentPackage.ENTERPRISE],
        role: [Role.Admin, Role.SuperAdmin, Role.User],

      },
      // {
      //   id: 'matching-cv-jd',
      //   title: 'Chấm điểm phù hợp',
      //   translate: 'MENU.TALENT_POOL.MONITOR_UPLOAD',
      //   type: 'item',
      //   icon: 'refresh-ccw',
      //   url: '/matching-cv-jd',
      //   // permissions: Permissions.SEARCH_ENGINE,
      //   current_package: [CurrentPackage.FREE_TRIAL, CurrentPackage.BASIC, CurrentPackage.ADVANCED, CurrentPackage.ENTERPRISE],
      //   role: [Role.Admin, Role.SuperAdmin, Role.User, Role.FreeTrial],

      // },
    ]
  },
  // Manage package
  {
    id: 'manage-package',
    type: 'section',
    title: 'GÓI BÁN',
    translate: 'MENU.MANAGE_PACKAGE.SECTION',
    icon: 'package',
    role: [Role.SuperUser],
    children: [
      {
        id: 'manage-package',
        title: 'Quản lý gói bán',
        translate: 'MENU.MANAGE_PACKAGE.MANAGE_PACKAGE',
        type: 'item',
        icon: 'credit-card',
        url: '/packages',
        permissions: false,
        // permissions: Permissions.SEARCH_ENGINE,
        role: [Role.SuperUser],

      },
    ]
  },
  
];
