import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ResumeParserService } from '../services/resume-parser.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import Swal from 'sweetalert2';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { Role, User } from 'app/auth/models';
import { Router } from '@angular/router';
@Component({
  selector: 'app-talent-pool',
  templateUrl: './talent-pool.component.html',
  styleUrls: ['./talent-pool.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TalentPoolComponent implements OnInit {
  public talentPools: any[] = []
  searchText: string = ""
  public editFolderBoolean = false;
  public createFolderForm: FormGroup;
  id: string
  public owner__email: string[] = []
  public owner__email_items: any[]=[]
  public user: User = new User()

  constructor(
    private resumeService: ResumeParserService,
    private _router: Router,
    private toast: ToastrService,
    private _translateService: TranslateService,
    private _coreTranslateService: CoreTranslationService,
    private _modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
  ) {
    this._coreTranslateService.translate(enLocale);
    this._coreTranslateService.translate(vnLocal);
    this.createFolderForm = this.formBuilder.group({
      name: ["", Validators.required]
    })
  }
  getListUser(){
    this._authenticationService.listUsers('').subscribe(
      (res)=>{
        for (let i=0;i<res.results.length;i++){
          let owner__email_item = res.results[i].last_name + ' '+ res.results[i].first_name + ': '+ res.results[i].email
          this.owner__email_items.push({"name":owner__email_item, "value": res.results[i].email, "id":res.results[i].id})
          
        }
        this.owner__email_items = [...this.owner__email_items]
      },
      (err)=>{
        console.log(err)
      }
    )
  }
  searchFolder() {
    let url_dynamic = ''
    if(this.searchText){
      url_dynamic +="&name="+this.searchText
    }
    let owner__email = ""
    if (this.owner__email.length > 0) {
      for (let i = 0; i < this.owner__email.length; i++) {
        let url_current_owner_email = "&owner__email=" + this.owner__email[i]
        owner__email = owner__email + url_current_owner_email
      }
    } else {
      owner__email = ""
    }
    if (this.owner__email !== undefined && this.owner__email !== null) {
      url_dynamic = url_dynamic + owner__email
    }
    this.resumeService.listTalentPool(url_dynamic).subscribe((res) => {
      this.talentPools = res
      this.saveFilter()
    })
  }
  toggle(event: MouseEvent){
    event.stopPropagation()
  }
  addModalFolder(modal: any, info: any = null) {
    if (info) {
      this.editFolderBoolean = true;
      this.createFolderForm.get("name").setValue(info.name);
      this.id = info.id
    } else {
      this.searchText = "";
      this.searchFolder();
      this.editFolderBoolean = false;
    }
    this._modalService.open(modal, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }
  onCreateNewOrUpdateFolder() {
    const formData = {};
    formData['name'] = this.createFolderForm.get("name").value
    if(this.editFolderBoolean){
      this.resumeService.updateTalentPool(this.id, formData).subscribe((res)=>{
        this._modalService.dismissAll();
        this.createFolderForm.reset();
        this.searchFolder();
      },
      (err)=>{
        this._modalService.dismissAll();
        this.toast.error(err?.error?.message);
        this.createFolderForm.reset();
      })
    }else{
      this.resumeService.createTalentPool(formData).subscribe((res)=>{
        this._modalService.dismissAll();
        this.createFolderForm.reset();
        this.searchFolder();
      },
      (err)=>{
        this._modalService.dismissAll();
        this.toast.error(err?.error?.message);
        this.createFolderForm.reset();
      })
    }
  }
  cancel() {

  }
  toListDocument(id: string) {
    let link = "/talent-pool/" + id;
    this._router.navigateByUrl(link);
  }
  onKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === "Tab" && index === this.talentPools.length) {
      event.preventDefault();
    } else if (event.key === "Enter") {
      event.preventDefault();
      this.toListDocument(this.talentPools[index].id);
    }
  }
  getListTalentPool() {
    let url_dynamic = ''
    this.resumeService.listTalentPool(url_dynamic).subscribe((res) => {
      this.talentPools = res
    })
  }
  deleteTalentPool(id: string) {
    Swal.fire({
      title: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_T"),
      text: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANT_UNDO"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      cancelButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL"),
      confirmButtonText: this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.CONFIRM"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary ml-1",
      },
      preConfirm: async () => {
        this.resumeService.deleteTalentPool(id).subscribe(
          (res) => {
            this.toast.success(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.MODAL.DELETE_T"),
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.SUCCESS"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
            this.getListTalentPool();
          },
          (err) => {
            this.toast.error(
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.ERROR"),
              this._translateService.instant("RESUME_PARSER.MANAGE_RESUME.TOAST.FAILED"),
              {
                toastClass: "toast ngx-toastr",
                closeButton: true,
              }
            );
          }
        );
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
    });
  }
  saveFilter(){
    this.resumeService.setFilterFolderPageTalentpoolValue(
      this.searchText,
      this.owner__email
    )
  }
  ngOnInit(): void {
    const { searchText, owner__email } = this.resumeService.getFilterFolderPageTalentpoolValue();
    this.searchText = searchText || null;
    this.owner__email = owner__email || [];
    this.searchFolder()
    this.user = this._authenticationService.currentUserValue
    if(this.user.role!=Role.FreeTrial&&this.user.role!=Role.User){
      this.getListUser()
    }
  }

}
