import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageComponent } from './package.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { TranslateModule } from '@ngx-translate/core';
import { ShareComponentModule } from '../share-component/share-component.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { DetailPackageComponent } from './detail-package/detail-package.component';

const routes: Routes = [
  {
    path: "packages",
    component: PackageComponent,
    canActivate: [AuthGuard]
  }
]

@NgModule({
  declarations: [
    PackageComponent,
    DetailPackageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    CoreCommonModule,
    TranslateModule,
    NgxDatatableModule,
    ShareComponentModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CoreTouchspinModule
  ]
})
export class PackageModule { }
