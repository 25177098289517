<div class="content-wrapper container-xxxl p-0 card">
    <div class="content-body card-body" id="ngx-datatable-row-details">
        <div class="row" >
            <!-- <div class="col-md-3 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label
                  class="d-flex align-items-center"
                  style="max-width: 100%; flex-wrap: nowrap"
                  ><span [translate]="'SHOW'"></span>
                  <select
                    class="form-control mx-25 pr-3 show-doc"
                    [(ngModel)]="page.size"
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                  <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                </label>
              </div>
            </div> -->
            <div class="col-12 row align-items-center justify-content-end mb-1">
              <div class="">
                
                <button class="btn btn-primary btn-icon mr-2" rippleEffect (click)="modalOpenCreateApiKey(modalApiKey)">
                    <span [translate]="'CREATE_API_KEY.CREATE'" class="d-none d-lg-inline"></span>
                    
                </button>
              </div>
              <ng-template #modalApiKey let-modal>

                <form [formGroup]="CreateApiKeyForm">
                    <div class="modal-header">
                        <h4 class="modal-title" [translate]="'CREATE_API_KEY.CREATE'">
                        </h4>
                        
                        <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                          aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button> -->
                      </div>
                      <div class="modal-body" ngbAutofocus>
                        <div class="form-group">
                          <label for="token_name"
                            ><span [translate]="'NAME'"></span><b style="color:red">*</b></label>
                            <input autocomplete="off" [ngClass]="{
                                'is-invalid': (submitted && f.token_name.errors)||(f.token_name.invalid &&
                                f.token_name.touched)
                              }" type="text" id="token_name" formControlName="token_name" class="form-control" name="token_name" />
                            <div *ngIf="(submitted && f.token_name.errors)||(f.token_name.invalid &&
                            f.token_name.touched)" class="invalid-feedback">
                              <div [translate]="'CREATE_API_KEY.NAME_REQUIRED'"></div>
                            </div>
                          
                        </div>
                
                        <div class="form-group">
                          <div class="d-flex justify-content-between">
                            <label for="login-password"
                              ><span [translate]="'CREATE_API_KEY.PASSWORD'"></span><b style="color:red">*</b></label>
                          </div>
                          <div class="input-group input-group-merge form-password-toggle">
                            <input autocomplete="off" [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                              class="form-control form-control-merge" [ngClass]="{
                                                      'is-invalid error': (submitted && f.password.errors)||(f.password.invalid &&
                            f.password.touched)
                                                    }" placeholder="············"
                              />
                            <div class="input-group-append">
                              <span class="input-group-text cursor-pointer"><i class="feather font-small-4"
                                  [ngClass]="{
                                                          'icon-eye-off': passwordTextType,
                                                          'icon-eye': !passwordTextType
                                                        }" (click)="togglePasswordTextType()"></i></span>
                            </div>
                          </div>
                          <div *ngIf="(submitted && f.password.errors)||(f.password.invalid &&
                            f.password.touched)" class="invalid-feedback"
                            [ngClass]="{ 'd-block': (submitted && f.password.errors)||(f.password.invalid &&
                            f.password.touched) }">
                            <div *ngIf="f.password.errors.required"
                              [translate]="'PASSWORD_REQUIRED.PASSWORD_REQUIRED'"></div>
                          </div>
                        </div>
                        <div class="form-group">
                            <label for="expired_time"
                              [translate]="'CREATE_API_KEY.EXPIRED_CREATE'"></label>
                              <input id="expired_time" formControlName="expired_time"
                              bsDatepicker type="text" class="form-control"
                              [minDate]="minDate"
                              [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY' }" autocomplete="off"/>
                        </div>
            
                        
                      </div>
                      <div class="modal-footer">
                        <button (click)="modal.dismiss('Cross click')" type="reset" rippleEffect class="btn btn-secondary mr-1"
                  [translate]="'CREATE_API_KEY.CANCEL'"></button>
                        <button [disabled]="!CreateApiKeyForm.valid" type="button" (click)="createApiKey()"
                          (click)="modal.close('Accept click')" class="btn btn-primary"
                          [translate]="'CREATE_API_KEY.SAVE'"></button>
                      </div>
                </form>
              </ng-template>
            </div>
        </div>
        <ngx-datatable
          id="table-api-key"
          #tableRowApiKey
          class="bootstrap core-bootstrap"
          [rows]="listApiKey"
          [externalPaging]="true"
            [count]="page.totalElements"
            [offset]="page.pageNumber"
            [limit]="page.size"
            (page)="setPage($event)"
          [rowHeight]="50"
          class="bootstrap core-bootstrap"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="false"

        >
          <ngx-datatable-column
            [name]="'NAME'|translate"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100">
                <span>{{row.token_name}}</span>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="'CREATED'|translate"
            
            
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
              <span>{{row.created|date:'dd/MM/yyyy'}}</span>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="'EXPIRED'|translate"
          >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="w-100" >
              <span *ngIf="row.expired;else Expired">{{row.expired|date:'dd/MM/yyyy'}}</span>
              <ng-template #Expired>
              <span [translate]="'NO_LIMIT'"></span>
              </ng-template>
            </div>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
                  [name]="'ACTION' | translate"
                  [sortable]="false"
                  [sortable]="false"
                  [draggable]="false"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                      <a type="button" href="javascript:void(0)" (click)="copyToThisClipboard(row.key)" title="Copy token" class="btn btn-icon btn-flat-primary rounded-circle" rippleEffect>
                        <span
                              class="d-flex align-items-center justify-content-center"
                              ><i class="fa fa-clone"></i>
                            </span>
                        </a
                        >
                      <a type="button" href="javascript:void(0)" (click)="deleteApiKey(row.key)" title="Delete" class="btn btn-icon btn-flat-danger rounded-circle" rippleEffect>
                        <span
                              class="d-flex align-items-center justify-content-center"
                              ><i class="fa fa-trash-o"></i>
                            </span>
                          </a>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
        </ngx-datatable>
    </div>
  </div>