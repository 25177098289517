<div class="content-wrapper container-xxxl p-0 card">
  <div class="content-body card-body">
    <div>
      <ul ngbNav #nav="ngbNav" class="nav nav-tabs" [(activeId)]="selectedTab">

        <li [ngbNavItem]="'integration'">
          <a (click)="integration()" ngbNavLink class="d-flex align-items-center">
            <i data-feather="upload" class="mr-sm-50"></i><span class="d-none d-sm-block"
              [translate]="'RESUME_PARSER.INTEGRATION.INTEGRATION'"></span></a>
          <ng-template ngbNavContent>
            <!-- Information Tab starts -->
            <div class="tab-pane" id="information" aria-labelledby="information-tab" role="tabpanel">
              <!-- users edit Info form start  -->
              <form (ngSubmit)="(SearchForm.form.valid)" #SearchForm="ngForm">

                <div class="row mb-2">
                  <div class="col-md-3">

                    <div class="input-group">

                      <input (keydown.enter)="onSubmit()" type="text" class="form-control" id="job-search"
                        [placeholder]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate" name="file_name"
                        [(ngModel)]="file_name" />
                    </div>

                  </div>
                  <div class="col-md-3 me-2 pl-0"
                    *ngIf="user.role=='Site Admin'||user.role=='Super admin' || user.role=='Admin'">

                    <ng-select (keydown.enter)="onSubmit()" [multiple]="true" [items]="owner__email_items" bindLabel="name" bindValue="value"
                      [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate" name="owner__email"
                      [(ngModel)]="owner__email" id="owner__email">

                    </ng-select>

                  </div>
                  <div class="col-md-3 pl-0">

                    <div class="input-group">

                      <input (keydown.enter)="onSubmit()" type="text" class="form-control" id="job-search"
                        [placeholder]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" name="current_title"
                        [(ngModel)]="current_title" />
                    </div>

                  </div>
                  <div class="col-md-2 pl-0">
                    <button style="height: 38px;padding:6px" type="button" (click)="onSubmit()"
                      class="btn fw-bold btn-primary" rippleEffect><span
                        [translate]="'RESUME_PARSER.MANAGE_RESUME.SEARCH'"></span> </button>

                  </div>

                </div>


              </form>
              <!-- users edit Info form ends -->
              <div class="content-body" id="ngx-datatable-sf4c">
                <div class="d-flex justify-content-center" *ngIf="isLoading; else no_loading" id="loading"
                  style="margin: 50px auto">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <ng-template #no_loading>
                  <div class="row">
                    <div class="d-flex mb-1 col-md-3 col-12">
                      <div class="d-flex justify-content-between align-items-center">
                        <label class="d-flex align-items-center" style="flex-wrap: nowrap"><span
                            [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                          <select class="form-control mx-25 pr-1 show-doc" (change)="showPageSize($event.target.value)" [(ngModel)]="page.size">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                        </label>
                      </div>
                      <!-- <div class="d-flex justify-content-start align-items-center">
                        <span [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                        <div class="btn-group">
                          <div ngbDropdown>
                            <button ngbDropdownToggle class="btn btn-outline-secondary" style="margin: 4px;"
                              type="button" id="dropdownMenuButton" rippleEffect>
                              <span>{{page.size}}</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                              <a ngbDropdownItem (click)="showPageSize(5)">5</a>
                              <a ngbDropdownItem (click)="showPageSize(10)">10</a>
                              <a ngbDropdownItem (click)="showPageSize(15)">15</a>
                              <a ngbDropdownItem (click)="showPageSize(25)">25</a>
                              <a ngbDropdownItem (click)="showPageSize(50)">50</a>
                              <a ngbDropdownItem (click)="showPageSize(100)">100</a>

                            </div>
                          </div>
                        </div>
                        <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                      </div> -->
                      <div class="d-flex justify-content-between align-items-center col-5">
            
                        <label class="d-flex align-items-center" style="flex-wrap: nowrap">
                          <input type="number" class="form-control" id="job-search"
                          [placeholder]="'RESUME_PARSER.MANAGE_RESUME.PAGE'|translate" name="pageNumberIntegration"
                          [(ngModel)]="pageNumberIntegration" (ngModelChange)="changePage($event)"/>
                        <span class="mx-25" [translate]="'RESUME_PARSER.MANAGE_RESUME.PAGE'"></span>
                      </label>
                            
                          
                      </div>

                    </div>
                  </div>
                  <ngx-datatable id="table-user" #tableRowDetails class="bootstrap core-bootstrap"
                    [rows]="resume_parser" [externalPaging]="true" [count]="page.totalElements"
                    [offset]="page.pageNumber" [limit]="page.size" (page)="setPage($event)" [rowHeight]="50"
                    [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50" [scrollbarH]="true"
                    [selectionType]="SelectionType.checkbox" (activate)="onActivate($event)" (select)="onSelect($event)"
                    [selected]="selectedRows" [messages]="{
                      emptyMessage: 'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                      totalMessage:  ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT' | translate)
                    }">
                    <ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                      [resizeable]="false">
                      <ng-template ngx-datatable-header-template let-value="value" let-selectFn="selectFn"
                        let-allRowsSelected="allRowsSelected">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                            (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                          <label class="custom-control-label" for="headerChkbxRef"></label>
                        </div>
                      </ng-template>
                      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
                        let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" [checked]="setIsSelectedRows(row)"
                            (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                          <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                      [width]="widthColumn" prop="file_name">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <a routerLink="/integration/detail-resume/{{row.id}}"
                            class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip"
                            data-bs-placement="bottom" container="body" ngbTooltip="{{row.file_name}}" style="max-width: 200px;">{{
                            row.file_name }}</a>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'|translate" prop="sf4c_status"
                      [width]="widthColumn*2/3">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span class="badge bg-{{status_color[row.sf4c_status]}}" data-bs-toggle="tooltip"
                            data-bs-placement="bottom" title="{{row.sf4c_message}}">
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C wait to upload'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_WAIT'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C failed'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_FAILED'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C uploading'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADING'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C uploaded'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADED'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='Not upload'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_NOT_UPLOAD'"></span>

                          </span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <!-- <ngx-datatable-column
                  [name]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'|translate"
                  prop="sf4c_status"
                  [width]="widthColumn/2"
                >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100" >
                    <span class="badge bg-{{status_color[row.sf4c_status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.sf4c_message}}" >{{row.sf4c_status.replaceAll("_"," ")}}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column> -->
                    <!-- <ngx-datatable-column
                [name]="'RESUME_PARSER.PARSE_RESUME.STATUS'|translate"
                prop="status"
                [width]="widthColumn/2"
              >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="w-100" >
                  <span class="badge bg-{{status_color[row.status]}}" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.message}}" >{{row.status}}</span>
                </div>
              </ng-template>
              </ngx-datatable-column> -->

                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CONF_SCORE'|translate"
                      [width]="widthColumn*2/3" prop="conf_score">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span *ngIf="row.conf_score>range_conf.success"
                            class="badge bg-success">{{row.conf_score*100|number:'1.2-2'}}%</span>
                          <span *ngIf="row.conf_score>range_conf.warning && row.conf_score < range_conf.success"
                            class="badge bg-warning">{{row.conf_score*100|number:'1.2-2'}}%</span>
                          <span *ngIf="row.conf_score<range_conf.warning"
                            class="badge bg-danger">{{row.conf_score*100|number:'1.2-2'}}%</span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate"
                      [width]="widthColumn" prop=current_title>
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip"
                            data-bs-placement="bottom" container="body" ngbTooltip="{{row.current_title}}" style="max-width: 200px;">{{
                            row.current_title }}</span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.OWNER'|translate" [width]="widthColumn"
                      *ngIf="user.role=='Site Admin' || user.role=='Admin'||user.role=='Super admin'" prop="owner">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span>{{ row.owner }}</span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.MODIFIED_AT'|translate"
                      [width]="widthColumn*2/3" prop="modified_at">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          {{row.modified_at|date:'d/M/yyyy, h:mm a'}}
                        </div>
                      </ng-template>
                    </ngx-datatable-column>

                    <!-- <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_COMPANY'|translate" [width]="widthColumn">
                <ng-template ngx-datatable-cell-template let-row="row">
                  <div class="w-100">
                    <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{row.current_company}}">{{ row.current_company }}</span>
                  </div>
                </ng-template>
              </ngx-datatable-column> -->
                  </ngx-datatable>
                </ng-template>
              </div>
            </div>
            <!-- Information Tab ends -->
          </ng-template>
        </li>
        <li [ngbNavItem]="'ja'">
          <a (click)="updateJa()" ngbNavLink class="d-flex align-items-center"><i data-feather="refresh-cw"
              class="mr-sm-50"></i><span id="update-ja" class="d-none d-sm-block"
              [translate]="'RESUME_PARSER.INTEGRATION.UPDATE_JA_SUCCESSFACTORS'"></span></a>
          <ng-template ngbNavContent>
            <!-- Social Tab starts -->
            <div class="tab-pane" id="social" aria-labelledby="social-tab" role="tabpanel">
              <!-- users edit Info form start  -->
              <form (ngSubmit)="(SearchFormJA.form.valid)" #SearchFormJA="ngForm">

                <div class="row mb-2">

                  <div class="col-md-2">

                    <div class="input-group">

                      <input (keydown.enter)="onSubmitJA()" type="text" class="form-control" id="job-search"
                        [placeholder]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate" name="file_nameJA"
                        [(ngModel)]="file_nameJA" />
                    </div>

                  </div>
                  <div class="col-md-2 pl-0">
                    <ng-select (keydown.enter)="onSubmitJA()" bindLabel="name" bindValue="value"
                      [placeholder]="'RESUME_PARSER.INTEGRATION.STATUS'|translate" name="selectedSf4cStatus"
                      [(ngModel)]="selectedSf4cStatus" id="selectedSf4cStatus" [multiple]="true">
                      <ng-option *ngFor="let _sf4c_status of sf4c_status" [value]="_sf4c_status.value">
                        <span *ngIf="_sf4c_status.name=='SF wait to upload'"
                          [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_WAIT'"></span>
                        <span *ngIf="_sf4c_status.name=='SF failed'"
                          [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_FAILED'"></span>
                        <span *ngIf="_sf4c_status.name=='SF uploading'"
                          [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADING'"></span>
                        <span *ngIf="_sf4c_status.name=='SF uploaded'"
                          [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADED'"></span>
                        <span *ngIf="_sf4c_status.name=='SF not upload'"
                          [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_NOT_UPLOAD'"></span>


                      </ng-option>

                    </ng-select>

                  </div>
                  <div class="col-md-3 pl-0"
                    *ngIf="user.role=='Site Admin' || user.role=='Admin'||user.role=='Super admin'">

                    <ng-select (keydown.enter)="onSubmitJA()" [multiple]="true" [items]="owner__email_items" bindLabel="name" bindValue="value"
                      [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_OWNER'|translate" name="owner__emailJA"
                      [(ngModel)]="owner__emailJA" id="owner__emailJA">

                    </ng-select>

                  </div>
                  <div class="col-md-2 pl-0">

                    <div class="input-group">

                      <input (keydown.enter)="onSubmitJA()" type="text" class="form-control" id="job-search"
                        [placeholder]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate" name="current_titleJA"
                        [(ngModel)]="current_titleJA" />
                    </div>
                  </div>
                  <div class="col-md-2 pl-0">

                    <ng-select (keydown.enter)="onSubmitJA()" tabindex="4" [multiple]="true" [items]="job_boards" bindLabel="name" bindValue="value"
                      [placeholder]="'RESUME_PARSER.MANAGE_RESUME.CHOICE_SOURCE'|translate" name="source"
                      [(ngModel)]="source" id="source">
                    </ng-select>

                  </div>
                  <div class="col-md-1 pl-0">
                    <button style="height: 38px;padding:6px" type="button" (click)="onSubmitJA()"
                      class="btn fw-bold btn-primary" rippleEffect><span
                        [translate]="'RESUME_PARSER.MANAGE_RESUME.SEARCH'"></span> </button>

                  </div>
                </div>

              </form>
              <!-- users edit Info form ends -->
              <div class="content-body" id="ngx-datatable-ja">
                <div class="d-flex justify-content-center" *ngIf="isLoadingJA; else no_loadingJA" id="loadingJA"
                  style="margin: 50px auto">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <ng-template #no_loadingJA>
                  <div class="row">
                    <div class="d-flex mb-1 col-md-3 col-12">
                      <div class="d-flex justify-content-between align-items-center">
                        <label class="d-flex align-items-center" style="max-width: 100%; flex-wrap: nowrap"><span
                            [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                          <select class="form-control mx-25 pr-1 show-doc" (change)="showPageJaSize($event.target.value)" [(ngModel)]="pageJA.size">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                        </label>
                      </div>
                      <!-- <div class="d-flex justify-content-start align-items-center">
                        <span [translate]="'RESUME_PARSER.MANAGE_RESUME.SHOW'"></span>
                        <div class="btn-group">
                          <div ngbDropdown>
                            <button ngbDropdownToggle class="btn btn-outline-secondary" type="button"
                              id="dropdownMenuButton" rippleEffect style="margin: 4px;">
                              <span>{{pageJA.size}}</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                              <a ngbDropdownItem (click)="showPageJaSize(5)">5</a>
                              <a ngbDropdownItem (click)="showPageJaSize(10)">10</a>
                              <a ngbDropdownItem (click)="showPageJaSize(15)">15</a>
                              <a ngbDropdownItem (click)="showPageJaSize(25)">25</a>
                              <a ngbDropdownItem (click)="showPageJaSize(50)">50</a>
                              <a ngbDropdownItem (click)="showPageJaSize(100)">100</a>

                            </div>
                          </div>
                        </div>
                        <span [translate]="'RESUME_PARSER.PARSE_RESUME.RESUMES'"></span>
                      </div> -->
                      <div class="d-flex justify-content-between align-items-center col-5">
            
                        <label class="d-flex align-items-center" style="flex-wrap: nowrap">
                          <input type="number" class="form-control" id="job-search"
                          [placeholder]="'RESUME_PARSER.MANAGE_RESUME.PAGE'|translate" name="pageNumberJA"
                          [(ngModel)]="pageNumberJA" (ngModelChange)="changePageJA($event)"/>
                        <span class="mx-25" [translate]="'RESUME_PARSER.MANAGE_RESUME.PAGE'"></span>
                      </label>
                            
                          
                      </div>
                    </div>

                  </div>
                  <ngx-datatable id="table-user" #tableRowDetails class="bootstrap core-bootstrap"
                    [rows]="resume_parserJA" [externalPaging]="true" [count]="pageJA.totalElements"
                    [offset]="pageJA.pageNumber" [limit]="pageJA.size" (page)="setPageJA($event)" [rowHeight]="50"
                    class="bootstrap core-bootstrap" [columnMode]="ColumnMode.force" [headerHeight]="50"
                    [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
                    (activate)="onActivate($event)" (select)="onSelectJA($event)" [selected]="selectedRowsJA" [messages]="{
                      emptyMessage: 'RESUME_PARSER.MANAGE_RESUME.TABLE.NO_DATA' | translate,
                      totalMessage:  ('RESUME_PARSER.MANAGE_RESUME.TABLE.TOTAL_COUNT' | translate)
                    }">
                    <ngx-datatable-row-detail [rowHeight]="50*(lengthJaStatus+1)">
                      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                        <div class="ml-75 pl-5 pt-75" id="heightRow">
                          <div class="table-container">
                            <table class="table table-hover align-middle table-bordered">
                              <thead>
                                <tr>


                                  <th style="width: 10%;" [translate]="'RESUME_PARSER.INTEGRATION.JOB_APPLICATION_ID'">
                                  </th>
                                  <th [translate]="'RESUME_PARSER.INTEGRATION.JOB_REQ_ID'"></th>
                                  <th [translate]="'RESUME_PARSER.INTEGRATION.MATCHING_SCORE'"></th>
                                  <th [translate]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'"></th>
                                  <th [translate]="'RESUME_PARSER.INTEGRATION.MESSAGE'"></th>
                                  <th [translate]="'RESUME_PARSER.PARSE_RESUME.MODIFIED_AT'"></th>

                                </tr>
                              </thead>
                              <tbody *ngFor="let _jaStatus of jaStatus[row.id]">
                                <tr>

                                  <td>{{_jaStatus.job_application_id}}</td>
                                  <td>{{_jaStatus.job_req_id}}</td>
                                  <td>{{_jaStatus.matching_score*100|number:'1.2-2'}}%</td>
                                  <td>
                                    <span class="badge bg-{{job_status_color[_jaStatus.status]}}">
                                      <span *ngIf="_jaStatus.status=='Check'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.JA_STATUS.CHECK'"></span>
                                      <span *ngIf="_jaStatus.status=='Success'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.JA_STATUS.SUCCESS'"></span>
                                      <span *ngIf="_jaStatus.status=='Fail'"
                                        [translate]="'RESUME_PARSER.MANAGE_RESUME.JA_STATUS.FAILURE'"></span>


                                    </span>
                                  </td>
                                  <td>
                                    {{_jaStatus.message}}
                                  </td>
                                  <td>
                                    {{_jaStatus.modified_at|date:'dd/MM/yyyy hh:mm a'}}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </ng-template>
                    </ngx-datatable-row-detail>
                    <ngx-datatable-column [width]="40" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                      [resizeable]="false">
                      <ng-template ngx-datatable-header-template let-value="value" let-selectFn="selectFn"
                        let-allRowsSelected="allRowsSelected">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                            (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                          <label class="custom-control-label" for="headerChkbxRef"></label>
                        </div>
                      </ng-template>
                      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row"
                        let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input"
                            [checked]="isSelected && (row.sf4c_status=='SF4C_uploaded')"
                            (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}"
                            [disabled]="(row.sf4c_status!='SF4C_uploaded')" />
                          <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false"
                      [canAutoResize]="false">
                      <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded"
                        ngx-datatable-cell-template>
                        <a href="javascript:void(0)" class="text-body" [class.datatable-icon-right]="!expanded"
                          [class.datatable-icon-down]="expanded" title="Show job application"
                          (click)="rowDetailsToggleExpand(row, rowIndex)">
                        </a>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.FILE_NAME'|translate"
                      [width]="widthColumn" prop="file_name">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <a routerLink="/ja/detail-resume/{{row.id}}" container="body" ngbTooltip="{{row.file_name}}"
                            class="d-inline-block text-truncate max-width-custom w-100" style="max-width: 200px;">{{
                            row.file_name }}</a>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.SF4C_STATUS'|translate" prop="sf4c_status"
                      [width]="widthColumn*2/3">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span class="badge bg-{{status_color[row.sf4c_status]}}" data-bs-toggle="tooltip"
                            data-bs-placement="bottom" title="{{row.sf4c_message}}">
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C wait to upload'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_WAIT'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C failed'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_FAILED'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C uploading'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADING'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='SF4C uploaded'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_UPLOADED'"></span>
                            <span *ngIf="row.sf4c_status.replaceAll('_',' ')=='Not upload'"
                              [translate]="'RESUME_PARSER.MANAGE_RESUME.STATUS_SF4C.SF4C_NOT_UPLOAD'"></span>

                          </span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>

                    
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.CURRENT_TITLE'|translate"
                      [width]="widthColumn" prop=current_title>
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span class="d-inline-block text-truncate max-width-custom w-100" data-bs-toggle="tooltip"
                            data-bs-placement="bottom" container="body" ngbTooltip="{{row.current_title}}" style="max-width: 200px;">{{
                            row.current_title }}</span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.OWNER'|translate" [width]="widthColumn"
                      *ngIf="user.role=='Site Admin' || user.role=='Admin' || user.role=='Super admin'" prop="owner">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span>{{ row.owner }}</span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.MANAGE_RESUME.SOURCE'|translate" [width]="widthColumn"
                      prop="source">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          <span>{{row.source}}</span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [name]="'RESUME_PARSER.PARSE_RESUME.MODIFIED_AT'|translate"
                      [width]="widthColumn*2/3" prop="modified_at">
                      <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="w-100">
                          {{row.modified_at|date:'d/M/yyyy, h:mm a'}}
                        </div>
                      </ng-template>
                    </ngx-datatable-column>

                  </ngx-datatable>
                </ng-template>
              </div>
            </div>
            <!-- Social Tab ends -->
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<!-- / Modal Edit End-->
<div class="popup" [@slideFade]="showActionsPopup ? 'visible' : 'hidden'">
  <div class="button-container" *ngIf="showActionsPopup">
    <button type="button" class="btn btn-link action-btn rounded-left" style="margin: 0" (click)="unselectAllRows()">
      <span data-feather="x"></span>
    </button>
    <div>
      <div>
        <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
          id="dropdownMenuButton" rippleEffect (click)="modalOpenUploadSf4c(modalBasic)">
          <div style="margin: 0 auto 5px auto">
            <span data-feather="upload"></span>
          </div>
          <span [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD'"></span>
        </button>
        <ng-template #noConnectSf4c>
          <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
            id="dropdownMenuButton" rippleEffect (click)="modalOpenWarning(modalBasicWarning)">
            <div style="margin: 0 auto 5px auto">
              <span data-feather="upload"></span>
            </div>
            <span [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD'"></span>
          </button>
        </ng-template>
        <ng-template #modalBasicWarning let-modal>
          <div class="modal-header">
            <h4 class="modal-title" [translate]="'RESUME_PARSER.INTEGRATION.NOTI'"></h4>

          </div>
          <div class="modal-body" ngbAutofocus>
            <span [translate]="'RESUME_PARSER.INTEGRATION.NOTI_TITLE'"></span><a routerLink="/account-settings"
              (click)="modal.dismiss('Cross click')"><span [translate]="'RESUME_PARSER.INTEGRATION.SET_UP'"></span></a>
          </div>

        </ng-template>
        <ng-template #modalBasic let-modal>

          <div class="modal-header">
            <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>

          </div>
          <div class="form-group" style="margin:1.3rem">
            <label class="form-label" for="basic-icon-default-fullname"><span
                [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></span></label>
          </div>
          <div class="me-4">
            <div class="d-flex justify-content-end">

              <div class="input-group col-md-6">

                <input type="text" class="form-control" id="job-search"
                  [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SEARCH' | translate" aria-label="Search..."
                  aria-describedby="job-search" [(ngModel)]="searchText" (input)="Search()" />
              </div>

            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div *ngIf="isLoadingJr" id="loading" style="margin: 50px auto">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

          </div>

          <form [formGroup]="formCheckBoxValid">
            <div class="modal-body" ngbAutofocus style="overflow-y: visible;">


              <table class="table align-middle table-bordered">
                <thead>
                  <tr>
                    <th style="width: 1%;"></th>
                    <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                    <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>



                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let resultJr_ of resultJr
                                            | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                    <td *ngIf="arr_checkboxJr.includes(resultJr_.hcm_system_jd);else noChecked">
                      <input [value]="resultJr_.hcm_system_jd" (change)="onCheckboxChangeJr($event)" type="checkbox"
                        class="form-check" checked>

                    </td>
                    <ng-template #noChecked>
                      <td>
                        <input [value]="resultJr_.hcm_system_jd" (change)="onCheckboxChangeJr($event)" type="checkbox"
                          class="form-check">

                      </td>
                    </ng-template>

                    <td>
                      <div class="">{{resultJr_.hcm_system_jd}}</div>
                    </td>
                    <td>
                      <div class="">{{resultJr_.job_title}}</div>
                    </td>


                  </tr>

                </tbody>
              </table>
              <div class="row mt-1 mt-lg-1 justify-content-end align-items-center" *ngIf="resultJr.length>0">
                <div class="col-auto">

                  <nav class="mt-1 mt-sm-0" aria-label="Jobs list pagination">

                    <ngb-pagination [collectionSize]="resultJr.length" [(page)]="pageJr"
                      [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                    </ngb-pagination>
                  </nav>
                </div>

              </div>
              <div class="form-group"
                *ngIf="sf4cPicklist?.importedby?.length>0 && (user.role=='Super admin' || user.role=='Admin')">
                <label class="form-label" for="basic-icon-default-fullname"><span
                    [translate]="'RESUME_PARSER.INTEGRATION.IMPORTEDBY'">Imported by</span></label>
                <ng-select style="height: 2.714rem;" type="text" id="importedby"
                  [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SELECTED_IMPORTEDBY'|translate"
                  formControlName="importedby">
                  <ng-option *ngFor="let importedby of sf4cPicklist.importedby"
                    [value]="importedby">{{importedby.value}}
                  </ng-option>
                </ng-select>
              </div>
              <div *ngIf="formCheckBoxValid.value.importedby">
                <label [translate]="'RESUME_PARSER.INTEGRATION.SOURCE'"></label><span>:
                </span><span>{{formCheckBoxValid.value?.importedby?.source_value}}</span>
              </div>
            </div>
            <div class="modal-footer">

              <button *ngIf="loading_;else noLoadingUpload" (click)="FormCheckValidUpload(modalCheckValid)"
                class="btn btn-primary ms-3 mr-1" type="button" disabled>
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
              </button>
              <ng-template #noLoadingUpload>
                <button *ngIf="resultCheckValidUpload?.invalid_resume_id?.length>0;else noArr"
                  class="btn btn-primary ms-3 mr-1" rippleEffect type="button"
                  (click)="FormCheckValidUpload(modalCheckValid)">
                  <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                </button>
                <ng-template #noArr>
                  <button class="btn btn-primary ms-3 mr-1" rippleEffect
                    (click)="FormCheckValidUpload(modalCheckValid)">
                    <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                  </button>
                </ng-template>
              </ng-template>
              <button type="reset" (click)="modal.dismiss('Cross click')" (click)="cancelFormCheckboxValid()"
                rippleEffect class="btn btn-secondary"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
            </div>
          </form>
        </ng-template>
        <ng-template #modalCheckValid let-modal>

          <div class="modal-header">
            <h4 class="modal-title" [translate]="'RESUME_PARSER.INTEGRATION.TITLE_PROCESS'"></h4>

          </div>



          <form [formGroup]="formCheckBoxJr">
            <div class="modal-body" ngbAutofocus>


              <table class="table align-middle table-bordered">
                <thead>
                  <tr>
                    <th [translate]="'RESUME_PARSER.INTEGRATION.OVERWRITE'"></th>
                    <th [translate]="'RESUME_PARSER.INTEGRATION.RESUME_ID'"></th>
                    <th [translate]="'RESUME_PARSER.INTEGRATION.JOB_REQ_ID'"></th>
                    <th [translate]="'RESUME_PARSER.INTEGRATION.ERROR'"></th>



                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let _resultCheckValidUpload of resultCheckValidUpload?.invalid_resume_id
                                            | slice: (pageCheckValid - 1) * basicSelectedOption:(pageCheckValid - 1) * basicSelectedOption + basicSelectedOption">
                    <td *ngIf="!_resultCheckValidUpload.overwrite;else Overwrite">
                      <input [value]="_resultCheckValidUpload.id" (change)="onCheckboxChangeValid($event)"
                        type="checkbox" class="form-check" disabled>
                    </td>
                    <ng-template #Overwrite>
                      <td *ngIf="arr_checkboxValid.includes(_resultCheckValidUpload.id);else noChecked">
                        <input [value]="_resultCheckValidUpload.id" (change)="onCheckboxChangeValid($event)"
                          type="checkbox" class="form-check" checked>

                      </td>
                      <ng-template #noChecked>
                        <td>
                          <input [value]="_resultCheckValidUpload.id" (change)="onCheckboxChangeValid($event)"
                            type="checkbox" class="form-check">

                        </td>
                      </ng-template>
                    </ng-template>

                    <td>
                      <div class="">{{_resultCheckValidUpload.id}}</div>
                    </td>
                    <td>
                      <div class="">{{_resultCheckValidUpload.job_id}}</div>
                    </td>
                    <td>
                      <div *ngIf="language=='en';else VN" class="">{{_resultCheckValidUpload.error.en}}</div>
                      <ng-template #VN>
                        <div class="">{{_resultCheckValidUpload.error.vn}}</div>

                      </ng-template>
                    </td>


                  </tr>

                </tbody>
              </table>
              <div class="row mt-1 mt-lg-1 justify-content-between align-items-center"
                *ngIf="resultCheckValidUpload?.invalid_resume_id.length>0">
                <div class="col-auto">

                  <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">

                    <ngb-pagination [collectionSize]="resultCheckValidUpload?.invalid_resume_id.length"
                      [(page)]="pageCheckValid" [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true"
                      [boundaryLinks]="true">


                    </ngb-pagination>
                  </nav>
                </div>

              </div>
            </div>
            <div class="modal-footer">

              <button *ngIf="loading_;else noLoadingUploadJA" (click)="FormCheckBoxJr()"
                class="btn btn-primary ms-3 mr-1" type="button" disabled>
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
              </button>
              <ng-template #noLoadingUploadJA>
                <button [disabled]="arr_checkboxValid.length==0 && resultCheckValidUpload.resume_id.length==0"
                  class="btn btn-primary ms-3 mr-1" rippleEffect type="button" (click)="FormCheckBoxJr()">
                  <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                </button>
              </ng-template>
              <button (click)="modal.dismiss('Cross click')" (click)="cancelFormCheckBoxJr()" type="reset" rippleEffect
                class="btn btn-secondary" [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
            </div>
          </form>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- Modal Edit Stảrt-->
<!-- / Modal Edit End-->
<div class="popup" [@slideFade]="showActionsPopupJA ? 'visible' : 'hidden'">
  <div class="button-container" *ngIf="showActionsPopupJA">
    <button type="button" class="btn btn-link action-btn rounded-left" style="margin: 0" (click)="unselectAllRows()">
      <span data-feather="x"></span>
    </button>
    <div>
      <div>
        <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
          id="dropdownMenuButton" rippleEffect (click)="modalOpenUploadSf4c(modalBasic, true)">
          <div style="margin: 0 auto 5px auto">
            <span data-feather="refresh-cw"></span>
          </div>
          <span [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD_JA'"></span>
        </button>
        <ng-template #noConnectSf4c>
          <button class="btn btn-primary d-flex flex-column" style="margin: 0; border-radius: 0" type="button"
            id="dropdownMenuButton" rippleEffect (click)="modalOpenWarning(modalBasicWarning)">
            <div style="margin: 0 auto 5px auto">
              <span data-feather="refresh-cw"></span>
            </div>
            <span [translate]="'RESUME_PARSER.INTEGRATION.UPLOAD_JA'"></span>
          </button>

        </ng-template>
        <ng-template #modalBasicWarning let-modal>
          <div class="modal-header">
            <h4 class="modal-title" [translate]="'RESUME_PARSER.INTEGRATION.NOTI'"></h4>
            <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> -->
          </div>
          <div class="modal-body" ngbAutofocus>
            <span [translate]="'RESUME_PARSER.INTEGRATION.NOTI_TITLE'"></span><a (click)="modal.dismiss('Cross click')"
              routerLink="/account-settings"><span [translate]="'RESUME_PARSER.INTEGRATION.SET_UP'"></span></a>
          </div>
          <!-- <div class="modal-footer">
                  <button (click)="modal.dismiss('Cross click')" class="btn btn-primary" rippleEffect [translate]="'RESUME_PARSER.PARSE_RESUME.SUBMIT'">
              
                  </button>
                </div> -->
        </ng-template>
        <ng-template #modalBasic let-modal>

          <div class="modal-header">
            <h4 class="modal-title" [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></h4>
            <!-- <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button> -->
          </div>
          <div class="form-group" style="margin:1.3rem">
            <label class="form-label" for="basic-icon-default-fullname"><span
                [translate]="'RESUME_PARSER.PARSE_RESUME.SELECT_JOB'"></span></label>
          </div>
          <div class="me-4">
            <div class="d-flex justify-content-end">

              <div class="input-group col-md-6">
                <!-- <span class="input-group-text"><span class="fa fa-search"></span></span> -->
                <input type="text" class="form-control" id="job-search"
                  [placeholder]="'RESUME_PARSER.INTEGRATION.PLACEHOLDER.SEARCH' | translate" aria-label="Search..."
                  aria-describedby="job-search" [(ngModel)]="searchText" (input)="Search()" />
              </div>

            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div *ngIf="isLoadingJr" id="loading" style="margin: 50px auto">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <form [formGroup]="formCheckBoxValid">
            <div class="modal-body" ngbAutofocus>


              <table class="table align-middle table-bordered">
                <thead>
                  <tr>
                    <th style="width: 1%;"></th>
                    <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_REQ_ID'"></th>
                    <th [translate]="'RESUME_PARSER.PARSE_RESUME.JOB_TITLE'"></th>



                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let resultJr_ of resultJr
                                            | slice: (pageJr - 1) * basicSelectedOption:(pageJr - 1) * basicSelectedOption + basicSelectedOption">
                    <td *ngIf="arr_checkboxJr.includes(resultJr_.hcm_system_jd);else noChecked">
                      <input [value]="resultJr_.hcm_system_jd" (change)="onCheckboxChangeJr($event)" type="checkbox"
                        class="form-check" checked>

                    </td>
                    <ng-template #noChecked>
                      <td>
                        <input [value]="resultJr_.hcm_system_jd" (change)="onCheckboxChangeJr($event)" type="checkbox"
                          class="form-check">

                      </td>
                    </ng-template>

                    <td>
                      <div class="">{{resultJr_.hcm_system_jd}}</div>
                    </td>
                    <td>
                      <div class="">{{resultJr_.job_title}}</div>
                    </td>


                  </tr>

                </tbody>
              </table>
              <div class="row mt-1 mt-lg-1 justify-content-end align-items-center" *ngIf="resultJr.length>0">
                <div class="col-auto">

                  <nav class="mt-1 mt-sm-0" aria-label="Jobs list pagination">

                    <ngb-pagination [collectionSize]="resultJr.length" [(page)]="pageJr"
                      [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true" [boundaryLinks]="true">


                    </ngb-pagination>
                  </nav>
                </div>

              </div>
            </div>
            <div class="modal-footer">

              <button *ngIf="loading_;else noLoadingUpload" (click)="FormCheckValidUpload(modalCheckValid, true)"
                class="btn btn-primary ms-3 mr-1" type="button" disabled>
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
              </button>
              <ng-template #noLoadingUpload>
                <button [disabled]="arr_checkboxJr?.length==0"
                  *ngIf="resultCheckValidUpload?.invalid_job_app_id?.length>0;else noArr"
                  class="btn btn-primary ms-3 mr-1" rippleEffect (click)="FormCheckValidUpload(modalCheckValid, true)"
                  (click)="modal.dismiss('Cross click')">
                  <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                </button>
                <ng-template #noArr>
                  <button [disabled]="arr_checkboxJr?.length==0" class="btn btn-primary ms-3 mr-1" rippleEffect
                    (click)="FormCheckValidUpload(modalCheckValid, true)">
                    <span translate="RESUME_PARSER.INTEGRATION.NEXT"></span>
                  </button>
                </ng-template>
              </ng-template>
              <button (click)="modal.dismiss('Cross click')" (click)="cancelFormCheckboxValid()" type="reset"
                rippleEffect class="btn btn-secondary"
                [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
            </div>
          </form>
        </ng-template>
        <ng-template #modalCheckValid let-modal>
          <div class="modal-header">
            <h4 class="modal-title" [translate]="'RESUME_PARSER.INTEGRATION.TITLE_PROCESS'"></h4>
          </div>
          <form [formGroup]="formCheckBoxJr">
            <div class="modal-body" ngbAutofocus>
              <table class="table align-middle table-bordered" style="height: 300px;">
                <thead>
                  <tr>
                    <th [translate]="'RESUME_PARSER.INTEGRATION.RESUME_ID'"></th>
                    <th [translate]="'RESUME_PARSER.INTEGRATION.JOB_REQ_ID'"></th>
                    <th [translate]="'RESUME_PARSER.INTEGRATION.ERROR'"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let _resultCheckValidUpload of resultCheckValidUpload?.invalid_job_app_id
                                            | slice: (pageCheckValid - 1) * basicSelectedOption:(pageCheckValid - 1) * basicSelectedOption + basicSelectedOption">
                    <td>
                      <div class="">{{_resultCheckValidUpload.id}}</div>
                    </td>
                    <td>
                      <div class="">{{_resultCheckValidUpload.job_id}}</div>
                    </td>
                    <td>
                      <div *ngIf="language=='en';else VN" class="">{{_resultCheckValidUpload.error.en}}</div>
                      <ng-template #VN>
                        <div class="">{{_resultCheckValidUpload.error.vn}}</div>

                      </ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row mt-1 mt-lg-1 justify-content-between align-items-center"
                *ngIf="resultCheckValidUpload?.invalid_job_app_id.length>0">
                <div class="col-auto">
                  <nav class="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                    <ngb-pagination [collectionSize]="resultCheckValidUpload?.invalid_job_app_id.length"
                      [(page)]="pageCheckValid" [pageSize]="basicSelectedOption" [maxSize]="10" [rotate]="true"
                      [boundaryLinks]="true">
                    </ngb-pagination>
                  </nav>
                </div>
              </div>
            </div>
            <div class="modal-footer">

              <button *ngIf="loadingFormJA;else noLoadingUploadJA" (click)="FormCheckBoxJA()"
                class="btn btn-primary ms-3 mr-1" type="button" disabled>
                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
              </button>
              <ng-template #noLoadingUploadJA>
                <button class="btn btn-primary ms-3 mr-1" rippleEffect (click)="FormCheckBoxJA()">
                  <span translate="RESUME_PARSER.PARSE_RESUME.SUBMIT"></span>
                </button>
              </ng-template>
              <button (click)="modal.dismiss('Cross click')" (click)="cancelFormCheckBoxJr()" type="reset" rippleEffect
                class="btn btn-secondary" [translate]="'RESUME_PARSER.MANAGE_RESUME.MODAL.CANCEL'"></button>
            </div>
          </form>
        </ng-template>
        <ng-container *ngIf="errorMessagesSf4cUpload">
          <div class="alert alert-danger mt-2" role="alert">
            {{errorMessagesSf4cUpload}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Modal Edit Stảrt-->