import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as localeEn } from "./../i18n/en";
import { locale as localeVn } from "./../i18n/vn";
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentInfo } from '../models/payment.model';
import { PaymentService } from '../services/payment.service';
import { User } from 'app/auth/models/user';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { PackageService } from 'app/main/package/services/package.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
enum CheckBoxType { VNPAY, MOMO, PAYPAL };

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class PaymentDetailComponent implements OnInit {
  public paymentForm: FormGroup;
  public paymentInfo: PaymentInfo;
  public userInfo: any = new Object();
  public displayAmount: any = '';
  public paymentQuota: any;
  public quotaParsing:any
  public quotaMatching:any
  public quotaParseThread:any
  public quotaMatchingThread:any
  public quotaMaxUser:any


  public paymentMethod: string='vnpay';
  public isLoading = false
  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType=CheckBoxType.VNPAY;
  public payFormValid = false
  public currentUser: User
  detailPackage: any
  public company_name: string;
  /**
   *  Constructor
   *
   * @param {EcommerceService} _ecommerceService
   */
  constructor(
    private _coreTranslateService: CoreTranslationService,
    private _paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
    private packageService: PackageService,
    private modalService: NgbModal,
    private _toastrService: ToastrService,


  ) {
    this._coreTranslateService.translate(localeEn);
    this._coreTranslateService.translate(localeVn);
    this.paymentForm = this.formBuilder.group({
      optionVnpay: [null],
      optionMomo: [null],  
      optionPaypal: [null],  

    })
    this.currentlyChecked = CheckBoxType.VNPAY
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  selectCheckBox(targetType: CheckBoxType) {
    //If the checkbox was already checked, clear the currentlyChecked variable
    this.currentlyChecked = targetType;
    if(targetType===CheckBoxType.VNPAY){
      this.paymentForm.get("optionVnpay").setValue(true)
      this.paymentForm.get("optionMomo").setValue(false)
      this.paymentForm.get("optionPaypal").setValue(false)
      this.payFormValid=true

    }else if(targetType===CheckBoxType.MOMO){
      
      this.paymentForm.get("optionVnpay").setValue(false)
      this.paymentForm.get("optionMomo").setValue(true)
      this.paymentForm.get("optionPaypal").setValue(false)
      this.payFormValid=true

    }else if(targetType===CheckBoxType.PAYPAL){
      
      this.paymentForm.get("optionVnpay").setValue(false)
      this.paymentForm.get("optionMomo").setValue(false)
      this.paymentForm.get("optionPaypal").setValue(true)
      this.payFormValid=true

    }
  }
  validateForm(){
    if(this.paymentForm.get("optionVnpay").value==true||this.paymentForm.get("optionMomo").value==true||this.paymentForm.get("optionPaypal").value==true){
      this.payFormValid=true
    }else{
      this.payFormValid=false
    }
  }
  //company name
  modalOpenCompanyName(companyName:any){
    
    this.modalService.open(companyName, {
      windowClass: 'modal',
      size: 'lg',
      scrollable: true
    });
  }
  detailSubscriptionPlan(id:any){
    this.packageService.detailSubscriptionPlan(id).subscribe(
      (res)=>{
        this.detailPackage = res
        this.paymentQuota = this.detailPackage?.default_cost?.cost;
        this.quotaParsing = this.detailPackage?.package?.parse_limited
        this.quotaMatching = this.detailPackage?.package?.matching_limited
        this.quotaParseThread = this.detailPackage?.package?.parse_thread_limit
        this.quotaMatchingThread = this.detailPackage?.package?.matching_thread_limit
        this.quotaMaxUser = this.detailPackage?.package?.max_user

        this.displayAmount = new Intl.NumberFormat(
          'vi-IN', 
          { style: "currency", currency: "VND" }
      ).format(this.paymentQuota);
      },
      (err)=>{
        console.log("err",err)
      }
    )
  }
  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue;
    // if(this.currentUser.role=='Free trial user'){
    //   this.paymentForm = this.formBuilder.group({
    //     optionVnpay: [null],
    //     optionMomo: [null],  
    //     optionPaypal: [null],  
    //     company_name: ['']
    //   })
    // }
    this.validateForm()
    this.paymentInfo = this._paymentService.paymentInfoValue;
    this.detailSubscriptionPlan(this.paymentInfo.id)
  }

  submitPaymentForm(){
    if(this.paymentForm.get("optionVnpay").value){
      this.paymentMethod = 'vnpay'
    }else if(this.paymentForm.get("optionMomo").value){
      this.paymentMethod = 'momo'
    }else if(this.paymentForm.get("optionPaypal").value){
      this.paymentMethod = 'paypal'
    }
    // let company_name: string = ''
    // if(this.paymentForm.get("company_name").value){
    //   company_name = this.paymentForm.get("company_name").value
    // }
    this.isLoading = true
    if(this.currentUser.role == 'Free trial user'){
      this._paymentService
      .buyPackageFreeTrial(this.paymentInfo.costId, this.company_name, this.paymentMethod)
      .subscribe((res) => {
        if(res.payment_url||res.paypal_payment_url){
          this.isLoading = false
          this.paymentForm.reset()
          window.location.href = res.payment_url||res.paypal_payment_url;
          this.paymentForm.reset()
          window.location.href = res.payment_url||res.paypal_payment_url;
        }
      },
      (err)=>{
        this._toastrService.warning(err.error.message)
        this.isLoading = false

      });
    }else{
      this._paymentService
      .buyPackageCompany(this.paymentInfo.costId, this.paymentMethod)
      .subscribe((res) => {
        if(res.payment_url||res.paypal_payment_url){
          this.isLoading = false
          this.paymentForm.reset()
          window.location.href = res.payment_url||res.paypal_payment_url;
          this.paymentForm.reset()
          window.location.href = res.payment_url||res.paypal_payment_url;
        }
      },
      (err)=>{
        this._toastrService.warning(err.error.message)
        this.isLoading = false

      });
    }    
  }
}
