import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ResumeParserService } from 'app/main/resume-parser/services/resume-parser.service';

@Component({
  selector: 'app-shared-user',
  templateUrl: './shared-user.component.html',
  styleUrls: ['./shared-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedUserComponent implements OnInit {
  @Input() listShared; 
  @Output() detailListShared = new EventEmitter<any>();
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private resumeService: ResumeParserService,

  ) { }
  removeSharedUser(sharedUser: any){
    this.resumeService.removeSharedUser(sharedUser.resume, sharedUser.id).subscribe((
      res
    )=>{
      let currentShared = this.listShared.filter((e)=>e.id!=sharedUser.id)
      this.listShared = currentShared
      this.detailListShared.emit(sharedUser)
    })
  }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  ngOnInit(): void {
  }

}
