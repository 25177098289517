import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PaymentService } from '../../services/payment.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { locale as billingEn } from "../../i18n/en";
import { locale as billingVn } from "../../i18n/vn";
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-detail-transaction',
  templateUrl: './detail-transaction.component.html',
  styleUrls: ['./detail-transaction.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailTransactionComponent implements OnInit {
  public transaction: any
  constructor(
    private _paymentService: PaymentService,
    private _coreTranslateService: CoreTranslationService,
    private route: ActivatedRoute,

  ) {
    this._coreTranslateService.translate(billingEn);
    this._coreTranslateService.translate(billingVn);
   }
  getTransaction(){
    let id= this.route.snapshot.params["id"] 
    this._paymentService.getTransaction(id).subscribe(
      (res)=>{
        this.transaction = res
      },
      (err)=>{
        console.error("Error: "+err)
      }
    )
  }
  ngOnInit(): void {
    this.getTransaction()
  }

}
