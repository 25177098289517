export const locale = {
    lang: 'vn',
    data: {
      RESUME_PARSER: {
        PARSE_RESUME: {
            
            UPLOAD_UP_TO: "Tải lên tối đa 50 tệp CV (PDF, DOCX, EXCEL, JPEG)",
            RESUMES: "hồ sơ",
            UPLOAD: "Tải lên",
            FILE_NAME: "Tên tệp",
            STATUS: "Trạng thái",
            SF4C_STATUS: "Trạng thái SAP SF",
            CREATE_AT: "Thời gian tạo",
            MODIFIED_AT: "Thời gian sửa",
            FIRST_NAME: "Tên",
            LAST_NAME: "Họ",
            ADDRESS: "Địa chỉ",
            EMAIL: "E-mail",
            PHONE_NUMBER: "Số điện thoại",
            GENDER: "Giới tính",
            DATE_OF_BIRTH: "Ngày sinh",
            CURRENT_TITLE: "Công việc gần nhất",
            CURRENT_COMPANY: "Công ty hiện tại",
            CONF_SCORE: "Điểm tin cậy",
            APPROVE_RESUMES: "Phê duyệt hồ sơ",
            SELECT_JOB: "Chọn tiêu đề yêu cầu công việc",
            UPLOAD_SF4C: "Tải lên SAP SF",
            JOB_REQ_ID: "ID công việc",
            JOB_TITLE: "Tiêu đề công việc",
            SUBMIT: "Gửi",
            LABEL_UPLOAD: 'Kéo thả tài liệu vào đây hoặc tải lên tệp CV (PDF, DOC, DOCX, JPEG)',
            FROM_DEVICE: 'Từ thiết bị',
            SYNC_EMAIL: "Trích xuất hồ sơ từ e-mail",
            REUPLOAD: "Tải lại lên SAP SF",
        },
        MANAGE_RESUME: {
            SEARCH: "Tìm kiếm",
            SHOW: "Hiển thị",
            ADD_RESUME: "Thêm hồ sơ",
            MODIFIED_AT: "Thời gian sửa",
            YEAR_EXPERIENCE: "Năm kinh nghiệm",
            JOB_APPLICATION_STATUS: "Trạng thái công việc ứng tuyển",
            DELETE_RESUME: "Xóa hồ sơ!",
            DOWNLOAD_TYPE: "Báo cáo",
            DOWNLOAD_STAT: "Tải báo cáo thống kê",
            DOWNLOADING: "Đang tải",
            CANCEL: "Hủy",
            ACCEPT: "Chấp nhận",
            REQUIRED: "Trường này bắt buộc",
            INVALID_DATE_RANGE: "Phạm vi ngày không hợp lệ",
            OWNER: "Chủ sở hữu",
            OPTION: "Cấu hình tệp báo cáo",
            CHOICE_STATUS: "Trạng thái",
            CHOICE_SF4C_STATUS: "Trạng thái SAP SF",
            TYPE: "Định dạng",
            CHOICE_OWNER: "Chủ sở hữu",
            CHOICE_SOURCE: "Nguồn",
            MIN_CONF: "Điểm tin cậy nhỏ nhất (0,1)",
            MAX_CONF: "Điểm tin cậy lớn nhất (0,1)",
            ACTION: "Hành động",
            SOURCE: "Nguồn",
            ID: "Mã hồ sơ",
            ADD_RESUME_TO_TALENTPOOL: "Thêm ứng viên vào kho",
            ADD_RESUMES: "Thêm",
            SYNC_EMAIL: "Hồ sơ mới từ email đang được trích xuất",
            MODAL: {
              ACTION: "Hành động",
              DELETE_R: "Xóa hồ sơ",
              DELETE_RESUMES: "Xóa các hồ sơ",
              CANT_UNDO: "Bạn không thể hoàn tác",
              CANCEL: "Hủy",
              CONFIRM: "Xác nhận",
              IMPORT: "Tải lên",
              TITLE: "Tải file lên để trích xuất",
              DELETE_T: "Xóa thư mục"

            },
            TOAST: {
              EDIT_R_SUC: "Sửa hồ sơ",
              CREATE_R_SUC: "Tạo hồ sơ",
              DELETE_R_SUC: "Xóa hồ sơ",
              ERROR: "Lỗi khi thực hiện",
              FAILED: "Thất bại",
              SUCCESS: "Thành công",
              APPROVE_MUL_RESUME: "Phê duyệt các hồ sơ"
            },
            STATUS: {
              APPROVED: "Đã phê duyệt",
              DONE: "Đã tải lên",
              FAILURE: "Thất bại",
              PROCESSING: "Đang xử lý",
              WAIT_TO_PROCESS: "Chờ xử lý",
              SUSPENDED: "Hoãn lại",
              MODIFIED: "Đã sửa",
              DUPLICATED: "Trùng lặp"
            },
            STATUS_SF4C:{
              SF4C_BACKGROUND_FAILED: "SF background failed",
              SF4C_WAIT: "Chờ xử lý",
              SF4C_FAILED: "Tải lên SF thất bại",
              SF4C_UPLOADING: "Đang xử lý",
              SF4C_UPLOADED: "Đã tải lên SF",
              SF4C_NOT_UPLOAD: "Chưa tải lên SF"
            },
            JA_STATUS: {
              CHECK: "Kiểm tra",
              SUCCESS: "Tải lên thành công",
              FAILURE: "Tải lên thất bại"
            }

        },
        INTEGRATION: {
            INTEGRATION: "Tải hồ sơ lên SuccessFactors",
            NO_SUITABLE_JR: "Không có vị trí công việc phù hợp",
            UPLOAD_FAILED: "Tải lên thất bại!",
            UPDATE_JA_SUCCESSFACTORS: "Trạng thái hồ sơ trên SuccessFactors",
            UPLOAD_JA: " Tạo job application",
            UPLOAD: "Tải lên SucceessFactors",
            NO_RESUME_APPROVE:"Không có hồ sơ nào ở trạng thái được chấp nhận.",
            NO_RESUME_SF4C_UPLOAD:"Không có hồ sơ nào ở trạng thái đã tải lên SF.",
            NEXT: "Tiếp tục",
            TITLE_PROCESS: "Lựa chọn tải lên các hồ sơ không hợp lệ",
            RESUME_ID: "Id hồ sơ không hợp lệ",
            ERROR: "Lỗi",
            OVERWRITE: "Ghi đè",
            NOTI: "Thông báo",
            NOTI_TITLE: "Bạn chưa thiết lập tài khoản kết nối SuccessFactors, hãy nhấn vào đường dẫn: ",
            CONFIRM: "Xác nhận",
            SET_UP: "Cấu hình",
            IMPORTEDBY: "Người tải lên",
            SOURCE: "Nguồn",
            PLACEHOLDER: {
              SEARCH: "Tìm kiếm tiêu đề công việc",
              SELECTED_IMPORTEDBY: "Lựa chọn người tải lên",
              SELECTED_SOURCE: "Lựa chọn nguồn"
            },
            JOB_APPLICATION_ID: "Id ứng tuyển",
            JOB_REQ_ID: "Id công việc",
            MATCHING_SCORE: "Điểm phù hợp",
            STATUS: "Trạng thái SAP SF",
            MESSAGE: "Nội dung",
            MODIFIED_AT: "Thời gian cập nhật",
            IMPORTED_BY: "Tải lên bởi",
            ASKED: "Vui lòng ấn nút Hủy để chọn công việc khác"
        },
        DETAIL_RESUME:{
            LAST_MODIFIED_BY: "Cập nhật lần cuối bởi",

            TAB_CHR: "Trích xuất hồ sơ trên C-HR",
            TAB_SF4C: "Trích xuất hồ sơ trên SuccessFactors",
            CANDIDATE_INFORMATION: "Thông tin ứng viên",
            SF4C_RESUME_ID: "Mã ứng viên SAP SF:",
            CANDIDATE: "Mã ứng viên CHR:",
            RESUME_ID: "Mã hồ sơ:",
            META_DATA: "Thông tin chung",
            DOB: "Ngày sinh",
            ESTIMATE_DOB: "Ước lượng ngày sinh",
            CATEGORY: "Ngành nghề",

            SKILLS: "Kỹ năng",
            CONF_SCORE: "Điểm tin cậy",
            WORK_EXPERIENCE: "Kinh nghiệm làm việc",
            POSITION: "Vị trí",
            COMPANY: "Công ty",
            INDUSTRY: "Lĩnh vực",
            EDUCATION: "Học vấn và đào tạo",
            EDUCATION_: "Học vấn",
            MAJOR: "Chuyên ngành",
            SCHOOL: "Trường",
            DIPLOMA: "Bằng cấp",
            GPA: "Điểm tích lũy",
            JOB_REQ_MATCHING: "Chấm điểm ứng viên",
            SELECT_JR: "Lựa chọn công việc:",
            ANALYSIS: "Phân tích",
            NO_MATCHING: "Không tìm thấy kết quả phù hợp",
            SEARCHING: "Kết quả chấm điểm",
            MATCHING_POINT: "Điểm phù hợp",
            EXPERIENCE: "Kinh nghiệm",
            FOREIGN_LANGUAGE: "Ngoại ngữ",
            LANGUAGE: "Ngôn ngữ",
            DETAIL_SCORE: "Chi tiết chấm điểm",
            CANDIDATE_SKILL: "Các kỹ năng của ứng viên phù hợp với công việc:",
            APPROVE: "Phê duyệt",
            UPSERT: "Khớp lại",
            EDIT: "Lưu",
            CENSOR: "Che thông tin",
            CANDIDATE_SF4C: "Thông tin ứng viên trên SuccessFactors",
            YEAR_EXPERIENCE: "Năm kinh nghiệm",
            PICKLIST_MAJOR: "Loại chuyên ngành",
            DEGREE: "Bằng cấp",
            DECENSOR: "Bỏ che thông tin",
            EDIT_CANDIDATE: "Sửa đổi thông tin ứng viên.",
            CHOOSE_GENDER: "Chọn giới tính",
            CHOOSE_CATEGORY: "Chọn loại",
            CHOOSE_SOURCE: "Chọn nguồn",
            CHOOSE_YEAR_EXPERIENCE: "Chọn năm kinh nghiệm",
            START_TIME: "Bắt đầu",
            END_TIME: "Kết thúc",
            START_TIME_CREATE: "Thời gian tạo từ",
            END_TIME_CREATE: "Thời gian tạo tới",
            CHOOSE_INDUSTRY: "Chọn lĩnh vực",
            DETAIL: "Chi tiết",
            DESCRIPTION_JOB: "Mô tả công việc",
            CHOOSE_MAJOR: "Chọn chuyên ngành",
            CHOOSE_DIPLOMA: "Chọn bằng cấp",
            PHONE_NUMBER_PATTERN: "Số điện thoại phải có 10 chữ số và không được chữ.",
            EMAIL_PATTERN: "Email không đúng định dạng",
            UPLOAD_SAP_SF: "Hồ sơ đang được tải lên SAP SF",
            UPDATE_SAP_SF: "Hồ sơ đang được xử lý",
            ADD_TALENT_POOL: "Thêm vào kho",
            PLACEHOLDER:{
              PLACEHOLDER_SELECT_MATCHING: "Công việc"
            },
            GENDER_:{
              MALE: "Nam",
              FEMALE: "Nữ",
            },
            YEAR_EXP_:{
              UNDER_YEAR: "Dưới 1 năm",
              THREE_YEAR: "1 - 3 năm",
              FIVE_YEAR: "3 - 5 năm",
              TEN_YEAR: "5 - 10 năm",
              OVER_TEN_YEAR: "Trên 10 năm",
            },
            TOTAL_YEAR_EXP: "Tổng số năm kinh nghiệm",
            YEAR: "năm",
            MONTH: "tháng",
            CATEGORY_:{
              PRODUCTION: "Sản xuất",
              ENGINEERING: "Kỹ thuật",
              ACCOUNT: "Kế toán / Tài chính",
              CONSTRUCTION: "Xây dựng",
              SERVICES: "Dịch vụ",
              ADMINISTRATION: "Hành chính / Nhân sự",
              SALES: 'Bán hàng / Tiếp thị',
              COMPUTER: 'Máy tính / Công nghệ thông tin',
              MEDIA: 'Truyền thông / Media',
              HOSPITALITY: 'Khách sạn / Du lịch',
              SCIENCE: "Khoa học",
              INDUSTRIES: 'Nhóm ngành khác',
              HEALTHCARE: 'Chăm sóc sức khỏe',
              EDUCATION: 'Giáo dục / Đào tạo',
              CONSUMER: 'Hàng tiêu dùng',
          },
            SHARE: "Chia sẻ",
            REMOVE_SHARE: "Loại bỏ chia sẻ",
            TITLE_SHARE: "Chia sẻ hồ sơ",
            USER: "Người dùng",
            PLACEHOLDER_SHARE: "Lựa chọn người dùng được chia sẻ",
            CONFIRM: "Xác nhận",
            CANCEL: "Hủy",
            SHARED: "Người được chia sẻ",
            SHARE_BY: "Người chia sẻ",
            READ_MORE: "Xem thêm",
            REMOVE_SHARED: "Xóa người dùng được chia sẻ",
            NOT_FOUND: "Hồ sơ không tìm thấy hoặc không có quyền truy cập",
            RETURN_PAGE: "Quay lại"
        },
        TALENT_POOL: {
          TITLE: "Kho ứng viên",
          PLACEHOLDER_SEARCH: "Tìm kiếm theo tên",
          EDIT_FOLDER: "Sửa tên kho ứng viên",
          DELETE_FOLDER: "Xóa kho ứng viên",
          ADD_FOLDER: "Thêm kho ứng viên",
          NAME_FOLDER: "Tên kho ứng viên",
          FOLDER: "Kho ứng viên",
          REQUIRED: "Trường này yêu cầu bắt buộc",
          CREATE: "Tạo",
          EDIT: "Sửa",
          CANCEL: "Hủy",
          REMOVE_RESUMES: "Xóa khỏi kho ứng viên",
          CHANGE_RESUMES: "Thay đổi kho ứng viên",
          CHANGE: "Thay đổi"
        },
        CONNECT_SF4C: {
          TITLE: "Kết nối tới SuccessFactors",
          EDIT_CRED: "Sửa thông tin xác thực",
          UPLOAD_FILE: "Tải lên tệp PEM",
          API_KEY: "Khóa API",
          COMPANY_ID: "Mã công ty",
          USER_NAME: "Tên người dùng",
          TOKEN_URL: "API server URL",
          ACCESS_URL: "Access URL",
          INFO_CREDENTIAL: "Danh sách thông tin xác thực",
          DETAIL_CREDENTIAL: "Thông tin xác thực",
          USER: "Người dùng",
          CLIENT_ID: "Mã khách hàng",
          MODIFIED_AT: "Thời gian cập nhật",
          EXPIRE_IN_DAYS: "Hết hạn sau ngày",
          YOUR_CREDENTIAL: "Chủ sở hữu",
          CREATE_CREDENTIAL: "Tạo thông tin xác thực",
          AUTO_SETTING_CONFIG: "Cấu hình cài đặt tự động",
          MAPPING_SOURCE: "Cấu hình các trường dữ liệu",
          MODAL: {
              ACTION: "Hành động",
              DELETE_C: "Xóa thông tin xác thực",
              CANT_UNDO: "Bạn không thể hoàn tác",
              CANCEL: "Hủy",
              CONFIRM: "Xác nhận",
              DELETE_M: "Xóa mapping picklist"

            },
            TOAST: {
              DELETE_C_SUC: "Xóa thông tin xác thực",
              ERROR: "Lỗi khi thực hiện",
              FAILED: "Thất bại",
              SUCCESS: "Thành công"
            },

      },
      },
      
    }
  };
  