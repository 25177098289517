import { status } from 'app/main/talent-pool/models/resume.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SearchResume } from '../../models/resume.model';
import { CV_task, City } from '../../models/job.model';
import { TalentPoolService } from '../../services/talent-pool.service';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, concat, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
const modalFadeAnimation = trigger("modalFadeAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("500ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [animate("500ms", style({ opacity: 0 }))]),
]);
@Component({
  selector: 'app-recommend-resume',
  templateUrl: './recommend-resume.component.html',
  styleUrls: ['./recommend-resume.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideFade", [
      state(
        "visible",
        style({
          bottom: "20px",
          opacity: 1,
          transform: "translateY(0%)",
        })
      ),
      state(
        "hidden",
        style({
          bottom: "-20px",
          opacity: 0,
          transform: "translateY(100%)",
        })
      ),
      transition("hidden => visible", [animate("0.5s ease-out")]),
      transition("visible => hidden", [animate("0.5s ease-in")]),
    ]),
    modalFadeAnimation,
  ],
})
export class RecommendResumeComponent implements OnInit {

  public resultRecommendCvs: SearchResume[] = []
  public selectedCity: string[] = []
  public company_talentpool: boolean
  public user_talentpool: ''
  public job_title: string = ''
  public city = [];
  public task_loaded: boolean = false;
  public fulltext_bool_expression: string = ''
  public experience_bool_expression: string = ''
  public education_bool_expression: string = ''
  public current_title_bool_expression: string[] = []
  public customTitle: string[] = []

  public current_company_bool_expression: string[] = []
  public customCompany: string[] = []
  full_name_bool_expression: string = ''
  public jobTitle: string = ''
  public taskId: number = 0
  public saved_cvs: any[] = []
  public page_num = 1
  public num_result = 0
  public num_page = 0
  public pageSizeNum = 12
  public jd_id: number = 0
  public errorMessagesRecommendResume!: any
  public loading = false
  public hidden_menu = false
  public hcm_system_jd!: any
  items = 0
  from: any;
  to: any;
  checkSearch: Boolean = false
  INTERVAL_TIME = 5000;
  intervalSubscription: Subscription;
  saveCandidatesForm = this.fb.group({
    checkCandidateArray: this.fb.array([])
  })
  public idCandidates: any[] = []
  public showActionsPopup: boolean = false;
  resultSavedMultiCandidate:any[] = []
  formData: any

  constructor(
    private _router: Router,
    private talentPoolService: TalentPoolService,
    private route: ActivatedRoute,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
  }
  onSubmit() {
    this.page_num = 1
    this.SearchCv()
  }
  SearchCv() {
    this.loading = true
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.num_result = 0;
    this.resultRecommendCvs = []
    let url_dynamic = '?job_description=' + this.jd_id
    let formData = {}
    formData['page_num'] = this.page_num
    formData['from'] = this.from
    formData['to'] = this.to
    formData['city'] = this.selectedCity
    if(this.user_talentpool){
      formData['user_talentpool'] = this.user_talentpool
    }else{
      formData['company_talentpool'] = this.company_talentpool
    }
    if(this.fulltext_bool_expression){
      formData['fulltext_bool_expression'] = this.fulltext_bool_expression
    }
    if(this.full_name_bool_expression){
      formData['full_name_bool_expression'] = this.full_name_bool_expression
    }
    if(this.education_bool_expression){
      formData['education_bool_expression'] = this.education_bool_expression
    }
    if(this.experience_bool_expression){
      formData['experience_bool_expression'] = this.experience_bool_expression
    }
    if(this.current_title_bool_expression){
      formData['current_title_list'] = this.current_title_bool_expression
    }
    if(this.current_company_bool_expression){
      formData['current_company_list'] = this.current_company_bool_expression
    }
    this.formData = formData
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
    const api$ = this.talentPoolService.recommend_resume(url_dynamic, formData)
    const intervalAfterCallApi = interval(this.INTERVAL_TIME).pipe(
      switchMap(() =>
        this.talentPoolService.recommend_resume(url_dynamic, formData)
      )
    );
    this.intervalSubscription = concat(api$, intervalAfterCallApi).subscribe(
      (res) => {
        this.resultRecommendCvs = res.result;
        if(res.stop_refresh){
          this.intervalSubscription.unsubscribe()
        }
        this.talentPoolService.setNewListCandidate(res.result)
        this.num_result = res.num_result;
        this.num_page = res.num_result;
        this.checkSearch = true
        this.saveFilter();
        this.loading = false
      },
      (err) => {
        this.toastr.error(err?.error?.message)
        this.intervalSubscription.unsubscribe()
        this.errorMessagesRecommendResume = err
        this.checkSearch = true
        this.loading = false
      }
    )
  }
  pageChangeEventGetCv(event: number) {
    this.page_num = event;
    this.saveFilter();
    this.SearchCv();
  }
  GetTask() {
    this.jd_id = Number(this.route.snapshot.paramMap.get('id'))
    this.talentPoolService.setNewIdJd(this.jd_id.toString())
    this.talentPoolService.detail_jd(this.jd_id).subscribe(
      (res) => {
        this.jobTitle = res.job_title
        this.task_loaded = true
        this.taskId = res.id
        this.items = res.num_saved_candidates
        this.hcm_system_jd = res.hcm_system_jd
      }
    )
  }

  addItem() {
    this.items = this.items + this.resultSavedMultiCandidate.filter((e)=>e.status=='Success').length;
  }
  ngOnDestroy(): void {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }
  SaveResumeByJd() {
    this.talentPoolService.save_resume_by_jd(this.jd_id, this.idCandidates).subscribe(
      (res) => {
        this.resultSavedMultiCandidate = res.message
        this.addItem();
        this.unselectAllRows();
      },
      (err) => {
        err.error.detail ? this.toastr.error(this._translateService.instant("TALENT_POOL.SEARCH_RESUME.NOT_FOUND")) : this.toastr.error(err.error.message)
      }
    )
  }
  selectAll() {
    this.idCandidates = this.resultRecommendCvs.map(x => x.id)
    if (this.idCandidates.length > 0) {
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
    }
  }
  unSelectAll() {
    this.idCandidates = []
    if (this.idCandidates.length > 0) {
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
    }
  }
  unselectAllRows() {
    this.idCandidates = [];
    this.showActionsPopup = false;
    this.SearchCv()
  }
  onCheckboxChange(e: any) {
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe()
    let checkCandidateArray: FormArray = this.saveCandidatesForm.get('checkCandidateArray') as FormArray;
    if (e.target.checked) {
      checkCandidateArray.push(new FormControl(e.target.value));
      this.idCandidates.push(e.target.value)
    } else {
      let i: number = 0;
      checkCandidateArray.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkCandidateArray.removeAt(i);
          this.idCandidates = this.idCandidates.filter((item) => item !== e.target.value)
          return;
        }
        i++;
      })
    }

    if (this.idCandidates.length > 0) {
      this.showActionsPopup = true;
    } else {
      this.showActionsPopup = false;
    }
  }
  resetTabTemporaryData() {
    this.idCandidates = [];
    this.showActionsPopup = false;
  }
  saveFilter() {
    this.talentPoolService.setFilterCandidatesPageRecommendValue(
      this.page_num,
      this.fulltext_bool_expression,
      this.full_name_bool_expression,
      this.experience_bool_expression,
      this.education_bool_expression,
      this.current_title_bool_expression,
      this.current_company_bool_expression
    );
  }
  ngOnInit(): void {
    this.GetTask()
    this.talentPoolService.optionFilterRecommendForm.subscribe((res) => {
      this.from = res['from']
      this.to = res['to']
      this.selectedCity = res['selectedCity']
      if(res['company_talentpool']){
        this.company_talentpool = res['company_talentpool']
      }
      if(res['user_talentpool']){
        this.user_talentpool = res['user_talentpool']
      }

    })
    const { pageRecommend, fulltext_bool_expression, full_name_bool_expression, experience_bool_expression
      ,education_bool_expression, current_title_bool_expression, current_company_bool_expression
    } = this.talentPoolService.getFilterCandidatesPageRecommendValue();
    this.page_num = pageRecommend || 1;
    this.fulltext_bool_expression = fulltext_bool_expression || null;
    this.full_name_bool_expression = full_name_bool_expression || null;
    this.experience_bool_expression = experience_bool_expression || null;
    this.education_bool_expression = education_bool_expression || null;
    this.current_title_bool_expression = current_title_bool_expression || [];
    this.current_company_bool_expression = current_company_bool_expression || [];
    this.SearchCv()
    City.sort((a, b) => a.localeCompare(b, "fr", { ignorePunctuation: true }));
    let city = [{ "name": 'Hà Nội', "value": "Hà Nội" }, { "name": 'Hồ Chí Minh', "value": "Hồ Chí Minh" }, { "name": 'Đà Nẵng', "value": "Đà Nẵng" }]
    for (let i = 0; i < City.length; i++) {
      city.push({ "name": City[i], "value": City[i] })
    }
    this.city = city
  }

}
