import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { User } from 'app/auth/models';
import { ActivityStat, CompanyActivity, ResultDetailResumeMatching, ResultPackage, Stat, StatResumeMatching } from 'app/main/resume-parser/models/resume.model';
import { DashboardService } from '../services/dashboard.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { locale as enLocale } from "../i18n/en";
import { locale as vnLocal } from "../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from 'app/main/resume-parser/models/paging.model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticsComponent implements OnInit {
  public activityStat = new ActivityStat()
  public companyActivity: ResultPackage[] = []
  public trialActivity: ResultPackage[] = []
  public companyActivityCurrent: any[]=[]
  public userActivityCurrent: any[]=[]
  public ColumnMode = ColumnMode;
  widthColumn: number = 0;
  public pageCompany = new Page({});
  public pageTrial = new Page({});
  public pageCompanyActivityCurrent = new Page({});
  public pageUserActivityCurrent = new Page({});

  public url_dynamic: string

  @ViewChild(DatatableComponent) tableCompany: DatatableComponent;
  @ViewChild(DatatableComponent) tableTrial: DatatableComponent;
  @ViewChild(DatatableComponent) tableCompanyActivityCurrent: DatatableComponent;
  @ViewChild(DatatableComponent) tableTrialActivityCurrent: DatatableComponent;
   //Period
   public companys = [{id:19, name:""}]
   FilterStatForm = this.fb.group({
     company: new FormControl(''),
     period: new FormControl([]),
   })
  public user = new User()
  @ViewChild('packageChartRef') packageChartRef: any;
  @ViewChild('activeChartRef') activeChartRef: any;
  @ViewChild('revenueChartRef') revenueChartRef: any;


  public packageChartoptions;
  public activeChartOptions;
  public revenueChartOptions;
  public displayLast7day = false
  public is_superuser = false
  public errorMessagesStat: any
  //matching
  // public pageCompany = 1
  // public pageSizeCompany = 5
  // public totalItemsCompany = 0
  // public pageSizesCompany = [5, 10, 15]
  // public view_resume_check = -1
  // public view_resume = false
  // view_detail_resume(i: any){
  //   this.view_resume_check = i
  //   this.view_resume = !this.view_resume
  // }
  public isLoading = false
  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private elementRef: ElementRef,
    private toastr: ToastrService,

  ) {
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.getPackage()
    this.getActive()
    this.getRevenue()
    // this.getStatusUploadSuccessFactors();
    // this.getSuccessfactorStatusByDay()
   }
   getPackage(){
    this.packageChartoptions = {
      chart: {
        type: 'donut',
        height: 400,
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      series: [],
      legend: { show: false },
      comparedResult: [2, -3, 8],
      labels: [],
      stroke: { width: 0 },
      colors: ['#219cd7', '#28c76f','#ff9f43'],
      grid: {
        padding: {
          right: -20,
          bottom: -8,
          left: -20
        }
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 15
              },
              value: {
                offsetY: -15,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
              // total: {
              //   show: true,
              //   offsetY: 15,
              //   label: 'admin',
              //   formatter: function (w) {
              //     return parseInt(w);
              //   }
              // }
            }
          }
        }
      },
    };
  }
  getActive(){
    this.activeChartOptions = {
      series: [
        {
          name: "",
          data: []
        },
        {
          name: "",
          data: []
        }
      ],
      chart: {
        height: 400,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: ['#fff'],
        colors: ['#219cd7','#28c76f']
      },
      colors: ['#219cd7','#28c76f'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      xaxis: {
        title: {text: this._translateService.instant('DASHBOARD.XAXIS_TITLE')},
        categories: [
          
        ]
      },
      yaxis: {
        title: {text: this._translateService.instant('DASHBOARD.YAXIS_COUNT_NUMBER')}
        
      },
      tooltip: {
        shared: false,
        custom: function (data) {
          return (
            '<div class="px-1 py-50">' +
            '<span>' +
            data.series[data.seriesIndex][data.dataPointIndex] +
            '</span>' +
            '</div>'
          );
        }
      }
    };
  }
  getRevenue(){
    this.revenueChartOptions = {
      series: [
        {
          name: "",
          data: []
        },
      ],
      chart: {
        height: 400,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: ['#fff'],
        colors: ['#219cd7']
      },
      colors: ['#219cd7'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      xaxis: {
        title: {text: this._translateService.instant('DASHBOARD.XAXIS_TITLE')},
        categories: [
          
        ]
      },
      yaxis: {
        title: {text: this._translateService.instant('DASHBOARD.YAXIS_REVENUE')}
      },
      tooltip: {
        custom: function (data) {
          return (
            '<div class="px-1 py-50">' +
            '<span>' +
            data.series[data.seriesIndex][data.dataPointIndex] +
            '</span>' +
            '</div>'
          );
        }
      }
    };
  }
  // getStatusUploadSuccessFactors(){
  //   this.statusUploadSuccessFactorsChartoptions = {
  //     series: [
  //       {
  //         name: '',
  //         data: []
  //       },
  //       {
  //         name: '',
  //         data: []
  //       }
  //     ],
  //     chart: {
  //       type: 'bar',
  //       height: 400,
  //       stacked: false,
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     grid: {
  //       xaxis: {
  //         lines: {
  //           show: true
  //         }
  //       }
  //     },
  //     legend: {
  //       show: false,
  //       position: 'bottom',
  //       horizontalAlign: 'center'
  //     },
  //     plotOptions: {
  //       bar: {
  //         distributed: true,
  //         columnWidth: '15%',
  //         colors: {
  //           backgroundBarColors: [
              
  //           ],
  //           backgroundBarRadius: 10
  //         }
  //       }
  //     },
  //     colors: [],
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ['transparent']
  //     },
  //     xaxis: {
  //       categories: []
  //     },
  //     yaxis: {
  //       title: {
  //         text: ''
  //       }
  //     },
  //     fill: {
  //       opacity: 1
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: function (val) {
  //           return val;
  //         }
  //       }
  //     }
  //   };
  // }
  // getSuccessfactorStatusByDay(){
  //   this.resumeStatusUploadedSf4cByDayChartoptions = {
  //     series: [
  //       {
  //         data: []
  //       }
  //     ],
  //     chart: {
  //       height: 400,
  //       type: 'line',
  //       zoom: {
  //         enabled: false
  //       },
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     grid: {
  //       xaxis: {
  //         lines: {
  //           show: true
  //         }
  //       }
  //     },
  //     markers: {
  //       strokeWidth: 7,
  //       strokeOpacity: 1,
  //       strokeColors: ['#fff'],
  //       colors: ['FF9F43']
  //     },
  //     colors: ['FF9F43'],
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       curve: 'smooth'
  //     },
  //     xaxis: {
  //       categories: [
          
  //       ]
  //     },
  //     tooltip: {
  //       custom: function (data) {
  //         return (
  //           '<div class="px-1 py-50">' +
  //           '<span>' +
  //           data.series[data.seriesIndex][data.dataPointIndex] +
  //           '</span>' +
  //           '</div>'
  //         );
  //       }
  //     }
  //   };
  // }
  getCompanyActivityCurrent(){
    this.dashboardService.companyActivityCurrent().subscribe(
      (res)=>{
        this.companyActivityCurrent = [res]
        const currentPageNumber = this.pageCompany.pageNumber;
        const currentPageSize = this.pageCompany.size;
        this.pageCompanyActivityCurrent = new Page({
          size: currentPageSize,
          totalElements: this.companyActivityCurrent.length,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        this.toastr.error(err.error.message)
      }
    )
  }
  getUserActivityCurrent(){
    this.dashboardService.userActivityCurrent().subscribe(
      (res)=>{
        this.userActivityCurrent = [res]
        const currentPageNumber = this.pageCompany.pageNumber;
        const currentPageSize = this.pageCompany.size;
        this.pageUserActivityCurrent = new Page({
          size: currentPageSize,
          totalElements: this.userActivityCurrent.length,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        this.toastr.error(err.error.message)
      }
    )
  }
  changeCompany(e: any) {
    this.company?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  get company() {
    return this.FilterStatForm.get('company');
  }
  get period():any{
    return this.FilterStatForm.get('period')
  }
  onSubmit(){
    this.FilterStat();
    
  }
  onReset(){
    this.displayLast7day = true
    this.period.reset()
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(company!=undefined&& company!=null&&company!=''){
      url_dynamic = url_dynamic+ "&company="+company
    }
    this.statistic(url_dynamic)
  }
  FilterStat(){
    let url_dynamic = ""
    let company= this.FilterStatForm.value.company
    if(this.FilterStatForm.value.period!=undefined){
      
      let start_time_raw = this.FilterStatForm.value.period[0]
      let end_time_raw = this.FilterStatForm.value.period[1]

      let gmtOffsetStart = 7;
      let gmtOffsetEnd = 31;
      
      // if(start_time_raw!=undefined && end_time_raw!=undefined && new Date(new Date(start_time_raw?.getFullYear(),start_time_raw?.getMonth(),start_time_raw?.getDate())?.getTime())?.toISOString()==new Date(new Date(end_time_raw?.getFullYear(),end_time_raw?.getMonth(),end_time_raw?.getDate())?.getTime())?.toISOString()){
      //   gmtOffsetEnd = 31;
      // }else{
      //   gmtOffsetStart = 7;
      //   gmtOffsetEnd = 7;

      // }
      let start_time = this.FilterStatForm.value.period[0]==undefined?undefined:new Date(new Date(start_time_raw.getFullYear(),start_time_raw.getMonth(),start_time_raw.getDate()).getTime()+ gmtOffsetStart * 60 * 60 * 1000).toISOString()
      let end_time = this.FilterStatForm.value.period[1]==undefined?undefined:new Date(new Date(end_time_raw.getFullYear(),end_time_raw.getMonth(),end_time_raw.getDate()).getTime()+ gmtOffsetEnd * 60 * 60 * 1000).toISOString()
      if(company!=undefined&& company!=null&&company!=''){
        url_dynamic = url_dynamic+ "&company="+company
      }
      if(start_time!=undefined&& start_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&start="+start_time
      }
      if(end_time!=undefined&& end_time!=null){
        this.displayLast7day = false
        url_dynamic =url_dynamic+ "&end="+end_time
      }
      this.statistic(url_dynamic);
    }else{
      this.statistic(url_dynamic);
    }
    
    
  }
  ListCompany(){
    this.authService.list_company().subscribe(
      (res)=>{
        this.companys = res.results
      },
      (err)=>{
        console.log("err",err)
      }
    )
  }
  statistic(url_dynamic:string){
    this.isLoading = true;
    this.dashboardService.getStatActivity(url_dynamic).subscribe(
      (res)=>{
        this.isLoading = false;
        this.activityStat = res;
        let label_package = []
        let value_package = []
      //pie chart group package
      if(this.activityStat?.group_package?.length>0){
        for(let i=0; i<this.activityStat?.group_package?.length;i++){
          label_package.push(this.activityStat.group_package[i].name.replace("_"," "))
          value_package.push(this.activityStat.group_package[i].total)
        }
      }else{
        label_package=[]
        value_package=[]
      }
      let backgroundColorPackage = []
      for(let i=0;i<label_package.length;i++){
        let randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      // Ensure the random color is always six characters long
      while (randomColor.length < 7) {
        randomColor += "0";
      }
      backgroundColorPackage.push(randomColor);
      }
      this.packageChartoptions.labels = label_package;
      this.packageChartoptions.series = value_package;
      this.packageChartoptions.colors = backgroundColorPackage;
      let label_active = []
      let value_parsing_active = []
      let value_matching_active = []

      if(this.activityStat.active_user_by_day.length>0){
        for(let i=0; i<this.activityStat.active_user_by_day.length;i++){
          label_active.push(this.activityStat.active_user_by_day[i].index)
          value_parsing_active.push(this.activityStat.active_user_by_day[i].parsing_active_user)
          value_matching_active.push(this.activityStat.active_user_by_day[i].matching_active_user)
        }
      }else{
        label_active=[]
        value_parsing_active=[]
        value_matching_active=[]
      }
      this.activeChartOptions.xaxis.categories = label_active;
      this.activeChartOptions.series[0].name = "Parsing active user";
      this.activeChartOptions.series[1].name = "Matching active user";

      this.activeChartOptions.series[0].data = value_parsing_active;
      this.activeChartOptions.series[1].data = value_matching_active;
      let label_revenue = []
      let value_revenue = []

      if(this.activityStat.revenue_per_day.length>0){
        for(let i=0; i<this.activityStat.revenue_per_day.length;i++){
          label_revenue.push(this.activityStat.revenue_per_day[i].index)
          value_revenue.push(this.activityStat.revenue_per_day[i].revenue)
          
        }
      }else{
        label_revenue=[]
        value_revenue=[]
      }
      this.revenueChartOptions.xaxis.categories = label_revenue;
      this.revenueChartOptions.series[0].name = "Revenue";
      this.revenueChartOptions.series[0].data = value_revenue;

    },
    (err)=>{
      console.log(err)
    } 
    );
  }
  getCompanyActivity(url_dynamic: string){
    this.dashboardService.companyAcivity(url_dynamic, this.pageCompany.pageNumber,this.pageCompany.size).subscribe(
      (res)=>{
        this.companyActivity = res.results
        const currentPageNumber = this.pageCompany.pageNumber;
        const currentPageSize = 5;
        this.pageCompany = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
        
      },
      (err)=>{
        console.log(err)
      }
    );
  }
  setPageCompany(pageInfo) {
    this.pageCompany.pageNumber = pageInfo.offset;
    this.getCompanyActivity(this.url_dynamic)
  }
  setPageCompanyActivityCurrent(pageInfo) {
    this.pageCompany.pageNumber = pageInfo.offset;
    this.getCompanyActivityCurrent()
  }
  setPageUserActivityCurrent(pageInfo) {
    this.pageCompany.pageNumber = pageInfo.offset;
    this.getUserActivityCurrent()
  }
  getTrialActivity(url_dynamic: string){
    this.dashboardService.trialActivity(url_dynamic, this.pageTrial.pageNumber,this.pageTrial.size).subscribe(
      (res)=>{
        this.trialActivity = res.results
        const currentPageNumber = this.pageTrial.pageNumber;
        const currentPageSize = 5;
        this.pageTrial = new Page({
          size: currentPageSize,
          totalElements: res.count,
          pageNumber: currentPageNumber,
        });
      },
      (err)=>{
        console.log(err)
      }
    )
  }
  setPageTrial(pageInfo) {
    this.pageTrial.pageNumber = pageInfo.offset;
    this.getTrialActivity(this.url_dynamic)
  }
  ngOnInit(): void {
    
    this.displayLast7day = true
    this.user = this._authenticationService.currentUserValue
    let url_dynamic = ""
    if(this.user.is_superuser){
      this.is_superuser = true
      this.ListCompany()
      
      this.statistic(url_dynamic);
      this.getCompanyActivity(url_dynamic);
      this.getTrialActivity(url_dynamic);
    }else if(this.user.is_site_admin || this.user.role=="User"){
      this.getCompanyActivityCurrent()
    }else if(this.user.role=="Free trial user"){
      this.getUserActivityCurrent()
    } 
  }


}
