import { status } from './../../models/resume.model';
import { Subject, Subscription, interval } from 'rxjs';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ListResumeMatching, ResumeMatching } from '../../models/resume.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { TalentPoolService } from '../../services/talent-pool.service';
import { switchMap } from 'rxjs/operators';
import { locale as enLocale } from "../../i18n/en";
import { locale as vnLocal } from "../../i18n/vn";
import { TranslateService } from '@ngx-translate/core';
import { CoreTranslationService } from '@core/services/translation.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-resume-matching',
  templateUrl: './resume-matching.component.html',
  styleUrls: ['./resume-matching.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResumeMatchingComponent implements OnInit {
  public MatchingForm: FormGroup;
  public MatchingFileForm: FormGroup;
  submitted = false
  isLoading = false
  resultMatching:any
  @ViewChild('InputVar')
  myInputVariable!: ElementRef;
  constructor(
    private fb: FormBuilder,
    private talentpoolService: TalentPoolService,
    public _translateService: TranslateService,
    public _coreTranslationService: CoreTranslationService,
    private toastr: ToastrService,

  ) {
    this.resultMatching = {}
    this._coreTranslationService.translate(enLocale);
    this._coreTranslationService.translate(vnLocal);
    this.MatchingForm = this.fb.group({
      url: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      requirements: ['', Validators.required],
      mode: ['dev']
    });
    this.MatchingFileForm = this.fb.group({
      file: [null, Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      requirements: ['', Validators.required],
    })
   }
  get f(){
    return this.MatchingForm.controls;
  }
  get ff(){
    return this.MatchingFileForm.controls;
  }
  tabFile(){
    this.MatchingFileForm.reset()
    this.resultMatching = null
  }
  tabLink(){
    this.MatchingForm.reset()
    this.resultMatching = null

  }
  onSubmit(){
    this.submitted = true;
    if(this.MatchingForm.invalid){
      return;
    }
    this.isLoading = true;
    const formData: any = {}
    let job_description = {
      title: this.f.title.value,
      description: this.f.description.value,
      requirements: this.f.requirements.value
    }
    formData["url"] = this.f.url.value;
    formData["job_description"] = job_description;
    formData["mode"] = this.f.mode.value
    this.talentpoolService.matching_cv_linkJD(formData).subscribe(
      (res)=>{
        this.getDetailTask(res.id)
        // this.MatchingForm.reset()
        this.submitted = false
      },
      (err)=>{
        this.toastr.warning(err.error.message)
        this.isLoading = false
        this.submitted = false

      }
    )

  }
  //connect sf4c
  selectedFiles?: FileList;

  onFileSelect(event: any) {
    const file: File = event.target.files[0];
    if (file.type === 'application/zip' || file.name.endsWith('.zip')||file.type === 'application/pptx' || file.name.endsWith('.pptx')) {
      // Clear the file input
      event.target.value = '';
      return;
    }else{
      this.selectedFiles = event.target.files;
      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);
        if(file){
          // const file = event.target.files.item(0);
          this.MatchingFileForm.patchValue({ file: file });
          }
        }
      }
    }
    
  onSubmitMatchingFile(){
    this.submitted = true;
    if(this.MatchingFileForm.invalid){
      return;
    }
    this.isLoading = true;
    let job_description = {
      title: this.ff.title.value,
      description: this.ff.description.value,
      requirements: this.ff.requirements.value
    }
    let job_description_cp = JSON.stringify(job_description)
    console.log(this.MatchingFileForm.value.file)
    let file = this.MatchingFileForm.value.file
    this.talentpoolService.matching_cv_fileJD(file, job_description_cp).subscribe(
      (res)=>{
        this.getDetailTask(res.id)
        // this.MatchingFileForm.reset()
        this.submitted = false

      },
      (err)=>{
        console.log(err.error.error)
        this.toastr.warning(err.error.message)
        this.isLoading = false
        this.submitted = false

      }
    )

  }
  getDetailTask(id: any){
    this.talentpoolService.getDetailTask(id).subscribe(
      (res)=>{
        if(res.status!='Done' && res.status!='Failure'){
          this.getDetailTask(res.id)
          // this.toastr.success("Matching cv link JD success")
        }
        this.isLoading = false
        this.resultMatching = res
        
      },
      (err)=>{
        this.toastr.error(err.error.message)
        this.isLoading = false
      }
    )
  }
  gaugeColor(value:number) {
    if (value > 80) {
      return '#198754'
    } else if(value > 50) {
      return '#ffc107'
    } else{
      return '#dc3545'
    }
  }
  ngOnInit(): void {

  }

}
